import { CheckCircleIcon } from "@heroicons/react/solid";
import { useLocation } from "react-router-dom";
import { currencyFormatter } from "../../../utils/helpers";
import format from "date-fns/format";
import { useRecoilValue } from "recoil";
import { currentAccountState } from "../../../components";
import { PurchaseRequestMeter } from "./types";

export default function SubmitPurchaseRequest() {
  const { getCurrentAccount } = useRecoilValue(currentAccountState);

  const { state } = useLocation();

  const submitDate = format(new Date(), "yyyy-MM-dd HH:mm:ss");
  return (
    <>
      <div className="w-full">
        <div className="max-w-3xl mx-auto grid grid-cols-1 gap-6  lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2">
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            {/* Description list*/}
            <section aria-labelledby="applicant-information-title">
              <div className="bg-white py-4 px-4 shadow shadow-sm ">
                <div className="px-4 pb-5  flex w-full">
                  <h2
                    id="applicant-information-title"
                    className="text-lg leading-6 font-medium text-green-500"
                  >
                    Purchase Request Submitted For Approval
                  </h2>
                  <CheckCircleIcon
                    className="hidden flex-shrink-0 ml-1 h-6 w-6 text-green-500 lg:block"
                    aria-hidden="true"
                  />
                </div>

                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <div className=" pb-5">
                    <h2
                      id="applicant-information-title"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Purchase Request Details
                    </h2>
                  </div>
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-700">
                        Purchase Request Amount
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {currencyFormatter(
                          (state as PurchaseRequestMeter[]).reduce(
                            (acc, meter) => {
                              return acc + +meter.purchaseAmount;
                            },
                            0
                          )
                        )}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-700">
                        Items in Request
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {(state as PurchaseRequestMeter[]).length}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-700">
                        Submitted By
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {getCurrentAccount().displayName}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-700">
                        Date of Submission
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        <time dateTime={submitDate}>{submitDate}</time>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

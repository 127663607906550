/* eslint-disable react-hooks/exhaustive-deps */
import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon, CheckIcon } from "@heroicons/react/outline";
import { Fragment, useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState, useRecoilValue } from "recoil";
import { currentAccountState } from "../../../../components";
import { Validation } from "../../../../protocols";

import { classNames } from "../../../../utils/helpers";
import { addMeterState, meterCompaniesListState } from "../atoms/atoms";
import Input from "./input";

type Props = {
  validation: Validation;
};

export default function AddMeterDetails({ validation }: Props) {
  const resetLoginState = useResetRecoilState(addMeterState);
  const [state, setState] = useRecoilState(addMeterState);
  const { getCurrentAccount } = useRecoilValue(currentAccountState);
  const companiesList = useRecoilValue(meterCompaniesListState).companiesList;
  // const [newMeters, setNewMeters] = useRecoilState(addMetersState);
  const [selectedCompany, setSelectedCompany] = useState("Select Company");

  const validate = (field: string): void => {
    const { meterName, meterLocation } = state;
    const formData = {
      meterName,
      meterLocation,
    };
    setState((old) => ({
      ...old,
      [`${field}Error`]: validation.validate(field, formData),
    }));

    setState((old) => ({
      ...old,
      isFormInvalid:
        !old.meterName || !old.meterLocation || !state.companyNumber,
    }));
  };
  const [showError, setShowError] = useState(false);

  useEffect(() => resetLoginState(), []);
  useEffect(() => validate("meterName"), [state.meterName]);
  useEffect(() => validate("meterLocation"), [state.meterLocation]);
  // useEffect(() => validate("companyNumber"), [state.companyNumber]);

  const handleAddMeterDetailsSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    // validate("meterName");
    // validate("meterLocation");
    // validate("companyNumber");
    if (
      state.isLoading ||
      !state.meterName ||
      !state.meterLocation ||
      !state.companyNumber
    ) {
      setShowError(true);
      return;
    }

    const item = {
      account: getCurrentAccount().account,
      createdBy: getCurrentAccount().email,
      createdByName: getCurrentAccount().displayName,
      dateCreated: new Date(),
      dateUpdated: new Date(),
      emailContact: getCurrentAccount().email,
      isActive: false,
      location: state.meterLocation,
      meterName: state.meterName,
      meterNumber: state.currentValidatedMeter.meterDetail.msno,
      meterStatus: "PENDING",
      ipAddress: "41.18.20.78",
      companyNumber: state.companyNumber,
      company: state.company,
      registeredOwner: state.currentValidatedMeter.custVendDetail.name,
    };

    console.log("item", item);

    // const setMessages = useSetRecoilState(messages);
    // setMessages((currentState) => [...currentState, newMessage]);

    let newMetersArray = [...state.metersToAdd];
    console.log("newMetersArray", newMetersArray);
    if (item !== null) {
      newMetersArray = [...newMetersArray, item];
    }

    setState((old) => ({ ...old, isLoading: false, isFormInvalid: false }));

    setState({
      ...state,
      metersToAdd: newMetersArray,
      currentValidatedMeter: null,
    });
    // return item ? item : null;
  };

  const handleAddMeterDetailsCancel = (e: React.SyntheticEvent) => {
    e.preventDefault();

    setState({
      ...state,
      meterNumber: "",
      currentValidatedMeter: null,
      validateMeterFailed: false,
    });
  };

  const handleCompanyChange = (value: string) => {
    // event.preventDefault();
    let company: string;

    console.log("companyNumber", value);
    if (value === "Not Registered") {
      setSelectedCompany("None");
      company = "None";
    } else {
      company = companiesList.find((c) => c.companyNumber === value).name;
      setSelectedCompany(company);
    }

    setState((old) => ({
      ...old,
      company: company,
      companyNumber: value,
      companyNumberError: undefined,
    }));
    console.log("state", state);
  };

  return state.currentValidatedMeter ? (
    <form
      className="flex-grow flex flex-col"
      onSubmit={(e) => handleAddMeterDetailsSubmit(e)}
    >
      <div className="-my-2 flex-grow bg-transparent ">
        <div className="pb-5">
          <h2
            id="add-meter-detail"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            Meter Details - {state.currentValidatedMeter.meterDetail.msno}
          </h2>
        </div>
        <div className="bg-transparent">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-6">
              <label
                htmlFor="registeredOwner"
                className="block text-sm font-medium text-gray-700"
              >
                Registered Owner
              </label>
              <span className="mt-1 text-sm text-gray-500">
                {state.currentValidatedMeter.custVendDetail.name}
              </span>
            </div>

            <div className="grid grid-cols-12 gap-4 col-span-6">
              <div className="col-span-5">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Select Company...
                </label>

                <Listbox value={selectedCompany} onChange={handleCompanyChange}>
                  {({ open }) => (
                    <>
                      <div className="relative">
                        <Listbox.Button className="mt-1 bg-gray-50 relative w-full border border-gray-300 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                          <span className="block truncate">
                            {selectedCompany}
                          </span>
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60  py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                            <Listbox.Option
                              key={0}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "text-white bg-blue-600"
                                    : "text-gray-900",
                                  "cursor-default select-none relative py-2 pl-3 pr-9"
                                )
                              }
                              value="Not Registered"
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={classNames(
                                      selected
                                        ? "font-semibold"
                                        : "font-normal",
                                      "block truncate"
                                    )}
                                  >
                                    {`None`}
                                  </span>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active ? "text-white" : "text-blue-600",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                            {companiesList.map((company, index) => (
                              <Listbox.Option
                                key={index}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "text-white bg-blue-600"
                                      : "text-gray-900",
                                    "cursor-default select-none relative py-2 pl-3 pr-9"
                                  )
                                }
                                value={company.companyNumber}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "block truncate"
                                      )}
                                    >
                                      {`${company.name}`}
                                    </span>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-blue-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
                {showError && (
                  <div className=" block text-sm text-red-500">
                    {state.companyNumberError && state.companyNumberError}
                  </div>
                )}
              </div>
            </div>

            <div className="col-span-6">
              <label
                htmlFor="emailAddress"
                className="block text-sm font-medium text-gray-700"
              >
                Meter Name
              </label>
              <Input
                type="text"
                name="meterName"
                className="mt-1 block  placeholder-gray-300 font-medium rounded-sm py-2 mr-4 border border-gray-300 bg-white focus:border-blue-600 focus:ring-0 sm:text-sm"
                placeholder="Meter Name"
              />
              {showError && (
                <div className=" block text-sm text-red-500">
                  {state.meterNameError && state.meterNameError}
                </div>
              )}
            </div>

            <div className="col-span-6">
              <label
                htmlFor="meterLocation"
                className="block text-sm font-medium text-gray-700"
              >
                City/Village
              </label>
              <Input
                type="text"
                name="meterLocation"
                className="mt-1 block  placeholder-gray-300 font-medium rounded-sm py-2 mr-4 border border-gray-300 bg-white focus:border-blue-600 focus:ring-0 sm:text-sm"
                placeholder="Meter Location"
              />
              {showError && (
                <div className=" block text-sm text-red-500">
                  {state.meterLocationError && state.meterLocationError}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
          >
            Create User
          </button>
        </div> */}

        <div className="mt-8 flex items-center">
          <div className="flex justify-end">
            <button
              onClick={(e) => handleAddMeterDetailsCancel(e)}
              className="bg-white py-2 px-6 border border-2 border-red-500 rounded-sm shadow-sm text-sm font-medium text-red-500 hover:bg-gray-50 focus:outline-none "
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-6 border border-transparent rounded-sm shadow-sm disabled:opacity-50 text-sm font-medium  text-white bg-blue-600 hover:bg-blue-700  active:bg-indingo-800 focus:outline-none "
            >
              Add Meter
            </button>
          </div>
        </div>
      </div>
    </form>
  ) : null;
}

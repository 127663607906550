import { atom, selector } from "recoil";
import { RemotePurchaseRequestModel } from "../../../../data/models";
import { CellphoneModel } from "../../../../domain/models/cellphone-model";
import { MeterModel } from "../../../../domain/models/meter-model";
import { SmartcardModel } from "../../../../domain/models/smartcard-model";
import { PurchaseRequestType } from "../../../../types/purchaseRequestType";

export const pendingMeterApprovalsState = atom({
  key: "pendingMeterApprovalsState",
  default: {},
});

export const VATDetailsState = atom({
  key: "VATDetailsState",
  default: {
    pendingApprovals: [] as PurchaseRequestType[],
  },
});

export const pendingPurchaseRequestsState = atom({
  key: "pendingPurchaseRequestsState",
  default: {
    pendingApprovals: [] as PurchaseRequestType[],
  },
});

export const pendingApprovalsState = atom({
  key: "pendingApprovalsState",
  default: {
    pendingPurchaseRequests: [] as RemotePurchaseRequestModel[],
    pendingMeterApprovals: [] as MeterModel[],
    pendingCellphoneApprovals: [] as CellphoneModel[],
    pendingSmartcardApprovals: [] as SmartcardModel[],
    requestsChecked: false,
    metersChecked: false,
    cellphonesChecked: false,
    smartcardsChecked: false,
    avaliableBalance: 0,
    reload: false,
    isLoading: false,
    error: "",
  },
});

export const pendingPurchaseAmount = selector({
  key: "pendingPurchaseAmount",
  get: ({ get }) => {
    const pendingPurchaseRequests = get(
      pendingApprovalsState
    ).pendingPurchaseRequests;

    const amount = pendingPurchaseRequests.reduce((acc, req) => {
      return acc + req.purchaseRequestTotal;
    }, 0);
    return amount;
  },
});

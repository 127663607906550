import {
  ValidationComposite,
  ValidationBuilder as Builder,
} from "../../../../../validation/validators";

export const makeValidateAddMeterDetailsValidation = (): ValidationComposite =>
  ValidationComposite.build([
    ...Builder.field("meterName").required().build(),
    ...Builder.field("meterLocation").required().build(),
    ...Builder.field("companyNumber").required().build(),
  ]);

import { makeRemoteGetSmartcardsByStatus } from "../../usecases/smartcard/remote-get-smartcards-by-status-factory";
import PurchaseRequestLayout from "../../../../presentation/pages/purchaseRequest/dstv/purchaseRequest";

export const makeDSTVPurchaseRequest = () => {
  console.log("makeDSTVPurchaseRequest");
  return (
    <PurchaseRequestLayout
      getActiveSmartcards={makeRemoteGetSmartcardsByStatus()}
    />
  );
};

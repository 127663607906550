import { GetSmartcardsState } from "../../../../../domain/usecases/get-smartcard";
import AmendSmartcardDetails from "../../../../../presentation/pages/smartcards/manage-smartcards/components/amendSmartcardDetails";
import { makeRemoteAmendSmartcard } from "../../../usecases/smartcard/remote-amend-smartcard-factory";
import { makeValidateAmendSmartcardDetailsValidation } from "./validate-amend-smartcard-details-validation-factory";

export const makeAmendSmartcardDetails = (smartcard: GetSmartcardsState.Model) => {
  return (
    <AmendSmartcardDetails
      amendSmartcard={makeRemoteAmendSmartcard()}
      validation={makeValidateAmendSmartcardDetailsValidation()}
      smartcard={smartcard}
    />
  );
};

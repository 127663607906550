import { useRecoilState } from "recoil";
import React, { useEffect, Fragment, useState } from "react";
import { Input } from "./components";

import { manageUserState, toggleEditUserState } from "./components/atoms";
import { Validation } from "../../../protocols";
import { useFirestore } from "../../../hooks/use-firestore";
import { ChangeUserDetails } from "../../../../domain/usecases";
import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon, CheckIcon } from "@heroicons/react/outline";
import { classNames } from "../../../utils/helpers";
import { GetUsersState } from "../../../../domain/usecases/get-user";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../../../main/firebase";

type Props = {
  validation: Validation;
  changeUserDetails: ChangeUserDetails;
  user: GetUsersState.Model;
};

export default function EditUser({
  changeUserDetails,
  validation,
  user,
}: Props) {
  const [state, setState] = useRecoilState(manageUserState);
  const [toggleState, setToggleState] = useRecoilState(toggleEditUserState);

  const [showError, setshowError] = useState(false);

  const firestore = useFirestore();
  console.log("user", user);
  const [selectedRole, setSelectedRole] = useState(user.role);

  const validate = (field: string): void => {
    const { firstName, lastName, emailAddress, contactNumber, role } = state;
    const formData = {
      firstName,
      lastName,
      emailAddress,
      contactNumber,
      role,
    };
    setState((old) => ({
      ...old,
      [`${field}Error`]: validation.validate(field, formData),
    }));
    setState((old) => ({
      ...old,
      isFormInvalid:
        !!old.firstNameError ||
        !!old.lastNameError ||
        !!old.emailAddressError ||
        !!old.contactNumberError ||
        !!old.roleError,
    }));
  };

  useEffect(() => {
    const q = query(collection(db, "roles"), where("scope", "==", "Merchant"));
    return onSnapshot(q, (querySnapshot) => {
      let result = querySnapshot.docs.map((doc) => {
        return {
          role: doc.data().role,
        };
      });
      setState((old) => ({ ...old, roles: result }));
    });
  }, []);

  useEffect(
    () =>
      setState((old) => ({
        ...old,
        firstName: user.firstName,
        lastName: user.lastName,
        emailAddress: user.email,
        contactNumber: user.contactNumber,
        role: user.role,
      })),
    []
  );

  useEffect(() => validate("firstName"), [state.firstName]);
  useEffect(() => validate("lastName"), [state.lastName]);
  useEffect(() => validate("emailAddress"), [state.emailAddress]);
  useEffect(() => validate("contactNumber"), [state.contactNumber]);
  useEffect(() => validate("role"), [state.role]);

  const handleRoleChange = (role: string) => {
    setSelectedRole(role);
    setState((old) => ({ ...old, role: role }));
  };

  const toggleEditUserClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    setToggleState((old) => ({
      ...old,
      toggle: !toggleState.toggle,
    }));
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    try {
      if (state.isLoading || state.isFormInvalid) {
        setshowError(true);

        return;
      }

      setState((old) => ({ ...old, isLoading: true }));

      const updatedDetails = {
        firstName: state.firstName,
        lastName: state.lastName,
        emailAddress: state.emailAddress,
        contactNumber: state.contactNumber,
        role: state.role,
      };

      firestore.editUserDetails(user.uid, updatedDetails);

      setState((old) => ({ ...old, isLoading: false }));
      setToggleState((old) => ({ ...old, toggle: !toggleState.toggle }));
      // navigate("/", { replace: true });
      // navigate("/users/create/complete");
    } catch (error) {
      setState((old) => ({
        ...old,
        isLoading: false,
        mainError: error.message,
      }));
    }
  };

  return (
    <div className="min-w-0 border-b border-gray-200 flex-1 lg:grid lg:grid-cols-12 lg:gap-x-5">
      <div className="lg:col-span-3"> </div>
      <div className="lg:col-span-9">
        <form onSubmit={handleSubmit}>
          <div className="overflow-hidden sm:rounded-sm">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-2 sm:col-span-2">
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First name
                  </label>

                  <Input
                    type="text"
                    name="firstName"
                    id="firstName"
                    value={state.firstName}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-sm"
                  />
                  {showError && (
                    <div className=" block text-sm text-red-500">
                      {state.firstNameError && state.firstNameError}
                    </div>
                  )}
                </div>

                <div className="col-span-2 sm:col-span-2">
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last name
                  </label>
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={state.lastName}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-sm"
                  />
                  {showError && (
                    <div className=" block text-sm text-red-500">
                      {state.lastNameError && state.lastNameError}
                    </div>
                  )}
                </div>

                <div className="col-span-2 sm:col-span-2">
                  <label
                    htmlFor="emailAddress"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <Input
                    type="text"
                    name="emailAddress"
                    id="emailAddress"
                    value={state.emailAddress}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-sm"
                  />
                  {showError && (
                    <div className=" block text-sm text-red-500">
                      {state.emailAddressError && state.emailAddressError}
                    </div>
                  )}
                </div>

                <div className="col-span-2 sm:col-span-2">
                  <label
                    htmlFor="contactNumber"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Contact Number
                  </label>
                  <Input
                    type="text"
                    name="contactNumber"
                    id="contactNumber"
                    value={state.contactNumber}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-sm"
                  />
                  {showError && (
                    <div className=" block text-sm text-red-500">
                      {state.contactNumberError && state.contactNumberError}
                    </div>
                  )}
                </div>

                <div className="col-span-2 sm:col-span-2">
                  <label
                    htmlFor="role"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Role
                  </label>

                  <Listbox value={selectedRole} onChange={handleRoleChange}>
                    {({ open }) => (
                      <>
                        {/* <Listbox.Label className="block text-xs font-normal text-gray-400">
                            Location
                          </Listbox.Label> */}
                        <div className="relative">
                          <Listbox.Button className="bg-gray-50 mt-1 relative w-full border border-gray-300 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                            <span className="block truncate">
                              {selectedRole}
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <SelectorIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60  py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                              {state.roles.map((r, index) => (
                                <Listbox.Option
                                  key={index}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? "text-white bg-blue-600"
                                        : "text-gray-900",
                                      "cursor-default select-none relative py-2 pl-3 pr-9"
                                    )
                                  }
                                  value={r.role}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )}
                                      >
                                        {r.role}
                                      </span>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? "text-white"
                                              : "text-blue-600",
                                            "absolute inset-y-0 right-0 flex items-center pr-4"
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                onClick={(e) => toggleEditUserClick(e)}
                type="button"
                className="bg-white py-2 px-6 border border-2 border-red-500 rounded-sm shadow-sm text-sm font-medium text-red-500 disabled:opacity-50 hover:bg-gray-50 focus:outline-none "
              >
                Cancel
              </button>
              <button
                type="submit"
                className="inline-flex ml-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
              >
                Edit User Details
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

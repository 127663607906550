const defaultOptions = {
  significantDigits: 2,
  thousandsSeparator: ",",
  decimalSeparator: ".",
  symbol: "",
};

export function currencyFormatter(value: any, options = defaultOptions) {
  if (typeof value !== "number") value = 0.0;
  options = { ...defaultOptions, ...options };
  value = value.toFixed(options.significantDigits);

  const [currency, decimal] = value.split(".");
  return `${options.symbol} ${currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    options.thousandsSeparator
  )}${options.decimalSeparator}${decimal}`;
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

// const currencyFormatter = (value: any, options: any) => {
//   if (typeof value !== "number") value = 0.0;
//   options = { ...defaultOptions, ...options };
//   value = value.toFixed(options.significantDigits);

//   const [currency, decimal] = value.split(".");
//   return `${options.symbol} ${currency.replace(
//     /\B(?=(\d{3})+(?!\d))/g,
//     options.thousandsSeparator
//   )}${options.decimalSeparator}${decimal}`;
// };

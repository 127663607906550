import React, { useRef } from "react";

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  state: any;
  setState: any;
};

const Input = ({ state, setState, ...props }: Props) => {
  const inputRef = useRef<HTMLInputElement>();
  const error = state[`${props.name}Error`];
  return (
    <div data-status={error ? "invalid" : "valid"}>
      <input
        {...props}
        ref={inputRef as unknown as () => HTMLInputElement}
        title={error}
        data-testid={props.name}
        readOnly
        onFocus={(e) => {
          e.target.readOnly = false;
        }}
        onChange={(e) => {
          setState({ ...state, [e.target.name]: e.target.value });
        }}
      />
    </div>
  );
};

export default Input;

import AddSmartcards from "../../../../../presentation/pages/smartcards/add-smartcard/steps/addSmartcard";

import { makeRemoteValidateSmartcard } from "../../../usecases/smartcard/remote-validate-smartcard-factory";
import { makeValidateSmartcardValidation } from "./validate-smartcard-validation-factory";

export const makeAddSmartcard = () => {
  return (
    <AddSmartcards
      validateSmartcard={makeRemoteValidateSmartcard()}
      validation={makeValidateSmartcardValidation()}
    />
  );
};

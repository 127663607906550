import { AccessDeniedError, UnexpectedError } from "../../../domain/errors";
import { GetPurchaseRequestsByStatus } from "../../../domain/usecases/get-purchase-requests-by-status";
import { RemotePurchaseRequestModel } from "../../models";
import { HttpClient, HttpStatusCode } from "../../protocols/http";

export class RemoteGetPurchaseRequestsByStatus
  implements GetPurchaseRequestsByStatus
{
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<
      RemoteGetPurchaseRequestsByStatus.Model[]
    >
  ) {}

  async fetch(
    params: GetPurchaseRequestsByStatus.Params
  ): Promise<RemoteGetPurchaseRequestsByStatus.Model[]> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "post",
      body: params,
    });
    const remotePurchaseRequests = httpResponse.body || [];

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return remotePurchaseRequests
          .map((remotePurchaseRequest) => ({
            ...remotePurchaseRequest,
          }))
          .sort(
            (a, b) =>
              Number(new Date(a.dateCreated)) - Number(new Date(b.dateCreated))
          );
      case HttpStatusCode.noContent:
        return [];
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteGetPurchaseRequestsByStatus {
  export type Model = RemotePurchaseRequestModel;

  // export type Model = {
  //   id: string;
  //   account: number;
  //   initiator: string;
  //   dateTime: number;
  //   ipAddress: string;
  //   purchaseMeters: PurchaseRequestMeterModel[];
  //   status: number;
  //   dateUpdated: Date | string;
  //   dateCreated: Date | string;
  //   currency: string;
  // };

  // type PurchaseRequestMeterModel = {
  //   meterNumber: string;
  //   amount: string;
  //   account: number;
  //   accountName: string;
  //   location: string;
  //   name: string;
  //   lastPurchaseDate: string;
  //   lastPurchaseAmount: string;
  // };
}

import {
  CheckIcon,
  ChevronRightIcon,
  SelectorIcon,
} from "@heroicons/react/outline";
import { format } from "date-fns";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { Fragment, useEffect, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { PurchaseRequestModel } from "../../../../domain/usecases/get-account-balances";
import { productTypeListState, reportsState } from "../atoms/atoms";
import { classNames, currencyFormatter } from "../../../utils/helpers";
import { db } from "../../../../main/firebase";
import { currentAccountState } from "../../../components";
// import { dasboardState } from "../../atoms/atoms";
import TransactionRows from "./transactionRows";
import Loading from "../../../components/loading/loading";
import { Listbox, Transition } from "@headlessui/react";

export default function PurchaseRequests() {
  const { getCurrentAccount } = useRecoilValue(currentAccountState);
  const [state, setState] = useRecoilState(reportsState);
  const purchaseRequests = useRecoilValue(reportsState).purchaseRequests;
  const account = getCurrentAccount().account;
  const [purchaseRequestNoFilter, setPurchaseRequestNoFilter] = useState(null);
  const [initiatorFilter, setInitiatorFilter] = useState(null);

  const purchaseRequestTypes = useRecoilValue(productTypeListState);

  const [selectedPurchaseRequestType, setSelectedPurchaseRequestType] =
    useState(purchaseRequestTypes[0]);
  const [purchaseRequestInitiator, setPurchaseRequestReference] = useState("");
  const [purchaseRequestNumber, setPurchaseRequestNumber] = useState("");

  useEffect(() => {
    setState((old) => ({
      ...old,
      isFiltered: false,
      isLoading: true,
    }));
    const q = query(
      collection(db, "purchaseRequests"),
      where("account", "==", account),
      where("dateCreated", ">=", state.startDate.startOf("day").toDate()),
      where("dateCreated", "<=", state.endDate.endOf("day").toDate())
    );

    return onSnapshot(q, (querySnapshot) => {
      let requests = querySnapshot.docs
        .sort(
          (a, b) =>
            a.data().dateUpdated.toDate() - b.data().dateUpdated.toDate()
        )
        .map((doc) => {
          return {
            id: doc.data().purchaseRequestNo,
            purchaseRequestNo: doc.data().purchaseRequestNo,
            initiator: doc
              .data()
              .status_history.find((s) => s.status === "PENDING")
              .actionedByName,
            amount: doc.data().purchaseRequestTotal,
            currency: "BWP",
            status: doc.data().status,
            date: doc.data().dateUpdated.toDate(),
            purchaseType: doc.data().purchaseType,
            rejectionReason: doc
              .data()
              .status_history.find((s) => s.status === "REJECTED")
              ? doc.data().status_history.find((s) => s.status === "REJECTED")
                  .reason
              : null,
            items: doc.data().purchaseItems.map((doc) => {
              return {
                account: doc.account,
                merchant: doc.accountName,
                location: doc.location ? doc.location : null,
                name: doc.name ? doc.name : null,
                itemType: doc.itemType,
                network: doc.network ? doc.network : null,
                jobTitle: doc.jobTitle ? doc.jobTitle : null,
                owner: doc.registeredOwner ? doc.registeredOwner : doc.owner,
                number: doc.itemNumber,
                purchaseAmount: doc.amount,
                purchaseRequestNo: doc.purchaseRequestNo,
                lastPurchaseDate: doc.lastPurchaseDate,
                lastPurchaseAmount: doc.lastPurchaseAmount,
              };
            }),
          } as PurchaseRequestModel;
        });

      // account2981
      // accountName"ElliotCosmas"
      // amount10
      // company{city: "Not Registered", n...}
      // dateCreatedMay 14, 2022 at 4:14:21 PM UTC+2
      // dateUpdatedMay 14, 2022 at 4:17:01 PM UTC+2
      // itemNumber"1234567890"
      // itemType"DSTV"
      // lastPurchaseAmount10
      // lastPurchaseDate"2022-05-12T00:54:03.987Z"
      // location"Gaborone"
      // name"Test"
      // owner"JE Smith"
      // provider"MultiChoice Botswana"

      // balances[1].amount = transactions.length;
      // valueOfPurchaseRequests(transactions);
      // pendingApprovals(transactions);
      // setPendingApprovals({ amount: pendingApprovals(), ...valueFailedPurchases });
      setState((old) => ({
        ...old,
        isLoading: false,
        purchaseRequests: requests,
      }));
    });
  }, [state.startDate, state.endDate]);

  const handlePurchaseRequestTypeChange = (type: string) => {
    setSelectedPurchaseRequestType(type);

    const purchaseRequestType =
      type.toLowerCase() === "none" ? "Not Registered" : type;

    const isPurchaseTypesFiltered =
      purchaseRequestType.toLowerCase() !== "all purchase types";
    const isPurchaseRequestInitiatorFiltered =
      purchaseRequestInitiator.toLowerCase() !== "";
    const isPurchaseNumberFiltered = purchaseRequestNumber.toLowerCase() !== "";

    let filtered: PurchaseRequestModel[];

    console.log("isPurchaseTypesFiltered", isPurchaseTypesFiltered);
    console.log(
      "isPurchaseRequestInitiatorFiltered",
      isPurchaseRequestInitiatorFiltered
    );
    console.log("isPurchaseNumberFiltered", isPurchaseNumberFiltered);

    // purchaseRequestInitiator: string;
    // purchaseRequestNumber: string;

    if (
      isPurchaseNumberFiltered &&
      !isPurchaseTypesFiltered &&
      !isPurchaseRequestInitiatorFiltered
    ) {
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return purchaseRequest.purchaseRequestNo
          .toLowerCase()
          .includes(purchaseRequestNumber.toLowerCase());
      });
    } else if (
      isPurchaseNumberFiltered &&
      isPurchaseTypesFiltered &&
      !isPurchaseRequestInitiatorFiltered
    ) {
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.purchaseRequestNo
            .toLowerCase()
            .includes(purchaseRequestNumber.toLowerCase()) &&
          purchaseRequest.purchaseType.toLowerCase() ===
            purchaseRequestType.toLowerCase()
        );
      });
    } else if (
      isPurchaseNumberFiltered &&
      isPurchaseTypesFiltered &&
      !isPurchaseRequestInitiatorFiltered
    ) {
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.purchaseRequestNo
            .toLowerCase()
            .includes(purchaseRequestNumber.toLowerCase()) &&
          purchaseRequest.purchaseType.toLowerCase() ===
            purchaseRequestType.toLowerCase()
        );
      });
    } else if (
      isPurchaseNumberFiltered &&
      isPurchaseTypesFiltered &&
      isPurchaseRequestInitiatorFiltered
    ) {
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.purchaseRequestNo
            .toLowerCase()
            .includes(purchaseRequestNumber.toLowerCase()) &&
          purchaseRequest.purchaseType.toLowerCase() ===
            purchaseRequestType.toLowerCase() &&
          purchaseRequest.initiator
            .toLowerCase()
            .includes(purchaseRequestInitiator.toLowerCase())
        );
      });
    } else if (
      !isPurchaseNumberFiltered &&
      isPurchaseTypesFiltered &&
      isPurchaseRequestInitiatorFiltered
    ) {
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.purchaseType.toLowerCase() ===
            purchaseRequestType.toLowerCase() &&
          purchaseRequest.initiator
            .toLowerCase()
            .includes(purchaseRequestInitiator.toLowerCase())
        );
      });
    } else if (
      !isPurchaseNumberFiltered &&
      !isPurchaseTypesFiltered &&
      isPurchaseRequestInitiatorFiltered
    ) {
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return purchaseRequest.initiator
          .toLowerCase()
          .includes(purchaseRequestInitiator.toLowerCase());
      });
    } else if (
      !isPurchaseNumberFiltered &&
      !isPurchaseTypesFiltered &&
      isPurchaseRequestInitiatorFiltered
    ) {
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return purchaseRequest.initiator
          .toLowerCase()
          .includes(purchaseRequestInitiator.toLowerCase());
      });
    } else if (
      !isPurchaseNumberFiltered &&
      isPurchaseTypesFiltered &&
      !isPurchaseRequestInitiatorFiltered
    ) {
      console.log("purchaseRequestType", purchaseRequestType.toLowerCase());
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.purchaseType.toLowerCase() ===
          purchaseRequestType.toLowerCase()
        );
      });
    } else if (
      !isPurchaseNumberFiltered &&
      isPurchaseTypesFiltered &&
      isPurchaseRequestInitiatorFiltered
    ) {
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.purchaseType.toLowerCase() ===
            purchaseRequestType.toLowerCase() &&
          purchaseRequest.initiator
            .toLowerCase()
            .includes(purchaseRequestInitiator.toLowerCase())
        );
      });
    } else if (
      !isPurchaseNumberFiltered &&
      isPurchaseTypesFiltered &&
      !isPurchaseRequestInitiatorFiltered
    ) {
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.purchaseType.toLowerCase() ===
          purchaseRequestType.toLowerCase()
        );
      });
    } else if (
      isPurchaseNumberFiltered &&
      !isPurchaseTypesFiltered &&
      isPurchaseRequestInitiatorFiltered
    ) {
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.purchaseRequestNo
            .toLowerCase()
            .includes(purchaseRequestNumber.toLowerCase()) &&
          purchaseRequest.initiator
            .toLowerCase()
            .includes(purchaseRequestInitiator.toLowerCase())
        );
      });
    } else if (
      isPurchaseNumberFiltered &&
      !isPurchaseTypesFiltered &&
      !isPurchaseNumberFiltered
    ) {
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return purchaseRequest.purchaseRequestNo
          .toLowerCase()
          .includes(purchaseRequestNumber.toLowerCase());
      });
    } else if (
      isPurchaseNumberFiltered &&
      !isPurchaseTypesFiltered &&
      isPurchaseRequestInitiatorFiltered
    ) {
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.purchaseRequestNo
            .toLowerCase()
            .includes(purchaseRequestNumber.toLowerCase()) &&
          purchaseRequest.initiator
            .toLowerCase()
            .includes(purchaseRequestInitiator.toLowerCase())
        );
      });
    } else if (
      isPurchaseNumberFiltered &&
      isPurchaseTypesFiltered &&
      isPurchaseRequestInitiatorFiltered
    ) {
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.purchaseRequestNo
            .toLowerCase()
            .includes(purchaseRequestNumber.toLowerCase()) &&
          purchaseRequest.initiator
            .toLowerCase()
            .includes(purchaseRequestInitiator.toLowerCase()) &&
          purchaseRequest.purchaseType.toLowerCase() ===
            purchaseRequestType.toLowerCase()
        );
      });
    } else {
      filtered = [];
    }

    console.log("filtered", filtered);

    let updatetypeState = {
      ...state,
      filteredPurchaseRequests: filtered,
      isPurchaseRequestFiltered:
        isPurchaseTypesFiltered ||
        isPurchaseRequestInitiatorFiltered ||
        isPurchaseNumberFiltered
          ? true
          : false,
    };

    setState(updatetypeState);
  };

  const handlePurchaseRequestNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    const keyword = e.target.value.toLowerCase();
    const purchaseRequestType = selectedPurchaseRequestType;

    const isPurchaseTypesFiltered =
      purchaseRequestType.toLowerCase() !== "all purchase types";
    const isPurchaseRequestInitiatorFiltered =
      purchaseRequestInitiator.toLowerCase() !== "";

    const isKeyword = keyword.length > 0 ? true : false;

    let filtered: PurchaseRequestModel[];

    console.log("isPurchaseTypesFiltered", isPurchaseTypesFiltered);
    console.log(
      "isPurchaseRequestInitiatorFiltered",
      isPurchaseRequestInitiatorFiltered
    );
    console.log("isKeyword", isKeyword);

    if (
      isKeyword &&
      !isPurchaseTypesFiltered &&
      !isPurchaseRequestInitiatorFiltered
    ) {
      setPurchaseRequestNumber(keyword);
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return purchaseRequest.purchaseRequestNo
          .toLowerCase()
          .includes(keyword);
      });
    } else if (
      isKeyword &&
      isPurchaseTypesFiltered &&
      !isPurchaseRequestInitiatorFiltered
    ) {
      setPurchaseRequestNumber(keyword);
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.purchaseRequestNo.toLowerCase().includes(keyword) &&
          purchaseRequest.purchaseType.toLowerCase() ===
            purchaseRequestType.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      isPurchaseTypesFiltered &&
      !isPurchaseRequestInitiatorFiltered
    ) {
      setPurchaseRequestNumber(keyword);
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.purchaseRequestNo.toLowerCase().includes(keyword) &&
          purchaseRequest.purchaseType.toLowerCase() ===
            purchaseRequestType.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      isPurchaseTypesFiltered &&
      isPurchaseRequestInitiatorFiltered
    ) {
      setPurchaseRequestNumber(keyword);
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.purchaseRequestNo.toLowerCase().includes(keyword) &&
          purchaseRequest.purchaseType.toLowerCase() ===
            purchaseRequestType.toLowerCase() &&
          purchaseRequest.initiator
            .toLowerCase()
            .includes(purchaseRequestInitiator.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      isPurchaseTypesFiltered &&
      isPurchaseRequestInitiatorFiltered
    ) {
      setPurchaseRequestNumber("");
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.purchaseType.toLowerCase() ===
            purchaseRequestType.toLowerCase() &&
          purchaseRequest.initiator
            .toLowerCase()
            .includes(purchaseRequestInitiator.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      !isPurchaseTypesFiltered &&
      isPurchaseRequestInitiatorFiltered
    ) {
      setPurchaseRequestNumber("");
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return purchaseRequest.initiator
          .toLowerCase()
          .includes(purchaseRequestInitiator.toLowerCase());
      });
    } else if (
      !isKeyword &&
      !isPurchaseTypesFiltered &&
      isPurchaseRequestInitiatorFiltered
    ) {
      setPurchaseRequestNumber("");
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return purchaseRequest.initiator
          .toLowerCase()
          .includes(purchaseRequestInitiator.toLowerCase());
      });
    } else if (
      !isKeyword &&
      isPurchaseTypesFiltered &&
      !isPurchaseRequestInitiatorFiltered
    ) {
      setPurchaseRequestNumber("");
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.purchaseType.toLowerCase() ===
          purchaseRequestType.toLowerCase()
        );
      });
    } else if (
      !isKeyword &&
      isPurchaseTypesFiltered &&
      isPurchaseRequestInitiatorFiltered
    ) {
      setPurchaseRequestNumber("");
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.purchaseType.toLowerCase() ===
            purchaseRequestType.toLowerCase() &&
          purchaseRequest.initiator
            .toLowerCase()
            .includes(purchaseRequestInitiator.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      isPurchaseTypesFiltered &&
      !isPurchaseRequestInitiatorFiltered
    ) {
      setPurchaseRequestNumber("");
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.purchaseType.toLowerCase() ===
          purchaseRequestType.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      !isPurchaseTypesFiltered &&
      isPurchaseRequestInitiatorFiltered
    ) {
      setPurchaseRequestNumber(keyword);
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.purchaseRequestNo.toLowerCase().includes(keyword) &&
          purchaseRequest.initiator
            .toLowerCase()
            .includes(purchaseRequestInitiator.toLowerCase())
        );
      });
    } else if (
      isKeyword &&
      !isPurchaseTypesFiltered &&
      !isPurchaseRequestInitiatorFiltered
    ) {
      setPurchaseRequestNumber(keyword);
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return purchaseRequest.purchaseRequestNo
          .toLowerCase()
          .includes(keyword);
      });
    } else if (
      isKeyword &&
      !isPurchaseTypesFiltered &&
      isPurchaseRequestInitiatorFiltered
    ) {
      setPurchaseRequestNumber(keyword);
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.purchaseRequestNo.toLowerCase().includes(keyword) &&
          purchaseRequest.initiator
            .toLowerCase()
            .includes(purchaseRequestInitiator.toLowerCase())
        );
      });
    } else if (
      isKeyword &&
      isPurchaseTypesFiltered &&
      isPurchaseRequestInitiatorFiltered
    ) {
      setPurchaseRequestNumber(keyword);
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.purchaseRequestNo.toLowerCase().includes(keyword) &&
          purchaseRequest.initiator
            .toLowerCase()
            .includes(purchaseRequestInitiator.toLowerCase()) &&
          purchaseRequest.purchaseType.toLowerCase() ===
            purchaseRequestType.toLowerCase()
        );
      });
    } else {
      setPurchaseRequestNumber("");
      filtered = [];
    }

    console.log("filtered", filtered);
    console.log("isKeyword", isKeyword);
    console.log("isPurchaseTypesFiltered", isPurchaseTypesFiltered);
    console.log(
      "isPurchaseRequestInitiatorFiltered",
      isPurchaseRequestInitiatorFiltered
    );

    let updateCellphonesState = {
      ...state,
      filteredPurchaseRequests: filtered.length > 0 ? filtered : [],
      isPurchaseRequestFiltered:
        isPurchaseTypesFiltered ||
        isPurchaseRequestInitiatorFiltered ||
        isKeyword
          ? true
          : false,
    };

    setState(updateCellphonesState);
  };

  const handlePurchaseRequestInitiatorChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();

    const keyword = e.target.value.toLowerCase();
    const purchaseRequestType = selectedPurchaseRequestType;

    const isPurchaseTypesFiltered =
      purchaseRequestType.toLowerCase() !== "all purchase types";
    const isPurchaseNumberFiltered = purchaseRequestNumber.toLowerCase() !== "";

    const isKeyword = keyword.length > 0 ? true : false;

    let filtered: PurchaseRequestModel[];

    console.log("isPurchaseTypesFiltered", isPurchaseTypesFiltered);
    console.log("isPurchaseNumberFiltered", isPurchaseNumberFiltered);
    console.log(
      "purchaseRequestNumber.toLowerCase().length",
      purchaseRequestNumber.toLowerCase().length
    );
    console.log("purchaseRequestNumber", purchaseRequestNumber);
    console.log("isKeyword", isKeyword);

    if (isKeyword && !isPurchaseTypesFiltered && !isPurchaseNumberFiltered) {
      setPurchaseRequestReference(keyword);
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return purchaseRequest.initiator.toLowerCase().includes(keyword);
      });
    } else if (
      isKeyword &&
      isPurchaseTypesFiltered &&
      !isPurchaseNumberFiltered
    ) {
      setPurchaseRequestReference(keyword);
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.initiator.toLowerCase().includes(keyword) &&
          purchaseRequest.purchaseType.toLowerCase() ===
            purchaseRequestType.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      isPurchaseTypesFiltered &&
      !isPurchaseNumberFiltered
    ) {
      setPurchaseRequestReference(keyword);
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.initiator.toLowerCase().includes(keyword) &&
          purchaseRequest.purchaseType.toLowerCase() ===
            purchaseRequestType.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      isPurchaseTypesFiltered &&
      isPurchaseNumberFiltered
    ) {
      setPurchaseRequestReference(keyword);
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.initiator.toLowerCase().includes(keyword) &&
          purchaseRequest.purchaseType.toLowerCase() ===
            purchaseRequestType.toLowerCase() &&
          purchaseRequest.purchaseRequestNo
            .toLowerCase()
            .includes(purchaseRequestNumber.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      isPurchaseTypesFiltered &&
      isPurchaseNumberFiltered
    ) {
      setPurchaseRequestReference(keyword);
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.purchaseType.toLowerCase() ===
            purchaseRequestType.toLowerCase() &&
          purchaseRequest.purchaseRequestNo
            .toLowerCase()
            .includes(purchaseRequestNumber.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      !isPurchaseTypesFiltered &&
      isPurchaseNumberFiltered
    ) {
      setPurchaseRequestReference(keyword);
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return purchaseRequest.purchaseRequestNo
          .toLowerCase()
          .includes(purchaseRequestNumber.toLowerCase());
      });
    } else if (
      !isKeyword &&
      !isPurchaseTypesFiltered &&
      isPurchaseNumberFiltered
    ) {
      setPurchaseRequestReference(keyword);
      console.log("keyword", keyword);
      console.log(
        "purchaseRequestNumber.toLowerCase()",
        purchaseRequestNumber.toLowerCase()
      );
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return purchaseRequest.purchaseRequestNo
          .toLowerCase()
          .includes(purchaseRequestNumber.toLowerCase());
      });
    } else if (
      !isKeyword &&
      isPurchaseTypesFiltered &&
      !isPurchaseNumberFiltered
    ) {
      setPurchaseRequestReference(keyword);
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.purchaseType.toLowerCase() ===
          purchaseRequestType.toLowerCase()
        );
      });
    } else if (
      !isKeyword &&
      isPurchaseTypesFiltered &&
      isPurchaseNumberFiltered
    ) {
      setPurchaseRequestReference(keyword);
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.purchaseType.toLowerCase() ===
            purchaseRequestType.toLowerCase() &&
          purchaseRequest.purchaseRequestNo
            .toLowerCase()
            .includes(purchaseRequestNumber.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      isPurchaseTypesFiltered &&
      !isPurchaseNumberFiltered
    ) {
      setPurchaseRequestReference(keyword);
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.purchaseType.toLowerCase() ===
          purchaseRequestType.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      !isPurchaseTypesFiltered &&
      isPurchaseNumberFiltered
    ) {
      setPurchaseRequestReference(keyword);
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.initiator.toLowerCase().includes(keyword) &&
          purchaseRequest.purchaseRequestNo
            .toLowerCase()
            .includes(purchaseRequestNumber.toLowerCase())
        );
      });
    } else if (
      isKeyword &&
      !isPurchaseTypesFiltered &&
      !isPurchaseNumberFiltered
    ) {
      setPurchaseRequestReference(keyword);
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return purchaseRequest.initiator.toLowerCase().includes(keyword);
      });
    } else if (
      isKeyword &&
      !isPurchaseTypesFiltered &&
      isPurchaseNumberFiltered
    ) {
      setPurchaseRequestReference(keyword);
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.initiator.toLowerCase().includes(keyword) &&
          purchaseRequest.purchaseRequestNo
            .toLowerCase()
            .includes(purchaseRequestNumber.toLowerCase())
        );
      });
    } else if (
      isKeyword &&
      isPurchaseTypesFiltered &&
      isPurchaseNumberFiltered
    ) {
      setPurchaseRequestReference(keyword);
      filtered = state.purchaseRequests.filter((purchaseRequest) => {
        return (
          purchaseRequest.initiator.toLowerCase().includes(keyword) &&
          purchaseRequest.purchaseRequestNo
            .toLowerCase()
            .includes(purchaseRequestNumber.toLowerCase()) &&
          purchaseRequest.purchaseType.toLowerCase() ===
            purchaseRequestType.toLowerCase()
        );
      });
    } else {
      setPurchaseRequestReference("");
      filtered = [];
    }

    console.log("filtered", filtered);
    console.log("isPurchaseTypesFiltered", isPurchaseTypesFiltered);
    console.log("isPurchaseNumberFiltered", isPurchaseNumberFiltered);
    console.log("isKeyword", isKeyword);
    console.log("keyword", keyword);

    let updateCellphonesState = {
      ...state,
      filteredPurchaseRequests: filtered.length > 0 ? filtered : [],
      isPurchaseRequestFiltered:
        isPurchaseTypesFiltered || isPurchaseNumberFiltered || isKeyword
          ? true
          : false,
    };

    setState(updateCellphonesState);
  };

  console.log(
    "state.isPurchaseRequestFiltered",
    state.isPurchaseRequestFiltered
  );

  const requestsList = state.isPurchaseRequestFiltered
    ? state.filteredPurchaseRequests.length > 0
      ? state.filteredPurchaseRequests
      : []
    : state.purchaseRequests;

  console.log("requestsList", requestsList);

  const transactions =
    requestsList &&
    requestsList.map((transaction, index) => {
      return (
        <TransactionRows
          key={transaction.id}
          index={index}
          transaction={transaction}
        />
      );
    });

  return (
    <>
      {transactions && (
        <div className="w-full lg:max-w-7xl bg-white overflow-hidden shadow rounded-sm">
          <div className="px-6 py-4">
            <div className="mb-0">
              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                <div className="sm:col-span-1 my-auto align-middle">
                  <h2 className="mx-auto text-lg leading-6 font-medium text-gray-900">
                    Filter:
                  </h2>
                </div>

                <div className="sm:col-span-2">
                  <Listbox
                    value={selectedPurchaseRequestType}
                    onChange={handlePurchaseRequestTypeChange}
                  >
                    {({ open }) => (
                      <>
                        {/* <Listbox.Label className="block text-xs font-normal text-gray-400">
                          Company
                        </Listbox.Label> */}
                        <div className="relative">
                          <Listbox.Button className="bg-gray-50 relative w-full border-b border-gray-300 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                            <span className="block truncate">
                              {selectedPurchaseRequestType}
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <SelectorIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60  py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                              {purchaseRequestTypes.map((type, index) => (
                                <Listbox.Option
                                  key={index}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? "text-white bg-blue-600"
                                        : "text-gray-900",
                                      "cursor-default select-none relative py-2 pl-3 pr-9"
                                    )
                                  }
                                  value={type}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )}
                                      >
                                        {type}
                                      </span>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? "text-white"
                                              : "text-blue-600",
                                            "absolute inset-y-0 right-0 flex items-center pr-4"
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>

                <div className="sm:col-span-2">
                  <div className=" border-b border-gray-300 focus-within:border-blue-600">
                    <input
                      onChange={(e) => handlePurchaseRequestNumberChange(e)}
                      type="text"
                      name="requestNo"
                      id="requestNo"
                      className="block w-full border-0 py-2 border-b border-transparent bg-gray-50 focus:border-blue-600 focus:ring-0 sm:text-sm"
                      placeholder="Request Number"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <div className=" border-b border-gray-300 focus-within:border-blue-600">
                    <input
                      onChange={(e) => handlePurchaseRequestInitiatorChange(e)}
                      type="text"
                      name="initiator"
                      id="initiator"
                      className="block w-full border-0  py-2 border-b border-transparent bg-gray-50 focus:border-blue-600 focus:ring-0 sm:text-sm"
                      placeholder="Initiator"
                    />
                  </div>
                </div>

                <div className="lg:col-span-7 text-right">
                  {/* <ItemisedAmountTotal transactions={itemisedPurchasesList} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="p-6">
            <div className="shadow sm:hidden">
              <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                {purchaseRequests.map((transaction) => (
                  <li key={transaction.id}>
                    <a
                      href={transaction.id}
                      className="block px-4 py-4 bg-white hover:bg-gray-50"
                    >
                      <span className="flex items-center space-x-4">
                        <span className="flex-1 flex space-x-2 truncate">
                          <span className="flex flex-col text-gray-500 text-sm truncate">
                            <span className="truncate">{transaction.id}</span>
                            <span>
                              <span className="text-gray-900 font-medium">
                                {currencyFormatter(transaction.amount)}
                              </span>
                            </span>
                            <div>
                              {format(transaction.date, "yyyy-MM-dd HH:mm:ss")}
                            </div>
                          </span>
                        </span>
                        <ChevronRightIcon
                          className="flex-shrink-0 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </a>
                  </li>
                ))}
              </ul>

              <nav
                className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
                aria-label="Pagination"
              >
                <div className="flex-1 flex justify-between">
                  <a
                    href="/"
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                  >
                    Previous
                  </a>
                  <a
                    href="/"
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                  >
                    Next
                  </a>
                </div>
              </nav>
            </div>

            {/* Activity table (small breakpoint and up) */}
            <div className="hidden  sm:block">
              <div className="max-w-7xl mx-auto">
                <div className="flex flex-col">
                  <div className="align-middle min-w-full flex-grow overflow-x-auto overflow-hidden ">
                    {transactions.length > 0 ? (
                      <table
                        id="my-table"
                        className="min-w-full divide-y divide-gray-200"
                      >
                        <thead>
                          <tr>
                            <th className="pr-6 py-2  text-left text-sm font-medium text-gray-500  tracking-wider">
                              Date
                            </th>
                            <th className="px-6 py-2 text-left text-sm font-medium text-gray-500  tracking-wider">
                              Request Number
                            </th>

                            <th className="px-6 py-2  text-left text-sm font-medium text-gray-500  tracking-wider">
                              Initiator
                            </th>
                            <th className="px-6 py-2 text-left text-sm font-medium text-gray-500  tracking-wider">
                              Type
                            </th>
                            <th className="px-6 py-2 text-right text-sm font-medium text-gray-500  tracking-wider">
                              Amount
                            </th>
                            <th className="hidden px-6 py-2  text-left text-sm font-medium text-gray-500  tracking-wider md:block">
                              Status
                            </th>
                            <th className="px-2 py-2  text-left text-sm font-medium text-gray-500  tracking-wider"></th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-100">
                          {transactions}
                        </tbody>
                      </table>
                    ) : (
                      <h2 className="text-md leading-6 font-medium text-gray-900">
                        There no transactions for selected parameters. (Date or
                        Filters).
                      </h2>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {state.isLoading && <Loading />}
    </>
  );
}

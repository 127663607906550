import { GetMetersState } from "../../../../../domain/usecases/get-meter";
import AmendMeterDetails from "../../../../../presentation/pages/meters/manage-meters/components/amendMeterDetails";
import { makeRemoteAmendMeter } from "../../../usecases/meter/remote-amend-meter-factory";
import { makeValidateAmendMeterDetailsValidation } from "./validate-amend-meter-details-validation-factory";

export const makeAmendMeterDetails = (meter: GetMetersState.Model) => {
  return (
    <AmendMeterDetails
      amendMeter={makeRemoteAmendMeter()}
      validation={makeValidateAmendMeterDetailsValidation()}
      meter={meter}
    />
  );
};

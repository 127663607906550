import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon, CheckIcon } from "@heroicons/react/outline";
import { format } from "date-fns";
import { Fragment, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";

import { SmartcardType } from "../../../../types";

import { currencyFormatter } from "../../../utils/helpers";

import {
  dstvPurchaseRequestCompanyState,
  smartcardLocationState,
  purchaseRequestSmartcardsState,
  dstvPurchaseRequestState,
  dstvPurchaseRequestStepsState,
} from "./atoms/atoms";
import { StepsButton } from "./components/stepsButton";
import { PurchaseRequestSmartcard } from "./types";
import { PurchaseRequestType } from "./types/purchaseRequest";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const defaultOptions = {
  significantDigits: 2,
  thousandsSeparator: ",",
  decimalSeparator: ".",
  symbol: "",
};

export default function InitiateDSTVPurchaseRequest() {
  const [state, setState] = useRecoilState(purchaseRequestSmartcardsState);
  // const resetPurchaseRequestSmartcardsState = useResetRecoilState(
  //   purchaseRequestSmartcardsState
  // );
  const resetStepsState = useResetRecoilState(dstvPurchaseRequestStepsState);
  // const setPurchaseRequestStepsState = useSetRecoilState(dstvPurchaseRequestStepsState);
  const locations = useRecoilValue(smartcardLocationState);
  const companies = useRecoilValue(dstvPurchaseRequestCompanyState);
  const purchaseRequest = useRecoilValue(
    dstvPurchaseRequestState
  ) as PurchaseRequestType;

  const [selected, setSelected] = useState(locations[0]);
  const [selectedCompany, setSelectedCompany] = useState(companies[0]);
  const [smartcardName, setSmartcardName] = useState("");
  const [smartcardNumber, setSmartcardNumber] = useState("");

  useEffect(() => resetStepsState(), []);
  useEffect(
    () =>
      setState((old) => ({
        ...old,
        isFiltered: false,
        // selectedCompany: companies[0],
        // location: locations[0],
      })),

    []
  );

  const smartcardList = purchaseRequest.isFiltered
    ? purchaseRequest.filteredSmartcards.length > 0
      ? purchaseRequest.filteredSmartcards
      : []
    : purchaseRequest.smartcards;

  const addSmartcardToRequest = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const isRequestFiltered = purchaseRequest.isFiltered;

    const location = selected;
    const company =
      selectedCompany === "None" ? "Not Registered" : selectedCompany;
    const amount = e.target.value;
    const smartcard = e.target.id;

    const updatedSmartcards = state.smartcards.map((item) => {
      if (item.number === smartcard) {
        return { ...item, purchaseAmount: +amount };
      } else {
        return item;
      }
    });

    let updateSmartcardsState = isRequestFiltered
      ? {
          ...state,
          smartcards: updatedSmartcards,
          filteredSmartcards: updatedSmartcards.filter((smartcard) => {
            const isCompanyFiltered =
              selectedCompany.toLowerCase() !== "all companies";
            const isLocationFiltered =
              location.toLowerCase() !== "all locations";
            const isSmartcardNumberFiltered =
              smartcardNumber.toLowerCase() !== "";
            const isSmartcardNameFiltered = smartcardName.toLowerCase() !== "";

            if (
              isSmartcardNameFiltered &&
              !isCompanyFiltered &&
              !isLocationFiltered &&
              !isSmartcardNumberFiltered
            ) {
              return smartcard.name
                .toLowerCase()
                .includes(smartcardName.toLowerCase());
            } else if (
              isSmartcardNameFiltered &&
              isCompanyFiltered &&
              !isLocationFiltered &&
              !isSmartcardNumberFiltered
            ) {
              return (
                smartcard.name
                  .toLowerCase()
                  .includes(smartcardName.toLowerCase()) &&
                smartcard.company.toLowerCase() === company.toLowerCase()
              );
            } else if (
              isSmartcardNameFiltered &&
              isCompanyFiltered &&
              isLocationFiltered &&
              !isSmartcardNumberFiltered
            ) {
              return (
                smartcard.name
                  .toLowerCase()
                  .includes(smartcardName.toLowerCase()) &&
                smartcard.company.toLowerCase() === company.toLowerCase() &&
                smartcard.location.toLowerCase() === location.toLowerCase()
              );
            } else if (
              isSmartcardNameFiltered &&
              isCompanyFiltered &&
              isLocationFiltered &&
              isSmartcardNumberFiltered
            ) {
              return (
                smartcard.name
                  .toLowerCase()
                  .includes(smartcardName.toLowerCase()) &&
                smartcard.company.toLowerCase() === company.toLowerCase() &&
                smartcard.location.toLowerCase() === location.toLowerCase() &&
                smartcard.number
                  .toLowerCase()
                  .includes(smartcardNumber.toLowerCase())
              );
            } else if (
              !isSmartcardNameFiltered &&
              isCompanyFiltered &&
              isLocationFiltered &&
              isSmartcardNumberFiltered
            ) {
              return (
                smartcard.company.toLowerCase() === company.toLowerCase() &&
                smartcard.location.toLowerCase() === location.toLowerCase() &&
                smartcard.number
                  .toLowerCase()
                  .includes(smartcardNumber.toLowerCase())
              );
            } else if (
              !isSmartcardNameFiltered &&
              !isCompanyFiltered &&
              isLocationFiltered &&
              isSmartcardNumberFiltered
            ) {
              return (
                smartcard.location.toLowerCase() === location.toLowerCase() &&
                smartcard.number
                  .toLowerCase()
                  .includes(smartcardNumber.toLowerCase())
              );
            } else if (
              !isSmartcardNameFiltered &&
              !isCompanyFiltered &&
              !isLocationFiltered &&
              isSmartcardNumberFiltered
            ) {
              return smartcard.number
                .toLowerCase()
                .includes(smartcardNumber.toLowerCase());
            } else if (
              !isSmartcardNameFiltered &&
              isCompanyFiltered &&
              isLocationFiltered &&
              !isSmartcardNumberFiltered
            ) {
              return (
                smartcard.company.toLowerCase() === company.toLowerCase() &&
                smartcard.location.toLowerCase() === location.toLowerCase()
              );
            } else if (
              !isSmartcardNameFiltered &&
              isCompanyFiltered &&
              !isLocationFiltered &&
              isSmartcardNumberFiltered
            ) {
              return (
                smartcard.company.toLowerCase() === company.toLowerCase() &&
                smartcard.number
                  .toLowerCase()
                  .includes(smartcardNumber.toLowerCase())
              );
            } else if (
              !isSmartcardNameFiltered &&
              !isCompanyFiltered &&
              isLocationFiltered &&
              !isSmartcardNumberFiltered
            ) {
              return (
                smartcard.location.toLowerCase() === location.toLowerCase()
              );
            } else if (
              !isSmartcardNameFiltered &&
              isCompanyFiltered &&
              !isLocationFiltered &&
              !isSmartcardNumberFiltered
            ) {
              return smartcard.company.toLowerCase() === company.toLowerCase();
            } else if (
              isSmartcardNameFiltered &&
              !isCompanyFiltered &&
              !isLocationFiltered &&
              isSmartcardNumberFiltered
            ) {
              return (
                smartcard.name
                  .toLowerCase()
                  .includes(smartcardName.toLowerCase()) &&
                smartcard.number
                  .toLowerCase()
                  .includes(smartcardNumber.toLowerCase())
              );
            } else if (
              isSmartcardNameFiltered &&
              !isCompanyFiltered &&
              isLocationFiltered &&
              !isSmartcardNameFiltered
            ) {
              return (
                smartcard.name
                  .toLowerCase()
                  .includes(smartcardName.toLowerCase()) &&
                smartcard.location.toLowerCase() === selected.toLowerCase()
              );
            } else if (
              isSmartcardNameFiltered &&
              !isCompanyFiltered &&
              isLocationFiltered &&
              isSmartcardNumberFiltered
            ) {
              return (
                smartcard.name
                  .toLowerCase()
                  .includes(smartcardName.toLowerCase()) &&
                smartcard.number
                  .toLowerCase()
                  .includes(smartcardNumber.toLowerCase()) &&
                smartcard.location.toLowerCase() === selected.toLowerCase()
              );
            } else if (
              isSmartcardNameFiltered &&
              isCompanyFiltered &&
              !isLocationFiltered &&
              isSmartcardNumberFiltered
            ) {
              return (
                smartcard.name
                  .toLowerCase()
                  .includes(smartcardName.toLowerCase()) &&
                smartcard.number
                  .toLowerCase()
                  .includes(smartcardNumber.toLowerCase()) &&
                smartcard.company.toLowerCase() === company.toLowerCase()
              );
            } else {
              return smartcard;
            }
          }),
          isFiltered: true,
        }
      : {
          ...state,
          smartcards: updatedSmartcards,
          filteredSmartcards: [],
          isFiltered: false,
        };

    setState(updateSmartcardsState);
  };

  const handleLocationChange = (location: string) => {
    // event.preventDefault();

    setSelected(location);
    const company =
      selectedCompany === "None" ? "Not Registered" : selectedCompany;

    const isCompanyFiltered = company.toLowerCase() !== "all companies";
    const isLocationFiltered = location.toLowerCase() !== "all locations";
    const isSmartcardNumberFiltered = smartcardNumber.toLowerCase() !== "";
    const isSmartcardNameFiltered = smartcardName.toLowerCase() !== "";

    let filtered: PurchaseRequestSmartcard[];

    if (
      isSmartcardNameFiltered &&
      !isCompanyFiltered &&
      !isLocationFiltered &&
      !isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return smartcard.name
          .toLowerCase()
          .includes(smartcardName.toLowerCase());
      });
    } else if (
      isSmartcardNameFiltered &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      !isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.name.toLowerCase().includes(smartcardName.toLowerCase()) &&
          smartcard.company.toLowerCase() === company.toLowerCase()
        );
      });
    } else if (
      isSmartcardNameFiltered &&
      isCompanyFiltered &&
      isLocationFiltered &&
      !isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.name.toLowerCase().includes(smartcardName.toLowerCase()) &&
          smartcard.company.toLowerCase() === company.toLowerCase() &&
          smartcard.location.toLowerCase() === location.toLowerCase()
        );
      });
    } else if (
      isSmartcardNameFiltered &&
      isCompanyFiltered &&
      isLocationFiltered &&
      isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.name.toLowerCase().includes(smartcardName.toLowerCase()) &&
          smartcard.company.toLowerCase() === company.toLowerCase() &&
          smartcard.location.toLowerCase() === location.toLowerCase() &&
          smartcard.number.toLowerCase().includes(smartcardNumber.toLowerCase())
        );
      });
    } else if (
      !isSmartcardNameFiltered &&
      isCompanyFiltered &&
      isLocationFiltered &&
      isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.company.toLowerCase() === company.toLowerCase() &&
          smartcard.location.toLowerCase() === location.toLowerCase() &&
          smartcard.number.toLowerCase().includes(smartcardNumber.toLowerCase())
        );
      });
    } else if (
      !isSmartcardNameFiltered &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.location.toLowerCase() === location.toLowerCase() &&
          smartcard.number.toLowerCase().includes(smartcardNumber.toLowerCase())
        );
      });
    } else if (
      !isSmartcardNameFiltered &&
      !isCompanyFiltered &&
      !isLocationFiltered &&
      isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return smartcard.number
          .toLowerCase()
          .includes(smartcardNumber.toLowerCase());
      });
    } else if (
      !isSmartcardNameFiltered &&
      isCompanyFiltered &&
      isLocationFiltered &&
      !isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.company.toLowerCase() === company.toLowerCase() &&
          smartcard.location.toLowerCase() === location.toLowerCase()
        );
      });
    } else if (
      !isSmartcardNameFiltered &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.company.toLowerCase() === company.toLowerCase() &&
          smartcard.number.toLowerCase().includes(smartcardNumber.toLowerCase())
        );
      });
    } else if (
      !isSmartcardNameFiltered &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      !isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return smartcard.location.toLowerCase() === location.toLowerCase();
      });
    } else if (
      !isSmartcardNameFiltered &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      !isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return smartcard.company.toLowerCase() === company.toLowerCase();
      });
    } else if (
      isSmartcardNameFiltered &&
      !isCompanyFiltered &&
      !isLocationFiltered &&
      isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.name.toLowerCase().includes(smartcardName.toLowerCase()) &&
          smartcard.number.toLowerCase().includes(smartcardNumber.toLowerCase())
        );
      });
    } else if (
      isSmartcardNameFiltered &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      !isSmartcardNameFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.name.toLowerCase().includes(smartcardName.toLowerCase()) &&
          smartcard.location.toLowerCase() === selected.toLowerCase()
        );
      });
    } else if (
      isSmartcardNameFiltered &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.name.toLowerCase().includes(smartcardName.toLowerCase()) &&
          smartcard.number
            .toLowerCase()
            .includes(smartcardNumber.toLowerCase()) &&
          smartcard.location.toLowerCase() === selected.toLowerCase()
        );
      });
    } else if (
      isSmartcardNameFiltered &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.name.toLowerCase().includes(smartcardName.toLowerCase()) &&
          smartcard.number
            .toLowerCase()
            .includes(smartcardNumber.toLowerCase()) &&
          smartcard.company.toLowerCase() === company.toLowerCase()
        );
      });
    } else {
      filtered = [];
    }

    let updateSmartcardsState = {
      ...state,
      filteredSmartcards: filtered,
      isFiltered:
        isCompanyFiltered ||
        isLocationFiltered ||
        isSmartcardNumberFiltered ||
        isSmartcardNameFiltered
          ? true
          : false,
    };

    setState(updateSmartcardsState);
  };

  const handleCompanyChange = (selected_company: string) => {
    setSelectedCompany(selected_company);

    const company =
      selected_company.toLowerCase() === "none"
        ? "Not Registered"
        : selected_company;

    const location = selected;

    const isCompanyFiltered = company.toLowerCase() !== "all companies";
    const isLocationFiltered = location.toLowerCase() !== "all locations";
    const isSmartcardNumberFiltered = smartcardNumber.toLowerCase() !== "";
    const isSmartcardNameFiltered = smartcardName.toLowerCase() !== "";

    let filtered: PurchaseRequestSmartcard[];

    if (
      isSmartcardNameFiltered &&
      !isCompanyFiltered &&
      !isLocationFiltered &&
      !isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return smartcard.name
          .toLowerCase()
          .includes(smartcardName.toLowerCase());
      });
    } else if (
      isSmartcardNameFiltered &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      !isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.name.toLowerCase().includes(smartcardName.toLowerCase()) &&
          smartcard.company.toLowerCase() === company.toLowerCase()
        );
      });
    } else if (
      isSmartcardNameFiltered &&
      isCompanyFiltered &&
      isLocationFiltered &&
      !isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.name.toLowerCase().includes(smartcardName.toLowerCase()) &&
          smartcard.company.toLowerCase() === company.toLowerCase() &&
          smartcard.location.toLowerCase() === location.toLowerCase()
        );
      });
    } else if (
      isSmartcardNameFiltered &&
      isCompanyFiltered &&
      isLocationFiltered &&
      isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.name.toLowerCase().includes(smartcardName.toLowerCase()) &&
          smartcard.company.toLowerCase() === company.toLowerCase() &&
          smartcard.location.toLowerCase() === location.toLowerCase() &&
          smartcard.number.toLowerCase().includes(smartcardNumber.toLowerCase())
        );
      });
    } else if (
      !isSmartcardNameFiltered &&
      isCompanyFiltered &&
      isLocationFiltered &&
      isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.company.toLowerCase() === company.toLowerCase() &&
          smartcard.location.toLowerCase() === location.toLowerCase() &&
          smartcard.number.toLowerCase().includes(smartcardNumber.toLowerCase())
        );
      });
    } else if (
      !isSmartcardNameFiltered &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.location.toLowerCase() === location.toLowerCase() &&
          smartcard.number.toLowerCase().includes(smartcardNumber.toLowerCase())
        );
      });
    } else if (
      !isSmartcardNameFiltered &&
      !isCompanyFiltered &&
      !isLocationFiltered &&
      isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return smartcard.number
          .toLowerCase()
          .includes(smartcardNumber.toLowerCase());
      });
    } else if (
      !isSmartcardNameFiltered &&
      isCompanyFiltered &&
      isLocationFiltered &&
      !isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.company.toLowerCase() === company.toLowerCase() &&
          smartcard.location.toLowerCase() === location.toLowerCase()
        );
      });
    } else if (
      !isSmartcardNameFiltered &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.company.toLowerCase() === company.toLowerCase() &&
          smartcard.number.toLowerCase().includes(smartcardNumber.toLowerCase())
        );
      });
    } else if (
      !isSmartcardNameFiltered &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      !isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return smartcard.location.toLowerCase() === location.toLowerCase();
      });
    } else if (
      !isSmartcardNameFiltered &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      !isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return smartcard.company.toLowerCase() === company.toLowerCase();
      });
    } else if (
      isSmartcardNameFiltered &&
      !isCompanyFiltered &&
      !isLocationFiltered &&
      isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.name.toLowerCase().includes(smartcardName.toLowerCase()) &&
          smartcard.number.toLowerCase().includes(smartcardNumber.toLowerCase())
        );
      });
    } else if (
      isSmartcardNameFiltered &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      !isSmartcardNameFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.name.toLowerCase().includes(smartcardName.toLowerCase()) &&
          smartcard.location.toLowerCase() === selected.toLowerCase()
        );
      });
    } else if (
      isSmartcardNameFiltered &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.name.toLowerCase().includes(smartcardName.toLowerCase()) &&
          smartcard.number
            .toLowerCase()
            .includes(smartcardNumber.toLowerCase()) &&
          smartcard.location.toLowerCase() === selected.toLowerCase()
        );
      });
    } else if (
      isSmartcardNameFiltered &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      isSmartcardNumberFiltered
    ) {
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.name.toLowerCase().includes(smartcardName.toLowerCase()) &&
          smartcard.number
            .toLowerCase()
            .includes(smartcardNumber.toLowerCase()) &&
          smartcard.company.toLowerCase() === company.toLowerCase()
        );
      });
    } else {
      filtered = [];
    }

    let updateCompanyState = {
      ...state,
      filteredSmartcards: filtered,
      isFiltered:
        isCompanyFiltered ||
        isLocationFiltered ||
        isSmartcardNumberFiltered ||
        isSmartcardNameFiltered
          ? true
          : false,
    };

    setState(updateCompanyState);
  };

  const handleSmartcardNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    const keyword = e.target.value.toLowerCase();
    const company =
      selectedCompany === "None" ? "Not Registered" : selectedCompany;

    const isCompanyFiltered = company.toLowerCase() !== "all companies";
    const isLocationFiltered = selected.toLowerCase() !== "all locations";
    const isSmartcardNumberFiltered = smartcardNumber.toLowerCase() !== "";

    const isKeyword = keyword.length > 0 ? true : false;

    let filtered: PurchaseRequestSmartcard[];

    if (
      isKeyword &&
      !isCompanyFiltered &&
      !isLocationFiltered &&
      !isSmartcardNumberFiltered
    ) {
      setSmartcardName(keyword);
      filtered = state.smartcards.filter((smartcard) => {
        return smartcard.name.toLowerCase().includes(keyword);
      });
    } else if (
      isKeyword &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      !isSmartcardNumberFiltered
    ) {
      setSmartcardName(keyword);
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.name.toLowerCase().includes(keyword) &&
          smartcard.company.toLowerCase() === company.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      isCompanyFiltered &&
      isLocationFiltered &&
      !isSmartcardNumberFiltered
    ) {
      setSmartcardName(keyword);
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.name.toLowerCase().includes(keyword) &&
          smartcard.company.toLowerCase() === company.toLowerCase() &&
          smartcard.location.toLowerCase() === selected.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      isCompanyFiltered &&
      isLocationFiltered &&
      isSmartcardNumberFiltered
    ) {
      setSmartcardName(keyword);
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.name.toLowerCase().includes(keyword) &&
          smartcard.company.toLowerCase() === company.toLowerCase() &&
          smartcard.location.toLowerCase() === selected.toLowerCase() &&
          smartcard.number.toLowerCase().includes(smartcardNumber.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      isCompanyFiltered &&
      isLocationFiltered &&
      isSmartcardNumberFiltered
    ) {
      setSmartcardName("");
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.company.toLowerCase() === company.toLowerCase() &&
          smartcard.location.toLowerCase() === selected.toLowerCase() &&
          smartcard.number.toLowerCase().includes(smartcardNumber.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      isSmartcardNumberFiltered
    ) {
      setSmartcardName("");
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.location.toLowerCase() === selected.toLowerCase() &&
          smartcard.number.toLowerCase().includes(smartcardNumber.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      !isCompanyFiltered &&
      !isLocationFiltered &&
      isSmartcardNumberFiltered
    ) {
      setSmartcardName("");
      filtered = state.smartcards.filter((smartcard) => {
        return smartcard.number
          .toLowerCase()
          .includes(smartcardNumber.toLowerCase());
      });
    } else if (
      !isKeyword &&
      isCompanyFiltered &&
      isLocationFiltered &&
      !isSmartcardNumberFiltered
    ) {
      setSmartcardName("");
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.company.toLowerCase() === company.toLowerCase() &&
          smartcard.location.toLowerCase() === selected.toLowerCase()
        );
      });
    } else if (
      !isKeyword &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      isSmartcardNumberFiltered
    ) {
      setSmartcardName("");
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.company.toLowerCase() === company.toLowerCase() &&
          smartcard.number.toLowerCase().includes(smartcardNumber.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      !isSmartcardNumberFiltered
    ) {
      setSmartcardName("");
      filtered = state.smartcards.filter((smartcard) => {
        return smartcard.location.toLowerCase() === selected.toLowerCase();
      });
    } else if (
      !isKeyword &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      !isSmartcardNumberFiltered
    ) {
      setSmartcardName("");
      filtered = state.smartcards.filter((smartcard) => {
        return smartcard.company.toLowerCase() === company.toLowerCase();
      });
    } else if (
      isKeyword &&
      !isCompanyFiltered &&
      !isLocationFiltered &&
      isSmartcardNumberFiltered
    ) {
      setSmartcardName(keyword);
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.name.toLowerCase().includes(keyword) &&
          smartcard.number.toLowerCase().includes(smartcardNumber.toLowerCase())
        );
      });
    } else if (
      isKeyword &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      !isSmartcardNumberFiltered
    ) {
      setSmartcardName(keyword);
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.name.toLowerCase().includes(keyword) &&
          smartcard.location.toLowerCase() === selected.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      isSmartcardNumberFiltered
    ) {
      setSmartcardName(keyword);
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.name.toLowerCase().includes(keyword) &&
          smartcard.number
            .toLowerCase()
            .includes(smartcardNumber.toLowerCase()) &&
          smartcard.location.toLowerCase() === selected.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      isSmartcardNumberFiltered
    ) {
      setSmartcardName(keyword);
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.name.toLowerCase().includes(keyword) &&
          smartcard.number
            .toLowerCase()
            .includes(smartcardNumber.toLowerCase()) &&
          smartcard.company.toLowerCase() === company.toLowerCase()
        );
      });
    } else {
      setSmartcardName("");
      filtered = [];
    }

    let updateSmartcardsState = {
      ...state,
      filteredSmartcards: filtered.length > 0 ? filtered : [],
      isFiltered:
        isCompanyFiltered ||
        isLocationFiltered ||
        isSmartcardNumberFiltered ||
        isKeyword
          ? true
          : false,
    };

    setState(updateSmartcardsState);
  };

  const handleSmartcardNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();

    const keyword = e.target.value.toLowerCase();
    const company =
      selectedCompany === "None" ? "Not Registered" : selectedCompany;

    const isCompanyFiltered = company.toLowerCase() !== "all companies";
    const isLocationFiltered = selected.toLowerCase() !== "all locations";
    const isSmartcardNameFiltered = smartcardName.toLowerCase() !== "";

    const isKeyword = keyword.length > 0 ? true : false;

    let filtered: PurchaseRequestSmartcard[];

    if (
      isKeyword &&
      !isCompanyFiltered &&
      !isLocationFiltered &&
      !isSmartcardNameFiltered
    ) {
      setSmartcardNumber(keyword);
      filtered = state.smartcards.filter((smartcard) => {
        return smartcard.number.toLowerCase().includes(keyword);
      });
    } else if (
      isKeyword &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      !isSmartcardNameFiltered
    ) {
      setSmartcardNumber(keyword);
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.number.toLowerCase().includes(keyword) &&
          smartcard.company.toLowerCase() === company.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      isCompanyFiltered &&
      isLocationFiltered &&
      !isSmartcardNameFiltered
    ) {
      setSmartcardNumber(keyword);
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.number.toLowerCase().includes(keyword) &&
          smartcard.company.toLowerCase() === company.toLowerCase() &&
          smartcard.location.toLowerCase() === selected.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      isCompanyFiltered &&
      isLocationFiltered &&
      isSmartcardNameFiltered
    ) {
      setSmartcardNumber(keyword);
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.number.toLowerCase().includes(keyword) &&
          smartcard.company.toLowerCase() === company.toLowerCase() &&
          smartcard.location.toLowerCase() === selected.toLowerCase() &&
          smartcard.name.toLowerCase().includes(smartcardName.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      isCompanyFiltered &&
      isLocationFiltered &&
      isSmartcardNameFiltered
    ) {
      setSmartcardNumber(keyword);
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.company.toLowerCase() === company.toLowerCase() &&
          smartcard.location.toLowerCase() === selected.toLowerCase() &&
          smartcard.name.toLowerCase().includes(smartcardName.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      isSmartcardNameFiltered
    ) {
      setSmartcardNumber(keyword);
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.location.toLowerCase() === selected.toLowerCase() &&
          smartcard.name.toLowerCase().includes(smartcardName.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      !isCompanyFiltered &&
      !isLocationFiltered &&
      isSmartcardNameFiltered
    ) {
      setSmartcardNumber(keyword);

      filtered = state.smartcards.filter((smartcard) => {
        return smartcard.name
          .toLowerCase()
          .includes(smartcardName.toLowerCase());
      });
    } else if (
      !isKeyword &&
      isCompanyFiltered &&
      isLocationFiltered &&
      !isSmartcardNameFiltered
    ) {
      setSmartcardNumber(keyword);
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.company.toLowerCase() === company.toLowerCase() &&
          smartcard.location.toLowerCase() === selected.toLowerCase()
        );
      });
    } else if (
      !isKeyword &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      isSmartcardNameFiltered
    ) {
      setSmartcardNumber(keyword);
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.company.toLowerCase() === company.toLowerCase() &&
          smartcard.name.toLowerCase().includes(smartcardName.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      !isSmartcardNameFiltered
    ) {
      setSmartcardNumber(keyword);
      filtered = state.smartcards.filter((smartcard) => {
        return smartcard.location.toLowerCase() === selected.toLowerCase();
      });
    } else if (
      !isKeyword &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      !isSmartcardNameFiltered
    ) {
      setSmartcardNumber(keyword);
      filtered = state.smartcards.filter((smartcard) => {
        return smartcard.company.toLowerCase() === company.toLowerCase();
      });
    } else if (
      isKeyword &&
      !isCompanyFiltered &&
      !isLocationFiltered &&
      isSmartcardNameFiltered
    ) {
      setSmartcardNumber(keyword);
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.number.toLowerCase().includes(keyword) &&
          smartcard.name.toLowerCase().includes(smartcardName.toLowerCase())
        );
      });
    } else if (
      isKeyword &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      !isSmartcardNameFiltered
    ) {
      setSmartcardNumber(keyword);
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.number.toLowerCase().includes(keyword) &&
          smartcard.location.toLowerCase() === selected.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      isSmartcardNameFiltered
    ) {
      setSmartcardNumber(keyword);
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.number.toLowerCase().includes(keyword) &&
          smartcard.name.toLowerCase().includes(smartcardName.toLowerCase()) &&
          smartcard.location.toLowerCase() === selected.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      isSmartcardNameFiltered
    ) {
      setSmartcardNumber(keyword);
      filtered = state.smartcards.filter((smartcard) => {
        return (
          smartcard.number.toLowerCase().includes(keyword) &&
          smartcard.name.toLowerCase().includes(smartcardName.toLowerCase()) &&
          smartcard.company.toLowerCase() === company.toLowerCase()
        );
      });
    } else {
      setSmartcardNumber("");
      filtered = [];
    }

    let updateSmartcardsState = {
      ...state,
      filteredSmartcards: filtered.length > 0 ? filtered : [],
      isFiltered:
        isLocationFiltered ||
        isCompanyFiltered ||
        isSmartcardNameFiltered ||
        isKeyword
          ? true
          : false,
    };

    setState(updateSmartcardsState);
  };

  return (
    <>
      <div className="w-full">
        {state.smartcards && (
          <>
            <div className="mb-6">
              {purchaseRequest.showFilters ? (
                <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                  <div className="sm:col-span-2">
                    <Listbox
                      value={selectedCompany}
                      onChange={handleCompanyChange}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-xs font-normal text-gray-400">
                            Company
                          </Listbox.Label>
                          <div className="relative">
                            <Listbox.Button className="bg-gray-50 relative w-full border-b border-gray-300 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                              <span className="block truncate">
                                {selectedCompany}
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60  py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {companies.map((company, index) => (
                                  <Listbox.Option
                                    key={index}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "text-white bg-blue-600"
                                          : "text-gray-900",
                                        "cursor-default select-none relative py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={company}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate"
                                          )}
                                        >
                                          {company}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-blue-600",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                  <div className="sm:col-span-2">
                    <Listbox value={selected} onChange={handleLocationChange}>
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-xs font-normal text-gray-400">
                            Location
                          </Listbox.Label>
                          <div className="relative">
                            <Listbox.Button className="bg-gray-50 relative w-full border-b border-gray-300 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                              <span className="block truncate">{selected}</span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60  py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {locations.map((location, index) => (
                                  <Listbox.Option
                                    key={index}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "text-white bg-blue-600"
                                          : "text-gray-900",
                                        "cursor-default select-none relative py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={location}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate"
                                          )}
                                        >
                                          {location}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-blue-600",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="name"
                      className="block text-xs font-normal text-gray-400"
                    >
                      Smartcard Name
                    </label>
                    <div className=" border-b border-gray-300 focus-within:border-blue-600">
                      <input
                        onChange={(e) => handleSmartcardNameChange(e)}
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full border-0 py-2 border-b border-transparent bg-gray-50 focus:border-blue-600 focus:ring-0 sm:text-sm"
                        placeholder="Smartcard Name"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="smartcard"
                      className="block text-xs font-normal text-gray-400"
                    >
                      Smartcard Number
                    </label>
                    <div className=" border-b border-gray-300 focus-within:border-blue-600">
                      <input
                        onChange={(e) => handleSmartcardNumberChange(e)}
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full border-0  py-2 border-b border-transparent bg-gray-50 focus:border-blue-600 focus:ring-0 sm:text-sm"
                        placeholder="Smartcard Number"
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <form action="#" method="POST">
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-1 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    {!purchaseRequest.purchaseAmountsValid && (
                      <div className="my-4 block text-sm font-bold text-red-500">
                        Maximum amount allowed per smartcard:{" "}
                        {currencyFormatter(1000, defaultOptions)}
                      </div>
                    )}
                    <div className="pb-8 overflow-hidden">
                      {smartcardList.length > 0 ? (
                        <table className="min-w-full bg-gray-50 border rounded-sm border-gray-200">
                          <thead className="sticky bg-gray-200">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
                              >
                                <div>Company</div>
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
                              >
                                <div>Smartcard Name &amp;</div>
                                <div>Location</div>
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
                              >
                                <div>Number &amp;</div>
                                <div>Owner</div>
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
                              >
                                <div>Last Purchase</div>
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
                              >
                                Purchase Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {smartcardList.map((smartcard, smartcardIdx) => (
                              <tr
                                key={smartcard.id}
                                className="border-b border-gray-150"
                              >
                                <td className="px-6 py-1 whitespace-nowrap">
                                  <div className="text-sm font-medium text-gray-900">
                                    {smartcard.company === "Not Registered"
                                      ? "None"
                                      : smartcard.company}
                                  </div>
                                </td>
                                <td className="px-6 py-1 whitespace-nowrap text-sm font-normal">
                                  <div className="text-sm font-medium text-gray-900">
                                    {smartcard.name}
                                  </div>
                                  <div className="text-sm text-gray-500">
                                    {smartcard.location}
                                  </div>
                                </td>
                                <td className="px-6 py-1 whitespace-nowrap">
                                  <div className="text-sm font-medium text-gray-900">
                                    {smartcard.number}
                                  </div>
                                  <div className="text-sm text-gray-500">
                                    {smartcard.owner}
                                  </div>
                                </td>
                                <td className="px-6 py-1 whitespace-nowrap text-sm font-normal text-gray-500">
                                  <div className="text-sm font-medium text-gray-900">
                                    {smartcard.lastPurchase.dateCreated ===
                                    "None"
                                      ? "None"
                                      : format(
                                          new Date(
                                            smartcard.lastPurchase.dateCreated
                                          ),
                                          "yyyy-MM-dd"
                                        )}
                                    {/* &nbsp; &mdash; &nbsp; */}
                                  </div>
                                  <div className="text-sm text-gray-500">
                                    {currencyFormatter(
                                      smartcard.lastPurchase.purchaseAmount,
                                      defaultOptions
                                    )}
                                  </div>
                                </td>
                                <td className="px-6 py-1 whitespace-nowrap text-sm text-gray-500">
                                  <div className="border border-gray-100 bg-white focus-within:border-blue-600">
                                    <input
                                      onWheel={(event) =>
                                        event.currentTarget.blur()
                                      }
                                      onChange={(e) => addSmartcardToRequest(e)}
                                      value={
                                        smartcard.purchaseAmount
                                          ? smartcard.purchaseAmount
                                          : ""
                                      }
                                      type="number"
                                      min={0}
                                      name="purchaseAmount"
                                      id={`${smartcard.number}`}
                                      className="block w-full border border-gray-200 placeholder-gray-300 font-medium py-2 border-b border-transparent bg-white focus:border-blue-600 focus:ring-0 sm:text-sm"
                                      placeholder="0.00"
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <h2 className="text-md leading-6 font-medium text-gray-900">
                          No results found for the selected filter(s).
                        </h2>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* {state.error && <Error error={state.error} reload={reload} />} */}
            </form>
          </>
        )}

        {purchaseRequest.enableStepsButton &&
          purchaseRequest.purchaseAmountsValid && (
            <StepsButton
              createDSTVPurchaseRequest={null}
              step="initiate"
              isEnabled={purchaseRequest.enableStepsButton}
              primaryText="Confirm Request"
              secondaryText="Clear All"
            />
          )}
      </div>
    </>
  );
}

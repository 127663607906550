import { StepsButton } from "./components/stepsButton";
import { currencyFormatter } from "../../../utils/helpers";

// import { useRecoilState, useRecoilValue } from "recoil";
import { purchaseRequestCellphonesState } from "./atoms/atoms";
import { CreateAirtimePurchaseRequest } from "../../../../domain/usecases";
import Loading from "../../../components/loading/loading";
import { useRecoilValue } from "recoil";
import { format } from "date-fns";

type Props = {
  createAirtimePurchaseRequest: CreateAirtimePurchaseRequest;
};

export default function ConfirmAirtimePurchaseRequest({
  createAirtimePurchaseRequest,
}: Props) {
  const state = useRecoilValue(purchaseRequestCellphonesState);
  // const [state, setState] = useRecoilState(purchaseRequestCellphonesState);

  // payload = locations.filter((c) => c !== location);
  const cellphoneList = state.cellphones.filter((cellphone) => {
    return +cellphone.purchaseAmount > 0;
  });

  return (
    <>
      <div className="w-full">
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-1 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="pb-8 overflow-hidden">
                <table className="min-w-full bg-gray-50 border rounded-sm border-gray-200">
                  <thead className="sticky bg-gray-200">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
                      >
                        <div>Owner &amp;</div>
                        <div>Job Title</div>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
                      >
                        <div>Number &amp;</div>
                        <div>Network</div>
                      </th>

                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
                      >
                        Last Purchase &amp; Amount
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
                      >
                        Purchase Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cellphoneList.map((cellphone, cellphoneIdx) => (
                      <tr
                        key={cellphone.id}
                        className="border-b border-gray-150"
                      >
                        <td className="px-6 py-1 whitespace-nowrap text-sm font-normal">
                          <div className="text-sm font-medium text-gray-900">
                            {cellphone.cellphoneOwner}
                          </div>
                          <div className="text-sm text-gray-500">
                            {cellphone.jobTitle}
                          </div>
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {cellphone.number}
                          </div>
                          <div className="text-sm text-gray-500">
                            {cellphone.network}
                          </div>
                        </td>

                        <td className="px-6 py-1 whitespace-nowrap text-sm font-normal text-gray-500">
                          {cellphone.lastPurchaseDate === "None"
                            ? "None"
                            : format(
                                new Date(cellphone.lastPurchaseDate),
                                "yyyy-MM-dd"
                              )}
                          &nbsp; &mdash; &nbsp;
                          {currencyFormatter(cellphone.lastPurchaseAmount)}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-sm text-gray-500">
                          <span className="text-gray-900 font-bold">
                            {currencyFormatter(cellphone.purchaseAmount)}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <StepsButton
          createAirtimePurchaseRequest={createAirtimePurchaseRequest}
          step="confirm"
          isEnabled={true}
          primaryText="Submit Request"
          secondaryText="   Back   "
        />
      </div>

      {state.isLoading && <Loading />}
    </>
  );
}

import { EmailInUseError, UnexpectedError } from "../../../domain/errors";
import { GetSmartcardsByStatus } from "../../../domain/usecases/get-smartcards-by-status";
import { HttpClient, HttpStatusCode } from "../../protocols/http";

export class RemoteGetSmartcardsByStatus implements GetSmartcardsByStatus {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<RemoteGetSmartcardsByStatus.Model>
  ) {}

  async fetch(
    params: GetSmartcardsByStatus.Params
  ): Promise<RemoteGetSmartcardsByStatus.Model> {
    console.log("body: params,", params);

    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "post",
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body!;
      case HttpStatusCode.forbidden:
        throw new EmailInUseError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteGetSmartcardsByStatus {
  export type Model = GetSmartcardsByStatus.Model;
}

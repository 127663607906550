import { useRecoilValue } from "recoil";
import { FormStatusBase } from "../../../../components";
import { meterCompaniesState } from "../atoms/atoms";

// const FormStatus: React.FC = () => {
export default function FormStatus() {
  const state = useRecoilValue(meterCompaniesState);
  return <FormStatusBase state={state} />;
}

// export default FormStatus;

import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon, CheckIcon } from "@heroicons/react/outline";
import { format } from "date-fns";
import { Fragment, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";

import { MeterType } from "../../../../types";

import { currencyFormatter } from "../../../utils/helpers";

import {
  electricityPurchaseRequestCompanyState,
  meterLocationState,
  purchaseRequestMetersState,
  electricityPurchaseRequestState,
  electricityPurchaseRequestStepsState,
} from "./atoms/atoms";
import { StepsButton } from "./components/stepsButton";
import { PurchaseRequestType } from "./types/purchaseRequest";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const defaultOptions = {
  significantDigits: 2,
  thousandsSeparator: ",",
  decimalSeparator: ".",
  symbol: "",
};

export default function InitiatePurchaseRequest() {
  const [state, setState] = useRecoilState(purchaseRequestMetersState);
  const resetStepsState = useResetRecoilState(
    electricityPurchaseRequestStepsState
  );
  // const resetPurchaseRequestMeterState = useResetRecoilState(
  //   purchaseRequestMetersState
  // );

  const locations = useRecoilValue(meterLocationState);
  const companies = useRecoilValue(electricityPurchaseRequestCompanyState);
  const purchaseRequest = useRecoilValue(
    electricityPurchaseRequestState
  ) as PurchaseRequestType;

  const [selected, setSelected] = useState(locations[0]);
  const [selectedCompany, setSelectedCompany] = useState(companies[0]);
  const [meterName, setMeterName] = useState("");
  const [meterNumber, setMeterNumber] = useState("");

  useEffect(() => resetStepsState(), []);
  useEffect(
    () =>
      setState((old) => ({
        ...old,
        isFiltered: false,
      })),

    []
  );

  const meterList = purchaseRequest.isFiltered
    ? purchaseRequest.filteredMeters.length > 0
      ? purchaseRequest.filteredMeters
      : []
    : purchaseRequest.meters;

  const addMeterToRequest = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const isRequestFiltered = purchaseRequest.isFiltered;

    const location = selected;
    const company =
      selectedCompany === "None" ? "Not Registered" : selectedCompany;
    const amount = e.target.value ? e.target.value : 0;
    const meter = e.target.id;

    // const isBelowThreshold = (currentValue) => currentValue <= 1000;

    const updatedMeters = state.meters.map((item) => {
      if (item.number === meter) {
        return { ...item, purchaseAmount: +amount };
      } else {
        return item;
      }
    });

    let updateMetersState = isRequestFiltered
      ? {
          ...state,
          meters: updatedMeters,
          filteredMeters: updatedMeters.filter((meter) => {
            const isCompanyFiltered =
              selectedCompany.toLowerCase() !== "all companies";
            const isLocationFiltered =
              location.toLowerCase() !== "all locations";
            const isMeterNumberFiltered = meterNumber.toLowerCase() !== "";
            const isMeterNameFiltered = meterName.toLowerCase() !== "";

            if (
              isMeterNameFiltered &&
              !isCompanyFiltered &&
              !isLocationFiltered &&
              !isMeterNumberFiltered
            ) {
              return meter.name.toLowerCase().includes(meterName.toLowerCase());
            } else if (
              isMeterNameFiltered &&
              isCompanyFiltered &&
              !isLocationFiltered &&
              !isMeterNumberFiltered
            ) {
              return (
                meter.name.toLowerCase().includes(meterName.toLowerCase()) &&
                meter.company.toLowerCase() === company.toLowerCase()
              );
            } else if (
              isMeterNameFiltered &&
              isCompanyFiltered &&
              isLocationFiltered &&
              !isMeterNumberFiltered
            ) {
              return (
                meter.name.toLowerCase().includes(meterName.toLowerCase()) &&
                meter.company.toLowerCase() === company.toLowerCase() &&
                meter.location.toLowerCase() === location.toLowerCase()
              );
            } else if (
              isMeterNameFiltered &&
              isCompanyFiltered &&
              isLocationFiltered &&
              isMeterNumberFiltered
            ) {
              return (
                meter.name.toLowerCase().includes(meterName.toLowerCase()) &&
                meter.company.toLowerCase() === company.toLowerCase() &&
                meter.location.toLowerCase() === location.toLowerCase() &&
                meter.number.toLowerCase().includes(meterNumber.toLowerCase())
              );
            } else if (
              !isMeterNameFiltered &&
              isCompanyFiltered &&
              isLocationFiltered &&
              isMeterNumberFiltered
            ) {
              return (
                meter.company.toLowerCase() === company.toLowerCase() &&
                meter.location.toLowerCase() === location.toLowerCase() &&
                meter.number.toLowerCase().includes(meterNumber.toLowerCase())
              );
            } else if (
              !isMeterNameFiltered &&
              !isCompanyFiltered &&
              isLocationFiltered &&
              isMeterNumberFiltered
            ) {
              return (
                meter.location.toLowerCase() === location.toLowerCase() &&
                meter.number.toLowerCase().includes(meterNumber.toLowerCase())
              );
            } else if (
              !isMeterNameFiltered &&
              !isCompanyFiltered &&
              !isLocationFiltered &&
              isMeterNumberFiltered
            ) {
              return meter.number
                .toLowerCase()
                .includes(meterNumber.toLowerCase());
            } else if (
              !isMeterNameFiltered &&
              isCompanyFiltered &&
              isLocationFiltered &&
              !isMeterNumberFiltered
            ) {
              return (
                meter.company.toLowerCase() === company.toLowerCase() &&
                meter.location.toLowerCase() === location.toLowerCase()
              );
            } else if (
              !isMeterNameFiltered &&
              isCompanyFiltered &&
              !isLocationFiltered &&
              isMeterNumberFiltered
            ) {
              return (
                meter.company.toLowerCase() === company.toLowerCase() &&
                meter.number.toLowerCase().includes(meterNumber.toLowerCase())
              );
            } else if (
              !isMeterNameFiltered &&
              !isCompanyFiltered &&
              isLocationFiltered &&
              !isMeterNumberFiltered
            ) {
              return meter.location.toLowerCase() === location.toLowerCase();
            } else if (
              !isMeterNameFiltered &&
              isCompanyFiltered &&
              !isLocationFiltered &&
              !isMeterNumberFiltered
            ) {
              return meter.company.toLowerCase() === company.toLowerCase();
            } else if (
              isMeterNameFiltered &&
              !isCompanyFiltered &&
              !isLocationFiltered &&
              isMeterNumberFiltered
            ) {
              return (
                meter.name.toLowerCase().includes(meterName.toLowerCase()) &&
                meter.number.toLowerCase().includes(meterNumber.toLowerCase())
              );
            } else if (
              isMeterNameFiltered &&
              !isCompanyFiltered &&
              isLocationFiltered &&
              !isMeterNameFiltered
            ) {
              return (
                meter.name.toLowerCase().includes(meterName.toLowerCase()) &&
                meter.location.toLowerCase() === selected.toLowerCase()
              );
            } else if (
              isMeterNameFiltered &&
              !isCompanyFiltered &&
              isLocationFiltered &&
              isMeterNumberFiltered
            ) {
              return (
                meter.name.toLowerCase().includes(meterName.toLowerCase()) &&
                meter.number
                  .toLowerCase()
                  .includes(meterNumber.toLowerCase()) &&
                meter.location.toLowerCase() === selected.toLowerCase()
              );
            } else if (
              isMeterNameFiltered &&
              isCompanyFiltered &&
              !isLocationFiltered &&
              isMeterNumberFiltered
            ) {
              return (
                meter.name.toLowerCase().includes(meterName.toLowerCase()) &&
                meter.number
                  .toLowerCase()
                  .includes(meterNumber.toLowerCase()) &&
                meter.company.toLowerCase() === company.toLowerCase()
              );
            } else {
              return meter;
            }
          }),
          isFiltered: true,
        }
      : {
          ...state,
          meters: updatedMeters,
          filteredMeters: [],
          isFiltered: false,
        };

    setState(updateMetersState);
  };

  const handleLocationChange = (location: string) => {
    // event.preventDefault();

    setSelected(location);
    const company =
      selectedCompany === "None" ? "Not Registered" : selectedCompany;

    const isCompanyFiltered = company.toLowerCase() !== "all companies";
    const isLocationFiltered = location.toLowerCase() !== "all locations";
    const isMeterNumberFiltered = meterNumber.toLowerCase() !== "";
    const isMeterNameFiltered = meterName.toLowerCase() !== "";

    let filtered: MeterType[];

    if (
      isMeterNameFiltered &&
      !isCompanyFiltered &&
      !isLocationFiltered &&
      !isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return meter.name.toLowerCase().includes(meterName.toLowerCase());
      });
    } else if (
      isMeterNameFiltered &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      !isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return (
          meter.name.toLowerCase().includes(meterName.toLowerCase()) &&
          meter.company.toLowerCase() === company.toLowerCase()
        );
      });
    } else if (
      isMeterNameFiltered &&
      isCompanyFiltered &&
      isLocationFiltered &&
      !isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return (
          meter.name.toLowerCase().includes(meterName.toLowerCase()) &&
          meter.company.toLowerCase() === company.toLowerCase() &&
          meter.location.toLowerCase() === location.toLowerCase()
        );
      });
    } else if (
      isMeterNameFiltered &&
      isCompanyFiltered &&
      isLocationFiltered &&
      isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return (
          meter.name.toLowerCase().includes(meterName.toLowerCase()) &&
          meter.company.toLowerCase() === company.toLowerCase() &&
          meter.location.toLowerCase() === location.toLowerCase() &&
          meter.number.toLowerCase().includes(meterNumber.toLowerCase())
        );
      });
    } else if (
      !isMeterNameFiltered &&
      isCompanyFiltered &&
      isLocationFiltered &&
      isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return (
          meter.company.toLowerCase() === company.toLowerCase() &&
          meter.location.toLowerCase() === location.toLowerCase() &&
          meter.number.toLowerCase().includes(meterNumber.toLowerCase())
        );
      });
    } else if (
      !isMeterNameFiltered &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return (
          meter.location.toLowerCase() === location.toLowerCase() &&
          meter.number.toLowerCase().includes(meterNumber.toLowerCase())
        );
      });
    } else if (
      !isMeterNameFiltered &&
      !isCompanyFiltered &&
      !isLocationFiltered &&
      isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return meter.number.toLowerCase().includes(meterNumber.toLowerCase());
      });
    } else if (
      !isMeterNameFiltered &&
      isCompanyFiltered &&
      isLocationFiltered &&
      !isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return (
          meter.company.toLowerCase() === company.toLowerCase() &&
          meter.location.toLowerCase() === location.toLowerCase()
        );
      });
    } else if (
      !isMeterNameFiltered &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return (
          meter.company.toLowerCase() === company.toLowerCase() &&
          meter.number.toLowerCase().includes(meterNumber.toLowerCase())
        );
      });
    } else if (
      !isMeterNameFiltered &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      !isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return meter.location.toLowerCase() === location.toLowerCase();
      });
    } else if (
      !isMeterNameFiltered &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      !isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return meter.company.toLowerCase() === company.toLowerCase();
      });
    } else if (
      isMeterNameFiltered &&
      !isCompanyFiltered &&
      !isLocationFiltered &&
      isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return (
          meter.name.toLowerCase().includes(meterName.toLowerCase()) &&
          meter.number.toLowerCase().includes(meterNumber.toLowerCase())
        );
      });
    } else if (
      isMeterNameFiltered &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      !isMeterNameFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return (
          meter.name.toLowerCase().includes(meterName.toLowerCase()) &&
          meter.location.toLowerCase() === selected.toLowerCase()
        );
      });
    } else if (
      isMeterNameFiltered &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return (
          meter.name.toLowerCase().includes(meterName.toLowerCase()) &&
          meter.number.toLowerCase().includes(meterNumber.toLowerCase()) &&
          meter.location.toLowerCase() === selected.toLowerCase()
        );
      });
    } else if (
      isMeterNameFiltered &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return (
          meter.name.toLowerCase().includes(meterName.toLowerCase()) &&
          meter.number.toLowerCase().includes(meterNumber.toLowerCase()) &&
          meter.company.toLowerCase() === company.toLowerCase()
        );
      });
    } else {
      filtered = [];
    }

    let updateMetersState = {
      ...state,
      filteredMeters: filtered,
      isFiltered:
        isCompanyFiltered ||
        isLocationFiltered ||
        isMeterNumberFiltered ||
        isMeterNameFiltered
          ? true
          : false,
    };

    setState(updateMetersState);
  };

  const handleCompanyChange = (selected_company: string) => {
    setSelectedCompany(selected_company);

    const company =
      selected_company.toLowerCase() === "none"
        ? "Not Registered"
        : selected_company;

    const location = selected;

    const isCompanyFiltered = company.toLowerCase() !== "all companies";
    const isLocationFiltered = location.toLowerCase() !== "all locations";
    const isMeterNumberFiltered = meterNumber.toLowerCase() !== "";
    const isMeterNameFiltered = meterName.toLowerCase() !== "";

    let filtered: MeterType[];

    if (
      isMeterNameFiltered &&
      !isCompanyFiltered &&
      !isLocationFiltered &&
      !isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return meter.name.toLowerCase().includes(meterName.toLowerCase());
      });
    } else if (
      isMeterNameFiltered &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      !isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return (
          meter.name.toLowerCase().includes(meterName.toLowerCase()) &&
          meter.company.toLowerCase() === company.toLowerCase()
        );
      });
    } else if (
      isMeterNameFiltered &&
      isCompanyFiltered &&
      isLocationFiltered &&
      !isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return (
          meter.name.toLowerCase().includes(meterName.toLowerCase()) &&
          meter.company.toLowerCase() === company.toLowerCase() &&
          meter.location.toLowerCase() === location.toLowerCase()
        );
      });
    } else if (
      isMeterNameFiltered &&
      isCompanyFiltered &&
      isLocationFiltered &&
      isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return (
          meter.name.toLowerCase().includes(meterName.toLowerCase()) &&
          meter.company.toLowerCase() === company.toLowerCase() &&
          meter.location.toLowerCase() === location.toLowerCase() &&
          meter.number.toLowerCase().includes(meterNumber.toLowerCase())
        );
      });
    } else if (
      !isMeterNameFiltered &&
      isCompanyFiltered &&
      isLocationFiltered &&
      isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return (
          meter.company.toLowerCase() === company.toLowerCase() &&
          meter.location.toLowerCase() === location.toLowerCase() &&
          meter.number.toLowerCase().includes(meterNumber.toLowerCase())
        );
      });
    } else if (
      !isMeterNameFiltered &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return (
          meter.location.toLowerCase() === location.toLowerCase() &&
          meter.number.toLowerCase().includes(meterNumber.toLowerCase())
        );
      });
    } else if (
      !isMeterNameFiltered &&
      !isCompanyFiltered &&
      !isLocationFiltered &&
      isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return meter.number.toLowerCase().includes(meterNumber.toLowerCase());
      });
    } else if (
      !isMeterNameFiltered &&
      isCompanyFiltered &&
      isLocationFiltered &&
      !isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return (
          meter.company.toLowerCase() === company.toLowerCase() &&
          meter.location.toLowerCase() === location.toLowerCase()
        );
      });
    } else if (
      !isMeterNameFiltered &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return (
          meter.company.toLowerCase() === company.toLowerCase() &&
          meter.number.toLowerCase().includes(meterNumber.toLowerCase())
        );
      });
    } else if (
      !isMeterNameFiltered &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      !isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return meter.location.toLowerCase() === location.toLowerCase();
      });
    } else if (
      !isMeterNameFiltered &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      !isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return meter.company.toLowerCase() === company.toLowerCase();
      });
    } else if (
      isMeterNameFiltered &&
      !isCompanyFiltered &&
      !isLocationFiltered &&
      isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return (
          meter.name.toLowerCase().includes(meterName.toLowerCase()) &&
          meter.number.toLowerCase().includes(meterNumber.toLowerCase())
        );
      });
    } else if (
      isMeterNameFiltered &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      !isMeterNameFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return (
          meter.name.toLowerCase().includes(meterName.toLowerCase()) &&
          meter.location.toLowerCase() === selected.toLowerCase()
        );
      });
    } else if (
      isMeterNameFiltered &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return (
          meter.name.toLowerCase().includes(meterName.toLowerCase()) &&
          meter.number.toLowerCase().includes(meterNumber.toLowerCase()) &&
          meter.location.toLowerCase() === selected.toLowerCase()
        );
      });
    } else if (
      isMeterNameFiltered &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      isMeterNumberFiltered
    ) {
      filtered = state.meters.filter((meter) => {
        return (
          meter.name.toLowerCase().includes(meterName.toLowerCase()) &&
          meter.number.toLowerCase().includes(meterNumber.toLowerCase()) &&
          meter.company.toLowerCase() === company.toLowerCase()
        );
      });
    } else {
      filtered = [];
    }

    let updateCompanyState = {
      ...state,
      filteredMeters: filtered,
      isFiltered:
        isCompanyFiltered ||
        isLocationFiltered ||
        isMeterNumberFiltered ||
        isMeterNameFiltered
          ? true
          : false,
    };

    setState(updateCompanyState);
  };

  const handleMeterNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const keyword = e.target.value.toLowerCase();
    const company =
      selectedCompany === "None" ? "Not Registered" : selectedCompany;

    const isCompanyFiltered = company.toLowerCase() !== "all companies";
    const isLocationFiltered = selected.toLowerCase() !== "all locations";
    const isMeterNumberFiltered = meterNumber.toLowerCase() !== "";

    const isKeyword = keyword.length > 0 ? true : false;

    let filtered: MeterType[];

    if (
      isKeyword &&
      !isCompanyFiltered &&
      !isLocationFiltered &&
      !isMeterNumberFiltered
    ) {
      setMeterName(keyword);
      filtered = state.meters.filter((meter) => {
        return meter.name.toLowerCase().includes(keyword);
      });
    } else if (
      isKeyword &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      !isMeterNumberFiltered
    ) {
      setMeterName(keyword);
      filtered = state.meters.filter((meter) => {
        return (
          meter.name.toLowerCase().includes(keyword) &&
          meter.company.toLowerCase() === company.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      isCompanyFiltered &&
      isLocationFiltered &&
      !isMeterNumberFiltered
    ) {
      setMeterName(keyword);
      filtered = state.meters.filter((meter) => {
        return (
          meter.name.toLowerCase().includes(keyword) &&
          meter.company.toLowerCase() === company.toLowerCase() &&
          meter.location.toLowerCase() === selected.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      isCompanyFiltered &&
      isLocationFiltered &&
      isMeterNumberFiltered
    ) {
      setMeterName(keyword);
      filtered = state.meters.filter((meter) => {
        return (
          meter.name.toLowerCase().includes(keyword) &&
          meter.company.toLowerCase() === company.toLowerCase() &&
          meter.location.toLowerCase() === selected.toLowerCase() &&
          meter.number.toLowerCase().includes(meterNumber.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      isCompanyFiltered &&
      isLocationFiltered &&
      isMeterNumberFiltered
    ) {
      setMeterName("");
      filtered = state.meters.filter((meter) => {
        return (
          meter.company.toLowerCase() === company.toLowerCase() &&
          meter.location.toLowerCase() === selected.toLowerCase() &&
          meter.number.toLowerCase().includes(meterNumber.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      isMeterNumberFiltered
    ) {
      setMeterName("");
      filtered = state.meters.filter((meter) => {
        return (
          meter.location.toLowerCase() === selected.toLowerCase() &&
          meter.number.toLowerCase().includes(meterNumber.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      !isCompanyFiltered &&
      !isLocationFiltered &&
      isMeterNumberFiltered
    ) {
      setMeterName("");
      filtered = state.meters.filter((meter) => {
        return meter.number.toLowerCase().includes(meterNumber.toLowerCase());
      });
    } else if (
      !isKeyword &&
      isCompanyFiltered &&
      isLocationFiltered &&
      !isMeterNumberFiltered
    ) {
      setMeterName("");
      filtered = state.meters.filter((meter) => {
        return (
          meter.company.toLowerCase() === company.toLowerCase() &&
          meter.location.toLowerCase() === selected.toLowerCase()
        );
      });
    } else if (
      !isKeyword &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      isMeterNumberFiltered
    ) {
      setMeterName("");
      filtered = state.meters.filter((meter) => {
        return (
          meter.company.toLowerCase() === company.toLowerCase() &&
          meter.number.toLowerCase().includes(meterNumber.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      !isMeterNumberFiltered
    ) {
      setMeterName("");
      filtered = state.meters.filter((meter) => {
        return meter.location.toLowerCase() === selected.toLowerCase();
      });
    } else if (
      !isKeyword &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      !isMeterNumberFiltered
    ) {
      setMeterName("");
      filtered = state.meters.filter((meter) => {
        return meter.company.toLowerCase() === company.toLowerCase();
      });
    } else if (
      isKeyword &&
      !isCompanyFiltered &&
      !isLocationFiltered &&
      isMeterNumberFiltered
    ) {
      setMeterName(keyword);
      filtered = state.meters.filter((meter) => {
        return (
          meter.name.toLowerCase().includes(keyword) &&
          meter.number.toLowerCase().includes(meterNumber.toLowerCase())
        );
      });
    } else if (
      isKeyword &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      !isMeterNumberFiltered
    ) {
      setMeterName(keyword);
      filtered = state.meters.filter((meter) => {
        return (
          meter.name.toLowerCase().includes(keyword) &&
          meter.location.toLowerCase() === selected.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      isMeterNumberFiltered
    ) {
      setMeterName(keyword);
      filtered = state.meters.filter((meter) => {
        return (
          meter.name.toLowerCase().includes(keyword) &&
          meter.number.toLowerCase().includes(meterNumber.toLowerCase()) &&
          meter.location.toLowerCase() === selected.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      isMeterNumberFiltered
    ) {
      setMeterName(keyword);
      filtered = state.meters.filter((meter) => {
        return (
          meter.name.toLowerCase().includes(keyword) &&
          meter.number.toLowerCase().includes(meterNumber.toLowerCase()) &&
          meter.company.toLowerCase() === company.toLowerCase()
        );
      });
    } else {
      setMeterName("");
      filtered = [];
    }

    let updateMetersState = {
      ...state,
      filteredMeters: filtered.length > 0 ? filtered : [],
      isFiltered:
        isCompanyFiltered ||
        isLocationFiltered ||
        isMeterNumberFiltered ||
        isKeyword
          ? true
          : false,
    };

    setState(updateMetersState);
  };

  const handleMeterNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const keyword = e.target.value.toLowerCase();
    const company =
      selectedCompany === "None" ? "Not Registered" : selectedCompany;

    const isCompanyFiltered = company.toLowerCase() !== "all companies";
    const isLocationFiltered = selected.toLowerCase() !== "all locations";
    const isMeterNameFiltered = meterName.toLowerCase() !== "";

    const isKeyword = keyword.length > 0 ? true : false;

    let filtered: MeterType[];

    if (
      isKeyword &&
      !isCompanyFiltered &&
      !isLocationFiltered &&
      !isMeterNameFiltered
    ) {
      setMeterNumber(keyword);
      filtered = state.meters.filter((meter) => {
        return meter.number.toLowerCase().includes(keyword);
      });
    } else if (
      isKeyword &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      !isMeterNameFiltered
    ) {
      setMeterNumber(keyword);
      filtered = state.meters.filter((meter) => {
        return (
          meter.number.toLowerCase().includes(keyword) &&
          meter.company.toLowerCase() === company.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      isCompanyFiltered &&
      isLocationFiltered &&
      !isMeterNameFiltered
    ) {
      setMeterNumber(keyword);
      filtered = state.meters.filter((meter) => {
        return (
          meter.number.toLowerCase().includes(keyword) &&
          meter.company.toLowerCase() === company.toLowerCase() &&
          meter.location.toLowerCase() === selected.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      isCompanyFiltered &&
      isLocationFiltered &&
      isMeterNameFiltered
    ) {
      setMeterNumber(keyword);
      filtered = state.meters.filter((meter) => {
        return (
          meter.number.toLowerCase().includes(keyword) &&
          meter.company.toLowerCase() === company.toLowerCase() &&
          meter.location.toLowerCase() === selected.toLowerCase() &&
          meter.name.toLowerCase().includes(meterName.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      isCompanyFiltered &&
      isLocationFiltered &&
      isMeterNameFiltered
    ) {
      setMeterNumber(keyword);
      filtered = state.meters.filter((meter) => {
        return (
          meter.company.toLowerCase() === company.toLowerCase() &&
          meter.location.toLowerCase() === selected.toLowerCase() &&
          meter.name.toLowerCase().includes(meterName.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      isMeterNameFiltered
    ) {
      setMeterNumber(keyword);
      filtered = state.meters.filter((meter) => {
        return (
          meter.location.toLowerCase() === selected.toLowerCase() &&
          meter.name.toLowerCase().includes(meterName.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      !isCompanyFiltered &&
      !isLocationFiltered &&
      isMeterNameFiltered
    ) {
      setMeterNumber(keyword);

      filtered = state.meters.filter((meter) => {
        return meter.name.toLowerCase().includes(meterName.toLowerCase());
      });
    } else if (
      !isKeyword &&
      isCompanyFiltered &&
      isLocationFiltered &&
      !isMeterNameFiltered
    ) {
      setMeterNumber(keyword);
      filtered = state.meters.filter((meter) => {
        return (
          meter.company.toLowerCase() === company.toLowerCase() &&
          meter.location.toLowerCase() === selected.toLowerCase()
        );
      });
    } else if (
      !isKeyword &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      isMeterNameFiltered
    ) {
      setMeterNumber(keyword);
      filtered = state.meters.filter((meter) => {
        return (
          meter.company.toLowerCase() === company.toLowerCase() &&
          meter.name.toLowerCase().includes(meterName.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      !isMeterNameFiltered
    ) {
      setMeterNumber(keyword);
      filtered = state.meters.filter((meter) => {
        return meter.location.toLowerCase() === selected.toLowerCase();
      });
    } else if (
      !isKeyword &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      !isMeterNameFiltered
    ) {
      setMeterNumber(keyword);
      filtered = state.meters.filter((meter) => {
        return meter.company.toLowerCase() === company.toLowerCase();
      });
    } else if (
      isKeyword &&
      !isCompanyFiltered &&
      !isLocationFiltered &&
      isMeterNameFiltered
    ) {
      setMeterNumber(keyword);
      filtered = state.meters.filter((meter) => {
        return (
          meter.number.toLowerCase().includes(keyword) &&
          meter.name.toLowerCase().includes(meterName.toLowerCase())
        );
      });
    } else if (
      isKeyword &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      !isMeterNameFiltered
    ) {
      setMeterNumber(keyword);
      filtered = state.meters.filter((meter) => {
        return (
          meter.number.toLowerCase().includes(keyword) &&
          meter.location.toLowerCase() === selected.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      !isCompanyFiltered &&
      isLocationFiltered &&
      isMeterNameFiltered
    ) {
      setMeterNumber(keyword);
      filtered = state.meters.filter((meter) => {
        return (
          meter.number.toLowerCase().includes(keyword) &&
          meter.name.toLowerCase().includes(meterName.toLowerCase()) &&
          meter.location.toLowerCase() === selected.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      isCompanyFiltered &&
      !isLocationFiltered &&
      isMeterNameFiltered
    ) {
      setMeterNumber(keyword);
      filtered = state.meters.filter((meter) => {
        return (
          meter.number.toLowerCase().includes(keyword) &&
          meter.name.toLowerCase().includes(meterName.toLowerCase()) &&
          meter.company.toLowerCase() === company.toLowerCase()
        );
      });
    } else {
      setMeterNumber("");
      filtered = [];
    }

    let updateMetersState = {
      ...state,
      filteredMeters: filtered.length > 0 ? filtered : [],
      isFiltered:
        isLocationFiltered ||
        isCompanyFiltered ||
        isMeterNameFiltered ||
        isKeyword
          ? true
          : false,
    };

    setState(updateMetersState);
  };

  return (
    <>
      <div className="w-full">
        {state.meters && (
          <>
            <div className="mb-6">
              {purchaseRequest.showFilters ? (
                <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                  <div className="sm:col-span-2">
                    <Listbox
                      value={selectedCompany}
                      onChange={handleCompanyChange}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-xs font-normal text-gray-400">
                            Company
                          </Listbox.Label>
                          <div className="relative">
                            <Listbox.Button className="bg-gray-50 relative w-full border-b border-gray-300 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                              <span className="block truncate">
                                {selectedCompany}
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60  py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {companies.map((company, index) => (
                                  <Listbox.Option
                                    key={index}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "text-white bg-blue-600"
                                          : "text-gray-900",
                                        "cursor-default select-none relative py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={company}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate"
                                          )}
                                        >
                                          {company}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-blue-600",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                  <div className="sm:col-span-2">
                    <Listbox value={selected} onChange={handleLocationChange}>
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-xs font-normal text-gray-400">
                            Location
                          </Listbox.Label>
                          <div className="relative">
                            <Listbox.Button className="bg-gray-50 relative w-full border-b border-gray-300 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                              <span className="block truncate">{selected}</span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60  py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {locations.map((location, index) => (
                                  <Listbox.Option
                                    key={index}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "text-white bg-blue-600"
                                          : "text-gray-900",
                                        "cursor-default select-none relative py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={location}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate"
                                          )}
                                        >
                                          {location}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-blue-600",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="name"
                      className="block text-xs font-normal text-gray-400"
                    >
                      Meter Name
                    </label>
                    <div className=" border-b border-gray-300 focus-within:border-blue-600">
                      <input
                        onChange={(e) => handleMeterNameChange(e)}
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full border-0 py-2 border-b border-transparent bg-gray-50 focus:border-blue-600 focus:ring-0 sm:text-sm"
                        placeholder="Meter Name"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="meter"
                      className="block text-xs font-normal text-gray-400"
                    >
                      Meter Number
                    </label>
                    <div className=" border-b border-gray-300 focus-within:border-blue-600">
                      <input
                        onChange={(e) => handleMeterNumberChange(e)}
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full border-0  py-2 border-b border-transparent bg-gray-50 focus:border-blue-600 focus:ring-0 sm:text-sm"
                        placeholder="Meter Number"
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <form action="#" method="POST">
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-1 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    {!purchaseRequest.purchaseAmountsValid && (
                      <div className="my-4 block text-sm font-bold text-red-500">
                        Maximum amount allowed per meter:{" "}
                        {currencyFormatter(10000, defaultOptions)}
                      </div>
                    )}

                    <div className="pb-8 overflow-hidden">
                      {meterList.length > 0 ? (
                        <table className="min-w-full bg-gray-50 border rounded-sm border-gray-200">
                          <thead className="sticky bg-gray-200">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
                              >
                                <div>Company</div>
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
                              >
                                <div>Meter Name &amp;</div>
                                <div>Location</div>
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
                              >
                                <div>Meter Number &amp;</div>
                                <div>Registered Owner</div>
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
                              >
                                <div>Last Purchase</div>
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
                              >
                                Purchase Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {meterList.map((meter, meterIdx) => (
                              <tr
                                key={meter.id}
                                className="border-b border-gray-150"
                              >
                                <td className="px-6 py-1 whitespace-nowrap">
                                  <div className="text-sm font-medium text-gray-900">
                                    {meter.company === "Not Registered"
                                      ? "None"
                                      : meter.company}
                                  </div>
                                </td>
                                <td className="px-6 py-1 whitespace-nowrap text-sm font-normal">
                                  <div className="text-sm font-medium text-gray-900">
                                    {meter.name}
                                  </div>
                                  <div className="text-sm text-gray-500">
                                    {meter.location}
                                  </div>
                                </td>
                                <td className="px-6 py-1 whitespace-nowrap">
                                  <div className="text-sm font-medium text-gray-900">
                                    {meter.number}
                                  </div>
                                  <div className="text-sm text-gray-500">
                                    {meter.registeredOwner}
                                  </div>
                                </td>
                                <td className="px-6 py-1 whitespace-nowrap text-sm font-normal text-gray-500">
                                  <div className="text-sm font-medium text-gray-900">
                                    {meter.lastPurchase.dateCreated === "None"
                                      ? "None"
                                      : format(
                                          new Date(
                                            meter.lastPurchase.dateCreated
                                          ),
                                          "yyyy-MM-dd"
                                        )}
                                    {/* &nbsp; &mdash; &nbsp; */}
                                  </div>
                                  <div className="text-sm text-gray-500">
                                    {currencyFormatter(
                                      meter.lastPurchase.purchaseAmount,
                                      defaultOptions
                                    )}
                                  </div>
                                </td>
                                <td className="px-6 py-1 whitespace-nowrap text-sm text-gray-500">
                                  <div className="border border-gray-100 bg-white focus-within:border-blue-600">
                                    <input
                                      onWheel={(event) =>
                                        event.currentTarget.blur()
                                      }
                                      onChange={(e) => addMeterToRequest(e)}
                                      value={
                                        meter.purchaseAmount
                                          ? meter.purchaseAmount
                                          : ""
                                      }
                                      type="number"
                                      min={0}
                                      name="purchaseAmount"
                                      id={`${meter.number}`}
                                      className="block w-full border border-gray-200 placeholder-gray-300 font-medium py-2 border-b border-transparent bg-white focus:border-blue-600 focus:ring-0 sm:text-sm"
                                      placeholder="0.00"
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <h2 className="text-md leading-6 font-medium text-gray-900">
                          No results found for the selected filter(s).
                        </h2>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* {state.error && <Error error={state.error} reload={reload} />} */}
            </form>
          </>
        )}

        {purchaseRequest.enableStepsButton &&
          purchaseRequest.purchaseAmountsValid && (
            <StepsButton
              createElectricityPurchaseRequest={null}
              step="initiate"
              isEnabled={purchaseRequest.enableStepsButton}
              primaryText="Confirm Request"
              secondaryText="Clear All"
            />
          )}
      </div>
    </>
  );
}

import ChangeCurrentUserPassword from "../../../../../../../presentation/pages/users/account/components/changeCurrentUserPassword";
import { makeChangeCurrentUserPasswordValidation } from "./change-current-user-password-validation-factory";

export const makeChangeCurrentUserPassword = () => {
  return (
    <ChangeCurrentUserPassword
      validation={makeChangeCurrentUserPasswordValidation()}
    />
  );
};

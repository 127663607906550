import { format } from "date-fns";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { RemotePurchaseRequestModel } from "../../../../data/models";
import {
  ApprovePurchaseRequest,
  GetPurchaseRequestsByStatus,
} from "../../../../domain/usecases";
import axios from "axios";
import { currentAccountState } from "../../../components";
import { currencyFormatter } from "../../../utils/helpers";
import { pendingApprovalsState } from "../atoms/atoms";
import { useErrorHandler } from "../../../hooks";
import { useEffect, useState } from "react";
import { useFirestore } from "../../../hooks/use-firestore";

type Props = {
  transaction: RemotePurchaseRequestModel;
  approvePurchaseRequest: ApprovePurchaseRequest;
  getPendingPurchaseRequests: GetPurchaseRequestsByStatus;
};

export default function TransactionDetailsRow({
  approvePurchaseRequest,
  getPendingPurchaseRequests,
  transaction,
}: Props) {
  const setState = useSetRecoilState(pendingApprovalsState);
  const { getCurrentAccount } = useRecoilValue(currentAccountState);
  const [ip, setIP] = useState("");
  const firestore = useFirestore();

  const [toggleReject, setToggleReject] = useState(false);
  const [toggleApproval, setToggleApproval] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [orderReference, setOrderReference] = useState("");
  const [orderReferenceError, setOrderReferenceError] = useState("");

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    console.log(res.data);
    setIP(res.data.IPv4);
  };
  const handleError = useErrorHandler((error: Error) => {
    console.log("error!!!", error);
    setState((old) => ({ ...old, isLoading: false, error: error.message }));
  });

  const toggleRejectClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setToggleReject(!toggleReject);
  };

  const toggleApprovalClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setToggleApproval(!toggleApproval);
  };

  const handleRejectReasonChange = (event) => {
    event.preventDefault();
    setRejectReason(event.target.value);
  };

  const rejectPurchaseRequest = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    setState((old) => ({
      ...old,
      isLoading: true,
    }));

    const rejection = {
      actionedBy: getCurrentAccount().user_id,
      actionedByName: getCurrentAccount().displayName,
      ipAddress: ip,
      status: "REJECTED",
      reason: rejectReason,
      dateTimeActioned: new Date(),
    };
    await firestore.rejectPurchaseRequest(transaction.id, rejection);
    toggleRejectClick(e);

    await getPendingPurchaseRequests
      .fetch({
        status: "PENDING",
        accountNumber: getCurrentAccount().account,
      })
      .then((result) =>
        setState((old) => ({
          ...old,
          pendingPurchaseRequests: result,
          isLoading: false,
        }))
      )
      .catch(handleError);
  };

  const approveMeterApplicationClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    console.log("isLoading: true");

    if (orderReference.length === 0) {
      setOrderReferenceError("Provide an order reference");
      return;
    }
    setState((old) => ({
      ...old,
      isLoading: true,
    }));

    const ordersReferenceExists = await firestore.checkIfOrdersReferenceExists(
      getCurrentAccount().account,
      orderReference
    );

    console.log("ordersReferenceExists", ordersReferenceExists, orderReference);

    if (ordersReferenceExists) {
      console.log("ordersReferenceExists", ordersReferenceExists);
      setOrderReferenceError(
        `The provided order reference \"${orderReference}\" is already in use`
      );

      setState((old) => ({
        ...old,
        isLoading: false,
      }));
      return;
    }

    await approvePurchaseRequest
      .approve({
        account: getCurrentAccount().account,
        userId: getCurrentAccount().user_id,
        userName: getCurrentAccount().displayName,
        purchaseRequestNo: transaction.purchaseRequestNo,
        orderReference: orderReference,
      })
      .then(async () => {
        await getPendingPurchaseRequests
          .fetch({
            status: "PENDING",
            accountNumber: getCurrentAccount().account,
          })
          .then((result) =>
            setState((old) => ({
              ...old,
              pendingPurchaseRequests: result,
            }))
          );
      })
      .catch(handleError);

    // = fetchData();

    setState((old) => ({
      ...old,
      isLoading: false,
    }));
  };

  const handleOrderReferenceChange = (event) => {
    event.preventDefault();

    setOrderReference(event.target.value);
    setOrderReferenceError("");
    // orderReference.length === 0
    //   ? setOrderReferenceError("Provide an order reference")
    //   :
  };

  const electricityRow = () => {
    return (
      <>
        <th
          scope="col"
          className="px-4 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <div>Meter Name &amp;</div>
          <div>Location</div>
        </th>
        <th
          scope="col"
          className="px-4 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <div>Meter Number &amp;</div>
          <div>Registered Owner</div>
        </th>
        <th
          scope="col"
          className="px-4 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <div>Last Purchase &amp;</div>
          <div>Amount</div>
        </th>
        <th
          scope="col"
          className="px-4 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          Amount
        </th>
      </>
    );
  };

  const airtimeRow = () => {
    return (
      <>
        <th
          scope="col"
          className="px-4 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <div>Owner &amp;</div>
          <div>Job Title</div>
        </th>
        <th
          scope="col"
          className="px-4 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <div>Number &amp;</div>
          <div>Network</div>
        </th>
        <th
          scope="col"
          className="px-4 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <div>Last Purchase &amp;</div>
          <div>Amount</div>
        </th>
        <th
          scope="col"
          className="px-4 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          Amount
        </th>
      </>
    );
  };

  const dstvRow = () => {
    return (
      <>
        <th
          scope="col"
          className="px-4 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <div>Smartcard Name &amp;</div>
          <div>Location</div>
        </th>
        <th
          scope="col"
          className="px-4 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <div>Smartcard Number &amp;</div>
          <div>Registered Owner</div>
        </th>
        <th
          scope="col"
          className="px-4 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <div>Last Purchase &amp;</div>
          <div>Amount</div>
        </th>
        <th
          scope="col"
          className="px-4 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          Amount
        </th>
      </>
    );
  };

  const tableHeader: { [key: string]: JSX.Element } = {
    electricity: electricityRow(),
    airtime: airtimeRow(),
    dstv: dstvRow(),
  };

  return (
    <>
      <div className="overflow-hidden w-full">
        <div className="flex flex-col">
          <div className=" overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-1 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              {/* Description list*/}
              <section aria-labelledby="purchase-request-approval-title">
                <div className="bg-white py-4 px-6 shadow ">
                  <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                    <div className="lg:col-span-6">
                      <h2 className="text-md leading-6 font-medium text-gray-900">
                        Purchase Request Approval
                      </h2>
                      <p className=" max-w-2xl text-sm text-gray-500">
                        Please review following purchase requests raised.
                      </p>
                    </div>
                    <div className="lg:col-span-6 text-right">
                      {getCurrentAccount().role === "Administrator" &&
                      !toggleReject &&
                      !toggleApproval ? (
                        <>
                          <button
                            onClick={(e) => toggleRejectClick(e)}
                            type="button"
                            className="bg-white py-2 px-6 s border-2 border-red-500 rounded-sm shadow-sm text-sm font-medium text-red-500 disabled:opacity-50 hover:text-white hover:bg-red-500 hover:border-transparent focus:outline-none active:bg-red-700"
                          >
                            Reject
                          </button>
                          <button
                            onClick={(e) => toggleApprovalClick(e)}
                            type="button"
                            className="ml-4 inline-flex justify-center py-2 px-6 border 
                                border-transparent rounded-sm shadow-sm disabled:opacity-50 
                                text-sm font-medium  text-white  bg-blue-600 hover:bg-blue-700  active:bg-indingo-800 focus:outline-none"
                          >
                            Approve
                          </button>
                        </>
                      ) : null}
                    </div>
                  </div>

                  <div className="border-t border-gray-200 py-4 mt-3 mb-3 ">
                    {toggleReject ? (
                      <div className="min-w-0 flex-1 lg:grid lg:grid-cols-12 lg:gap-x-5">
                        <div className="lg:col-span-6"> </div>
                        <div className="lg:col-span-6">
                          <form action="#">
                            <div>
                              <label
                                htmlFor="deactivateReason"
                                className="sr-only"
                              >
                                Reject Purchase Request
                              </label>
                              <textarea
                                id="rejectReason"
                                name="rejectReason"
                                rows={3}
                                value={rejectReason}
                                onChange={handleRejectReasonChange}
                                className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-sm"
                                placeholder="Please provide the reason for rejecting the purchase request."
                                defaultValue={""}
                              />
                            </div>
                            <div className="mt-3 flex items-center justify-between">
                              <span> </span>

                              <div>
                                <>
                                  <button
                                    onClick={(e) => toggleRejectClick(e)}
                                    type="button"
                                    className="bg-white py-2 px-6  border-2 border-red-500 rounded-sm shadow-sm text-sm font-medium text-red-500 disabled:opacity-50 hover:bg-gray-50 focus:outline-none "
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    onClick={(e) => rejectPurchaseRequest(e)}
                                    type="submit"
                                    className="ml-3 inline-flex justify-center py-2 px-6 border border-transparent rounded-sm shadow-sm disabled:opacity-50 text-sm font-medium  text-white  bg-blue-600 hover:bg-blue-700 active:bg-blue-800 focus:outline-none "
                                  >
                                    Reject Purchase Request
                                  </button>
                                </>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    ) : null}

                    {toggleApproval ? (
                      <div className="min-w-0 flex-1 lg:grid lg:grid-cols-12 lg:gap-x-5 place-items-end">
                        <div className="lg:col-span-6"> </div>
                        <div className="lg:col-span-6">
                          <form action="#">
                            <div className="flex">
                              <label
                                htmlFor="deactivateReason"
                                className="sr-only"
                              >
                                Rejection Reversal Reason
                              </label>
                              {/* <input type="text"
                               id="emailAddress"
                                name="emailAddress"
                                 class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-sm"
                                  title=""
                                   data-testid="emailAddress"></input> */}
                              <input
                                id="deactivateReason"
                                name="deactivateReason"
                                value={orderReference}
                                onChange={handleOrderReferenceChange}
                                className="appearance-none w-full content-end block px-3 py-2 border border-gray-300 rounded-sm shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                placeholder="Purchase Order Reference."
                              />
                            </div>
                            {orderReferenceError && (
                              <div>
                                <span className="text-sm text-red-500">
                                  {orderReferenceError}
                                </span>
                              </div>
                            )}

                            <div className="mt-3 flex items-center justify-between">
                              <span> </span>

                              <div>
                                <>
                                  <button
                                    onClick={(e) => toggleApprovalClick(e)}
                                    type="button"
                                    className="bg-white py-2 px-6 border-2 border-red-500 rounded-sm shadow-sm text-sm font-medium text-red-500 disabled:opacity-50 hover:bg-gray-50 focus:outline-none "
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    onClick={(e) =>
                                      approveMeterApplicationClick(e)
                                    }
                                    type="submit"
                                    className="ml-3 inline-flex justify-center py-2 px-6 border border-transparent rounded-sm shadow-sm disabled:opacity-50 text-sm font-medium  text-white  bg-blue-600 hover:bg-blue-700 active:bg-blue-800 focus:outline-none "
                                  >
                                    Approve Purchase Request
                                  </button>
                                </>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    ) : null}

                    <div className=" pb-5">
                      <h2
                        id="purchase-request-approval-title"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Purchase Request Details
                      </h2>
                    </div>
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-700">
                          Purchase Number
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {transaction.purchaseRequestNo}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-700">
                          Amount
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {currencyFormatter(transaction.purchaseRequestTotal)}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-700">
                          {/* <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium capitalize">
                            {transaction.purchaseType.toLocaleLowerCase()}s
                          </span> */}
                          Items in Request
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {transaction.purchaseRequestItems.length}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-700">
                          Submitted By
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {
                            transaction.statusHistory.find(
                              (s) => s.status === "PENDING"
                            ).actionedByName
                          }
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-700">
                          Date of Submission
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {format(
                            new Date(transaction.dateCreated),
                            "yyyy-MM-dd HH:mm:ss"
                          )}
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div className="overflow-hidden">
                    <table className="min-w-full bg-gray-50 border rounded-sm border-gray-200">
                      <thead className="sticky bg-gray-200">
                        <tr>
                          {tableHeader[transaction.purchaseType.toLowerCase()]}
                        </tr>
                      </thead>
                      <tbody>
                        {transaction &&
                          transaction.purchaseRequestItems.map(
                            (item, meterIdx) => (
                              <tr
                                key={item.itemNumber}
                                className="border-b border-gray-150"
                              >
                                {item.itemType === "AIRTIME" ? (
                                  <td className="px-4 py-1 whitespace-nowrap text-sm font-normal">
                                    <div className="text-sm text-gray-500">
                                      {item.name}
                                    </div>

                                    <div className="text-sm font-medium text-gray-900">
                                      {item.jobTitle}
                                    </div>
                                  </td>
                                ) : (
                                  <td className="px-4 py-1 whitespace-nowrap text-sm font-normal">
                                    <div className="text-sm text-gray-500">
                                      {item.name}
                                    </div>

                                    <div className="text-sm font-medium text-gray-900">
                                      {item.location}
                                    </div>
                                  </td>
                                )}

                                <td className="px-4 py-1 whitespace-nowrap">
                                  <div className="text-sm text-gray-500">
                                    {item.itemNumber}
                                  </div>
                                  <div className="text-sm font-medium text-gray-900 capitalize">
                                    {item.owner.toLowerCase()}
                                  </div>
                                </td>
                                <td className="px-4 py-1 whitespace-nowrap text-sm font-normal text-gray-500">
                                  <div className="text-sm text-gray-500">
                                    {item.lastPurchaseDate === "None"
                                      ? "None"
                                      : format(
                                          new Date(item.lastPurchaseDate),
                                          "yyyy-MM-dd"
                                        )}
                                  </div>
                                  <div className="text-sm font-medium text-gray-900">
                                    {currencyFormatter(item.lastPurchaseAmount)}
                                  </div>
                                </td>
                                <td className="px-4 py-1 whitespace-nowrap text-sm text-gray-500">
                                  <span className="text-gray-900 font-bold">
                                    {currencyFormatter(item.amount)}
                                    {console.log(
                                      "transaction.purchaseType",
                                      transaction.purchaseType
                                    )}
                                  </span>
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { Disclosure, Listbox, Transition } from "@headlessui/react";
import { SelectorIcon, CheckIcon } from "@heroicons/react/outline";
import { format } from "date-fns";
import { query, collection, onSnapshot } from "firebase/firestore";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { db } from "../../../main/firebase";

import logo from "../../assets/logo.svg";
import { classNames } from "../../utils/helpers";
import { merchantsState, merchantState } from "./atoms/atoms";

export default function LoginAs() {
  const [state, setState] = useRecoilState(merchantsState);
  const merchants = useRecoilValue(merchantState);
  const [selectedMerchant, setselectedMerchant] = useState(merchants[0]);

  const [selected, setSelected] = useState(merchants[0]);

  useEffect(() => {
    const q = query(collection(db, "account"));
    return onSnapshot(q, (querySnapshot) => {
      let result = querySnapshot.docs.map((doc) => {
        return {
          name: doc.data().name,
          number: doc.data().companyNumber,
          postalAddress: doc.data().postalAddress,
          city: doc.data().city,
        };
      });

      setState((old) => ({ ...old, merchants: result }));
    });
  }, []);

  const handleMerchantChange = (name: string) => {
    // event.preventDefault();

    setSelected(name);

    const filtered =
      name.toLowerCase() === "select a merchant"
        ? []
        : state.merchants.filter((merchant) => {
            return merchant.name.toLowerCase() === name.toLowerCase();
          });

    let updateMerchantState = {
      ...state,
      filteredMerchants: filtered,
      isFiltered: name.toLowerCase() === "select a merchant" ? false : true,
    };

    setState(updateMerchantState);
  };

  return (
    <>
      <div className="flex flex-col min-h-screen bg-gray-100">
        <Disclosure
          as="nav"
          className="sticky top-0 z-50  border-b border-gray-200 bg-white"
        >
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto">
                <div className="flex items-center justify-between h-16">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <Link to="/" className="block lg:hidden h-7 w-auto">
                        <img src={logo} alt="PrepaidPlus" />
                      </Link>

                      <Link to="/" className="hidden lg:block h-7 w-auto">
                        <img src={logo} alt="PrepaidPlus" />
                      </Link>
                    </div>
                    <div className="hidden md:block"></div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Disclosure>

        <div className="bg-blue-100 w-full">
          <div className=" lg:max-w-7xl lg:mx-auto">
            <div className="py-4 md:flex md:items-center md:justify-between">
              <div className="flex-1 min-w-0">
                <div className="flex items-center">
                  <h3 className="text-xl font-medium leading-6 text-gray-900 ">
                    <span>Login-As </span>
                  </h3>
                </div>
              </div>
              <div className="mt-4 flex space-x-2 md:mt-0 md:ml-4">
                <span className="text-xl leading-6 font-medium text-gray-900">
                  {/* {getCurrentAccount().merchantName} */}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col flex-grow lg:max-w-7xl lg:mx-auto">
          <div className="py-6">
            <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
              <aside className="py-8 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                <div className="sm:col-span-2">
                  <Listbox
                    value={selectedMerchant}
                    onChange={handleMerchantChange}
                  >
                    {({ open }) => (
                      <>
                        <Listbox.Label className="block text-xs font-normal text-gray-400">
                          Merchant
                        </Listbox.Label>
                        <div className="relative">
                          <Listbox.Button className="bg-gray-50 relative w-full border-b border-gray-300 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                            <span className="block truncate">
                              {selectedMerchant}
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <SelectorIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60  py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                              {merchants.map((merchant, index) => (
                                <Listbox.Option
                                  key={index}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? "text-white bg-blue-600"
                                        : "text-gray-900",
                                      "cursor-default select-none relative py-2 pl-3 pr-9"
                                    )
                                  }
                                  value={merchant}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )}
                                      >
                                        {merchant}
                                      </span>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? "text-white"
                                              : "text-blue-600",
                                            "absolute inset-y-0 right-0 flex items-center pr-4"
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>
              </aside>

              <div className="space-y-8 sm:px-6 lg:px-0 lg:col-span-9">
                Outlet
              </div>
            </div>
          </div>
        </div>

        <footer className="sticky bottom-0  z-40 bg-white  text-xs font-medium text-gray-500 ring-1 ring-gray-900 ring-opacity-5 shadow-sm">
          <div className="max-w-7xl mx-auto">
            <div className="flex items-center justify-between h-14">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <span className="hint-text">
                    Copyright © {format(new Date(), "yyyy")}
                  </span>
                  <span className="font-montserrat text-gray-900 font-bold">
                    &nbsp; Cachet Enterprises
                  </span>
                  <span className="hint-text">&nbsp; All rights reserved.</span>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <div className="flex justify-end" id="stepsButton"></div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

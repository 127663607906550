import CreateAppUser from "../../../../../../presentation/pages/users/createUser/createUser";
import { makeRemoteCreateUser } from "../../../../usecases";
import { makeCreateUserValidation } from "./create-user-validation-factory";

export const makeCreateUser = () => {
  return (
    <CreateAppUser
      createUser={makeRemoteCreateUser()}
      validation={makeCreateUserValidation()}
    />
  );
};

// import { sendPasswordResetEmail } from "firebase/auth";
// import { useState } from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { CreateAirtimePurchaseRequest } from "../../../../../domain/usecases";
// import { auth } from "../../../../main/firebase";
import { StepType } from "../../../../../types";
import { currentAccountState } from "../../../../components";
import {
  purchaseRequestCellphonesState,
  airtimePurchaseRequestStepsState,
} from "../atoms/atoms";

interface StepsButtonProps {
  step: string;
  isEnabled: boolean;
  primaryText: string;
  secondaryText: string;
  createAirtimePurchaseRequest: CreateAirtimePurchaseRequest;
}

export const StepsButton = ({
  step,
  isEnabled,
  primaryText,
  secondaryText,
  createAirtimePurchaseRequest,
}: StepsButtonProps) => {
  const navigate = useNavigate();
  const [state, setState] = useRecoilState(purchaseRequestCellphonesState);
  const { getCurrentAccount } = useRecoilValue(currentAccountState);
  const [stepsState, setStepsState] = useRecoilState(
    airtimePurchaseRequestStepsState
  );

  const resetRequest = () => {
    // const { location } = action.payload;
    const { cellphones } = state;

    const updatedCellphones = cellphones.map((item) => {
      return { ...item, purchaseAmount: 0 };
    });

    let updateCellphonesState = {
      ...state,
      cellphones: updatedCellphones,
      filteredCellphones: [],
      isFiltered: false,
      // isLoading: false,
    };

    setState(updateCellphonesState);
  };

  const backToCellphones = () => {
    const steps = JSON.parse(JSON.stringify(stepsState)) as StepType[];

    const step1 = steps.findIndex((step) => step.number === 1);
    const step2 = steps.findIndex((step) => step.number === 2);

    if (steps[step1]) {
      steps[step1].status = "current";
    }

    if (steps[step2]) {
      steps[step2].status = "upcoming";
    }

    // setState((old) => ({
    //   ...old,
    //   isLoading: false,
    // }));
    setStepsState(steps);
  };

  const confirmPurchaseRequest = () => {
    const steps = JSON.parse(JSON.stringify(stepsState)) as StepType[];

    const step1 = steps.findIndex((step) => step.number === 1);
    const step2 = steps.findIndex((step) => step.number === 2);

    if (steps[step1]) {
      steps[step1].status = "complete";
    }

    if (steps[step2]) {
      steps[step2].status = "current";
    }

    // setState((old) => ({
    //   ...old,
    //   isLoading: false,
    // }));

    let updateCellphonesState = {
      ...state,
      filteredCellphones: [],
      isFiltered: false,
      // isLoading: false,
    };

    setState(updateCellphonesState);
    setStepsState(steps);
  };

  const submitRequest = async () => {
    setState((old) => ({ ...old, isLoading: true }));
    await createAirtimePurchaseRequest
      .create({
        account: getCurrentAccount().account,
        type: "AIRTIME",
        userId: getCurrentAccount().user_id,
        userName: getCurrentAccount().displayName,
        purchaseItems: state.cellphones
          .filter((cellphone) => {
            return +cellphone.purchaseAmount > 0;
          })
          .map((m) => {
            return {
              itemNumber: m.number,
              amount: m.purchaseAmount,
              lastPurchaseDate:
                m.lastPurchaseDate === "None"
                  ? "None"
                  : new Date(m.lastPurchaseDate),
              lastPurchaseAmount: m.lastPurchaseAmount,
            };
          }),
      })
      .then(async () => {
        const steps = JSON.parse(JSON.stringify(stepsState)) as StepType[];
        const { cellphones } = state;

        const step2 = steps.findIndex((step) => step.number === 2);
        const step3 = steps.findIndex((step) => step.number === 3);

        if (steps[step2]) {
          steps[step2].status = "complete";
        }

        if (steps[step3]) {
          steps[step3].status = "complete";
        }

        const cellphonesInRequest = state.cellphones.filter((cellphone) => {
          return +cellphone.purchaseAmount > 0;
        });

        console.log("state", state);
        console.log(
          "airtimePurchaseRequestStepsState",
          airtimePurchaseRequestStepsState
        );

        const updatedCellphones = cellphones.map((item) => {
          return { ...item, purchaseAmount: 0 };
        });

        let updateCellphonesState = {
          ...state,
          cellphones: updatedCellphones,
          filteredCellphones: [],
          isFilterd: false,
          steps: steps,
        };

        setStepsState(steps);
        setState(updateCellphonesState);
        setState((old) => ({ ...old, isLoading: false }));
        navigate("/purchase/airtime/complete", { state: cellphonesInRequest });
      })
      .catch((error) => {
        setState((old) => ({ ...old, isLoading: false }));
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // ..
      });

    // const approvalPromise = approvePurchaseRequest
    // .approve({
    //   account: getCurrentAccount().account,
    //   userId: getCurrentAccount().user_id,
    //   userName: getCurrentAccount().displayName,
    //   purchaseRequestNo: transaction.purchaseRequestNo,
    // })
    // .then(() => {
    //   getPendingPurchaseRequests
    //     .fetch({
    //       status: "PENDING",
    //       accountNumber: getCurrentAccount().account,
    //     })
    //     .then((result) =>
    //       setState((old) => ({
    //         ...old,
    //         pendingPurchaseRequests: result,
    //         isLoading: false,
    //       }))
    //     )
    //     .catch(handleError);
    // });

    // = fetchData();
  };

  const handleSecondaryButtonClick = (step: string) => {
    if (step === "initiate") {
      resetRequest();
    }
    if (step === "confirm") {
      console.log("backToCellphones");
      backToCellphones();
      navigate("/purchase/airtime/initiate");
    }
  };

  const handlePrimaryButtonClick = async (step: string) => {
    if (step === "initiate") {
      confirmPurchaseRequest();
      navigate("/purchase/airtime/confirm");
    }
    if (step === "confirm") {
      setState((old) => ({
        ...old,
        isLoading: true,
      }));

      await submitRequest();

      setState((old) => ({
        ...old,
        isLoading: false,
      }));
    }
  };

  const mount = document.getElementById("stepsButton") as HTMLElement;

  let jsx = (
    <>
      <button
        disabled={!isEnabled || state.isLoading}
        onClick={() => handleSecondaryButtonClick(step)}
        type="button"
        className="bg-white py-2 px-6 border border-2 border-red-500 rounded-sm shadow-sm text-sm font-medium text-red-500 disabled:opacity-50 hover:bg-gray-50 focus:outline-none "
      >
        {secondaryText}
      </button>
      <button
        disabled={!isEnabled || state.isLoading}
        onClick={() => handlePrimaryButtonClick(step)}
        type="submit"
        className="ml-3 inline-flex justify-center py-2 px-6 border border-transparent rounded-sm shadow-sm disabled:opacity-50 text-sm font-medium  text-white bg-blue-600 hover:bg-blue-700  active:bg-indingo-800 focus:outline-none "
      >
        {primaryText}
      </button>
    </>
  );

  return ReactDOM.createPortal(jsx, mount);
};

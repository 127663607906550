import { CheckCircleIcon } from "@heroicons/react/solid";

export default function UserCreated() {
  // const navigate = useNavigate();

  // history.replace("/purchase/confirm");
  return (
    <>
      <div className="w-full">
        <div className="max-w-3xl mx-auto grid grid-cols-1 gap-6  lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2">
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            {/* Description list*/}
            <section aria-labelledby="applicant-information-title">
              <div className="bg-white py-4 px-4 shadow shadow-sm ">
                <div className="px-4 pb-5  flex w-full">
                  <h2
                    id="applicant-information-title"
                    className="text-lg leading-6 font-medium text-green-500"
                  >
                    User Successfully created
                  </h2>
                  <CheckCircleIcon
                    className="hidden flex-shrink-0 ml-1 h-6 w-6 text-green-500 lg:block"
                    aria-hidden="true"
                  />
                </div>

                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <div className=" pb-5">
                    <h2
                      id="applicant-information-title"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      User Details
                    </h2>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

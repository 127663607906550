// import { PurchaseRequestType } from "../../../../../types/purchaseRequestType";
import { PencilAltIcon } from "@heroicons/react/outline";
import { format } from "date-fns";
import { useState } from "react";
import { Link } from "react-router-dom";
import { GetCellphonesState } from "../../../../domain/usecases/get-cellphone";

import { classNames } from "../../../utils/helpers";
import CellphoneDetailsRow from "./cellphoneDetailsRow";

type Props = {
  cellphone: GetCellphonesState.Model;
  index: number;
};

const statusStyles: { [key: string]: string } = {
  active: "bg-green-100 text-green-800",
  disabled: "bg-red-100 text-red-800",
  rejected: "bg-red-100 text-red-800",
  pending: "bg-yellow-100 text-yellow-800",
};

export default function CellphoneRows({ cellphone, index }: Props) {
  const [isDetailOpen, setOpenDetail] = useState(false);

  const toggleRowExpansion = () => {
    setOpenDetail((isDetailOpen) => !isDetailOpen);
  };

  //   Name
  // Number
  // Owner
  // Location
  //   Status
  //   Created
  //   Manage
  return (
    <>
      <tr
        key={cellphone.number}
        // onClick={() => toggleRowExpansion()}
        className="bg-white"
      >
        <td className="px-6 py-3 text-left whitespace-nowrap text-sm text-gray-500">
          {cellphone.jobTitle}
        </td>
        <td className="pr-6 py-3 text-left whitespace-nowrap text-sm text-gray-900 font-bold">
          <div className="flex">
            <p className="truncate ">{cellphone.number}</p>
          </div>
        </td>
        <td className=" px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
          <span className="text-gray-900 font-normal">
            {cellphone.cellphoneOwner}
          </span>
        </td>
        <td className="px-6 py-3 text-left whitespace-nowrap text-sm text-gray-500">
          <span className="text-gray-900  font-medium">
            {cellphone.network}
          </span>
        </td>
        <td className="px-6 py-3 text-left whitespace-nowrap text-sm text-gray-500">
          {/* <span className="text-gray-900 font-bold">{cellphone.status}</span> */}

          <span
            className={classNames(
              statusStyles[cellphone.status.toLowerCase()],
              "inline-flex items-center px-2.5 py-0.5 rounded-md text-green-900  font-medium capitalize"
            )}
          >
            {cellphone.status}
          </span>
        </td>
        <td className="px-6 py-3 text-left whitespace-nowrap text-sm text-gray-500">
          <div className="block cursor-pointer w-auto">
            <PencilAltIcon
              className="text-gray-400 h-6 w-6 group-hover:text-gray-900"
              aria-hidden="true"
              onClick={() => toggleRowExpansion()}
            ></PencilAltIcon>
          </div>
        </td>
      </tr>
      {isDetailOpen ? (
        <tr className="bg-gray-200">
          <td
            colSpan={7}
            className=" px-6 py-4 whitespace-nowrap text-sm text-gray-500 "
          >
            <CellphoneDetailsRow cellphone={cellphone} />
          </td>
        </tr>
      ) : null}
    </>
  );
}

import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useResetRecoilState } from "recoil";
import { AddCellphones } from "../../../../../domain/usecases/add-cellphones";
import { StepType } from "../../../../../types";
import {
  addCellphoneFormState,
  addCellphonesStepsState,
  addCellphoneState,
  // addCellphoneState,
} from "../atoms/atoms";

interface StepsButtonProps {
  step: string;
  isEnabled: boolean;
  primaryText: string;
  secondaryText: string;
  addCellphones: AddCellphones;
}

export const AddCellphoneStepsButton = ({
  step,
  isEnabled,
  primaryText,
  secondaryText,
  addCellphones,
}: StepsButtonProps) => {
  const navigate = useNavigate();

  const [state, setState] = useRecoilState(addCellphoneState);
  const [formState, setFormState] = useRecoilState(addCellphoneFormState);
  // const [formState, setFormState] = useRecoilState(addCellphoneFormState);
  // const [cellphoneFormState, setCellphoneFormState] = useRecoilState(
  //   addCellphoneFormState
  // );
  const resetState = useResetRecoilState(addCellphoneState);
  const resetFormState = useResetRecoilState(addCellphoneFormState);
  const [stepsState, setStepsState] = useRecoilState(addCellphonesStepsState);

  // const purchaseOrder = useRecoilValue(purchaseOrderState);

  const submitForApproval = async () => {
    setFormState((old) => ({
      ...old,
      isLoading: true,
    }));

    const steps = JSON.parse(JSON.stringify(stepsState)) as StepType[];
    // const { cellphones } = state;

    const step1 = steps.findIndex((step) => step.number === 1);
    const step2 = steps.findIndex((step) => step.number === 2);

    if (steps[step2]) {
      steps[step2].status = "complete";
    }

    if (steps[step1]) {
      steps[step1].status = "complete";
    }
    console.log("addCellphonesState.cellphonesToAdd", state.cellphonesToAdd);
    await addCellphones.add(state.cellphonesToAdd);

    setState((old) => ({
      ...old,
      cellphonesToAdd: [],
    }));

    setFormState((old) => ({
      ...old,
      isLoading: false,
    }));

    // setCellphoneFormState((old) => ({
    //   ...old,

    // }));

    setStepsState(steps);
  };

  const handleSecondaryButtonClick = (step: string) => {
    if (step === "initiate") {
      resetState();
      resetFormState();
    }
  };

  const handlePrimaryButtonClick = async (step: string) => {
    isEnabled = false;
    if (step === "initiate") {
      const cellphoneCount = state.cellphonesToAdd.length;
      setFormState((old) => ({
        ...old,
        isLoading: true,
      }));
      await submitForApproval();

      setFormState((old) => ({
        ...old,
        isLoading: false,
      }));
      navigate("/cellphones/add/complete", { state: cellphoneCount });
    }
  };

  const mount = document.getElementById("stepsButton") as HTMLElement;

  let jsx = (
    <>
      <button
        disabled={!isEnabled || formState.isLoading}
        onClick={() => handleSecondaryButtonClick(step)}
        type="button"
        className="bg-white py-2 px-6  border-2 border-red-500 rounded-sm shadow-sm text-sm font-medium text-red-500 disabled:opacity-50 hover:bg-gray-50 focus:outline-none "
      >
        {secondaryText}
      </button>
      <button
        disabled={!isEnabled || formState.isLoading}
        onClick={() => handlePrimaryButtonClick(step)}
        type="submit"
        className="ml-3 inline-flex justify-center py-2 px-6 border border-transparent rounded-sm shadow-sm disabled:opacity-50 text-sm font-medium  text-white  bg-blue-600 hover:bg-blue-700 active:bg-blue-800 focus:outline-none "
      >
        {primaryText}
      </button>
    </>
  );

  return ReactDOM.createPortal(jsx, mount);
};

/* eslint-disable react-hooks/exhaustive-deps */
import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon, CheckIcon } from "@heroicons/react/outline";
import { query, collection, where, onSnapshot } from "firebase/firestore";
import { Fragment, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { ValidateCellphone } from "../../../../../domain/usecases";
import { AmendCellphone } from "../../../../../domain/usecases/amend-cellphone";
import { GetCellphonesState } from "../../../../../domain/usecases/get-cellphone";
import { db } from "../../../../../main/firebase";
import { currentAccountState } from "../../../../components";
import { useErrorHandler } from "../../../../hooks";
import { Validation } from "../../../../protocols";

import { classNames } from "../../../../utils/helpers";
import { manageCellphoneState, toggleEditCellphoneState } from "../atoms/atoms";
import FormStatus from "./form-status";
import Input from "./input";

type Props = {
  validation: Validation;
  amendCellphone: AmendCellphone;
  validateCellphone: ValidateCellphone;
  cellphone: GetCellphonesState.Model;
};

export default function AmendCellphoneDetails({
  validation,
  amendCellphone,
  validateCellphone,
  cellphone,
}: Props) {
  const [state, setState] = useRecoilState(manageCellphoneState);
  const { getCurrentAccount } = useRecoilValue(currentAccountState);
  const [toggleState, setToggleState] = useRecoilState(
    toggleEditCellphoneState
  );
  const [selectedCompany, setSelectedCompany] = useState(
    cellphone.company.name
  );
  const [selectedNetwork, setSelectedNetwork] = useState(cellphone.network);

  // const [newCellphones, setNewCellphones] = useRecoilState(addCellphonesState);

  useEffect(
    () =>
      setState((old) => ({
        ...old,
        cellphoneNumber: cellphone.number,
        currentCellphoneNumber: cellphone.number,
        cellphoneOwner: cellphone.cellphoneOwner,
        jobTitle: cellphone.jobTitle,
        network: cellphone.network,
        company: cellphone.company.name,
        companyNumber: cellphone.company.companyNumber,
        mainError: "",
      })),
    []
  );

  useEffect(() => {
    const q = query(
      collection(db, "companies"),
      where("account", "==", getCurrentAccount().account)
    );
    return onSnapshot(q, (querySnapshot) => {
      let result = querySnapshot.docs.map((doc) => {
        return {
          account: doc.data().account,
          createdBy: doc.data().createdBy,
          dateCreated: doc.data().dateCreated,
          isActive: doc.data().isActive,
          name: doc.data().name,
          companyNumber: doc.data().companyNumber,
          postalAddress: doc.data().postalAddress,
          city: doc.data().city,
          phoneNumber: doc.data().phoneNumber,
        };
      });

      setState((old) => ({ ...old, isLoading: false, companiesList: result }));
    });
  }, []);

  useEffect(() => validate("cellphoneNumber"), [state.cellphoneNumber]);
  useEffect(() => validate("cellphoneOwner"), [state.cellphoneOwner]);
  useEffect(() => validate("jobTitle"), [state.jobTitle]);

  const toggleEditCellphoneClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    setToggleState((old) => ({
      ...old,
      toggle: !toggleState.toggle,
    }));
  };

  const validate = (field: string): void => {
    const { cellphoneNumber, cellphoneOwner, jobTitle } = state;
    const formData = { cellphoneNumber, cellphoneOwner, jobTitle };
    setState((old) => ({
      ...old,
      [`${field}Error`]: validation.validate(field, formData),
    }));

    console.log("validate", state);
    setState((old) => ({
      ...old,
      isFormInvalid:
        !!old.cellphoneNumberError ||
        !!old.cellphoneOwnerError ||
        !!old.jobTitleError,
    }));
  };
  const [showError, setshowError] = useState(false);

  const handleError = useErrorHandler((error: Error) => {
    setState((old) => ({ ...old, isLoading: false, error: error.message }));
  });

  const handleChangeCellphoneDetailsSubmit = async (
    e: React.SyntheticEvent
  ) => {
    e.preventDefault();

    try {
      if (state.isLoading || state.isFormInvalid) {
        setshowError(true);
        return;
      }

      setState((old) => ({ ...old, isLoading: true }));

      if (state.cellphoneNumber !== state.currentCellphoneNumber) {
        const isCellphoneAlreadyRegistered = await validateCellphone.validate({
          account: getCurrentAccount().account,
          cellphoneNumber: state.cellphoneNumber,
        });

        if (isCellphoneAlreadyRegistered.response) {
          // formState.mainError();
          setState((old) => ({
            ...old,
            mainError: "The cellphone is already registered.",
          }));

          setState((old) => ({
            ...old,
            isLoading: false,
          }));

          return;
        }
      }

      const item = {
        account: state.cellphone.account,
        updatedBy: getCurrentAccount().displayName,
        cellphoneNumber: state.cellphoneNumber,
        currentCellphoneNumber: state.currentCellphoneNumber,
        cellphoneOwner: state.cellphoneOwner,
        jobTitle: state.jobTitle,
        company: state.company,
        companyNumber: state.companyNumber,
        network: state.network,
      };

      console.log(item);

      await amendCellphone.amend(item).catch(handleError);
      setToggleState((old) => ({ ...old, toggle: !toggleState.toggle }));

      setState((old) => ({ ...old, isLoading: false, isFormInvalid: false }));
    } catch (error) {
      console.log("error.message", error.message);
      setState((old) => ({
        ...old,
        isLoading: false,
        mainError: error.message,
      }));
    }
  };

  // const ChangeCellphoneDetailsCancel = (e: React.SyntheticEvent) => {
  //   e.preventDefault();

  //   setState({
  //     ...state,
  //     cellphoneNumber: "",
  //   });
  // };

  // const handleCompanyChange = (companyNumber: string) => {
  //   console.log("companyNumber", companyNumber);

  //   setSelectedCompany(companyNumber);
  //   setState((old) => ({
  //     ...old,
  //     companyNumber: companyNumber,
  //     companyNumberError: undefined,
  //   }));

  // };

  // const handleCompanyChange = (value: string) => {
  //   // event.preventDefault();
  //   let company: string;

  //   console.log("companyNumber", value);
  //   if (value === "Not Registered") {
  //     setSelectedCompany("None");
  //     company = "None";
  //   } else {
  //     company = state.companiesList.find((c) => c.companyNumber === value).name;
  //     setSelectedCompany(company);
  //   }

  //   setState((old) => ({
  //     ...old,
  //     company: company,
  //     companyNumber: value,
  //   }));
  //   console.log("state", state);
  // };

  const handleCompanyChange = (value: string) => {
    let company, companyNumber: string;

    if (value === "Not Registered") {
      company = "None";
      companyNumber = "Not Registered";
    } else {
      company = state.companiesList.find((c) => c.name === value).name;
      companyNumber = state.companiesList.find(
        (c) => c.name === value
      ).companyNumber;
    }

    setState((old) => ({
      ...old,
      company: company,
      companyNumber: companyNumber,
      companyError: undefined,
    }));
  };

  const handleNetworkChange = (value: string) => {
    // event.preventDefault();

    setSelectedNetwork(value);

    setState((old) => ({
      ...old,
      network: value,
      networkError: undefined,
    }));
  };

  return state.cellphone ? (
    <div className="min-w-0 border-b border-gray-200 flex-1 lg:grid lg:grid-cols-12 lg:gap-x-5">
      <div className="lg:col-span-3"> </div>
      <div className="lg:col-span-9">
        <form onSubmit={(e) => handleChangeCellphoneDetailsSubmit(e)}>
          <div className=" sm:rounded-sm">
            <div className="bg-transparent p-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-2">
                  <label
                    htmlFor="cellphoneNumber"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Number
                  </label>
                  <Input
                    type="text"
                    name="cellphoneNumber"
                    className="mt-1 block  placeholder-gray-300 font-medium rounded-sm py-2 mr-4 border border-gray-300 bg-white focus:border-blue-600 focus:ring-0 sm:text-sm"
                    placeholder="Cellphone Number"
                    value={state.cellphoneNumber}
                  />
                  {showError && (
                    <div className=" block text-sm text-red-500">
                      {state.cellphoneNumberError && state.cellphoneNumberError}
                    </div>
                  )}
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="cellphoneOwner"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Owner
                  </label>
                  <Input
                    type="text"
                    name="cellphoneOwner"
                    className="mt-1 block  placeholder-gray-300 font-medium rounded-sm py-2 mr-4 border border-gray-300 bg-white focus:border-blue-600 focus:ring-0 sm:text-sm"
                    placeholder="Cellphone Owner"
                    value={state.cellphoneOwner}
                  />
                  {showError && (
                    <div className=" block text-sm text-red-500">
                      {state.cellphoneOwnerError && state.cellphoneOwnerError}
                    </div>
                  )}
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="jobTitle"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Job Title
                  </label>
                  <Input
                    type="text"
                    name="jobTitle"
                    className="mt-1 block border border-gray-300 placeholder-gray-300 font-medium rounded-sm py-2 mr-4 bg-white focus:border-blue-600 focus:ring-0 sm:text-sm"
                    placeholder="Cellphone Location"
                    value={state.jobTitle}
                  />
                  {showError && (
                    <div className=" block text-sm text-red-500">
                      {state.jobTitleError && state.jobTitleError}
                    </div>
                  )}
                </div>

                <div className="col-span-2">
                  <div className="col-span-5">
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Select Company
                    </label>

                    <Listbox
                      value={state.company}
                      onChange={handleCompanyChange}
                    >
                      {({ open }) => (
                        <>
                          <div className="relative">
                            <Listbox.Button className="mt-1 bg-gray-50 relative w-full border border-gray-300 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                              <span className="block truncate">
                                {state.company}
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60  py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                <Listbox.Option
                                  key={0}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? "text-white bg-blue-600"
                                        : "text-gray-900",
                                      "cursor-default select-none relative py-2 pl-3 pr-9"
                                    )
                                  }
                                  value="Not Registered"
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )}
                                      >
                                        {`None`}
                                      </span>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? "text-white"
                                              : "text-blue-600",
                                            "absolute inset-y-0 right-0 flex items-center pr-4"
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                                {state.companiesList.map((company, index) => (
                                  <Listbox.Option
                                    key={index}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "text-white bg-blue-600"
                                          : "text-gray-900",
                                        "cursor-default select-none relative py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={company.name}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate"
                                          )}
                                        >
                                          {`${company.name}`}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-blue-600",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                    {showError && (
                      <div className=" block text-sm text-red-500">
                        {state.companyError && state.companyError}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-span-2">
                  <div className="col-span-5">
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Select Network
                    </label>

                    <Listbox
                      value={selectedNetwork}
                      onChange={handleNetworkChange}
                    >
                      {({ open }) => (
                        <>
                          <div className="relative">
                            <Listbox.Button className="mt-1 bg-gray-50 relative w-full border border-gray-300 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                              <span className="block truncate">
                                {selectedNetwork}
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60  py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {/* <Listbox.Option
                                  key={0}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? "text-white bg-blue-600"
                                        : "text-gray-900",
                                      "cursor-default select-none relative py-2 pl-3 pr-9"
                                    )
                                  }
                                  value="Not Registered"
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )}
                                      >
                                        {`None`}
                                      </span>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? "text-white"
                                              : "text-blue-600",
                                            "absolute inset-y-0 right-0 flex items-center pr-4"
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option> */}
                                {state.networkList.map((network, index) => (
                                  <Listbox.Option
                                    key={index}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "text-white bg-blue-600"
                                          : "text-gray-900",
                                        "cursor-default select-none relative py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={network.name}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate"
                                          )}
                                        >
                                          {network.name}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-blue-600",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                    {showError && (
                      <div className=" block text-sm text-red-500">
                        {state.networkError && state.networkError}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <FormStatus />
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                onClick={(e) => toggleEditCellphoneClick(e)}
                type="button"
                className="bg-white py-2 px-6 border border-red-500 rounded-sm shadow-sm text-sm font-medium text-red-500 disabled:opacity-50 hover:bg-gray-50 focus:outline-none "
              >
                Cancel
              </button>
              <button
                type="submit"
                className="inline-flex ml-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
              >
                Amend Cellphone
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  ) : null;
}

import React from "react";
import { makeLoginValidation } from "..";
import Login from "../../../../presentation/pages/signin/login";
import { makeRemoteAuthentication } from "../../usecases";

export const makeLogin = () => {
  return (
    <Login
      authentication={makeRemoteAuthentication()}
      validation={makeLoginValidation()}
    />
  );
};
// export const AuthorizedRoute = ({ children }: { children: JSX.Element }) => {
// export default makeLogin

import { RemotePurchaseRequestModel } from "../../../../data/models";
import TransactionDetailsRow from "../../../../presentation/pages/approvals/components/purchaseRequestRowDetail";
import { makeRemoteApprovePurchaseRequest } from "../../usecases/purchase-requests/remote-approve-purchase-request";
import { makeRemoteGetPurchaseRequestsByStatus } from "../../usecases/purchase-requests/remote-get-purchase-requests-by-status-factory";

export const makeApprovePurchaseRequest = (
  transaction: RemotePurchaseRequestModel
) => {
  return (
    <TransactionDetailsRow
      approvePurchaseRequest={makeRemoteApprovePurchaseRequest()}
      getPendingPurchaseRequests={makeRemoteGetPurchaseRequestsByStatus()}
      transaction={transaction}
    />
  );
};

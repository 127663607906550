import AddCompanyForm from "../../../../../presentation/pages/meters/company/components/addCompanyForm";
import { makeRemoteAddCompany } from "../../../usecases/meter";
import { makeAddCompanyValidation } from "./add-company-validation-form-factory";

export const makeAddCompanyForm = () => {
  return (
    <AddCompanyForm
      addCompany={makeRemoteAddCompany()}
      validation={makeAddCompanyValidation()}
    />
  );
};

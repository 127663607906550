type Props = {
  pageHeader: string;
  merchantName: string;
};

export default function MerchantInfo({ pageHeader, merchantName }: Props) {
  return (
    <>
      <div className="z-40 bg-blue-100 w-full">
        <div className="lg:max-w-7xl lg:mx-auto">
          <div className="py-4 md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
              {/* Profile */}
              <div className="flex items-center">
                <h3 className="text-lg font-medium leading-6 text-gray-900 ">
                  {pageHeader}
                  {/* <span>Purchase: </span> BPC Electricity */}
                </h3>
              </div>
            </div>
            <div className="mt-4 flex space-x-2 md:mt-0 md:ml-4">
              <span className="text-xl leading-6 font-medium text-gray-900">
                {merchantName}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

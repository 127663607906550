import { RemoteGetPurchaseRequestsByStatus } from "../../../../data/usecases";
import { GetPurchaseRequestsByStatus } from "../../../../domain/usecases";
import { makeApiUrl, makeAxiosHttpClient } from "../../http";

export const makeRemoteGetPurchaseRequestsByStatus =
  (): GetPurchaseRequestsByStatus =>
    new RemoteGetPurchaseRequestsByStatus(
      makeApiUrl("/purchases/get-purchase-requests-by-status"),
      makeAxiosHttpClient()
    );

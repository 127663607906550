/* eslint-disable react-hooks/exhaustive-deps */
import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon, CheckIcon } from "@heroicons/react/outline";
import { query, collection, where, onSnapshot } from "firebase/firestore";
import { Fragment, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { AmendMeter } from "../../../../../domain/usecases/amend-meter";
import { GetMetersState } from "../../../../../domain/usecases/get-meter";
import { db } from "../../../../../main/firebase";
import { currentAccountState } from "../../../../components";
import { useErrorHandler } from "../../../../hooks";
import { Validation } from "../../../../protocols";

import { classNames } from "../../../../utils/helpers";
import { manageMeterState, toggleEditMeterState } from "../atoms/atoms";
import Input from "./input";

type Props = {
  validation: Validation;
  amendMeter: AmendMeter;
  meter: GetMetersState.Model;
};

export default function AmendMeterDetails({
  validation,
  amendMeter,
  meter,
}: Props) {
  const [state, setState] = useRecoilState(manageMeterState);
  const { getCurrentAccount } = useRecoilValue(currentAccountState);
  const [toggleState, setToggleState] = useRecoilState(toggleEditMeterState);
  const [selectedCompany, setSelectedCompany] = useState(meter.company.name);

  useEffect(
    () =>
      setState((old) => ({
        ...old,
        meterNumber: meter.number,
        meterName: meter.name,
        meterLocation: meter.location,
        companyNumber: meter.company.companyNumber,
      })),
    []
  );

  useEffect(() => {
    const q = query(
      collection(db, "companies"),
      where("account", "==", getCurrentAccount().account)
    );
    return onSnapshot(q, (querySnapshot) => {
      let result = querySnapshot.docs.map((doc) => {
        return {
          account: doc.data().account,
          createdBy: doc.data().createdBy,
          dateCreated: doc.data().dateCreated,
          isActive: doc.data().isActive,
          name: doc.data().name,
          companyNumber: doc.data().companyNumber,
          postalAddress: doc.data().postalAddress,
          city: doc.data().city,
          phoneNumber: doc.data().phoneNumber,
        };
      });

      setState((old) => ({ ...old, isLoading: false, companiesList: result }));
    });
  }, []);

  useEffect(() => validate("meterName"), [state.meterName]);
  useEffect(() => validate("meterLocation"), [state.meterLocation]);

  const toggleEditMeterClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    setToggleState((old) => ({
      ...old,
      toggle: !toggleState.toggle,
    }));
  };

  const validate = (field: string): void => {
    const { meterName, meterLocation } = state;
    const formData = {
      meterName,
      meterLocation,
    };
    setState((old) => ({
      ...old,
      [`${field}Error`]: validation.validate(field, formData),
    }));

    setState((old) => ({
      ...old,
      isFormInvalid:
        !old.meterName || !old.meterLocation || !state.companyNumber,
    }));
    console.log(" state", state);
    console.log(
      "!old.meterName || !old.meterLocation ||",
      !state.meterName || !state.meterLocation || !state.companyNumber
    );
  };
  const [showError, setshowError] = useState(false);

  const handleError = useErrorHandler((error: Error) => {
    setState((old) => ({ ...old, isLoading: false, error: error.message }));
  });

  const handleChangeMeterDetailsSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (
      state.isLoading ||
      !state.meterName ||
      !state.meterLocation ||
      !state.companyNumber
    ) {
      setshowError(true);
      return;
    }

    setState((old) => ({ ...old, isLoading: true }));

    const item = {
      account: state.meter.account,
      updatedBy: getCurrentAccount().displayName,
      meterNumber: state.meter.number,
      meterName: state.meterName,
      location: state.meterLocation,
      companyNumber: state.companyNumber,
    };

    console.log(item);

    await amendMeter.amend(item).catch(handleError);
    setToggleState((old) => ({ ...old, toggle: !toggleState.toggle }));

    setState((old) => ({ ...old, isLoading: false, isFormInvalid: false }));
  };

  // const ChangeMeterDetailsCancel = (e: React.SyntheticEvent) => {
  //   e.preventDefault();

  //   setState({
  //     ...state,
  //     meterNumber: "",
  //   });
  // };

  // const handleCompanyChange = (companyNumber: string) => {
  //   console.log("companyNumber", companyNumber);

  //   setSelectedCompany(companyNumber);
  //   setState((old) => ({
  //     ...old,
  //     companyNumber: companyNumber,
  //     companyNumberError: undefined,
  //   }));

  // };

  const handleCompanyChange = (value: string) => {
    // event.preventDefault();
    let company: string;

    console.log("companyNumber", value);
    if (value === "Not Registered") {
      setSelectedCompany("None");
      company = "None";
    } else {
      company = state.companiesList.find((c) => c.companyNumber === value).name;
      setSelectedCompany(company);
    }

    setState((old) => ({
      ...old,
      company: company,
      companyNumber: value,
      companyNumberError: undefined,
    }));
    console.log("state", state);
  };

  return state.meter ? (
    <div className="min-w-0 border-b border-gray-200 flex-1 lg:grid lg:grid-cols-12 lg:gap-x-5">
      <div className="lg:col-span-3"> </div>
      <div className="lg:col-span-9">
        <form onSubmit={(e) => handleChangeMeterDetailsSubmit(e)}>
          <div className="overflow-hidden sm:rounded-sm">
            <div className="bg-transparent p-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-2">
                  <label
                    htmlFor="registeredOwner"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Meter Number
                  </label>
                  <span className="mt-1 text-sm text-gray-500">
                    {state.meter.number}
                  </span>
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="registeredOwner"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Registered Owner
                  </label>
                  <span className="mt-1 text-sm text-gray-500">
                    {state.meter.registeredOwner}
                  </span>
                </div>

                <div className="col-span-2">
                  <div className="col-span-5">
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Select Company
                    </label>

                    <Listbox
                      value={selectedCompany}
                      onChange={handleCompanyChange}
                    >
                      {({ open }) => (
                        <>
                          <div className="relative">
                            <Listbox.Button className="mt-1 bg-gray-50 relative w-full border border-gray-300 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                              <span className="block truncate">
                                {selectedCompany}
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60  py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                <Listbox.Option
                                  key={0}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? "text-white bg-blue-600"
                                        : "text-gray-900",
                                      "cursor-default select-none relative py-2 pl-3 pr-9"
                                    )
                                  }
                                  value="Not Registered"
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )}
                                      >
                                        {`None`}
                                      </span>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? "text-white"
                                              : "text-blue-600",
                                            "absolute inset-y-0 right-0 flex items-center pr-4"
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                                {state.companiesList.map((company, index) => (
                                  <Listbox.Option
                                    key={index}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "text-white bg-blue-600"
                                          : "text-gray-900",
                                        "cursor-default select-none relative py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={company.companyNumber}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate"
                                          )}
                                        >
                                          {`${company.name}`}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-blue-600",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                    {showError && (
                      <div className=" block text-sm text-red-500">
                        {state.companyNumberError && state.companyNumberError}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-span-2">
                  <label
                    htmlFor="emailAddress"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Meter Name
                  </label>
                  <Input
                    type="text"
                    name="meterName"
                    className="mt-1 block  placeholder-gray-300 font-medium rounded-sm py-2 mr-4 border border-gray-300 bg-white focus:border-blue-600 focus:ring-0 sm:text-sm"
                    placeholder="Meter Name"
                    value={state.meterName}
                  />
                  {showError && (
                    <div className=" block text-sm text-red-500">
                      {state.meterNameError && state.meterNameError}
                    </div>
                  )}
                </div>

                <div className="col-span-2">
                  <label
                    htmlFor="meterLocation"
                    className="block text-sm font-medium text-gray-700"
                  >
                    City/Village
                  </label>
                  <Input
                    type="text"
                    name="meterLocation"
                    className="mt-1 block  placeholder-gray-300 font-medium rounded-sm py-2 mr-4 border border-gray-300 bg-white focus:border-blue-600 focus:ring-0 sm:text-sm"
                    placeholder="Meter Location"
                    value={state.meterLocation}
                  />
                  {showError && (
                    <div className=" block text-sm text-red-500">
                      {state.meterLocationError && state.meterLocationError}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                onClick={(e) => toggleEditMeterClick(e)}
                type="button"
                className="bg-white py-2 px-6 border border-2 border-red-500 rounded-sm shadow-sm text-sm font-medium text-red-500 disabled:opacity-50 hover:bg-gray-50 focus:outline-none "
              >
                Cancel
              </button>
              <button
                type="submit"
                className="inline-flex ml-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
              >
                Amend Meter
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  ) : null;
}

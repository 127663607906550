import React from "react";

import { ReactComponent as Spinner } from "../../assets/progress.svg";

const Loading: React.FC = () => {
  return (
    <div
      data-testid="loading"
      className="flex z-20 items-center justify-center bg-white  fixed cursor-wait top-0 left-0 right-0 bottom-0"
    >
      <div className="flex flex-col items-center justify-center   w-300 h-150 rounded-lg">
        <Spinner />
        <span className="text-gray-900 mb-2 lowercase ">Please wait...</span>
      </div>
    </div>
  );
};

export default Loading;

import { useRecoilState } from "recoil";
import React, { useEffect, useState } from "react";
import { Input, toggleEditCurrentUserState, userAccountState } from ".";

import { Validation } from "../../../../protocols";
import { useFirestore } from "../../../../hooks/use-firestore";
// import { ChangeUserDetails } from "../../../../../domain/usecases";
// import { GetUsersState } from "../../../../../domain/usecases/get-user";

type Props = {
  validation: Validation;
};

export default function EditCurrentUser({ validation }: Props) {
  const [state, setState] = useRecoilState(userAccountState);
  const [toggleState, setToggleState] = useRecoilState(
    toggleEditCurrentUserState
  );

  // const [togglePasswordState, setTogglePasswordState] = useRecoilState(
  //   toggleChangePasswordState
  // );
  const [showError, setshowError] = useState(false);

  const firestore = useFirestore();

  const validate = (field: string): void => {
    const { firstName, lastName, emailAddress, contactNumber, role } = state;
    const formData = {
      firstName,
      lastName,
      emailAddress,
      contactNumber,
      role,
    };
    setState((old) => ({
      ...old,
      [`${field}Error`]: validation.validate(field, formData),
    }));
    setState((old) => ({
      ...old,
      isFormInvalid:
        !!old.firstNameError ||
        !!old.lastNameError ||
        !!old.emailAddressError ||
        !!old.contactNumberError ||
        !!old.roleError,
    }));
  };

  useEffect(
    () =>
      setState((old) => ({
        ...old,
        firstName: state.firstName,
        lastName: state.lastName,
        emailAddress: state.emailAddress,
        contactNumber: state.contactNumber,
      })),
    []
  );

  useEffect(() => validate("firstName"), [state.firstName]);
  useEffect(() => validate("lastName"), [state.lastName]);
  useEffect(() => validate("emailAddress"), [state.emailAddress]);
  useEffect(() => validate("contactNumber"), [state.contactNumber]);

  const toggleEditUserClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    setToggleState((old) => ({
      ...old,
      isSelected: !toggleState.isSelected,
    }));
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    try {
      if (state.isLoading || state.isFormInvalid) {
        setshowError(true);
        return;
      }

      setState((old) => ({ ...old, isLoading: true }));

      const updatedDetails = {
        firstName: state.firstName,
        lastName: state.lastName,
        emailAddress: state.emailAddress,
        contactNumber: state.contactNumber,
        role: state.role,
      };

      firestore.editUserDetails(state.uid, updatedDetails);

      setToggleState((old) => ({ ...old, isSelected: false }));
      setState((old) => ({ ...old, isLoading: false }));
      // navigate("/", { replace: true });
      // navigate("/users/create/complete");
    } catch (error) {
      setState((old) => ({
        ...old,
        isLoading: false,
        mainError: error.message,
      }));
    }
  };

  return (
    <div className="min-w-0 flex-1 lg:grid lg:grid-cols-12 lg:gap-x-5">
      {/* <div className="lg:col-span-3"> </div> */}
      <div className="lg:col-span-12">
        <form onSubmit={handleSubmit}>
          <div className="overflow-hidden sm:rounded-sm">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-2 sm:col-span-2">
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First name
                  </label>

                  <Input
                    type="text"
                    name="firstName"
                    id="firstName"
                    value={state.firstName}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-sm"
                  />
                  {showError && (
                    <div className=" block text-sm text-red-500">
                      {state.firstNameError && state.firstNameError}
                    </div>
                  )}
                </div>

                <div className="col-span-2 sm:col-span-2">
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last name
                  </label>
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={state.lastName}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-sm"
                  />
                  {showError && (
                    <div className=" block text-sm text-red-500">
                      {state.lastNameError && state.lastNameError}
                    </div>
                  )}
                </div>

                <div className="col-span-2 sm:col-span-2">
                  <label
                    htmlFor="emailAddress"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <Input
                    type="text"
                    name="emailAddress"
                    id="emailAddress"
                    value={state.emailAddress}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-sm"
                  />
                  {showError && (
                    <div className=" block text-sm text-red-500">
                      {state.emailAddressError && state.emailAddressError}
                    </div>
                  )}
                </div>

                <div className="col-span-2 sm:col-span-2">
                  <label
                    htmlFor="contactNumber"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Contact Number
                  </label>
                  <Input
                    type="text"
                    name="contactNumber"
                    id="contactNumber"
                    value={state.contactNumber}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-sm"
                  />
                  {showError && (
                    <div className=" block text-sm text-red-500">
                      {state.contactNumberError && state.contactNumberError}
                    </div>
                  )}
                </div>

                <div className="col-span-2 sm:col-span-2">
                  <label
                    htmlFor="role"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Role
                  </label>

                  <div className="text-sm font-medium text-gray-700">
                    {state.role}
                  </div>
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                onClick={(e) => toggleEditUserClick(e)}
                type="button"
                className="bg-white py-2 px-6 border border-2 border-red-500 rounded-sm shadow-sm text-sm font-medium text-red-500 disabled:opacity-50 hover:bg-gray-50 focus:outline-none "
              >
                Cancel
              </button>
              <button
                type="submit"
                className="inline-flex ml-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
              >
                Edit User Details
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

import { PlusIcon } from "@heroicons/react/outline";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import {
  addCellphoneFormState,
  addCellphonesStepsState,
  addCellphoneState,
  // addCellphoneState,
  cellphoneCompaniesListState,
} from "../atoms/atoms";

import AddCellphoneList from "../components/addCellphonesList";

import { Validation } from "../../../../protocols";
import { useEffect, useState } from "react";

import { query, collection, onSnapshot, where } from "firebase/firestore";
import { db } from "../../../../../main/firebase";
import { makeAddCellphoneDetails } from "../../../../../main/factories/pages/cellphones/add/add-cellphone-details-factory";
import { makeAddCellphoneStepsButton } from "../../../../../main/factories/pages/cellphones/add/add-cellphone-steps-button-factory";

import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";
import { currentAccountState } from "../../../../components";
import FormStatus from "../components/form-status";

export default function AddCellphones() {
  const { getCurrentAccount } = useRecoilValue(currentAccountState);
  // const resetAddCellphoneFormState = useResetRecoilState(addCellphoneFormState);
  const [state, setState] = useRecoilState(addCellphoneState);
  const [formState, setFormState] = useRecoilState(addCellphoneFormState);
  // const [state, setCellphoneFormState] = useRecoilState(
  //   addCellphoneFormState
  // );
  const setCellphonesStepsState = useSetRecoilState(addCellphonesStepsState);

  const setCompaniesList = useSetRecoilState(cellphoneCompaniesListState);

  const [showError, setshowError] = useState(false);

  useEffect(() => resetStepsState(), []);

  const resetStepsState = () => {
    const resetState = [
      {
        number: 1,
        name: "Add Cellphones",
        description: "Add cellphones to recharge.",
        href: "#",
        status: "current",
      },
      {
        number: 2,
        name: "Submit for approval",
        description: "Submit for approval.",
        href: "#",
        status: "upcoming",
      },
    ];

    setCellphonesStepsState(resetState);
  };

  useEffect(() => {
    const q = query(
      collection(db, "companies"),
      where("account", "==", getCurrentAccount().account)
    );
    return onSnapshot(q, (querySnapshot) => {
      let result = querySnapshot.docs.map((doc) => {
        return {
          account: doc.data().account,
          createdBy: doc.data().createdBy,
          dateCreated: doc.data().dateCreated,
          isActive: doc.data().isActive,
          name: doc.data().name,
          companyNumber: doc.data().companyNumber,
          postalAddress: doc.data().postalAddress,
          city: doc.data().city,
          phoneNumber: doc.data().phoneNumber,
        };
      });
      setCompaniesList((old) => ({ ...old, companiesList: result }));
      // setState((old) => ({ ...old, companiesList: result }));
    });
  }, []);

  const handleOpenForm = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();

    try {
      // resetAddCellphoneFormState();

      setState((old) => ({
        ...old,
        isLoading: false,
        showForm: true,
      }));
      setshowError(false);
      return;

      // setCurrentAccount(account)
      // history.replace('/')
    } catch (error) {
      setState((old) => ({
        ...old,
        // showForm: false,
        isLoading: false,
        mainError: error.message,
      }));
    }
  };

  return (
    <>
      {!state.showForm && (
        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
          <div className="sm:col-span-12">
            <form onSubmit={(e) => handleOpenForm(e)} className="flex">
              {/* <div className="flex flex-col">
                <label htmlFor="Cellphone" className="sr-only">
                  Cellphone Number
                </label>
                <Input
                  type="number"
                  name="CellphoneNumber"
                  className="block border border-gray-200 placeholder-gray-300 font-medium rounded-sm py-2 mr-4 border-b border-transparent bg-white focus:border-blue-600 focus:ring-0 sm:text-sm"
                  placeholder="BPC Cellphone"
                />
                {showError && (
                  <div className=" block text-sm text-red-500">
                    {state.CellphoneNumberError}
                  </div>
                )}
              </div> */}
              <div>
                <button
                  type="submit"
                  className="relative inline-flex justify-center py-2 px-6 border border-transparent rounded-sm shadow-sm disabled:opacity-50 text-sm font-medium  text-white bg-blue-600 hover:bg-blue-700  active:bg-indingo-800 focus:outline-none "
                  disabled={formState.isLoading}
                >
                  {formState.isLoading ? (
                    <>
                      <Spinner className=" h-5" /> Validating Cellphone...
                    </>
                  ) : (
                    <>
                      <PlusIcon
                        className="-ml-1 mr-2 h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      Add Cellphone
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {makeAddCellphoneDetails()}
      {/* <ValidateCellphoneFail /> */}
      <FormStatus />
      <AddCellphoneList />

      {console.log(
        "state.cellphonesToAdd.length",
        state.cellphonesToAdd.length
      )}

      {state.cellphonesToAdd.length > 0 &&
        makeAddCellphoneStepsButton({
          step: "initiate",
          isEnabled: state.cellphonesToAdd.length > 0 && !state.showForm,
          primaryText: "Submit Cellphones",
          secondaryText: "Clear All",
        })}
    </>
  );
}

import { Outlet } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentAccountState } from "../../../components";
import Loading from "../../../components/loading/loading";
import { useErrorHandler } from "../../../hooks";
import MerchantInfo from "../../../components/merchantInfo/merchantInfo";
import PurchaseRequestAmount from "./purchaseRequestAmount";
import PurchaseRequestSteps from "./purchaseRequestSteps";
import { useEffect } from "react";
import { purchaseRequestMetersState } from "./atoms/atoms";
import { GetMetersByStatus } from "../../../../domain/usecases";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../../../main/firebase";

type Props = {
  getActiveMeters: GetMetersByStatus;
};

export default function PurchaseRequestPage({ getActiveMeters }: Props) {
  const { getCurrentAccount } = useRecoilValue(currentAccountState);
  const [state, setState] = useRecoilState(purchaseRequestMetersState);
  const handleError = useErrorHandler((error: Error) => {
    setState((old) => ({ ...old, isLoading: false, error: error.message }));
  });

  useEffect(() => {
    const q = query(
      collection(db, "companies"),
      where("account", "==", getCurrentAccount().account)
    );
    return onSnapshot(q, (querySnapshot) => {
      let result = querySnapshot.docs.map((doc) => {
        return {
          name: doc.data().name,
          number: doc.data().companyNumber,
          postalAddress: doc.data().postalAddress,
          city: doc.data().city,
        };
      });

      result.push({
        name: "None",
        number: "Not Registered",
        postalAddress: "Not Registered",
        city: "Not Registered",
      });

      setState((old) => ({ ...old, companies: result }));
    });
  }, []);

  useEffect(() => {
    setState((old) => ({
      ...old,
      isLoading: true,
    }));

    console.log("PurchaseRequestPage getActiveMeters");

    getActiveMeters
      .fetch({
        meterStatus: "Active",
        accountNumber: getCurrentAccount().account,
      })
      .then((result) =>
        setState((old) => ({
          ...old,
          meters: result
            .sort((a, b) => {
              if (b.company.name < a.company.name) return 1;
              if (b.company.name > a.company.name) return -1;
              else {
                if (b.name < a.name) return 1;
                if (b.name > a.name) return -1;
                return 0;
              }
            })
            .map((meter, index) => {
              return {
                id: index,
                account: meter.account.toString(),
                merchant: getCurrentAccount().merchantName,
                initiator: getCurrentAccount().displayName,
                location: meter.location,
                name: meter.name,
                company: meter.company.name,
                lastPurchase: meter.lastPurchase,
                registeredOwner: meter.registeredOwner,
                number: meter.number,
                purchaseAmount: 0.0,
                lastPurchaseDate: meter.lastPurchase.dateCreated,
                lastPurchaseAmount: meter.lastPurchase.purchaseAmount,
              };
            }),
          isLoading: false,
        }))
      )
      .catch(handleError);
  }, []);

  console.log("PurchaseRequestPage");
  console.log("meters", state.meters);

  return (
    <>
      {state.meters && (
        <div className=" relative flex flex-col flex-grow">
          <MerchantInfo
            pageHeader="Purchase Request : Electricity"
            merchantName={getCurrentAccount().merchantName}
          />
          <div className=" antialiased font-sans flex-grow overflow-hidden">
            {/* <div className="px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8 py-6 lg:col-span-6"> */}
            <div className="max-w-7xl lg:mx-auto py-6 lg:col-span-6">
              <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                <div className="lg:col-span-6">
                  <PurchaseRequestSteps />
                </div>
                <div className="lg:col-span-6 text-right">
                  <PurchaseRequestAmount />
                </div>
              </div>
            </div>

            <div className="lg:max-w-7xl lg:mx-auto">
              <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                {/* <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                  <PurchaseRequestSteps />
                </aside> */}

                <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {state.isLoading && <Loading />}
    </>
  );
}

import { UnexpectedError } from "../../../domain/errors";
import { CompanyAlreadyRegisteredError } from "../../../domain/errors/company-already-exists-error";
import { AddCompany } from "../../../domain/usecases";

import { HttpClient, HttpStatusCode } from "../../protocols/http";

export class RemoteAddCompany implements AddCompany {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<void>
  ) {}

  async add(params: AddCompany.Params): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "post",
      body: params,
    });
    // switch (httpResponse.statusCode) {
    //   case HttpStatusCode.ok:
    //     return httpResponse.body!;
    //   case HttpStatusCode.forbidden:
    //     throw new EmailInUseError();
    //   default:
    //     throw new UnexpectedError();
    // }

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body!;
      case HttpStatusCode.conflict:
        throw new CompanyAlreadyRegisteredError();
      default:
        throw new UnexpectedError();
    }
  }
}

import { useNavigate } from "react-router-dom";
import { useRecoilState, useResetRecoilState, useRecoilValue } from "recoil";
import React, { Fragment, useEffect, useState } from "react";

import { sendPasswordResetEmail } from "firebase/auth";
import { auth, db } from "../../../../main/firebase";

import { createUserState, FormStatus, Input } from "./components";
import { Validation } from "../../../protocols";
import { CreateUser } from "../../../../domain/usecases";
import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon, CheckIcon } from "@heroicons/react/outline";
import { classNames } from "../../../utils/helpers";
import { currentAccountState } from "../../../components";
import { collection, onSnapshot, query, where } from "firebase/firestore";

type Props = {
  validation: Validation;
  createUser: CreateUser;
};

const CreateAppUser: React.FC<Props> = ({ validation, createUser }: Props) => {
  const resetCreateUserState = useResetRecoilState(createUserState);
  const [state, setState] = useRecoilState(createUserState);
  const { getCurrentAccount } = useRecoilValue(currentAccountState);

  const [showError, setshowError] = useState(false);
  const [selectedRole, setSelectedRole] = useState("Select Role");

  const navigate = useNavigate();

  useEffect(() => validate("firstName"), [state.firstName]);
  useEffect(() => validate("lastName"), [state.lastName]);
  useEffect(() => validate("emailAddress"), [state.emailAddress]);
  useEffect(() => validate("contactNumber"), [state.contactNumber]);
  // useEffect(() => validate("role"), [state.role]);

  const validate = (field: string): void => {
    const { firstName, lastName, emailAddress, contactNumber, role } = state;
    const formData = {
      firstName,
      lastName,
      emailAddress,
      contactNumber,
      role,
    };
    setState((old) => ({
      ...old,
      [`${field}Error`]: validation.validate(field, formData),
    }));
    setState((old) => ({
      ...old,
      isFormInvalid:
        !!old.firstNameError ||
        !!old.lastNameError ||
        !!old.emailAddressError ||
        !!old.contactNumberError ||
        !!old.roleError,
    }));
  };

  // const validate = (field: string): void => {
  //   const { meterName, meterLocation } = state;
  //   const formData = {
  //     meterName,
  //     meterLocation,
  //   };
  //   setState((old) => ({
  //     ...old,
  //     [`${field}Error`]: validation.validate(field, formData),
  //   }));

  //   setState((old) => ({
  //     ...old,
  //     isFormInvalid: !old.meterName || !old.meterLocation || !state.companyNumber,
  //   }));
  // };

  // useEffect(() => resetCreateUserState(), []);

  useEffect(() => {
    const q = query(collection(db, "roles"), where("scope", "==", "Merchant"));
    return onSnapshot(q, (querySnapshot) => {
      let result = querySnapshot.docs.map((doc) => {
        return {
          role: doc.data().role,
        };
      });
      setState((old) => ({ ...old, roles: result }));
    });
  }, []);

  const handleRoleChange = (value: string) => {
    console.log("role", value);
    if (value === "Select Role") {
      setSelectedRole("None");

      setState((old) => ({
        ...old,
        role: value,
        roleError: "This is a required field",
      }));
    } else {
      setSelectedRole(value);
      setState((old) => ({
        ...old,
        role: value,
        roleError: undefined,
      }));
    }
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    try {
      console.log("state!!!!!!!!!", state);
      if (state.isLoading || state.isFormInvalid) {
        setshowError(true);
        return;
      }

      setState((old) => ({ ...old, isLoading: true }));

      await createUser
        .create({
          firstName: state.firstName,
          lastName: state.lastName,
          email: state.emailAddress,
          contactNumber: state.contactNumber,
          role: state.role,
          account: getCurrentAccount().account,
          accountName: getCurrentAccount().merchantName,
          createdBy: getCurrentAccount().displayName,
          dateCreated: new Date(),
          apiKey: getCurrentAccount().api_key,
        })
        .then(async () => {
          console.log("resetting email", state);
          await sendPasswordResetEmail(auth, state.emailAddress);
        })
        .then(async () => {
          console.log("create user state", state);
          resetCreateUserState();
        })
        .catch((error) => {
          console.log("create user error state", error.message);

          setState((old) => ({
            ...old,
            isLoading: false,
            mainError: error.message,
          }));
        });

      setState((old) => ({
        ...old,
        isLoading: false,
        mainError: "",
      }));
      setshowError(false);
      console.log("navigate(users/create/complete)");

      // setState((old) => ({ ...old, isLoading: false }));
      // navigate("/", { replace: true });
      navigate("/users/create/complete", { replace: true });
    } catch (error) {
      console.log("Form state", state);
      setState((old) => ({
        ...old,
        isLoading: false,
        mainError: error.message,
      }));
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="shadow overflow-hidden sm:rounded-sm">
          <div className="px-4 py-5 bg-white sm:p-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700"
                >
                  First name
                </label>

                <Input
                  type="text"
                  name="firstName"
                  id="firstName"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-sm"
                />
                {showError && (
                  <div className=" block text-sm text-red-500">
                    {state.firstNameError}
                  </div>
                )}
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Last name
                </label>
                <Input
                  type="text"
                  name="lastName"
                  id="lastName"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-sm"
                />
                {showError && (
                  <div className=" block text-sm text-red-500">
                    {state.lastNameError}
                  </div>
                )}
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="role"
                  className="block text-sm font-medium text-gray-700"
                >
                  Role
                </label>

                <Listbox value={selectedRole} onChange={handleRoleChange}>
                  {({ open }) => (
                    <>
                      {/* <Listbox.Label className="block text-xs font-normal text-gray-400">
                            Location
                          </Listbox.Label> */}
                      <div className="relative">
                        <Listbox.Button className="bg-gray-50 mt-1 relative w-full border border-gray-300 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                          <span className="block truncate">{selectedRole}</span>
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60  py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                            {state.roles.map((r, index) => (
                              <Listbox.Option
                                key={index}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "text-white bg-blue-600"
                                      : "text-gray-900",
                                    "cursor-default select-none relative py-2 pl-3 pr-9"
                                  )
                                }
                                value={r.role}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "block truncate"
                                      )}
                                    >
                                      {r.role}
                                    </span>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-blue-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
                {showError && (
                  <div className=" block text-sm text-red-500">
                    {state.roleError}
                  </div>
                )}
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="emailAddress"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <Input
                  type="text"
                  name="emailAddress"
                  id="emailAddress"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-sm"
                />
                {showError && (
                  <div className=" block text-sm text-red-500">
                    {state.emailAddressError}
                  </div>
                )}
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="contactNumber"
                  className="block text-sm font-medium text-gray-700"
                >
                  Contact Number
                </label>
                <Input
                  type="text"
                  name="contactNumber"
                  id="contactNumber"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-sm"
                />
                {showError && (
                  <div className=" block text-sm text-red-500">
                    {state.contactNumberError}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
            >
              Create User
            </button>
          </div>
        </div>
      </form>
      <FormStatus />
    </>
  );
};

export default CreateAppUser;

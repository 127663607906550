import {
  ValidationComposite,
  ValidationBuilder as Builder,
} from "../../../../../../../validation/validators";

export const makeChangeCurrentUserPasswordValidation =
  (): ValidationComposite =>
    ValidationComposite.build([
      ...Builder.field("password").required().min(6).build(),
      ...Builder.field("confirmPassword").required().min(6).build(),
    ]);

import { CheckCircleIcon } from "@heroicons/react/solid";
import { format } from "date-fns";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { currentAccountState } from "../../../../components";

export default function SubmitMetersForApproval(props) {
  const { state } = useLocation();

  const { getCurrentAccount } = useRecoilValue(currentAccountState);
  const numberOfMeters = state;

  return (
    <>
      <div className="w-full">
        <div className="max-w-3xl mx-auto grid grid-cols-1 gap-6  lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2">
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            {/* Description list*/}
            <section aria-labelledby="applicant-information-title">
              <div className="bg-white px-4 py-4 shadow shadow-sm">
                <div className="px-4 pb-5  flex w-full">
                  <h2
                    id="applicant-information-title"
                    className="text-lg leading-6 font-medium text-green-500"
                  >
                    {numberOfMeters > 1
                      ? "The meters have been submitted successfully for approval"
                      : " The meter has been submitted successfully for approval"}
                  </h2>
                  <CheckCircleIcon
                    className="hidden flex-shrink-0 ml-1 h-6 w-6 text-green-500 lg:block"
                    aria-hidden="true"
                  />
                </div>

                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <div className=" pb-5">
                    <h2
                      id="applicant-information-title"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Meter Details
                    </h2>
                  </div>
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-700">
                        Number of meters submitted.
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {numberOfMeters}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-700">
                        Submitted By
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {getCurrentAccount().displayName}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-700">
                        Date of Submission
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {format(new Date(), "yyyy-MM-dd HH:mm:ss")}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

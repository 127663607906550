import { useState } from "react";
// import TransactionDetailsRow from "./purchaseRequestRowDetail";
import { currencyFormatter, classNames } from "../../../utils/helpers";
import { RemotePurchaseRequestModel } from "../../../../data/models";
import { format } from "date-fns";
import { makeApprovePurchaseRequest } from "../../../../main/factories/pages/purchaseRequests";

type Props = {
  transaction: RemotePurchaseRequestModel;
  index: number;
};

const statusStyles: { [key: string]: string } = {
  ELECTRICITY: "bg-green-100 text-green-800",
  AIRTIME: "bg-yellow-100 text-yellow-800",
  DSTV: "bg-blue-100 text-blue-800",
};

export default function TransactionRows({ transaction, index }: Props) {
  const [isDetailOpen, setOpenDetail] = useState(false);
  const toggleRowExpansion = () => {
    setOpenDetail((isDetailOpen) => !isDetailOpen);
  };

  console.log("transaction", transaction);

  return (
    <>
      <tr
        key={transaction.id}
        onClick={() => toggleRowExpansion()}
        className="bg-white"
      >
        <td className="pr-6 py-3 text-left whitespace-nowrap text-sm text-gray-900 font-bold">
          <time dateTime={new Date(transaction.dateCreated).toString()}>
            {format(new Date(transaction.dateCreated), "yyyy-MM-dd HH:mm:ss")}
          </time>
        </td>
        <td className="max-w-0  px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
          <div className="flex">
            <p className="truncate ">{transaction.purchaseRequestNo}</p>
          </div>
        </td>
        <td className="px-6 py-3 text-left whitespace-nowrap text-sm text-gray-500">
          <span className="text-gray-900 font-normal">
            {
              transaction.statusHistory.find((s) => s.status === "PENDING")
                .actionedByName
            }
          </span>
        </td>
        <td className="px-6 py-3 text-left whitespace-nowrap text-sm text-gray-500">
          <span className="text-gray-900 font-bold">
            {transaction.purchaseRequestItems.length}
          </span>
        </td>
        <td className="hidden px-6 py-3 whitespace-nowrap text-sm text-gray-500 md:block">
          <span
            className={classNames(
              statusStyles[`${transaction.purchaseType}`],
              "inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium capitalize"
            )}
          >
            {transaction.purchaseType}
          </span>
        </td>
        <td className="px-6 py-3 text-right whitespace-nowrap text-sm text-gray-500">
          <span className="text-gray-900 font-bold">
            {currencyFormatter(transaction.purchaseRequestTotal)}
          </span>
        </td>
        <td className="px-2 py-2   whitespace-nowrap text-sm text-gray-500 ">
          <div className="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </td>
      </tr>
      {isDetailOpen ? (
        <tr className="bg-gray-200">
          <td
            colSpan={7}
            className=" px-6 py-5 whitespace-nowrap text-sm text-gray-500 "
          >
            {makeApprovePurchaseRequest(transaction)}
            {/* <TransactionDetailsRow transaction={transaction} /> */}
          </td>
        </tr>
      ) : null}
    </>
  );
}

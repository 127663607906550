import { makeLocalStorageAdapter } from "../../factories/cache";
import { AccountModel } from "../../../domain/models";

export const setCurrentAccountAdapter = (account: AccountModel): void => {
  makeLocalStorageAdapter().set("account", account);
};

export const getCurrentAccountAdapter = (): AccountModel => {
  return makeLocalStorageAdapter().get("account");
};

// const [loggedInUser, setLoggedInUser] = useRecoilState(authState)
//     const classes = useStyles()

//     useEffect(() => {
//         firebaseApp.auth().onAuthStateChanged((user) => {
//             setLoggedInUser({ userEmail: user?.email || '' })
//         })
//     })

//     const onSignOut = () => {
//         firebaseApp.auth().signOut()
//     }

import PurchaseRequestLayout from "../../../../presentation/pages/purchaseRequest/airtime/purchaseRequest";
import { makeRemoteGetCellphonesByStatus } from "../../usecases/cellphone";

export const makeAirtimePurchaseRequest = () => {
  return (
    <PurchaseRequestLayout
      getActiveCellphones={makeRemoteGetCellphonesByStatus()}
    />
  );
};

import { atom } from "recoil";

export const loginState = atom({
  key: "loginState",
  default: {
    isLoading: false,
    isFormInvalid: true,
    email: "",
    password: "",
    emailError: "",
    passwordError: "",
    mainError: "",
  },
});

export const forgotPasswordState = atom({
  key: "forgotPasswordState",
  default: {
    isLoading: false,
    isFormInvalid: true,
    email: "",
    emailError: "",
    mainError: "",
  },
});

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useResetRecoilState, useRecoilState } from "recoil";

import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import logo from "../../assets/logo.svg";

import { Validation } from "../../protocols";
import { FormStatus, loginState } from "./components";
import Input from "./components/input";
import { useFirestore } from "../../hooks/use-firestore";

type Props = {
  validation: Validation;
};

export default function ForgotPassword({ validation }: Props) {
  const resetLoginState = useResetRecoilState(loginState);
  const firestore = useFirestore();

  const navigate = useNavigate();
  const [state, setState] = useRecoilState(loginState);
  const [showError, setshowError] = useState(false);

  const auth = getAuth();

  useEffect(() => resetLoginState(), []);
  useEffect(() => validate("email"), [state.email]);

  console.log(" state", state);

  const validate = (field: string): void => {
    const { email } = state;
    const formData = { email };
    setState((old) => ({
      ...old,
      [`${field}Error`]: validation.validate(field, formData),
    }));
    setState((old) => ({
      ...old,
      isFormInvalid: !!old.emailError,
    }));
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    setState((old) => ({
      ...old,
      isLoading: true,
    }));

    try {
      if (state.isLoading || state.isFormInvalid) {
        setshowError(true);
        setState((old) => ({ ...old, isLoading: false }));
        return;
      }

      const isValidUser = await firestore.checkEmail(state.email);

      if (!isValidUser) {
        navigate("/auth/notfound", { replace: true });
      } else {
        sendPasswordResetEmail(auth, state.email)
          .then(() => {
            navigate("/auth/complete", { replace: true });
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ..
          });
      }

      setshowError(false);
    } catch (error: any) {
      setshowError(false);
    }
  };

  return (
    <div className=" bg-white shadow shadow-sm sm:rounded-sm  py-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={logo} alt="PrepaidPlus" />

        <h2 className="mt-6 text-center text-xl font-semibold text-gray-900">
          Enter your PrepaidPlus User Name
        </h2>
      </div>

      <div className="py-8 px-4 sm:px-10">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email address
            </label>
            <div className="mt-1"></div>
            <Input type="email" name="email" placeholder="email address" />
            {showError && (
              <div className=" block text-sm text-red-500">
                {state.emailError && state.emailError}
              </div>
            )}
          </div>

          <div className="flex items-center justify-between">
            <div className="text-sm">
              <Link
                to="/auth/login"
                className="font-medium text-blue-600 hover:text-blue-500"
              >
                Back to Sign-in
              </Link>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-sm shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 active:bg-blue-800 focus:outline-none "
            >
              Reset Password
            </button>
          </div>

          <FormStatus />
        </form>
      </div>
    </div>
  );
}

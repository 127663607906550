import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentAccountState } from "../../../components";
import { useErrorHandler } from "../../../hooks";

// import transactions from "../../reports/transactions";
import { pendingApprovalsState } from "../atoms/atoms";
import PurchaseRequestBalances from "./purchaseRequestBalances";

import TransactionRows from "./transactionRows";

export default function PurchaseRequestApplications() {
  const [state, setState] = useRecoilState(pendingApprovalsState);
  // const { getCurrentAccount } = useRecoilValue(currentAccountState);
  // const handleError = useErrorHandler((error: Error) => {
  //   setState((old) => ({ ...old, isLoading: false, error: error.message }));
  // });

  // const [avaliableBalance] = useState({
  //   name: "Account Balance",
  //   href: "#",
  //   type: "currency",
  //   amount: state.avaliableBalance,
  // });

  const transactions =
    state.pendingPurchaseRequests &&
    state.pendingPurchaseRequests.map((transaction, index) => {
      return (
        <TransactionRows
          key={transaction.purchaseRequestNo}
          index={index}
          transaction={transaction}
        />
      );
    });

  return (
    <>
      <div className="bg-white overflow-hidden shadow rounded-sm">
        <div className="px-4 py-5 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-5">
          <div className="lg:col-span-6">
            <h2 className="text-lg leading-6 font-medium text-gray-900">
              Pending Approvals
            </h2>
          </div>
          <div className="lg:col-span-6 text-right">
            <PurchaseRequestBalances />
          </div>
        </div>

        {/* <div className="px-4 py-5 sm:px-6">
            <h2 className="max-w-7xl mx-auto  text-lg leading-6 font-medium text-gray-900">
             
            </h2>





          </div> */}
        <div className="px-6 pb-6">
          {/* Activity list (smallest breakpoint only) */}
          <div className="shadow sm:hidden">
            <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
              {/* {pendingRequests.map((transaction) => (
              <li key={transaction.id}>
                <span className="flex items-center space-x-4">
                  <span className="flex-1 flex space-x-2 truncate">
                    <span className="flex flex-col text-gray-500 text-sm truncate">
                      <span className="truncate">{transaction.id}</span>
                      <span>
                        <span className="text-gray-900 font-medium">
                          {currencyFormatter(transaction.amount)}
                        </span>
                      </span>
                      <time dateTime={transaction.datetime}>
                        {transaction.date}
                      </time>
                    </span>
                  </span>
                  <ChevronRightIcon
                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </li>
            ))} */}
            </ul>

            <nav
              className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
              aria-label="Pagination"
            >
              <div className="flex-1 flex justify-between">
                <a
                  href="/"
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                >
                  Previous
                </a>
                <a
                  href="/"
                  className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                >
                  Next
                </a>
              </div>
            </nav>
          </div>

          {/* Activity table (small breakpoint and up) */}
          <div className="hidden  sm:block">
            <div className="max-w-7xl mx-auto">
              <div className="flex flex-col mt-2">
                <div className="align-middle min-w-full flex-grow overflow-x-auto overflow-hidden ">
                  {transactions.length > 0 ? (
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr>
                          <th className="pr-6 py-3  text-left text-sm font-medium text-gray-500  tracking-wider">
                            Date and Time
                          </th>
                          <th className="px-6 py-3  text-left text-sm font-medium text-gray-500  tracking-wider">
                            Purchase Number
                          </th>

                          <th className="px-6 py-3  text-left text-sm font-medium text-gray-500  tracking-wider">
                            Initiator
                          </th>
                          <th className="px-6 py-3 text-left text-sm font-medium text-gray-500  tracking-wider">
                            Item Count
                          </th>

                          <th className="hidden px-6 py-3  text-left text-sm font-medium text-gray-500  tracking-wider md:block">
                            Type
                          </th>
                          <th className="px-6 py-3 text-right text-sm font-medium text-gray-500  tracking-wider">
                            Amount
                          </th>
                          <th className="px-2 py-2  text-right text-sm font-medium text-gray-500  tracking-wider"></th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-100">
                        {transactions}
                      </tbody>
                    </table>
                  ) : (
                    <h2 className="text-md leading-6 font-medium text-gray-900">
                      There are no approvals to action.
                    </h2>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

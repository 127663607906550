// import { PurchaseRequestType } from "../../../../../types/purchaseRequestType";
import { format } from "date-fns";
import { PurchaseRequestModel } from "../../../../../domain/usecases/get-account-balances";
import { classNames, currencyFormatter } from "../../../../utils/helpers";

const statusStyles: { [key: string]: string } = {
  complete: "bg-green-100 text-green-800",
  successful: "bg-green-100 text-green-800",
  pending: "bg-yellow-100 text-yellow-800",
  processing: "bg-yellow-100 text-yellow-800",
  failed: "bg-red-100 text-red-800",
  rejected: "bg-red-100 text-red-800",
};

type Props = {
  transaction: PurchaseRequestModel;
};

export default function TransactionDetailsRow({ transaction }: Props) {
  const electricityHeader = () => {
    return (
      <tr>
        <th
          scope="col"
          className="px-4 py-1 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <div>Meter Name &amp;</div>
          <div>Location</div>
        </th>
        <th
          scope="col"
          className="px-4 py-1 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <div>Meter Number &amp;</div>
          <div>Owner</div>
        </th>
        <th
          scope="col"
          className="px-4 py-1 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <div>Last Purchase &amp;</div>
          <div>Amount</div>
        </th>
        <th
          scope="col"
          className="px-4 py-1 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          Amount
        </th>
      </tr>
    );
  };

  const airtimeHeader = () => {
    return (
      <tr>
        <th
          scope="col"
          className="px-4 py-1 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <div>Owner &amp;</div>
          <div>Job Title</div>
        </th>
        <th
          scope="col"
          className="px-4 py-1 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <div>Number &amp;</div>
          <div>Network</div>
        </th>
        <th
          scope="col"
          className="px-4 py-1 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <div>Last Purchase &amp;</div>
          <div>Amount</div>
        </th>
        <th
          scope="col"
          className="px-4 py-1 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          Amount
        </th>
      </tr>
    );
  };

  const dstvHeader = () => {
    return (
      <tr>
        <th
          scope="col"
          className="px-4 py-1 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <div>Meter Name &amp;</div>
          <div>Location</div>
        </th>
        <th
          scope="col"
          className="px-4 py-1 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <div>Smartcard Number &amp;</div>
          <div>Owner</div>
        </th>
        <th
          scope="col"
          className="px-4 py-1 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <div>Last Purchase &amp;</div>
          <div>Amount</div>
        </th>
        <th
          scope="col"
          className="px-4 py-1 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          Amount
        </th>
      </tr>
    );
  };

  const tableHeader: { [key: string]: JSX.Element } = {
    electricity: electricityHeader(),
    airtime: airtimeHeader(),
    dstv: dstvHeader(),
  };
  const electricityRow = () => {
    return (
      transaction.purchaseType === "ELECTRICITY" &&
      transaction.items.map((transaction) => (
        <tr key={transaction.number} className="border-b border-gray-150">
          <td className="px-4 py-1 whitespace-nowrap text-sm font-normal">
            <div className="text-sm text-gray-500">{transaction.name}</div>
            <div className="text-sm font-medium text-gray-900">
              {transaction.location}
            </div>
          </td>
          <td className="px-4 py-1 whitespace-nowrap">
            <div className="text-sm text-gray-500">{transaction.number}</div>

            <div className="text-sm font-medium text-gray-900 capitalize">
              {transaction.owner.toLowerCase()}
            </div>
          </td>
          <td className="px-4 py-1 whitespace-nowrap text-sm font-normal text-gray-500">
            <div className="text-sm text-gray-500">
              {transaction.lastPurchaseDate === "None"
                ? "None"
                : format(new Date(transaction.lastPurchaseDate), "yyyy-MM-dd")}
            </div>
            <div className="text-sm font-medium text-gray-900">
              {currencyFormatter(transaction.lastPurchaseAmount)}
            </div>
          </td>
          <td className="px-4 py-1 whitespace-nowrap text-sm text-gray-500">
            <span className="text-gray-900 font-bold">
              {currencyFormatter(transaction.purchaseAmount)}
            </span>
          </td>
        </tr>
      ))
    );
  };

  const airtimeRow = () => {
    return (
      transaction.purchaseType === "AIRTIME" &&
      transaction.items.map((transaction) => (
        <tr key={transaction.number} className="border-b border-gray-150">
          <td className="px-4 py-1 whitespace-nowrap text-sm font-normal">
            <div className="text-sm  text-gray-500 capitalize">
              {transaction.owner.toLowerCase()}
            </div>
            <div className="text-sm font-medium text-gray-900">
              {transaction.jobTitle}
            </div>
          </td>
          <td className="px-4 py-1 whitespace-nowrap">
            <div className="text-sm text-gray-500">{transaction.number}</div>
            <div className="text-sm font-medium text-gray-900">
              {transaction.network}
            </div>
          </td>
          <td className="px-4 py-1 whitespace-nowrap text-sm font-normal text-gray-500">
            <div className="text-sm text-gray-500">
              {transaction.lastPurchaseDate === "None"
                ? "None"
                : format(new Date(transaction.lastPurchaseDate), "yyyy-MM-dd")}
            </div>
            <div className="text-sm font-medium text-gray-900">
              {currencyFormatter(transaction.lastPurchaseAmount)}
            </div>
          </td>
          <td className="px-4 py-1 whitespace-nowrap text-sm text-gray-500">
            <span className="text-gray-900 font-bold">
              {currencyFormatter(transaction.purchaseAmount)}
            </span>
          </td>
        </tr>
      ))
    );
  };

  const dstvRow = () => {
    return (
      transaction.purchaseType === "DSTV" &&
      transaction.items.map((transaction) => (
        <tr key={transaction.number} className="border-b border-gray-150">
          <td className="px-4 py-1 whitespace-nowrap text-sm font-normal">
            <div className="text-sm text-gray-500">{transaction.name}</div>
            <div className="text-sm font-medium text-gray-900">
              {transaction.location}
            </div>
          </td>
          <td className="px-4 py-1 whitespace-nowrap">
            <div className="text-sm text-gray-500">{transaction.number}</div>

            <div className="text-sm font-medium text-gray-900 capitalize">
              {transaction.owner.toLowerCase()}
            </div>
          </td>
          <td className="px-4 py-1 whitespace-nowrap text-sm font-normal text-gray-500">
            <div className="text-sm text-gray-500">
              {transaction.lastPurchaseDate === "None"
                ? "None"
                : format(new Date(transaction.lastPurchaseDate), "yyyy-MM-dd")}
            </div>
            <div className="text-sm font-medium text-gray-900">
              {currencyFormatter(transaction.lastPurchaseAmount)}
            </div>
          </td>
          <td className="px-4 py-1 whitespace-nowrap text-sm text-gray-500">
            <span className="text-gray-900 font-bold">
              {currencyFormatter(transaction.purchaseAmount)}
            </span>
          </td>
        </tr>
      ))
    );
  };

  const tableRow: { [key: string]: JSX.Element[] } = {
    electricity: electricityRow(),
    airtime: airtimeRow(),
    dstv: dstvRow(),
  };

  return (
    <>
      <div className="overflow-hidden w-full">
        <div className="flex flex-col">
          <div className=" overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-1 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              {/* Description list*/}
              <section aria-labelledby="purchase-request-approval-title">
                <div className="bg-white py-4 px-6 shadow shadow-sm ">
                  <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                    <div className="lg:col-span-6">
                      <h2 className="text-lg leading-6 font-medium text-gray-900">
                        Status
                      </h2>
                    </div>
                    <div className="lg:col-span-6 text-right">
                      <span
                        className={classNames(
                          statusStyles[transaction.status.toLowerCase()],
                          "inline-flex items-center px-5 py-1 rounded-md text-md font-medium capitalize"
                        )}
                      >
                        {transaction.status.toLowerCase()}
                      </span>
                    </div>
                  </div>

                  <div className="border-t border-gray-200 py-4 mt-3 mb-3 ">
                    <div className=" pb-5">
                      <h2
                        id="purchase-request-approval-title"
                        className="text-md leading-6 font-medium text-gray-900"
                      >
                        Purchase Request Details
                      </h2>
                    </div>
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-600">
                          Purchase Request No
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {transaction.id}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-700">
                          Amount
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {currencyFormatter(transaction.amount)}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-700">
                          Items in Request
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {transaction.items.length}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-700">
                          Submitted By
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {transaction.initiator}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-700">
                          Date of Submission
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {format(transaction.date, "yyyy-MM-dd HH:mm:ss")}
                        </dd>
                      </div>
                      {transaction.rejectionReason ? (
                        <div className="sm:col-span-1 overflow-hidden">
                          <dt className="text-sm font-medium text-gray-700">
                            Reason
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 whitespace-normal">
                            {transaction.rejectionReason}
                          </dd>
                        </div>
                      ) : null}
                    </dl>
                  </div>
                  <div className="overflow-hidden">
                    <table className="min-w-full bg-gray-50 border rounded-sm border-gray-200">
                      <thead className="sticky bg-gray-200">
                        {tableHeader[transaction.purchaseType.toLowerCase()]}
                      </thead>
                      <tbody>
                        {tableRow[transaction.purchaseType.toLowerCase()]}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

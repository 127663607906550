import ConfirmPurchaseRequest from "../../../../presentation/pages/purchaseRequest/electricity/confirmPurchaseRequest";
import { makeRemoteCreateElectricityPurchaseRequest } from "../../usecases/purchase-requests";

export const makeCreateElectricityPurchaseRequest = () => {
  return (
    <ConfirmPurchaseRequest
      createElectricityPurchaseRequest={makeRemoteCreateElectricityPurchaseRequest()}
    />
  );
};

import { Routes, Route, BrowserRouter } from "react-router-dom";
import AuthorizedLayout from "./routes/authorisedLayout";
import AuthorizedRoute from "../presentation/components/authorizedRoute/AuthorizedRoute";
import { makeLocalStorageAdapter } from "./factories/cache";
import { RecoilRoot } from "recoil";
import { currentAccountState } from "../presentation/components/atoms/atoms";
import { setCurrentAccountAdapter, getCurrentAccountAdapter } from "./adapters";
import { makeLogin } from "./factories/pages";
import { makeForgotPassword } from "./factories/pages/login/forgot-password-factory";
import Auth from "../presentation/pages/signin/auth";
import ResetCompleted from "../presentation/pages/signin/complete";
import EmailNotFound from "../presentation/pages/signin/notfound";
import LoginAs from "../presentation/pages/loginAs";

export default function App() {
  const state = {
    setCurrentAccount: setCurrentAccountAdapter,
    getCurrentAccount: getCurrentAccountAdapter,
  };
  console.log("state", state.getCurrentAccount());
  // const [loggedInUser, setLoggedInUser] = useRecoilState(authState)
  // const classes = useStyles()

  // useEffect(() => {
  //     firebaseApp.auth().onAuthStateChanged((user) => {
  //         setLoggedInUser({ userEmail: user?.email || '' })
  //     })
  // })

  // const onSignOut = () => {
  //     firebaseApp.auth().signOut()
  // }

  window.addEventListener(
    "beforeunload",
    function (e) {
      makeLocalStorageAdapter().clear();
    },
    false
  );

  return (
    <RecoilRoot initializeState={({ set }) => set(currentAccountState, state)}>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/auth" element={makeLogin()} /> */}
          {/* <Route path="/auth" element={makeLogin()} /> */}

          <Route path="/auth/*" element={<Auth />}>
            <Route path="login" element={makeLogin()} />
            <Route path="forgot" element={makeForgotPassword()} />
            <Route path="notfound" element={<EmailNotFound />} />
            <Route path="complete" element={<ResetCompleted />} />
          </Route>

          <Route path="/loginas" element={<LoginAs />} />

          <Route
            path="/*"
            element={
              <AuthorizedRoute>
                <AuthorizedLayout />
              </AuthorizedRoute>
            }
          />

          {/* <Navigate to="/dashboard" /> */}
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  );
}

import format from "date-fns/format";

export const Footer = () => {
  return (
    <footer className="sticky bottom-0  z-40 bg-white  text-xs font-medium text-gray-500 ring-1 ring-gray-900 ring-opacity-5 shadow-sm">
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center justify-between h-14">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <span className="hint-text">
                Copyright © {format(new Date(), "yyyy")}
              </span>
              <span className="font-montserrat text-gray-900 font-bold">
                &nbsp; Cachet Enterprises
              </span>
              <span className="hint-text">&nbsp; All rights reserved. </span>
            </div>
          </div>
          <div className="hidden md:block">
            <div className="ml-4 flex items-center md:ml-6">
              <div className="flex justify-end" id="stepsButton"></div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

import {
  ValidationComposite,
  ValidationBuilder as Builder,
} from "../../../../../validation/validators";

export const makeValidateMeterValidation = (): ValidationComposite =>
  ValidationComposite.build([
    ...Builder.field("meterNumber").required().build(),
    ...Builder.field("meterLocation").required().build(),
  ]);

// import { PurchaseRequestType } from "../../../../../types/purchaseRequestType";
import { PencilAltIcon } from "@heroicons/react/outline";
import { format } from "date-fns";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";

import { GetUsersState } from "../../../../domain/usecases/get-user";
import { classNames } from "../../../utils/helpers";
import { manageUserState } from "./components/atoms";
import UserDetailsRow from "./userDetailsRow";

type Props = {
  user: GetUsersState.Model;
  index: number;
};

const statusStyles: { [key: string]: string } = {
  true: "bg-green-100 text-green-800",
  false: "bg-red-100 text-red-800",
};

export default function UserRows({ user, index }: Props) {
  const [isDetailOpen, setOpenDetail] = useState(false);
  const setState = useSetRecoilState(manageUserState);

  const toggleRowExpansion = () => {
    setOpenDetail((isDetailOpen) => !isDetailOpen);
    setState((old) => ({
      ...old,
      selectedUser: user,
    }));
  };

  return (
    <>
      <tr
        key={user.uid}
        onClick={() => toggleRowExpansion()}
        className="bg-white"
      >
        <td className="pr-6 py-3 text-left whitespace-nowrap text-sm text-gray-900 font-bold">
          <div>{user.displayName}</div>
        </td>
        <td className="  px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
          <div className="flex">
            <p className="truncate ">{user.email}</p>
          </div>
        </td>
        <td className="px-6 py-3 text-left whitespace-nowrap text-sm text-gray-500">
          <span className="text-gray-900 font-normal">{user.role}</span>
        </td>

        <td className="px-6 py-3 text-left whitespace-nowrap text-sm text-gray-500">
          {/* <span className="text-gray-900 font-bold">{user.status}</span> */}

          <span
            className={classNames(
              statusStyles[user.isEnabled],
              "inline-flex items-center px-4 py-2 rounded-md text-xs font-medium capitalize"
            )}
          >
            {user.isEnabled === "true" ? "Enabled" : "Disabled"}
          </span>
        </td>
        <td className="px-6 py-3 text-left whitespace-nowrap text-sm text-gray-500">
          <div className="block cursor-pointer w-auto">
            <PencilAltIcon
              className="text-gray-400 h-6 w-6 group-hover:text-gray-900"
              aria-hidden="true"
            ></PencilAltIcon>
          </div>
        </td>
      </tr>
      {isDetailOpen ? (
        <tr className="bg-gray-200">
          <td
            colSpan={7}
            className=" px-6 py-4 whitespace-nowrap text-sm text-gray-500 "
          >
            <UserDetailsRow user={user} index={index} />
          </td>
        </tr>
      ) : null}
    </>
  );
}

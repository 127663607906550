import {
  ValidationComposite,
  ValidationBuilder as Builder,
} from "../../../../../validation/validators";

export const makeValidateAmendMeterDetailsValidation =
  (): ValidationComposite =>
    ValidationComposite.build([
      ...Builder.field("meterName").required().build(),
      ...Builder.field("meterLocation").required().build(),
    ]);

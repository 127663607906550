// import { makeRemoteAuthentication } from "../../usecases";

import ForgotPassword from "../../../../presentation/pages/signin/forgot";
import { makeForgotPasswordValidation } from "./forgot-password-validation-factory";

export const makeForgotPassword = () => {
  return <ForgotPassword validation={makeForgotPasswordValidation()} />;
};
// export const AuthorizedRoute = ({ children }: { children: JSX.Element }) => {
// export default makeLogin

import { format } from "date-fns";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentAccountState } from "../../../components";
import { PencilAltIcon } from "@heroicons/react/outline";
import {
  toggleChangePasswordState,
  toggleEditCurrentUserState,
} from "./components";
import { userAccountState } from "./components";
import { query, collection, where, onSnapshot } from "firebase/firestore";
import { db } from "../../../../main/firebase";
import Loading from "../../../components/loading/loading";
import { makeChangeCurrentUserDetails } from "../../../../main/factories/pages/signup/users/manageusers/user/edit-current-user-details-factory";
import { makeChangeCurrentUserPassword } from "../../../../main/factories/pages/signup/users/manageusers/user/change-current-userpassword-factory";

// export default function UserDetailsRow({ user }: Props) {
export default function Account() {
  const { getCurrentAccount } = useRecoilValue(currentAccountState);
  const [state, setState] = useRecoilState(userAccountState);
  const [changePasswordState, setChangePasswordState] = useRecoilState(
    toggleChangePasswordState
  );
  const [editUserDetailsState, setEditUserDetailsState] = useRecoilState(
    toggleEditCurrentUserState
  );

  useEffect(() => {
    const q = query(
      collection(db, "users"),
      where("uid", "==", getCurrentAccount().uid)
    );
    return onSnapshot(q, (querySnapshot) => {
      const doc = querySnapshot.docs[0].data();

      const user = {
        firstName: doc.firstName,
        lastName: doc.lastName,
        email: doc.email,
        contactNumber: doc.contactNumber,
        role: doc.role,
        dateCreated: doc.dateCreated,
        uid: doc.uid,
      };

      console.log("user", user);

      setState((old) => ({
        ...old,
        firstName: user.firstName,
        lastName: user.lastName,
        emailAddress: user.email,
        contactNumber: user.contactNumber,
        dateCreated: user.dateCreated.toDate(),
        uid: user.uid,
        role: user.role,
        isLoading: false,
      }));
    });
  }, []);

  const togglePasswordClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    setChangePasswordState((old) => ({
      ...old,
      isSelected: !changePasswordState.isSelected,
    }));
  };

  const editUserDetailsClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    setEditUserDetailsState((old) => ({
      ...old,
      isSelected: !editUserDetailsState.isSelected,
    }));
  };

  return (
    <>
      {!state.isLoading && (
        <div className="overflow-hidden w-full">
          <div className="bg-blue-100 w-full">
            <div className=" lg:max-w-7xl lg:mx-auto">
              <div className="py-4 md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                  <div className="flex items-center">
                    <h3 className="text-xl font-medium leading-6 text-gray-900 ">
                      <span>Account Settings </span>
                    </h3>
                  </div>
                </div>
                <div className="mt-4 flex space-x-2 md:mt-0 md:ml-4">
                  <span className="text-xl leading-6 font-medium text-gray-900">
                    {getCurrentAccount().merchantName}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className=" overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-1 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <section aria-labelledby="purchase-request-approval-title">
                  <div className=" py-4 px-6  ">
                    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                      <div className="lg:col-span-6">
                        <h2 className="text-lg leading-6 font-medium text-gray-900">
                          {`${state.firstName} ${state.lastName}`}
                        </h2>
                      </div>
                      <div className="lg:col-span-6  ml-auto">
                        <div className="ml-4 flex items-center md:ml-6">
                          {!changePasswordState.isSelected &&
                          !editUserDetailsState.isSelected ? (
                            <>
                              <button
                                type="button"
                                onClick={(e) => editUserDetailsClick(e)}
                                className="relative inline-flex justify-center py-2 px-6 border border-transparent rounded-sm shadow-sm disabled:opacity-50 text-sm font-medium text-gray-600 bg-gray-200 hover:bg-gray-300 active:bg-gray-400 focus:outline-none "
                              >
                                <>
                                  <PencilAltIcon
                                    className="-ml-1 mr-2 h-6 w-6 text-gray-500"
                                    aria-hidden="true"
                                  />
                                  Edit Details
                                </>
                              </button>

                              <div className="ml-3 border-l border-gray-300 relative lg:col-span-6 text-right">
                                {/* {statusBar(user.isEnabled)} */}

                                <button
                                  onClick={(e) => togglePasswordClick(e)}
                                  type="button"
                                  className="ml-4 inline-flex justify-center py-2 px-6 border 
              border-transparent rounded-sm shadow-sm disabled:opacity-50 
              text-sm font-medium  text-white  bg-blue-600 hover:bg-blue-700  
              active:bg-indingo-800 focus:outline-none"
                                >
                                  Change Password
                                </button>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="border-t border-gray-200 py-4 mt-3 mb- ">
                      {changePasswordState.isSelected
                        ? makeChangeCurrentUserPassword()
                        : null}

                      {editUserDetailsState.isSelected
                        ? makeChangeCurrentUserDetails()
                        : null}

                      <div className="pt-3 pb-5 ">
                        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                          <div className="lg:col-span-6 ml-auto"></div>
                        </div>
                      </div>
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-600">
                            Name
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {`${state.firstName} ${state.lastName}`}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-700">
                            Email...
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {state.emailAddress}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-700">
                            Role
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {state.role}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-700">
                            Contact Number
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {state.contactNumber}
                          </dd>
                        </div>

                        {/* <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-700">
                        Created By
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {state.currentUser.createdBy}
                      </dd>
                    </div> */}
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-700">
                            Date Created
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {format(
                              new Date(state.dateCreated),
                              "yyyy-MM-dd HH:mm:ss"
                            )}
                          </dd>
                        </div>
                      </dl>
                    </div>

                    {/* <div className="flex-grow w-full max-w-7xl mx-auto lg:flex">
                    <div className="max-w-7xl w-full mx-auto pr-4 sm:pr-6 lg:pr-8 bg-gray-100">
                      <div className="w-full mt-6">
                        <div className="sm:hidden">
                          <label htmlFor="tabs" className="sr-only">
                            Select a tab
                          </label>
                          <select
                            id="tabs"
                            name="tabs"
                            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                            defaultValue={tabs.find((tab) => tab.current)!.name}
                          >
                            {tabs.map((tab) => (
                              <option key={tab.name}>{tab.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="hidden sm:block">
                          <div className="border-b border-gray-200">
                            <nav
                              className="-mb-px flex space-x-8"
                              aria-label="Tabs"
                            >
                              {tabs.map((tab) => (
                                <NavLink
                                  className={({ isActive }) =>
                                    isActive
                                      ? "text-gray-900 border-b-2 px-4 border-gray-900 whitespace-nowrap font-medium"
                                      : "border-transparent border-b-2 px-4 text-gray-400 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-2 px-0 border-b-2"
                                  }
                                  to={tab.href}
                                  key={tab.name}
                                  aria-current={
                                    tab.current ? "page" : undefined
                                  }
                                >
                                  <>
                                    <h2
                                      id="meters-tab-title"
                                      className="text-md leading-6  font-medium"
                                    >
                                      {tab.name}
                                    </h2>
                                  </>
                                </NavLink>
                              ))}
                            </nav>
                          </div>
                           <div className="mt-6">
                            <Outlet />
                          </div> 
                        </div>
                      </div>
                    </div>
                  </div> */}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      )}
      {state.isLoading && <Loading />}
    </>
  );
}

import { loginState } from "./atoms";
import { InputBase } from "../../../components";

import { useRecoilState } from "recoil";
import React from "react";

type Props = {
  type: string;
  name: string;
  placeholder: string;
};

const Input: React.FC<Props> = ({
  type,
  name,
  placeholder,
  ...props
}: Props) => {
  const [state, setState] = useRecoilState(loginState);
  return (
    <InputBase
      {...props}
      type={type}
      name={name}
      placeholder={placeholder}
      state={state}
      setState={setState}
      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
    />
  );
};

export default Input;

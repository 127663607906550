import jsPDF from "jspdf";
import "jspdf-autotable";
import { format } from "date-fns";

import autoTable from "jspdf-autotable";
import { AccountModel } from "../../../../../domain/models";
import {
  ProductProvider,
  PurchasesOrderItem,
  SuccessfulPurchase,
} from "../../../../../domain/usecases/get-account-balances";
import { currencyFormatter } from "../../../../utils/helpers";

export const printTaxUnregistered = (
  transaction: PurchasesOrderItem,
  meterIdx: number,
  account: AccountModel,
  provider: ProductProvider
) => {
  console.log("transaction.dateUpdated", transaction.dateUpdated);

  const response = transaction.response as SuccessfulPurchase;

  const doc = new jsPDF();

  autoTable(doc, {
    // tableWidth: 100,
    theme: "plain",
    // startY: 50,
    headStyles: { fontSize: 14, fontStyle: "bold", halign: "left" },
    bodyStyles: { fontSize: 10, fontStyle: "normal", cellWidth: "auto" },
    styles: { cellPadding: 0.5 },
    columnStyles: {
      0: { halign: "left" },
      2: { halign: "left" },
      3: { halign: "right" },
    },

    head: [[`${provider.name}`, "", "", ""]],

    body: [
      [`${provider.postalAddress}`, "", "VAT#", `${provider.companyNumber}`],
      [`${provider.city}`, "", "Telephone:", `${provider.telephone}`],
      [
        `Date: ${format(new Date(transaction.dateUpdated), "yyyy-MM-dd")}`,
        "",
        "Receipt:",
        `${response.creditVendReceipt.receiptNo}`,
      ],
    ],
  });

  autoTable(doc, {
    theme: "plain",
    headStyles: { fontSize: 14, fontStyle: "bold", halign: "center" },
    styles: { cellPadding: 0.5 },
    startY: 43,
    tableWidth: "auto",
    head: [["", "", "TAX INVOICE", "", ""]],
    body: [["", "", "", "", ""]],
  });

  doc.line(195.5, 55, 14, 55);

  let wantedTableWidth = 82;
  let pageWidth = doc.internal.pageSize.width;
  let margin = (pageWidth - wantedTableWidth) / 2;

  autoTable(doc, {
    tableWidth: 76,
    theme: "plain",
    headStyles: { fontSize: 10, fontStyle: "bold", halign: "center" },
    bodyStyles: {
      fontSize: 10,
      fontStyle: "normal",
      halign: "center",
      cellWidth: "auto",
    },
    styles: { cellPadding: 0.5 },
    startY: 65,
    columnStyles: {
      center: { halign: "center" },
    },

    head: [["Sale  |  Credit Purchase  -  BPC"]],

    body: [
      {
        center: `${format(
          new Date(transaction.dateUpdated),
          "yyyy-MM-dd HH:mm:ss"
        )}`,
      },
    ],
    margin: { left: margin, right: margin },
    columns: [{ header: "", dataKey: "center" }],
  });

  autoTable(doc, {
    tableWidth: 96,
    theme: "plain",
    headStyles: { fontSize: 10, fontStyle: "bold" },
    bodyStyles: { fontSize: 10, fontStyle: "normal", cellWidth: "wrap" },
    styles: { cellPadding: 0.5 },
    startY: 75,
    head: [["", ""]],
    body: [
      [
        `ID:${response.creditVendReceipt.clientId}`,
        `Receipt:${response.creditVendReceipt.receiptNo}`,
      ],
      [
        `Acc:${response.creditVendReceipt.account}`,
        `Plot:${response.creditVendReceipt.location}`,
      ],
    ],
    margin: { left: margin, right: margin },
  });

  doc.setFontSize(10);
  doc.setTextColor(20);
  doc.text(`Name:${response.creditVendReceipt.name}`, 64.5, 93.5);

  autoTable(doc, {
    tableWidth: 76,
    theme: "plain",
    headStyles: { fontSize: 10, fontStyle: "bold", halign: "center" },
    bodyStyles: {
      fontSize: 10,
      fontStyle: "normal",
      halign: "center",
      cellWidth: "auto",
    },
    styles: { cellPadding: 0.5 },

    columnStyles: {
      center: { halign: "left" },
    },
    startY: 100,
    head: [["TOKEN"]],
    margin: { left: margin, right: margin },
    columns: [{ header: "", dataKey: "center" }],
  });

  const charge =
    response.creditVendReceipt.tariff === "Domestic"
      ? [
          "Domestic Charge",
          ":",
          currencyFormatter(response.creditVendReceipt.standardCharge),
        ]
      : [
          "Business Charge",
          ":",
          currencyFormatter(response.creditVendReceipt.standardCharge),
        ];

  autoTable(doc, {
    tableWidth: 76,
    theme: "plain",
    headStyles: { fontSize: 10, fontStyle: "bold" },
    bodyStyles: { fontSize: 10, fontStyle: "normal" },
    styles: { cellPadding: 0.5 },
    startY: 109,
    head: [["Desription", ":", "Amount"]],
    body: [
      [
        "Tendered",
        ":",
        `${currencyFormatter(response.creditVendReceipt.amtTendered)}`,
      ],
      [
        "Cost of Units",
        ":",
        `${currencyFormatter(response.creditVendReceipt.costUnits)}`,
      ],
      charge,
      [
        "Government Levy",
        ":",
        `${currencyFormatter(response.creditVendReceipt.governmentLevy)}`,
      ],
      ["VAT", ":", `${currencyFormatter(response.creditVendReceipt.vat)}`],
      [""],
      ["Tariff", ":", `${response.creditVendReceipt.tariff}`],
      ["Units", ":", `${response.creditVendReceipt.tokenUnits}`],
      ["Meter Type", ":", `${response.creditVendReceipt.meterType}`],
      ["krn", ":", `${response.creditVendReceipt.krn}`],
      ["ti", ":", `${response.creditVendReceipt.ti}`],
      ["STS Meter", ":", `${response.creditVendReceipt.meterNumber}`],
      ["sgc", ":", `${response.creditVendReceipt.sgc}`],
    ],
    margin: { left: margin, right: margin },
  });
  const keychangetokenexists = response.creditVendReceipt.keychangetoken1
    ? response.creditVendReceipt.keychangetoken1
    : undefined;

  if (keychangetokenexists) {
    doc.line(146.5, 187, 64.5, 187);

    doc.setFontSize(16);
    doc.text(`${response.creditVendReceipt.keychangetoken1}`, 70.5, 196);

    doc.line(146.5, 213, 64.5, 213);

    doc.setFontSize(16);
    doc.text(`${response.creditVendReceipt.keychangetoken2}`, 70.5, 224);
    // doc.setTextColor(100);

    doc.line(146.5, 226, 64.5, 226);

    doc.setFontSize(16);
    doc.text(`${response.creditVendReceipt.stsCipher}`, 70.5, 237);
    // doc.setTextColor(100);

    doc.line(146.5, 239, 64.5, 239);
  } else {
    doc.line(146.5, 187, 64.5, 187);

    doc.setFontSize(16);
    doc.text(`${response.creditVendReceipt.stsCipher}`, 70.5, 196);

    doc.line(146.5, 213, 64.5, 213);
  }
  doc.save(`${transaction.itemType}-${transaction.number}.pdf`);
};

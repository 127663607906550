import { RemoteCreateDSTVPurchaseRequest } from "../../../../data/usecases";
import { CreateDSTVPurchaseRequest } from "../../../../domain/usecases";
import { makeApiUrl, makeAxiosHttpClient } from "../../http";

export const makeRemoteCreateDSTVPurchaseRequest =
  (): CreateDSTVPurchaseRequest =>
    new RemoteCreateDSTVPurchaseRequest(
      makeApiUrl("/purchases/create-purchase-request"),
      makeAxiosHttpClient()
    );

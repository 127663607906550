// import Spinner from "../spinner/spinner";

// import { Spinner } from "..";
import Loading from "../loading/loading";

type Props = {
  state: any;
};

const FormStatus = ({ state }: Props) => {
  const { isLoading, mainError } = state;
  console.log("iFormStatus state", state);
  console.log("isLoading, mainError", isLoading, mainError);

  return (
    <div data-testid="error-wrap" className="flex flex-col items-left">
      {isLoading && <Loading />}
      {mainError && (
        <span
          data-testid="main-error"
          className="text-lg text-red-500 font-medium"
        >
          {mainError}
        </span>
      )}
    </div>
  );
};

export default FormStatus;

// .errorWrap {
//   display: flex;
//   flex-direction: column;
//   align-items: center;

//   .spinner {
//     margin-top: 30px;
//   }

//   .error {
//     margin-top: 30px;
//     color: $primaryLight;
//   }
// }

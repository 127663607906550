// import { PurchaseRequestType } from "../../../../../types/purchaseRequestType";
import { useState } from "react";

import { classNames, currencyFormatter } from "../../../utils/helpers";
import TransactionDetailsRow from "./transactionDetailsRow";
import { PurchaseRequestModel } from "../../../../domain/usecases/get-account-balances";
import { format } from "date-fns";

type Props = {
  transaction: PurchaseRequestModel;
  index: number;
};

const statusStyles: { [key: string]: string } = {
  complete: "bg-green-100 text-green-800",
  pending: "bg-yellow-100 text-yellow-800",
  processing: "bg-yellow-100 text-yellow-800",
  failed: "bg-red-100 text-red-800",
  rejected: "bg-red-100 text-red-800",
};

export default function TransactionRows({ transaction, index }: Props) {
  const [isDetailOpen, setOpenDetail] = useState(false);
  const toggleRowExpansion = () => {
    setOpenDetail((isDetailOpen) => !isDetailOpen);
  };
  return (
    <>
      <tr
        key={transaction.id}
        onClick={() => toggleRowExpansion()}
        className="bg-white"
      >
        <td className="pr-6 py-3 text-left whitespace-nowrap text-sm text-gray-900 font-bold">
          <div> {format(transaction.date, "yyyy-MM-dd")}</div>
        </td>
        <td className=" px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
          <div className="flex">
            <p className="truncate ">{transaction.id}</p>
          </div>
        </td>
        <td className="px-6 py-3 text-left whitespace-nowrap text-sm text-gray-500">
          <span className="text-gray-900 font-normal">
            {transaction.initiator}
          </span>
        </td>
        <td className="px-6 py-3 text-left whitespace-nowrap text-sm text-gray-500">
          <span className="text-gray-900 font-normal">
            {transaction.purchaseType}
          </span>
        </td>
        <td className="px-6 py-3 text-right whitespace-nowrap text-sm text-gray-500">
          <span className="text-gray-900 font-bold">
            {currencyFormatter(transaction.amount)}
          </span>
        </td>
        <td className="hidden px-6 py-3 whitespace-nowrap text-sm text-gray-500 md:block">
          <span
            className={classNames(
              statusStyles[transaction.status.toLowerCase()],
              "inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium capitalize"
            )}
          >
            {transaction.status.toLowerCase()}
          </span>
        </td>
        <td className="px-2 py-2   whitespace-nowrap text-sm text-gray-500 ">
          <div className="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </td>
      </tr>
      {isDetailOpen ? (
        <tr className="bg-gray-200">
          <td
            colSpan={7}
            className=" px-6 py-4 whitespace-nowrap text-sm text-gray-500 "
          >
            <TransactionDetailsRow transaction={transaction} />
          </td>
        </tr>
      ) : null}
    </>
  );
}

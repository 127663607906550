import { MeterNotFoundError, UnexpectedError } from "../../../domain/errors";
import { MeterAlreadyRegisteredError } from "../../../domain/errors/meter-already-exists-error";
import { AmendMeter } from "../../../domain/usecases/amend-meter";
import { HttpClient, HttpStatusCode } from "../../protocols/http";

export class RemoteAmendMeter implements AmendMeter {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<void>
  ) {}

  async amend(params: AmendMeter.Params): Promise<void> {
    console.log("AmendMeter.Params", params);
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "post",
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body!;
      case HttpStatusCode.notFound:
        throw new MeterNotFoundError();
      case HttpStatusCode.conflict:
        throw new MeterAlreadyRegisteredError();
      default:
        throw new UnexpectedError();
    }
  }
}

// export namespace RemoteAmendAccount {
//   export type Model = CreateUser.Model;
// }

import { Outlet } from "react-router-dom";

export default function PurchaseRequestLayout() {
  return (
    <>
      <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12">
        <Outlet />
      </div>
    </>
  );
}

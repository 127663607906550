import { RemoteCreateElectricityPurchaseRequest } from "../../../../data/usecases";
import { CreateElectricityPurchaseRequest } from "../../../../domain/usecases";
import { makeApiUrl, makeAxiosHttpClient } from "../../http";

export const makeRemoteCreateElectricityPurchaseRequest =
  (): CreateElectricityPurchaseRequest =>
    new RemoteCreateElectricityPurchaseRequest(
      makeApiUrl("/purchases/create-purchase-request"),
      makeAxiosHttpClient()
    );

import { AddMeterStepsButton } from "../../../../../presentation/pages/meters/add-meter/components/stepsButton";
import { makeRemoteAddMeters } from "../../../usecases/meter";

export const makeAddMeterStepsButton = (props) => {
  return <AddMeterStepsButton {...props} addMeters={makeRemoteAddMeters()} />;
};

// isEnabled={
//   this.metersToAdd.metersToAdd.length > 0 &&
//   metersToAdd.currentValidatedMeter === null
// },

import { GetMetersState } from "../../../../domain/usecases/get-meter";
import { classNames } from "../../../utils/helpers";
import format from "date-fns/format";
import { useEffect, useState } from "react";
import { useFirestore } from "../../../hooks/use-firestore";
import { useRecoilState, useRecoilValue } from "recoil";
import { UnexpectedError } from "../../../../domain/errors";
import { currentAccountState } from "../../../components";
import { NavLink, Outlet } from "react-router-dom";
import { manageMeterState, toggleEditMeterState } from "./atoms/atoms";
import { PencilAltIcon } from "@heroicons/react/outline";
import { makeAmendMeterDetails } from "../../../../main/factories/pages/meters/manage/amend-meter-details-factory";

type Props = {
  meter: GetMetersState.Model;
};

// const tabs = [
//   {
//     name: "Amend Meter Details",
//     href: "/meters/manage/meter",
//     current: true,
//   },
// ];

export default function MeterDetailsRow({ meter }: Props) {
  const { getCurrentAccount } = useRecoilValue(currentAccountState);
  const [toggleEditMeter, setToggleEditMeter] =
    useRecoilState(toggleEditMeterState);
  const [toggleReject, setToggleReject] = useState(false);
  const [toggleRejectReversal, setToggleRejectReversal] = useState(false);
  const [deactivateReason, setDeactivateReason] = useState("");
  const [rejectionReversalReason, setRejectionReversalReason] = useState("");
  const [state, setState] = useRecoilState(manageMeterState);

  const firestore = useFirestore();

  useEffect(() => {
    setState((old) => ({
      ...old,
      meter: meter,
    }));
  }, [meter]);

  const deactivateMeter = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    console.log("meter.id, reason", meter.id);
    await firestore.deactivateMeter(meter.id, deactivateReason);
    toggleRejectClick(e);
  };

  const activateMeter = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    console.log("meter.id, reason", meter.id);
    await firestore.activateMeter(meter.id);
  };

  const setMeterForApproval = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    await firestore.setMeterForApproval(meter.id, rejectionReversalReason);
    toggleRejectionReversalClick(e);
  };

  const toggleRejectClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setToggleReject(!toggleReject);
  };

  const toggleRejectionReversalClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setToggleRejectReversal(!toggleRejectReversal);
  };

  const toggleEditMeterClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    setToggleEditMeter((old) => ({
      ...old,
      toggle: !toggleEditMeter.toggle,
    }));
  };

  const handleChange = (event) => {
    event.preventDefault();
    setDeactivateReason(event.target.value);
  };

  const handleReversalReasonChange = (event) => {
    event.preventDefault();
    setRejectionReversalReason(event.target.value);
  };

  const statusBar = (status: string) => {
    switch (status.toLowerCase()) {
      case "active":
        return getCurrentAccount().role === "Administrator" &&
          !toggleReject &&
          !toggleEditMeter.toggle ? (
          <div className="ml-4 flex items-center md:ml-6">
            <button
              type="button"
              onClick={(e) => toggleEditMeterClick(e)}
              className="relative inline-flex justify-center py-2 px-6 border border-transparent rounded-sm shadow-sm disabled:opacity-50 text-sm font-medium text-gray-600 bg-gray-200 hover:bg-gray-300 active:bg-gray-400 focus:outline-none "
            >
              <>
                <PencilAltIcon
                  className="-ml-1 mr-2 h-6 w-6 text-gray-500"
                  aria-hidden="true"
                />
                Edit Meter
              </>
            </button>

            <div className="ml-3 border-l border-gray-300 relative lg:col-span-6 text-right">
              {/* {statusBar(user.isEnabled)} */}

              <button
                onClick={(e) => toggleRejectClick(e)}
                type="button"
                className="ml-4 inline-flex justify-center py-2 px-6 border 
              border-transparent rounded-sm shadow-sm disabled:opacity-50 
              text-sm font-medium  text-white  bg-blue-600 hover:bg-blue-700  
              active:bg-indingo-800 focus:outline-none"
              >
                Deactivate Meter
              </button>
            </div>
          </div>
        ) : null;

      case "disabled":
        return (
          <button
            onClick={(e) => activateMeter(e)}
            type="button"
            className="ml-4 inline-flex justify-center py-2 px-6 border 
                                border-transparent rounded-sm shadow-sm disabled:opacity-50 
                                text-sm font-medium  text-white  bg-blue-600 hover:bg-blue-700  
                                active:bg-indingo-800 focus:outline-none"
          >
            Activate Meter
          </button>
        );
      case "pending":
        return null;
      case "rejected":
        return getCurrentAccount().role === "Administrator" ? (
          !toggleRejectReversal ? (
            <button
              onClick={(e) => toggleRejectionReversalClick(e)}
              type="button"
              className="ml-4 inline-flex justify-center py-2 px-6 border 
                border-transparent rounded-sm shadow-sm disabled:opacity-50 
                text-sm font-medium  text-white  bg-blue-600 hover:bg-blue-700  
                active:bg-indingo-800 focus:outline-none"
            >
              Send for Approval
            </button>
          ) : null
        ) : null;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="overflow-hidden w-full">
        <div className="flex flex-col">
          <div className=" overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-1 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <section aria-labelledby="purchase-request-approval-title">
                <div className="bg-white py-4 px-6 shadow shadow-sm ">
                  <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                    <div className="lg:col-span-6">
                      <h2 className="text-lg leading-6 font-medium text-gray-900">
                        Status
                      </h2>
                    </div>
                    <div className="lg:col-span-6  ml-auto">
                      {statusBar(meter.status)}
                    </div>
                  </div>

                  <div className="border-t border-gray-200 py-4 mt-3 mb-3 ">
                    {toggleReject ? (
                      <div className="min-w-0 flex-1 lg:grid lg:grid-cols-12 lg:gap-x-5">
                        <div className="lg:col-span-6"> </div>
                        <div className="lg:col-span-6">
                          <form action="#">
                            <div>
                              <label
                                htmlFor="deactivateReason"
                                className="sr-only"
                              >
                                Deactivate Reason
                              </label>
                              <textarea
                                id="deactivateReason"
                                name="deactivateReason"
                                rows={3}
                                value={deactivateReason}
                                onChange={handleChange}
                                className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-sm"
                                placeholder="Please provide the reason for not deactivating the meter."
                                defaultValue={""}
                              />
                            </div>
                            <div className="mt-3 flex items-center justify-between">
                              <span> </span>

                              <div>
                                <>
                                  <button
                                    onClick={(e) => toggleRejectClick(e)}
                                    type="button"
                                    className="bg-white py-2 px-6 border border-2 border-red-500 rounded-sm shadow-sm text-sm font-medium text-red-500 disabled:opacity-50 hover:bg-gray-50 focus:outline-none "
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    onClick={(e) => deactivateMeter(e)}
                                    type="submit"
                                    className="ml-3 inline-flex justify-center py-2 px-6 border border-transparent rounded-sm shadow-sm disabled:opacity-50 text-sm font-medium  text-white  bg-blue-600 hover:bg-blue-700 active:bg-blue-800 focus:outline-none "
                                  >
                                    Deactivate
                                  </button>
                                </>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    ) : null}

                    {toggleRejectReversal ? (
                      <div className="min-w-0 flex-1 lg:grid lg:grid-cols-12 lg:gap-x-5">
                        <div className="lg:col-span-6"> </div>
                        <div className="lg:col-span-6">
                          <form action="#">
                            <div>
                              <label
                                htmlFor="deactivateReason"
                                className="sr-only"
                              >
                                Rejection Reversal Reason
                              </label>
                              <textarea
                                id="deactivateReason"
                                name="deactivateReason"
                                rows={3}
                                value={rejectionReversalReason}
                                onChange={handleReversalReasonChange}
                                className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-sm"
                                placeholder="Please provide the reason for reversing rejection of the meter."
                                defaultValue={""}
                              />
                            </div>
                            <div className="mt-3 flex items-center justify-between">
                              <span> </span>

                              <div>
                                <>
                                  <button
                                    onClick={(e) =>
                                      toggleRejectionReversalClick(e)
                                    }
                                    type="button"
                                    className="bg-white py-2 px-6 border border-2 border-red-500 rounded-sm shadow-sm text-sm font-medium text-red-500 disabled:opacity-50 hover:bg-gray-50 focus:outline-none "
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    onClick={(e) => setMeterForApproval(e)}
                                    type="submit"
                                    className="ml-3 inline-flex justify-center py-2 px-6 border border-transparent rounded-sm shadow-sm disabled:opacity-50 text-sm font-medium  text-white  bg-blue-600 hover:bg-blue-700 active:bg-blue-800 focus:outline-none "
                                  >
                                    Send for Approval
                                  </button>
                                </>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    ) : null}

                    {toggleEditMeter.toggle
                      ? makeAmendMeterDetails(meter)
                      : null}

                    <div className=" pb-5">
                      <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                        <div className="lg:col-span-6">
                          <h2
                            id="purchase-request-approval-title"
                            className="text-md leading-6 font-medium text-gray-900"
                          >
                            Meter Details
                          </h2>
                        </div>
                      </div>
                    </div>
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-600">
                          Name
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {meter.name}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-700">
                          Number
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {meter.number}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-700">
                          Registered Owner
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {meter.registeredOwner}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-700">
                          Company Name
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {meter.company.name}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-700">
                          Meter Location
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {meter.location}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-700">
                          Created By
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {meter.createdBy}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-700">
                          Date of Submission
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {format(
                            new Date(meter.dateCreated),
                            "yyyy-MM-dd HH:mm:ss"
                          )}
                        </dd>
                      </div>
                      {meter.status === "Rejected" ? (
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-700">
                            Reason for rejection
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {meter.rejectionReason}
                          </dd>
                        </div>
                      ) : meter.status === "Disabled" ? (
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-700">
                            Reason for disabling
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {meter.disabledReason}
                          </dd>
                        </div>
                      ) : null}
                    </dl>
                  </div>

                  {/* <div className="flex-grow w-full max-w-7xl mx-auto lg:flex">
                    <div className="max-w-7xl w-full mx-auto pr-4 sm:pr-6 lg:pr-8 bg-gray-100">
                      <div className="w-full mt-6">
                        <div className="sm:hidden">
                          <label htmlFor="tabs" className="sr-only">
                            Select a tab
                          </label>
                          <select
                            id="tabs"
                            name="tabs"
                            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                            defaultValue={tabs.find((tab) => tab.current)!.name}
                          >
                            {tabs.map((tab) => (
                              <option key={tab.name}>{tab.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="hidden sm:block">
                          <div className="border-b border-gray-200">
                            <nav
                              className="-mb-px flex space-x-8"
                              aria-label="Tabs"
                            >
                              {tabs.map((tab) => (
                                <NavLink
                                  className={({ isActive }) =>
                                    isActive
                                      ? "text-gray-900 border-b-2 px-4 border-gray-900 whitespace-nowrap font-medium"
                                      : "border-transparent border-b-2 px-4 text-gray-400 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-2 px-0 border-b-2"
                                  }
                                  to={tab.href}
                                  key={tab.name}
                                  aria-current={
                                    tab.current ? "page" : undefined
                                  }
                                >
                                  <>
                                    <h2
                                      id="meters-tab-title"
                                      className="text-md leading-6  font-medium"
                                    >
                                      {tab.name}
                                    </h2>
                                  </>
                                </NavLink>
                              ))}
                            </nav>
                          </div>
                          <div className="mt-6">
                            <Outlet />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

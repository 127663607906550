import { GetUsersState } from "../../../../domain/usecases/get-user";

import { format } from "date-fns";
import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentAccountState } from "../../../components";
import { useFirestore } from "../../../hooks/use-firestore";
import { PencilAltIcon } from "@heroicons/react/outline";
import { makeChangeUserDetails } from "../../../../main/factories/pages/signup/users/manageusers/user/edit-user-details-factory";
import { toggleEditUserState } from "./components";

type Props = {
  user: GetUsersState.Model;
  index: number;
};

// const tabs = [
//   {
//     name: "Reset Password",
//     href: "/users/manage/password",
//     current: true,
//   },
//   {
//     name: "Change E-mail",
//     href: "/users/manage/email",
//     current: true,
//   },
//   {
//     name: "Edit Name",
//     href: "/users/manage/name",
//     current: true,
//   },
//   {
//     name: "Edit Role",
//     href: "/users/manage/role",
//     current: true,
//   },
//   {
//     name: "Enable/Disable",
//     href: "/users/manage/status",
//     current: true,
//   },
// ];

// const statusStyles: { [key: string]: string } = {
//   true: "bg-green-100 text-green-800",
//   false: "bg-red-100 text-red-800",
// };

export default function UserDetailsRow({ user }: Props) {
  const [toggleEditUser, setToggleEditUser] =
    useRecoilState(toggleEditUserState);

  const { getCurrentAccount } = useRecoilValue(currentAccountState);

  const firestore = useFirestore();

  const [toggleReject, setToggleReject] = useState(false);
  const [disableReason, setDeactivateReason] = useState("");

  const disableUser = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    console.log("user.uid, reason", user.uid);
    await firestore.disableUser(
      user.uid,
      disableReason,
      getCurrentAccount().displayName
    );
    toggleRejectClick(e);
  };

  const enableUser = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    console.log("user.uid, reason", user.uid);
    await firestore.enableUser(user.uid, getCurrentAccount().displayName);
  };

  const toggleRejectClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setToggleReject(!toggleReject);
  };

  const toggleEditUserClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    setToggleEditUser((old) => ({
      ...old,
      toggle: !toggleEditUser.toggle,
    }));
  };

  const handleChange = (event) => {
    event.preventDefault();
    setDeactivateReason(event.target.value);
  };

  // const handleReversalReasonChange = (event) => {
  //   event.preventDefault();
  //   setRejectionReversalReason(event.target.value);
  // };

  const statusBar = (isEnabled: string) => {
    switch (isEnabled) {
      case "true":
        return getCurrentAccount().role === "Administrator" && !toggleReject ? (
          <button
            onClick={(e) => toggleRejectClick(e)}
            type="button"
            className="ml-4 inline-flex justify-center py-2 px-6 border 
              border-transparent rounded-sm shadow-sm disabled:opacity-50 
              text-sm font-medium  text-white  bg-blue-600 hover:bg-blue-700  
              active:bg-indingo-800 focus:outline-none"
          >
            Disable User
          </button>
        ) : null;
      case "false":
        return (
          <button
            onClick={(e) => enableUser(e)}
            type="button"
            className="ml-4 inline-flex justify-center py-2 px-6 border 
                                border-transparent rounded-sm shadow-sm disabled:opacity-50 
                                text-sm font-medium  text-white  bg-blue-600 hover:bg-blue-700  
                                active:bg-indingo-800 focus:outline-none"
          >
            Enable User
          </button>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="overflow-hidden w-full">
        <div className="flex flex-col">
          <div className=" overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-1 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <section aria-labelledby="purchase-request-approval-title">
                <div className="bg-white py-4 px-6 shadow shadow-sm ">
                  <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                    <div className="lg:col-span-6">
                      <h2 className="text-lg leading-6 font-medium text-gray-900">
                        Status
                      </h2>
                    </div>
                    <div className="lg:col-span-6  ml-auto">
                      <div className="ml-4 flex items-center md:ml-6">
                        {getCurrentAccount().role === "Administrator" &&
                        !toggleReject &&
                        !toggleEditUser.toggle ? (
                          <>
                            <button
                              type="button"
                              onClick={(e) => toggleEditUserClick(e)}
                              className="relative inline-flex justify-center py-2 px-6 border border-transparent rounded-sm shadow-sm disabled:opacity-50 text-sm font-medium text-gray-600 bg-gray-200 hover:bg-gray-300 active:bg-gray-400 focus:outline-none "
                            >
                              <>
                                <PencilAltIcon
                                  className="-ml-1 mr-2 h-6 w-6 text-gray-500"
                                  aria-hidden="true"
                                />
                                Edit User
                              </>
                            </button>

                            <div className="ml-3 border-l border-gray-300 relative lg:col-span-6 text-right">
                              {statusBar(user.isEnabled)}
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="border-t border-gray-200 py-4 mt-3 mb-3 ">
                    {toggleReject ? (
                      <div className="min-w-0 flex-1 lg:grid lg:grid-cols-12 lg:gap-x-5">
                        <div className="lg:col-span-6"> </div>
                        <div className="lg:col-span-6">
                          <form action="#">
                            <div>
                              <label
                                htmlFor="deactivateReason"
                                className="sr-only"
                              >
                                Disable Reason
                              </label>
                              <textarea
                                id="deactivateReason"
                                name="deactivateReason"
                                rows={3}
                                value={disableReason}
                                onChange={handleChange}
                                className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-sm"
                                placeholder="Please provide the reason for not disabling the user."
                                defaultValue={""}
                              />
                            </div>
                            <div className="mt-3 flex items-center justify-between">
                              <span> </span>

                              <div>
                                <>
                                  <button
                                    onClick={(e) => toggleRejectClick(e)}
                                    type="button"
                                    className="bg-white py-2 px-6 border border-2 border-red-500 rounded-sm shadow-sm text-sm font-medium text-red-500 disabled:opacity-50 hover:bg-gray-50 focus:outline-none "
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    onClick={(e) => disableUser(e)}
                                    type="submit"
                                    className="ml-3 inline-flex justify-center py-2 px-6 border border-transparent rounded-sm shadow-sm disabled:opacity-50 text-sm font-medium  text-white  bg-blue-600 hover:bg-blue-700 active:bg-blue-800 focus:outline-none "
                                  >
                                    Disable User
                                  </button>
                                </>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    ) : null}

                    {toggleEditUser.toggle ? makeChangeUserDetails(user) : null}

                    <div className=" pb-5">
                      <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                        <div className="lg:col-span-6 ml-auto"></div>
                      </div>
                    </div>
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-600">
                          Name
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {user.displayName}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-700">
                          Email
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {user.email}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-700">
                          Role
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {user.role}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-700">
                          Contact Number
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {user.contactNumber}
                        </dd>
                      </div>

                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-700">
                          Created By
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {user.createdBy}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-700">
                          Date Created
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {format(
                            new Date(user.dateCreated),
                            "yyyy-MM-dd HH:mm:ss"
                          )}
                        </dd>
                      </div>
                    </dl>
                  </div>

                  {/* <div className="flex-grow w-full max-w-7xl mx-auto lg:flex">
                    <div className="max-w-7xl w-full mx-auto pr-4 sm:pr-6 lg:pr-8 bg-gray-100">
                      <div className="w-full mt-6">
                        <div className="sm:hidden">
                          <label htmlFor="tabs" className="sr-only">
                            Select a tab
                          </label>
                          <select
                            id="tabs"
                            name="tabs"
                            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                            defaultValue={tabs.find((tab) => tab.current)!.name}
                          >
                            {tabs.map((tab) => (
                              <option key={tab.name}>{tab.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="hidden sm:block">
                          <div className="border-b border-gray-200">
                            <nav
                              className="-mb-px flex space-x-8"
                              aria-label="Tabs"
                            >
                              {tabs.map((tab) => (
                                <NavLink
                                  className={({ isActive }) =>
                                    isActive
                                      ? "text-gray-900 border-b-2 px-4 border-gray-900 whitespace-nowrap font-medium"
                                      : "border-transparent border-b-2 px-4 text-gray-400 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-2 px-0 border-b-2"
                                  }
                                  to={tab.href}
                                  key={tab.name}
                                  aria-current={
                                    tab.current ? "page" : undefined
                                  }
                                >
                                  <>
                                    <h2
                                      id="meters-tab-title"
                                      className="text-md leading-6  font-medium"
                                    >
                                      {tab.name}
                                    </h2>
                                  </>
                                </NavLink>
                              ))}
                            </nav>
                          </div>
                           <div className="mt-6">
                            <Outlet />
                          </div> 
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

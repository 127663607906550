import {
  ValidationComposite,
  ValidationBuilder as Builder,
} from "../../../../../validation/validators";

export const makeAddCompanyValidation = (): ValidationComposite =>
  ValidationComposite.build([
    ...Builder.field("name").required().build(),
    ...Builder.field("companyNumber").required().build(),
    ...Builder.field("phoneNumber").required().build(),
    ...Builder.field("postalAddress").required().build(),
    ...Builder.field("city").required().build(),
  ]);

import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon, CheckIcon } from "@heroicons/react/outline";
import { format } from "date-fns";
import { Fragment, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";

import { currencyFormatter } from "../../../utils/helpers";

import {
  airtimePurchaseRequestCompanyState,
  cellphoneJobTitleState,
  purchaseRequestCellphonesState,
  airtimePurchaseRequestState,
  airtimePurchaseRequestStepsState,
} from "./atoms/atoms";
import { StepsButton } from "./components/stepsButton";
import { PurchaseRequestCellphone } from "./types";
import { PurchaseRequestType } from "./types/purchaseRequest";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const defaultOptions = {
  significantDigits: 2,
  thousandsSeparator: ",",
  decimalSeparator: ".",
  symbol: "",
};

export default function InitiateAirtimePurchaseRequest() {
  const [state, setState] = useRecoilState(purchaseRequestCellphonesState);
  const resetPurchaseRequestCellphonesState = useResetRecoilState(
    purchaseRequestCellphonesState
  );
  const resetStepsState = useResetRecoilState(airtimePurchaseRequestStepsState);
  // const setPurchaseRequestStepsState = useSetRecoilState(airtimePurchaseRequestStepsState);
  const jobTitles = useRecoilValue(cellphoneJobTitleState);
  const companies = useRecoilValue(airtimePurchaseRequestCompanyState);
  const purchaseRequest = useRecoilValue(
    airtimePurchaseRequestState
  ) as PurchaseRequestType;

  const [selected, setSelected] = useState(jobTitles[0]);
  const [selectedCompany, setSelectedCompany] = useState(companies[0]);
  const [cellphoneOwner, setCellphoneName] = useState("");
  const [cellphoneNumber, setCellphoneNumber] = useState("");

  useEffect(() => resetStepsState(), []);
  useEffect(
    () =>
      setState((old) => ({
        ...old,
        isFiltered: false,
      })),

    []
  );
  const cellphoneList = purchaseRequest.isFiltered
    ? purchaseRequest.filteredCellphones.length > 0
      ? purchaseRequest.filteredCellphones
      : []
    : purchaseRequest.cellphones;

  console.log("purchaseRequest.isFiltered", purchaseRequest.isFiltered);
  console.log(
    "purchaseRequest.filteredCellphones",
    purchaseRequest.filteredCellphones
  );
  console.log("purchaseRequest.cellphones", purchaseRequest.cellphones);

  //isFiltered useEffect to reset jobTitles purchaseRequestStatusState

  const addCellphoneToRequest = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const isRequestFiltered = purchaseRequest.isFiltered;

    const jobTitle = selected;
    const company =
      selectedCompany === "None" ? "Not Registered" : selectedCompany;
    const amount = e.target.value;
    const cellphone = e.target.id;

    console.log("isRequestFiltered", isRequestFiltered);
    console.log("jobTitle", jobTitle);
    console.log("company", company);
    console.log("filteredCellphones", state.filteredCellphones);

    const updatedCellphones = state.cellphones.map((item) => {
      if (item.number === cellphone) {
        return { ...item, purchaseAmount: +amount };
      } else {
        return item;
      }
    });

    console.log("updatedCellphones", updatedCellphones);

    let updateCellphonesState = isRequestFiltered
      ? {
          ...state,
          cellphones: updatedCellphones,
          filteredCellphones: updatedCellphones.filter((cellphone) => {
            const isCompanyFiltered =
              selectedCompany.toLowerCase() !== "all companies";

            const isCellphoneNumberFiltered =
              cellphoneNumber.toLowerCase() !== "";
            const isCellphoneOwnerFiltered =
              cellphoneOwner.toLowerCase() !== "";

            console.log("isRequestFiltered", isRequestFiltered);

            console.log("isCompanyFiltered", isCompanyFiltered);
            console.log("isCellphoneNumberFiltered", isCellphoneNumberFiltered);
            console.log("isCellphoneOwnerFiltered", isCellphoneOwnerFiltered);

            if (
              isCellphoneOwnerFiltered &&
              !isCompanyFiltered &&
              !isCellphoneNumberFiltered
            ) {
              return cellphone.cellphoneOwner
                .toLowerCase()
                .includes(cellphoneOwner.toLowerCase());
            } else if (
              isCellphoneOwnerFiltered &&
              isCompanyFiltered &&
              !isCellphoneNumberFiltered
            ) {
              return (
                cellphone.cellphoneOwner
                  .toLowerCase()
                  .includes(cellphoneOwner.toLowerCase()) &&
                cellphone.company.toLowerCase() === company.toLowerCase()
              );
            } else if (
              isCellphoneOwnerFiltered &&
              isCompanyFiltered &&
              isCellphoneNumberFiltered
            ) {
              return (
                cellphone.cellphoneOwner
                  .toLowerCase()
                  .includes(cellphoneOwner.toLowerCase()) &&
                cellphone.company.toLowerCase() === company.toLowerCase() &&
                cellphone.number
                  .toLowerCase()
                  .includes(cellphoneNumber.toLowerCase())
              );
            } else if (
              !isCellphoneOwnerFiltered &&
              isCompanyFiltered &&
              isCellphoneNumberFiltered
            ) {
              return (
                cellphone.company.toLowerCase() === company.toLowerCase() &&
                cellphone.number
                  .toLowerCase()
                  .includes(cellphoneNumber.toLowerCase())
              );
            } else if (
              !isCellphoneOwnerFiltered &&
              !isCompanyFiltered &&
              isCellphoneNumberFiltered
            ) {
              return cellphone.number
                .toLowerCase()
                .includes(cellphoneNumber.toLowerCase());
            } else if (
              !isCellphoneOwnerFiltered &&
              !isCompanyFiltered &&
              isCellphoneNumberFiltered
            ) {
              return cellphone.number
                .toLowerCase()
                .includes(cellphoneNumber.toLowerCase());
            } else if (
              !isCellphoneOwnerFiltered &&
              isCompanyFiltered &&
              !isCellphoneNumberFiltered
            ) {
              return cellphone.company.toLowerCase() === company.toLowerCase();
            } else if (
              !isCellphoneOwnerFiltered &&
              isCompanyFiltered &&
              isCellphoneNumberFiltered
            ) {
              return (
                cellphone.company.toLowerCase() === company.toLowerCase() &&
                cellphone.number
                  .toLowerCase()
                  .includes(cellphoneNumber.toLowerCase())
              );
            } else if (
              !isCellphoneOwnerFiltered &&
              isCompanyFiltered &&
              !isCellphoneNumberFiltered
            ) {
              console.log("selectedCompany", company);
              return cellphone.company.toLowerCase() === company.toLowerCase();
            } else if (
              isCellphoneOwnerFiltered &&
              !isCompanyFiltered &&
              isCellphoneNumberFiltered
            ) {
              return (
                cellphone.cellphoneOwner
                  .toLowerCase()
                  .includes(cellphoneOwner.toLowerCase()) &&
                cellphone.number
                  .toLowerCase()
                  .includes(cellphoneNumber.toLowerCase())
              );
            } else if (
              isCellphoneOwnerFiltered &&
              !isCompanyFiltered &&
              !isCellphoneOwnerFiltered
            ) {
              return cellphone.cellphoneOwner
                .toLowerCase()
                .includes(cellphoneOwner.toLowerCase());
            } else if (
              isCellphoneOwnerFiltered &&
              !isCompanyFiltered &&
              isCellphoneNumberFiltered
            ) {
              return (
                cellphone.cellphoneOwner
                  .toLowerCase()
                  .includes(cellphoneOwner.toLowerCase()) &&
                cellphone.number
                  .toLowerCase()
                  .includes(cellphoneNumber.toLowerCase())
              );
            } else if (
              isCellphoneOwnerFiltered &&
              isCompanyFiltered &&
              isCellphoneNumberFiltered
            ) {
              return (
                cellphone.cellphoneOwner
                  .toLowerCase()
                  .includes(cellphoneOwner.toLowerCase()) &&
                cellphone.number
                  .toLowerCase()
                  .includes(cellphoneNumber.toLowerCase()) &&
                cellphone.company.toLowerCase() === company.toLowerCase()
              );
            } else {
              return cellphone;
            }
          }),
          isFiltered: true,
        }
      : {
          ...state,
          cellphones: updatedCellphones,
          filteredCellphones: [],
          isFiltered: false,
        };

    console.log("filteredCellphones", updateCellphonesState.filteredCellphones);

    setState(updateCellphonesState);
  };

  const handleCompanyChange = (selected_company: string) => {
    setSelectedCompany(selected_company);

    const company =
      selected_company.toLowerCase() === "none"
        ? "Not Registered"
        : selected_company;

    const jobTitle = selected;

    const isCompanyFiltered = company.toLowerCase() !== "all companies";
    const isCellphoneNumberFiltered = cellphoneNumber.toLowerCase() !== "";
    const isCellphoneOwnerFiltered = cellphoneOwner.toLowerCase() !== "";

    let filtered: PurchaseRequestCellphone[];

    console.log("selected jobTitle", jobTitle);
    console.log("isCompanyFiltered", isCompanyFiltered);
    console.log("isCellphoneNumberFiltered", isCellphoneNumberFiltered);
    console.log("isCellphoneOwnerFiltered", isCellphoneOwnerFiltered);

    if (
      isCellphoneOwnerFiltered &&
      !isCompanyFiltered &&
      !isCellphoneNumberFiltered
    ) {
      filtered = state.cellphones.filter((cellphone) => {
        return cellphone.cellphoneOwner
          .toLowerCase()
          .includes(cellphoneOwner.toLowerCase());
      });
    } else if (
      isCellphoneOwnerFiltered &&
      isCompanyFiltered &&
      !isCellphoneNumberFiltered
    ) {
      filtered = state.cellphones.filter((cellphone) => {
        return (
          cellphone.cellphoneOwner
            .toLowerCase()
            .includes(cellphoneOwner.toLowerCase()) &&
          cellphone.company.toLowerCase() === company.toLowerCase()
        );
      });
    } else if (
      isCellphoneOwnerFiltered &&
      isCompanyFiltered &&
      isCellphoneNumberFiltered
    ) {
      filtered = state.cellphones.filter((cellphone) => {
        return (
          cellphone.cellphoneOwner
            .toLowerCase()
            .includes(cellphoneOwner.toLowerCase()) &&
          cellphone.company.toLowerCase() === company.toLowerCase() &&
          cellphone.number.toLowerCase().includes(cellphoneNumber.toLowerCase())
        );
      });
    } else if (
      !isCellphoneOwnerFiltered &&
      isCompanyFiltered &&
      isCellphoneNumberFiltered
    ) {
      filtered = state.cellphones.filter((cellphone) => {
        return (
          cellphone.company.toLowerCase() === company.toLowerCase() &&
          cellphone.number.toLowerCase().includes(cellphoneNumber.toLowerCase())
        );
      });
    } else if (
      !isCellphoneOwnerFiltered &&
      !isCompanyFiltered &&
      isCellphoneNumberFiltered
    ) {
      filtered = state.cellphones.filter((cellphone) => {
        return cellphone.number
          .toLowerCase()
          .includes(cellphoneNumber.toLowerCase());
      });
    } else if (
      !isCellphoneOwnerFiltered &&
      !isCompanyFiltered &&
      isCellphoneNumberFiltered
    ) {
      filtered = state.cellphones.filter((cellphone) => {
        return cellphone.number
          .toLowerCase()
          .includes(cellphoneNumber.toLowerCase());
      });
    } else if (
      !isCellphoneOwnerFiltered &&
      isCompanyFiltered &&
      !isCellphoneNumberFiltered
    ) {
      filtered = state.cellphones.filter((cellphone) => {
        return cellphone.company.toLowerCase() === company.toLowerCase();
      });
    } else if (
      !isCellphoneOwnerFiltered &&
      isCompanyFiltered &&
      isCellphoneNumberFiltered
    ) {
      filtered = state.cellphones.filter((cellphone) => {
        return (
          cellphone.company.toLowerCase() === company.toLowerCase() &&
          cellphone.number.toLowerCase().includes(cellphoneNumber.toLowerCase())
        );
      });
    } else if (
      !isCellphoneOwnerFiltered &&
      isCompanyFiltered &&
      !isCellphoneNumberFiltered
    ) {
      filtered = state.cellphones.filter((cellphone) => {
        return cellphone.company.toLowerCase() === company.toLowerCase();
      });
    } else if (
      isCellphoneOwnerFiltered &&
      !isCompanyFiltered &&
      isCellphoneNumberFiltered
    ) {
      filtered = state.cellphones.filter((cellphone) => {
        return (
          cellphone.cellphoneOwner
            .toLowerCase()
            .includes(cellphoneOwner.toLowerCase()) &&
          cellphone.number.toLowerCase().includes(cellphoneNumber.toLowerCase())
        );
      });
    } else if (
      isCellphoneOwnerFiltered &&
      !isCompanyFiltered &&
      !isCellphoneOwnerFiltered
    ) {
      filtered = state.cellphones.filter((cellphone) => {
        return cellphone.cellphoneOwner
          .toLowerCase()
          .includes(cellphoneOwner.toLowerCase());
      });
    } else if (
      isCellphoneOwnerFiltered &&
      !isCompanyFiltered &&
      isCellphoneNumberFiltered
    ) {
      filtered = state.cellphones.filter((cellphone) => {
        return (
          cellphone.cellphoneOwner
            .toLowerCase()
            .includes(cellphoneOwner.toLowerCase()) &&
          cellphone.number.toLowerCase().includes(cellphoneNumber.toLowerCase())
        );
      });
    } else if (
      isCellphoneOwnerFiltered &&
      isCompanyFiltered &&
      isCellphoneNumberFiltered
    ) {
      filtered = state.cellphones.filter((cellphone) => {
        return (
          cellphone.cellphoneOwner
            .toLowerCase()
            .includes(cellphoneOwner.toLowerCase()) &&
          cellphone.number
            .toLowerCase()
            .includes(cellphoneNumber.toLowerCase()) &&
          cellphone.company.toLowerCase() === company.toLowerCase()
        );
      });
    } else {
      filtered = [];
    }

    console.log("filtered", filtered);

    let updateCompanyState = {
      ...state,
      filteredCellphones: filtered,
      isFiltered:
        isCompanyFiltered ||
        isCellphoneNumberFiltered ||
        isCellphoneOwnerFiltered
          ? true
          : false,
    };

    setState(updateCompanyState);
  };

  const handleCellphoneNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    const keyword = e.target.value.toLowerCase();
    const company =
      selectedCompany === "None" ? "Not Registered" : selectedCompany;

    const isCompanyFiltered = company.toLowerCase() !== "all companies";

    const isCellphoneNumberFiltered = cellphoneNumber.toLowerCase() !== "";

    const isKeyword = keyword.length > 0 ? true : false;

    let filtered: PurchaseRequestCellphone[];

    console.log("isCompanyFiltered", isCompanyFiltered);
    console.log("isCellphoneNumberFiltered", isCellphoneNumberFiltered);
    console.log("isKeyword", isKeyword);

    if (isKeyword && !isCompanyFiltered && !isCellphoneNumberFiltered) {
      setCellphoneName(keyword);
      filtered = state.cellphones.filter((cellphone) => {
        return cellphone.cellphoneOwner.toLowerCase().includes(keyword);
      });
    } else if (isKeyword && isCompanyFiltered && !isCellphoneNumberFiltered) {
      setCellphoneName(keyword);
      filtered = state.cellphones.filter((cellphone) => {
        return (
          cellphone.cellphoneOwner.toLowerCase().includes(keyword) &&
          cellphone.company.toLowerCase() === company.toLowerCase()
        );
      });
    } else if (isKeyword && isCompanyFiltered && !isCellphoneNumberFiltered) {
      setCellphoneName(keyword);
      filtered = state.cellphones.filter((cellphone) => {
        return (
          cellphone.cellphoneOwner.toLowerCase().includes(keyword) &&
          cellphone.company.toLowerCase() === company.toLowerCase()
        );
      });
    } else if (isKeyword && isCompanyFiltered && isCellphoneNumberFiltered) {
      setCellphoneName(keyword);
      filtered = state.cellphones.filter((cellphone) => {
        return (
          cellphone.cellphoneOwner.toLowerCase().includes(keyword) &&
          cellphone.company.toLowerCase() === company.toLowerCase() &&
          cellphone.number.toLowerCase().includes(cellphoneNumber.toLowerCase())
        );
      });
    } else if (!isKeyword && isCompanyFiltered && isCellphoneNumberFiltered) {
      setCellphoneName("");
      filtered = state.cellphones.filter((cellphone) => {
        return (
          cellphone.company.toLowerCase() === company.toLowerCase() &&
          cellphone.number.toLowerCase().includes(cellphoneNumber.toLowerCase())
        );
      });
    } else if (!isKeyword && !isCompanyFiltered && isCellphoneNumberFiltered) {
      setCellphoneName("");
      filtered = state.cellphones.filter((cellphone) => {
        return cellphone.number
          .toLowerCase()
          .includes(cellphoneNumber.toLowerCase());
      });
    } else if (!isKeyword && !isCompanyFiltered && isCellphoneNumberFiltered) {
      setCellphoneName("");
      filtered = state.cellphones.filter((cellphone) => {
        return cellphone.number
          .toLowerCase()
          .includes(cellphoneNumber.toLowerCase());
      });
    } else if (!isKeyword && isCompanyFiltered && !isCellphoneNumberFiltered) {
      setCellphoneName("");
      filtered = state.cellphones.filter((cellphone) => {
        return cellphone.company.toLowerCase() === company.toLowerCase();
      });
    } else if (!isKeyword && isCompanyFiltered && isCellphoneNumberFiltered) {
      setCellphoneName("");
      filtered = state.cellphones.filter((cellphone) => {
        return (
          cellphone.company.toLowerCase() === company.toLowerCase() &&
          cellphone.number.toLowerCase().includes(cellphoneNumber.toLowerCase())
        );
      });
    } else if (!isKeyword && isCompanyFiltered && !isCellphoneNumberFiltered) {
      setCellphoneName("");
      filtered = state.cellphones.filter((cellphone) => {
        return cellphone.company.toLowerCase() === company.toLowerCase();
      });
    } else if (isKeyword && !isCompanyFiltered && isCellphoneNumberFiltered) {
      setCellphoneName(keyword);
      filtered = state.cellphones.filter((cellphone) => {
        return (
          cellphone.cellphoneOwner.toLowerCase().includes(keyword) &&
          cellphone.number.toLowerCase().includes(cellphoneNumber.toLowerCase())
        );
      });
    } else if (isKeyword && !isCompanyFiltered && !isCellphoneNumberFiltered) {
      setCellphoneName(keyword);
      filtered = state.cellphones.filter((cellphone) => {
        return cellphone.cellphoneOwner.toLowerCase().includes(keyword);
      });
    } else if (isKeyword && !isCompanyFiltered && isCellphoneNumberFiltered) {
      setCellphoneName(keyword);
      filtered = state.cellphones.filter((cellphone) => {
        return (
          cellphone.cellphoneOwner.toLowerCase().includes(keyword) &&
          cellphone.number.toLowerCase().includes(cellphoneNumber.toLowerCase())
        );
      });
    } else if (isKeyword && isCompanyFiltered && isCellphoneNumberFiltered) {
      setCellphoneName(keyword);
      filtered = state.cellphones.filter((cellphone) => {
        return (
          cellphone.cellphoneOwner.toLowerCase().includes(keyword) &&
          cellphone.number
            .toLowerCase()
            .includes(cellphoneNumber.toLowerCase()) &&
          cellphone.company.toLowerCase() === company.toLowerCase()
        );
      });
    } else {
      setCellphoneName("");
      filtered = [];
    }

    console.log("filtered", filtered);

    let updateCellphonesState = {
      ...state,
      filteredCellphones: filtered.length > 0 ? filtered : [],
      isFiltered:
        isCompanyFiltered || isCellphoneNumberFiltered || isKeyword
          ? true
          : false,
    };

    setState(updateCellphonesState);
  };

  const handleCellphoneNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();

    const keyword = e.target.value.toLowerCase();
    const company =
      selectedCompany === "None" ? "Not Registered" : selectedCompany;

    const isCompanyFiltered = company.toLowerCase() !== "all companies";
    const isCellphoneOwnerFiltered = cellphoneOwner.toLowerCase() !== "";

    const isKeyword = keyword.length > 0 ? true : false;

    let filtered: PurchaseRequestCellphone[];

    console.log("isCompanyFiltered", isCompanyFiltered);
    console.log("isCellphoneOwnerFiltered", isCellphoneOwnerFiltered);
    console.log("isKeyword", isKeyword);

    if (isKeyword && !isCompanyFiltered && !isCellphoneOwnerFiltered) {
      setCellphoneNumber(keyword);
      filtered = state.cellphones.filter((cellphone) => {
        return cellphone.number.toLowerCase().includes(keyword);
      });
    } else if (isKeyword && isCompanyFiltered && !isCellphoneOwnerFiltered) {
      setCellphoneNumber(keyword);
      filtered = state.cellphones.filter((cellphone) => {
        return (
          cellphone.number.toLowerCase().includes(keyword) &&
          cellphone.company.toLowerCase() === company.toLowerCase()
        );
      });
    } else if (isKeyword && isCompanyFiltered && !isCellphoneOwnerFiltered) {
      setCellphoneNumber(keyword);
      filtered = state.cellphones.filter((cellphone) => {
        return (
          cellphone.number.toLowerCase().includes(keyword) &&
          cellphone.company.toLowerCase() === company.toLowerCase()
        );
      });
    } else if (isKeyword && isCompanyFiltered && isCellphoneOwnerFiltered) {
      setCellphoneNumber(keyword);
      filtered = state.cellphones.filter((cellphone) => {
        return (
          cellphone.number.toLowerCase().includes(keyword) &&
          cellphone.company.toLowerCase() === company.toLowerCase() &&
          cellphone.cellphoneOwner
            .toLowerCase()
            .includes(cellphoneOwner.toLowerCase())
        );
      });
    } else if (!isKeyword && isCompanyFiltered && isCellphoneOwnerFiltered) {
      setCellphoneNumber(keyword);
      filtered = state.cellphones.filter((cellphone) => {
        return (
          cellphone.company.toLowerCase() === company.toLowerCase() &&
          cellphone.cellphoneOwner
            .toLowerCase()
            .includes(cellphoneOwner.toLowerCase())
        );
      });
    } else if (!isKeyword && !isCompanyFiltered && isCellphoneOwnerFiltered) {
      setCellphoneNumber(keyword);
      filtered = state.cellphones.filter((cellphone) => {
        return cellphone.cellphoneOwner
          .toLowerCase()
          .includes(cellphoneOwner.toLowerCase());
      });
    } else if (!isKeyword && !isCompanyFiltered && isCellphoneOwnerFiltered) {
      setCellphoneNumber(keyword);
      console.log("keyword", keyword);
      console.log("cellphoneOwner.toLowerCase()", cellphoneOwner.toLowerCase());
      filtered = state.cellphones.filter((cellphone) => {
        return cellphone.cellphoneOwner
          .toLowerCase()
          .includes(cellphoneOwner.toLowerCase());
      });
    } else if (!isKeyword && isCompanyFiltered && !isCellphoneOwnerFiltered) {
      setCellphoneNumber(keyword);
      filtered = state.cellphones.filter((cellphone) => {
        return cellphone.company.toLowerCase() === company.toLowerCase();
      });
    } else if (!isKeyword && isCompanyFiltered && isCellphoneOwnerFiltered) {
      setCellphoneNumber(keyword);
      filtered = state.cellphones.filter((cellphone) => {
        return (
          cellphone.company.toLowerCase() === company.toLowerCase() &&
          cellphone.cellphoneOwner
            .toLowerCase()
            .includes(cellphoneOwner.toLowerCase())
        );
      });
    } else if (!isKeyword && isCompanyFiltered && !isCellphoneOwnerFiltered) {
      setCellphoneNumber(keyword);
      filtered = state.cellphones.filter((cellphone) => {
        return cellphone.company.toLowerCase() === company.toLowerCase();
      });
    } else if (isKeyword && !isCompanyFiltered && isCellphoneOwnerFiltered) {
      setCellphoneNumber(keyword);
      filtered = state.cellphones.filter((cellphone) => {
        return (
          cellphone.number.toLowerCase().includes(keyword) &&
          cellphone.cellphoneOwner
            .toLowerCase()
            .includes(cellphoneOwner.toLowerCase())
        );
      });
    } else if (isKeyword && !isCompanyFiltered && !isCellphoneOwnerFiltered) {
      setCellphoneNumber(keyword);
      filtered = state.cellphones.filter((cellphone) => {
        return cellphone.number.toLowerCase().includes(keyword);
      });
    } else if (isKeyword && !isCompanyFiltered && isCellphoneOwnerFiltered) {
      setCellphoneNumber(keyword);
      filtered = state.cellphones.filter((cellphone) => {
        return (
          cellphone.number.toLowerCase().includes(keyword) &&
          cellphone.cellphoneOwner
            .toLowerCase()
            .includes(cellphoneOwner.toLowerCase())
        );
      });
    } else if (isKeyword && isCompanyFiltered && isCellphoneOwnerFiltered) {
      setCellphoneNumber(keyword);
      filtered = state.cellphones.filter((cellphone) => {
        return (
          cellphone.number.toLowerCase().includes(keyword) &&
          cellphone.cellphoneOwner
            .toLowerCase()
            .includes(cellphoneOwner.toLowerCase()) &&
          cellphone.company.toLowerCase() === company.toLowerCase()
        );
      });
    } else {
      setCellphoneNumber("");
      filtered = [];
    }

    console.log("filtered", filtered);

    let updateCellphonesState = {
      ...state,
      filteredCellphones: filtered.length > 0 ? filtered : [],
      isFiltered:
        isCompanyFiltered || isCellphoneOwnerFiltered || isKeyword
          ? true
          : false,
    };

    setState(updateCellphonesState);
  };

  return (
    <>
      <div className="w-full">
        {state.cellphones && (
          <>
            <div className="mb-6">
              {purchaseRequest.showFilters ? (
                <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                  <div className="sm:col-span-2">
                    <Listbox
                      value={selectedCompany}
                      onChange={handleCompanyChange}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-xs font-normal text-gray-400">
                            Company
                          </Listbox.Label>
                          <div className="relative">
                            <Listbox.Button className="bg-gray-50 relative w-full border-b border-gray-300 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                              <span className="block truncate">
                                {selectedCompany}
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60  py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {companies.map((company, index) => (
                                  <Listbox.Option
                                    key={index}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "text-white bg-blue-600"
                                          : "text-gray-900",
                                        "cursor-default select-none relative py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={company}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate"
                                          )}
                                        >
                                          {company}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-blue-600",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="name"
                      className="block text-xs font-normal text-gray-400"
                    >
                      Name
                    </label>
                    <div className=" border-b border-gray-300 focus-within:border-blue-600">
                      <input
                        onChange={(e) => handleCellphoneNameChange(e)}
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full border-0 py-2 border-b border-transparent bg-gray-50 focus:border-blue-600 focus:ring-0 sm:text-sm"
                        placeholder="Name"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="cellphone"
                      className="block text-xs font-normal text-gray-400"
                    >
                      Cellphone Number
                    </label>
                    <div className=" border-b border-gray-300 focus-within:border-blue-600">
                      <input
                        onChange={(e) => handleCellphoneNumberChange(e)}
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full border-0  py-2 border-b border-transparent bg-gray-50 focus:border-blue-600 focus:ring-0 sm:text-sm"
                        placeholder="Cellphone Number"
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <form action="#" method="POST">
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-1 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    {!purchaseRequest.purchaseAmountsValid && (
                      <div className="my-4 block text-sm font-bold text-red-500">
                        Maximum amount allowed per cellphone:{" "}
                        {currencyFormatter(1000, defaultOptions)}
                      </div>
                    )}
                    <div className="pb-8 overflow-hidden">
                      {cellphoneList.length > 0 ? (
                        <table className="min-w-full bg-gray-50 border rounded-sm border-gray-200">
                          <thead className="sticky bg-gray-200">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
                              >
                                <div>Company</div>
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
                              >
                                <div>Owner &amp;</div> <div>Job Title</div>
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
                              >
                                <div>Network &amp;</div> <div>Number</div>
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
                              >
                                <div>Last Purchase</div>
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
                              >
                                Purchase Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {cellphoneList.map((cellphone, cellphoneIdx) => (
                              <tr
                                key={cellphone.id}
                                className="border-b border-gray-150"
                              >
                                <td className="px-6 py-1 whitespace-nowrap">
                                  <div className="text-sm font-medium text-gray-900">
                                    {cellphone.company === "Not Registered"
                                      ? "None"
                                      : cellphone.company}
                                  </div>
                                </td>
                                <td className="px-6 py-1 whitespace-nowrap">
                                  <div className="text-sm font-medium text-gray-900">
                                    {cellphone.cellphoneOwner}
                                  </div>
                                  <div className="text-sm text-gray-500">
                                    {cellphone.jobTitle}
                                  </div>
                                </td>
                                <td className="px-6 py-1 whitespace-nowrap text-sm font-normal">
                                  <div className="text-sm font-medium text-gray-900">
                                    {cellphone.network}
                                  </div>
                                  <div className="text-sm text-gray-500">
                                    {cellphone.number}
                                  </div>
                                </td>
                                <td className="px-6 py-1 whitespace-nowrap text-sm font-normal text-gray-500">
                                  <div className="text-sm font-medium text-gray-900">
                                    {cellphone.lastPurchase.dateCreated ===
                                    "None"
                                      ? "None"
                                      : format(
                                          new Date(
                                            cellphone.lastPurchase.dateCreated
                                          ),
                                          "yyyy-MM-dd"
                                        )}
                                    {/* &nbsp; &mdash; &nbsp; */}
                                  </div>
                                  <div className="text-sm text-gray-500">
                                    {currencyFormatter(
                                      cellphone.lastPurchase.purchaseAmount,
                                      defaultOptions
                                    )}
                                  </div>
                                </td>
                                <td className="px-6 py-1 whitespace-nowrap text-sm text-gray-500">
                                  <div className="border border-gray-100 bg-white focus-within:border-blue-600">
                                    <input
                                      onWheel={(event) =>
                                        event.currentTarget.blur()
                                      }
                                      onChange={(e) => addCellphoneToRequest(e)}
                                      value={
                                        cellphone.purchaseAmount
                                          ? cellphone.purchaseAmount
                                          : ""
                                      }
                                      type="number"
                                      min={0}
                                      max={1000}
                                      name="purchaseAmount"
                                      id={`${cellphone.number}`}
                                      className="block w-full border border-gray-200 placeholder-gray-300 font-medium py-2 border-b border-transparent bg-white focus:border-blue-600 focus:ring-0 sm:text-sm"
                                      placeholder="0.00"
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <h2 className="text-md leading-6 font-medium text-gray-900">
                          No results found for the selected filter(s).
                        </h2>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* {state.error && <Error error={state.error} reload={reload} />} */}
            </form>
          </>
        )}

        {console.log(
          "purchaseAmountsValid",
          purchaseRequest.purchaseAmountsValid
        )}

        {purchaseRequest.enableStepsButton &&
          purchaseRequest.purchaseAmountsValid && (
            <StepsButton
              createAirtimePurchaseRequest={null}
              step="initiate"
              isEnabled={purchaseRequest.enableStepsButton}
              primaryText="Confirm Request"
              secondaryText="Clear All"
            />
          )}
      </div>
    </>
  );
}

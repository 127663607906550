import { EmailInUseError, UnexpectedError } from "../../../domain/errors";
import { CreateAirtimePurchaseRequest } from "../../../domain/usecases";
import { HttpClient, HttpStatusCode } from "../../protocols/http";

export class RemoteCreateAirtimePurchaseRequest
  implements CreateAirtimePurchaseRequest
{
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<void>
  ) {}

  async create(params: CreateAirtimePurchaseRequest.Params): Promise<void> {
    console.log("CreateAirtimePurchaseRequest", params);
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "post",
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body!;
      case HttpStatusCode.forbidden:
        throw new EmailInUseError();
      default:
        throw new UnexpectedError();
    }
  }
}

// export namespace RemoteCreateAirtimePurchaseRequest {
//   export type Model = CreateAirtimePurchaseRequest.Model;
// }

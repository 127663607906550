import {
  SmartcardAlreadyRegisteredError,
  SmartcardNotFoundError,
  UnexpectedError,
} from "../../../domain/errors";

import { ValidateSmartcard } from "../../../domain/usecases/validate-smartcard";
import { HttpClient, HttpStatusCode } from "../../protocols/http";

export class RemoteValidateSmartcard implements ValidateSmartcard {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<ValidateSmartcard.Model>
  ) {}

  async validate(
    params: ValidateSmartcard.Params
  ): Promise<ValidateSmartcard.Model> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "post",
      body: params,
    });

    console.log("httpResponse", httpResponse);
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body!;
      case HttpStatusCode.conflict:
        throw new SmartcardAlreadyRegisteredError();
      case HttpStatusCode.notFound:
        throw new SmartcardNotFoundError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteValidateSmartcard {
  export type Model = ValidateSmartcard.Model;
}

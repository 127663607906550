import { atom } from "recoil";

export const createUserState = atom({
  key: "createUserState",
  default: {
    isLoading: false,
    roles: [],
    isFormInvalid: false,
    firstName: "",
    firstNameError: "",
    lastName: "",
    lastNameError: "",
    emailAddress: "",
    emailAddressError: "",
    contactNumber: "",
    contactNumberError: "",
    role: "",
    roleError: "This is a required field",
    mainError: "",
  },
});

export const rolesState = atom({
  key: "rolesState",
  default: [],
});

import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useResetRecoilState } from "recoil";
import { AddMeters } from "../../../../../domain/usecases/add-meters";
import { StepType } from "../../../../../types";
import { addMetersStepsState, addMeterState } from "../atoms/atoms";

interface StepsButtonProps {
  step: string;
  isEnabled: boolean;
  primaryText: string;
  secondaryText: string;
  addMeters: AddMeters;
}

export const AddMeterStepsButton = ({
  step,
  isEnabled,
  primaryText,
  secondaryText,
  addMeters,
}: StepsButtonProps) => {
  const navigate = useNavigate();

  const [state, setState] = useRecoilState(addMeterState);
  const resetState = useResetRecoilState(addMeterState);
  const [stepsState, setStepsState] = useRecoilState(addMetersStepsState);

  // const purchaseOrder = useRecoilValue(purchaseOrderState);

  const resetAddMeter = () => {
    resetState();
  };

  const submitForApproval = async () => {
    const steps = JSON.parse(JSON.stringify(stepsState)) as StepType[];
    // const { meters } = state;

    const step1 = steps.findIndex((step) => step.number === 1);
    const step2 = steps.findIndex((step) => step.number === 2);

    if (steps[step2]) {
      steps[step2].status = "complete";
    }

    if (steps[step1]) {
      steps[step1].status = "complete";
    }
    console.log("addMetersState.metersToAdd", state.metersToAdd);
    await addMeters.add(state.metersToAdd);

    setState((old) => ({
      ...old,
      metersToAdd: [],
      currentValidatedMeter: null,
      validateMeterFailed: false,
    }));

    setStepsState(steps);
  };

  const handleSecondaryButtonClick = (step: string) => {
    if (step === "initiate") {
      resetAddMeter();
    }
  };

  const handlePrimaryButtonClick = async (step: string) => {
    if (step === "initiate") {
      const meterCount = state.metersToAdd.length;

      setState((old) => ({
        ...old,
        isLoading: true,
      }));

      await submitForApproval();

      setState((old) => ({
        ...old,
        isLoading: false,
      }));

      navigate("/meters/add/complete", { state: meterCount });
    }
  };

  const mount = document.getElementById("stepsButton") as HTMLElement;

  let jsx = (
    <>
      <button
        disabled={!isEnabled || state.isLoading}
        onClick={() => handleSecondaryButtonClick(step)}
        type="button"
        className="bg-white py-2 px-6 border border-2 border-red-500 rounded-sm shadow-sm text-sm font-medium text-red-500 disabled:opacity-50 hover:bg-gray-50 focus:outline-none "
      >
        {secondaryText}
      </button>
      <button
        disabled={!isEnabled || state.isLoading}
        onClick={() => handlePrimaryButtonClick(step)}
        type="submit"
        className="ml-3 inline-flex justify-center py-2 px-6 border border-transparent rounded-sm shadow-sm disabled:opacity-50 text-sm font-medium  text-white  bg-blue-600 hover:bg-blue-700 active:bg-blue-800 focus:outline-none "
      >
        {primaryText}
      </button>
    </>
  );

  return ReactDOM.createPortal(jsx, mount);
};

import { Fragment, memo, useRef } from "react";
import { Link, NavLink } from "react-router-dom";

import logo from "../../assets/logo.svg";

// import { useAuthUser } from "../utils/AuthUser";
import { Disclosure, Menu, Popover, Transition } from "@headlessui/react";
import { BellIcon, XIcon, MenuIcon } from "@heroicons/react/outline";

import {
  ChevronDownIcon,
  UserGroupIcon,
  LightningBoltIcon,
} from "@heroicons/react/solid";
import { currentAccountState } from "..";
import { useRecoilValue } from "recoil";
import { useLogout } from "../../hooks/use-logout";

// const user = {
//   name: "Tefo Mmono",
//   email: "tmmono@verdure.co.bw",
//   imageUrl: profilePic,
// };
const navigation = [
  { name: "Dashboard", href: "/dashboard/orders", current: true },
  { name: "Purchase", href: "/purchase", current: false },
  { name: "Approvals", href: "/approvals/purchase-requests", current: false },
  { name: "Manage", href: "/manage", current: false },

  { name: "Reports", href: "/reports", current: false },
];
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];

const purchase = [
  {
    name: "Electricity",
    description: "Buy BPC Electricity.",
    href: "purchase/electricity/initiate",
    icon: LightningBoltIcon,
  },
  {
    name: "Airtime",
    description: "Buy beMobile, Mascom and Orange airtime.",
    href: "purchase/airtime/initiate",
    icon: LightningBoltIcon,
  },
  {
    name: "DSTV",
    description: "Pay your Multichoice Botswana subscription",
    href: "purchase/dstv/initiate",
    icon: LightningBoltIcon,
  },
];

const solutions = [
  {
    name: "BPC Meters",
    description: "Create and manage your BPC meters.",
    href: "/meters/add/initiate",
    icon: LightningBoltIcon,
  },
  {
    name: "Cellphones",
    description:
      "Create and manage your beMObile, Mascom and Orange cellphone numbers.",
    href: "/cellphones/add/initiate",
    icon: LightningBoltIcon,
  },
  {
    name: "Smartcards",
    description: "Create and manage your BPC meters.",
    href: "/smartcards/add/initiate",
    icon: LightningBoltIcon,
  },
  {
    name: "Users",
    description:
      "Create users and assign them roles on the PrepaidPlus platform.",
    href: "/users/manage",
    icon: UserGroupIcon,
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const AuthorizedPrimaryHeader = () => {
  const { getCurrentAccount } = useRecoilValue(currentAccountState);
  const logout = useLogout();
  const purchaseButtonRef = useRef(null);
  const manageButtonRef = useRef(null);

  const logOutClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void => {
    event.preventDefault();
    logout();
  };

  return (
    // <header className="authorized-primary-header">
    //   <Link to="/projects" className={classnames('logo', { 'logo-light': !lightBackground })}>
    //     <img src="/static/firebase.svg" alt="Firebase Logo" />
    //     <span>Firebase</span>
    //   </Link>
    //   <nav className="primary-nav">
    //     <button onClick={() => setLogged(false)} className="logout text-light-tint">
    //       Logout
    //     </button>
    //     <div className="avatar" />
    //   </nav>
    // </header>

    <Disclosure
      as="nav"
      className="sticky top-0 z-50  border-b border-gray-200 bg-white"
    >
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Link to="/" className="block lg:hidden h-7 w-auto">
                    <img src={logo} alt="PrepaidPlus" />
                  </Link>

                  <Link to="/" className="hidden lg:block h-7 w-auto">
                    <img src={logo} alt="PrepaidPlus" />
                  </Link>
                </div>
                <div className="hidden md:block">
                  <div className="ml-6 pl-6 border-l border-gray-200 flex items-baseline space-x-4">
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "text-gray-900 px-3 py-1 bg-blue-100 rounded-sm whitespace-nowrap text-sm font-medium"
                          : "text-gray-400 hover:bg-blue-50 hover:text-gray-900 px-3 py-1 rounded-sm whitespace-nowrap text-sm font-medium"
                      }
                      end
                      to="/dashboard/orders"
                    >
                      Dashboard
                    </NavLink>

                    {/* <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "text-gray-900 px-3 py-1 rounded-sm whitespace-nowrap text-sm font-medium"
                          : "text-gray-400 hover:bg-blue-50 hover:text-gray-900 px-3 py-1 rounded-sm whitespace-nowrap text-sm font-medium"
                      }
                      to="/purchase/electricity/initiate"
                    >
                      Purchase
                    </NavLink> */}

                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button
                            className={classNames(
                              open ? "text-gray-900" : "text-gray-400",
                              "group  rounded-0 inline-flex items-center rounded-sm text-sm font-medium hover:text-gray-900 focus:outline-none "
                            )}
                            ref={purchaseButtonRef}
                          >
                            <span>Purchase</span>
                            <ChevronDownIcon
                              className={classNames(
                                open ? "text-gray-900" : "text-gray-400",
                                "ml-1 h-5 w-5 group-hover:text-gray-900 "
                              )}
                              aria-hidden="true"
                            />
                          </Popover.Button>

                          <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-40 max-w-xs sm:px-0 lg:max-w-3xl">
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                              <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-1">
                                {purchase.map((item) => (
                                  <NavLink
                                    onClick={() =>
                                      purchaseButtonRef.current.click()
                                    }
                                    key={item.name}
                                    className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 text-gray-500 hover:bg-blue-50 hover:text-gray-900 px-3 py-1 rounded-sm text-sm font-medium"
                                    to={item.href}
                                  >
                                    <div className="ml-4">
                                      <p className="text-md font-medium text-gray-900">
                                        {item.name}
                                      </p>
                                    </div>
                                  </NavLink>
                                ))}
                              </div>
                            </div>
                          </Popover.Panel>
                        </>
                      )}
                    </Popover>

                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "text-gray-900 px-3 py-1 rounded-sm whitespace-nowrap text-sm font-medium"
                          : "text-gray-400 hover:bg-blue-50 hover:text-gray-900 px-3 py-1 rounded-sm whitespace-nowrap text-sm font-medium"
                      }
                      to="/approvals/purchase-requests"
                    >
                      Approvals
                    </NavLink>

                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button
                            className={classNames(
                              open ? "text-gray-900" : "text-gray-400",
                              "group  rounded-0 inline-flex items-center rounded-sm text-sm font-medium hover:text-gray-900 focus:outline-none "
                            )}
                            ref={manageButtonRef}
                          >
                            <span>Manage</span>
                            <ChevronDownIcon
                              className={classNames(
                                open ? "text-gray-900" : "text-gray-400",
                                "ml-1 h-5 w-5 group-hover:text-gray-900 "
                              )}
                              aria-hidden="true"
                            />
                          </Popover.Button>

                          <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-40 max-w-xs sm:px-0 lg:max-w-3xl">
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                              <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-1">
                                {solutions.map((item) => (
                                  <NavLink
                                    onClick={() =>
                                      manageButtonRef.current.click()
                                    }
                                    key={item.name}
                                    className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 text-gray-500 hover:bg-blue-50 hover:text-gray-900 px-3 py-1 rounded-sm text-sm font-medium"
                                    to={item.href}
                                  >
                                    <div className="ml-4">
                                      <p className="text-md font-medium text-gray-900">
                                        {item.name}
                                      </p>
                                    </div>
                                  </NavLink>
                                ))}
                              </div>
                            </div>
                          </Popover.Panel>
                        </>
                      )}
                    </Popover>

                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "text-gray-900 px-3 py-1 rounded-sm whitespace-nowrap text-sm font-medium"
                          : "text-gray-400 hover:bg-blue-50 hover:text-gray-900 px-3 py-1 rounded-sm whitespace-nowrap text-sm font-medium"
                      }
                      to="/reports/itemised"
                    >
                      Reports
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <button
                    type="button"
                    className="bg-white p-1 rounded-full text-gray-500 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  {/* Profile dropdown */}
                  <Menu
                    as="div"
                    className="ml-3 border-l border-gray-300 relative"
                  >
                    <div>
                      <Menu.Button className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
                        <span className="hidden ml-3 text-gray-700 text-sm font-medium lg:block">
                          <span className="sr-only">Open user menu for </span>
                          {getCurrentAccount().displayName}
                        </span>
                        <ChevronDownIcon
                          className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-500 lg:block"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/account"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Account Settings
                            </Link>
                          )}
                        </Menu.Item>
                        {/* <Menu.Item>
                          {({ active }) => (
                            <a
                              href="/account/details"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Edit Names
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="/account/password"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Change Password
                            </a>
                          )}
                        </Menu.Item> */}

                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                              onClick={logOutClick}
                            >
                              Logout
                            </Link>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block px-3 py-2 rounded-md text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </a>
              ))}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-700">
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  <span className="h-10 w-10 rounded-full">
                    {getCurrentAccount()
                      .displayName.match(/(\b\S)?/g)
                      .join("")
                      .match(/(^\S|\S$)?/g)
                      .join("")
                      .toUpperCase()}
                  </span>
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium leading-none text-white">
                    {getCurrentAccount().displayName}
                  </div>
                  <div className="text-sm font-medium leading-none text-gray-400">
                    {getCurrentAccount().email}
                  </div>
                </div>
                <button
                  type="button"
                  className="ml-auto bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-3 px-2 space-y-1">
                {userNavigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default memo(AuthorizedPrimaryHeader);

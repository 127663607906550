import {
  SmartcardNotFoundError,
  UnexpectedError,
} from "../../../domain/errors";
import { SmartcardAlreadyRegisteredError } from "../../../domain/errors/smartcard-already-exists-error";
import { AddSmartcards } from "../../../domain/usecases/add-smartcards";
import { HttpClient, HttpStatusCode } from "../../protocols/http";

export class RemoteAddSmartcards implements AddSmartcards {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<void>
  ) {}

  async add(params: AddSmartcards.Params): Promise<void> {
    console.log("AddSmartcards.Params", params);
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "post",
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body!;
      case HttpStatusCode.notFound:
        throw new SmartcardNotFoundError();
      case HttpStatusCode.conflict:
        throw new SmartcardAlreadyRegisteredError();
      default:
        throw new UnexpectedError();
    }
  }
}

// export namespace RemoteAddAccount {
//   export type Model = CreateUser.Model;
// }

import { ChevronRightIcon } from "@heroicons/react/outline";
import { query, collection, where, onSnapshot } from "firebase/firestore";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { GetSmartcardsState } from "../../../../domain/usecases/get-smartcard";
import { db } from "../../../../main/firebase";
import { currentAccountState } from "../../../components";
import Loading from "../../../components/loading/loading";
import { manageSmartcardState, toggleEditSmartcardState } from "./atoms/atoms";
import SmartcardRows from "./smartcardRows";

export default function ManageSmartcards() {
  const [state, setState] = useRecoilState(manageSmartcardState);
  const [toggleState, setToggleState] = useRecoilState(
    toggleEditSmartcardState
  );

  const { getCurrentAccount } = useRecoilValue(currentAccountState);

  useEffect(() => {
    const q = query(
      collection(db, "smartcard"),
      where("account", "==", getCurrentAccount().account)
    );

    return onSnapshot(q, (querySnapshot) => {
      let smartcards = querySnapshot.docs
        .sort((a, b) => a.data().displayName - b.data().displayName)
        .map((doc) => {
          console.log("doc.data()", doc.data());
          return {
            id: doc.id,
            account: doc.data().account,
            approvedBy: doc.data().approvedBy,
            createdBy: doc.data().createdBy,
            dateCreated: doc.data().dateCreated.toDate(),
            dateUpdated: doc.data().dateUpdated.toDate(),
            emailContact: doc.data().emailContact,
            isActive: doc.data().isActive,
            location: doc.data().location,
            smartcard_id: doc.data().smartcard_id,
            name: doc.data().name,
            number: doc.data().number,
            smartcardOwner: doc.data().smartcardOwner,
            status: doc.data().status,
            company: doc.data().company,
            rejectionReason: doc.data().rejectionReason
              ? doc.data().rejectionReason
              : null,
            disabledReason: doc.data().disabledReason
              ? doc.data().disabledReason
              : null,
          } as GetSmartcardsState.Model;
        });

      setState((old) => ({
        ...old,
        smartcards: smartcards,
        isLoading: false,
      }));
    });
  }, []);

  // useEffect(() => {
  //   getActiveSmartcards
  //     .fetch({
  //       smartcardStatus: "Active",
  //       accountNumber: getCurrentAccount().account,
  //     })
  //     .then((result) =>
  //       setState((old) => ({
  //         ...old,
  //         smartcards: result.map((smartcard, index) => {
  //           console.log("smartcard", smartcard);
  //           return {
  //             id: index,
  //             account: smartcard.account.toString(),
  //             merchant: getCurrentAccount().merchantName,
  //             location: smartcard.location,
  //             name: smartcard.name,
  //             number: smartcard.number,
  //             purchaseAmount: 0.0,
  //             lastPurchaseDate: "None",
  //             lastPurchaseAmount: 0.0,
  //           };
  //         }),
  //         isLoading: false,
  //       }))
  //     )
  //     .catch(handleError);
  // }, []);

  const toggleEditSmartcardClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    setToggleState((old) => ({
      ...old,
      toggle: !toggleState.toggle,
    }));
  };

  const smartcards =
    state.smartcards &&
    state.smartcards.map((smartcard, index) => {
      return (
        <SmartcardRows
          key={smartcard.number}
          index={index}
          smartcard={smartcard}
        />
      );
    });

  return (
    <>
      {state.smartcards && (
        <div className="w-full bg-white overflow-hidden shadow rounded-sm">
          <div className="px-4 py-5 sm:px-6">
            <h2 className="max-w-7xl mx-auto  text-lg leading-6 font-medium text-gray-900">
              Smartcards
            </h2>
          </div>
          <div className="px-6 pb-6">
            {/* Activity list (smallest breakpoint only) */}
            <div className="shadow sm:hidden">
              <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                {state.smartcards.map((smartcard) => (
                  <li key={smartcard.number}>
                    <a
                      href={smartcard.number}
                      className="block px-4 py-4 bg-white hover:bg-gray-50"
                    >
                      <span className="flex items-center space-x-4">
                        <span className="flex-1 flex space-x-2 truncate">
                          <span className="flex flex-col text-gray-500 text-sm truncate">
                            <span className="truncate">{smartcard.number}</span>
                            <span>
                              <span className="text-gray-900 font-medium">
                                {smartcard.name}
                              </span>
                            </span>
                            <div>{smartcard.number}</div>
                          </span>
                        </span>
                        <ChevronRightIcon
                          className="flex-shrink-0 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </a>
                  </li>
                ))}
              </ul>

              <nav
                className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
                aria-label="Pagination"
              >
                <div className="flex-1 flex justify-between">
                  <a
                    href="/"
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                  >
                    Previous
                  </a>
                  <a
                    href="/"
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                  >
                    Next
                  </a>
                </div>
              </nav>
            </div>

            {/* Activity table (small breakpoint and up) */}
            <div className="hidden  sm:block">
              <div className="max-w-7xl mx-auto">
                <div className="flex flex-col">
                  <div className="align-middle min-w-full flex-grow overflow-x-auto overflow-hidden ">
                    {state.smartcards.length > 0 ? (
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr>
                            <th className="pr-6 py-2  text-left text-sm font-medium text-gray-500  tracking-wider">
                              Number
                            </th>
                            <th className="px-6 py-2 text-left text-sm font-medium text-gray-500  tracking-wider">
                              Owner
                            </th>
                            <th className="px-6 py-2  text-left text-sm font-medium text-gray-500  tracking-wider">
                              Name
                            </th>
                            <th className="px-6 py-2 text-left text-sm font-medium text-gray-500  tracking-wider">
                              Location
                            </th>
                            <th className="px-6 py-2 text-left text-sm font-medium text-gray-500  tracking-wider">
                              Status
                            </th>

                            <th className="px-2 py-2  text-left text-sm font-medium text-gray-500  tracking-wider">
                              Manage
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-100">
                          {smartcards}
                        </tbody>
                      </table>
                    ) : (
                      <h2 className="text-md leading-6 font-medium text-gray-900">
                        Merchant has no smartcards.
                      </h2>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {state.isLoading && <Loading />}
    </>
  );
}

import { GetCellphonesState } from "../../../../../domain/usecases/get-cellphone";
import AmendCellphoneDetails from "../../../../../presentation/pages/cellphones/manage-cellphones/components/amendCellphoneDetails";
import { makeRemoteValidateCellphone } from "../../../usecases/cellphone";
import { makeRemoteAmendCellphone } from "../../../usecases/cellphone/remote-amend-cellphone-factory";
import { makeValidateAmendCellphoneDetailsValidation } from "./validate-amend-cellphone-details-validation-factory";

export const makeAmendCellphoneDetails = (
  cellphone: GetCellphonesState.Model
) => {
  return (
    <AmendCellphoneDetails
      amendCellphone={makeRemoteAmendCellphone()}
      validation={makeValidateAmendCellphoneDetailsValidation()}
      validateCellphone={makeRemoteValidateCellphone()}
      cellphone={cellphone}
    />
  );
};

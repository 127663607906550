import { atom, selector } from "recoil";
import {
  PurchaseRequestSmartcard,
  PurchaseRequestType,
  PurchaseRequestStatus,
  PurchaseRequestStep,
} from "../types";
import { PurchaseRequestCompany } from "../types/purchaseRequestCompany";

export const purchaseRequestSmartcardsState = atom({
  key: "purchaseRequestSmartcardsState",
  default: {
    smartcards: [] as PurchaseRequestSmartcard[],
    filteredSmartcards: [] as PurchaseRequestSmartcard[],
    companies: [] as PurchaseRequestCompany[],
    filteredCompanies: [] as PurchaseRequestCompany[],
    isFiltered: false,
    isCompaniesFiltered: false,
    showFilters: true,
    reload: false,
    isLoading: false,
    error: "",
    initiator: "",
  },
});

export const dstvPurchaseRequestStepsState = atom({
  key: "dstvPurchaseRequestStepsState",
  default: [
    {
      number: 1,
      name: "Smartcards",
      description: "Select smartcards to recharge.",
      href: "#",
      status: "current",
    },
    {
      number: 2,
      name: "Confirm",
      description: "Review and submit for approval.",
      href: "#",
      status: "upcoming",
    },
    {
      number: 3,
      name: "Completed",
      description: "Request submitted for approval.",
      href: "#",
      status: "upcoming",
    },
  ] as PurchaseRequestStep[],
});

export const dstvPurchaseRequestStatusState = atom({
  key: "dstvPurchaseRequestStatusState",
  default: {
    purchaseRequestStatus: "PurchaseRequestInitiated" as PurchaseRequestStatus,
  },
});

export const showSmartcardLocationFilters = selector({
  key: "showSmartcardLocationFilters",
  get: ({ get }) => {
    const smartcards = get(purchaseRequestSmartcardsState).smartcards;

    const set = Array.from(new Set(smartcards.map((x) => x.location))).sort();

    const locations = ["All Locations", ...set];
    return locations;
  },
});

export const enableSmartcardStepsButton = selector({
  key: "enableSmartcardStepsButton",
  get: ({ get }) => {
    const smartcards = get(purchaseRequestSmartcardsState).smartcards;

    return (
      smartcards.filter((smartcard) => {
        return +smartcard.purchaseAmount > 0;
      }).length > 0
    );
  },
});

export const smartcardLocationState = selector({
  key: "smartcardLocationState",
  get: ({ get }) => {
    const smartcards = get(purchaseRequestSmartcardsState).smartcards;

    const set = Array.from(new Set(smartcards.map((x) => x.location))).sort();

    const locations = ["All Locations", ...set];
    return locations;
  },
});

export const dstvPurchaseRequestCompanyState = selector({
  key: "dstvPurchaseRequestCompanyState",
  get: ({ get }) => {
    const companies = get(purchaseRequestSmartcardsState).companies;

    const set = Array.from(new Set(companies.map((x) => x.name))).sort();

    const locations = ["All Companies", ...set];
    return locations;
  },
});

export const dstvPurchaseRequestAmount = selector({
  key: "dstvPurchaseRequestAmount",
  get: ({ get }) => {
    const smartcards = get(purchaseRequestSmartcardsState).smartcards;

    const amount = smartcards.reduce((acc, smartcard) => {
      return acc + +smartcard.purchaseAmount;
    }, 0);
    return amount;
  },
});

const dstvPurchaseAmountsValid = selector({
  key: "dstvPurchaseAmountsValid",
  get: ({ get }) => {
    const smartcards = get(purchaseRequestSmartcardsState).smartcards;

    const isValid = smartcards.every((m) => m.purchaseAmount <= 1000);
    console.log("isValid", isValid);

    return isValid;
  },
});

export const dstvPurchaseRequestState = selector({
  key: "dstvPurchaseRequestState",
  get: ({ get }) => {
    return {
      smartcards: get(purchaseRequestSmartcardsState).smartcards,
      filteredSmartcards: get(purchaseRequestSmartcardsState)
        .filteredSmartcards,
      filteredCompanies: get(purchaseRequestSmartcardsState).filteredCompanies,
      isFiltered: get(purchaseRequestSmartcardsState).isFiltered,
      isCompaniesFiltered: get(purchaseRequestSmartcardsState)
        .isCompaniesFiltered,
      showFilters: true,
      enableStepsButton: get(enableSmartcardStepsButton),
      locations: get(smartcardLocationState),
      requestDetails: [],
      requestPurchaseAmount: get(dstvPurchaseRequestAmount),
      purchaseAmountsValid: get(dstvPurchaseAmountsValid),
      initiator: get(purchaseRequestSmartcardsState).initiator,
      steps: get(dstvPurchaseRequestStepsState),
      purchaseRequestStatus: get(dstvPurchaseRequestStatusState)
        .purchaseRequestStatus,
    } as PurchaseRequestType;
  },
});

import {
  CellphoneAlreadyRegisteredError,
  CellphoneNotFoundError,
  UnexpectedError,
} from "../../../domain/errors";

import { ValidateCellphone } from "../../../domain/usecases";
import { HttpClient, HttpStatusCode } from "../../protocols/http";

export class RemoteValidateCellphone implements ValidateCellphone {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<ValidateCellphone.Model>
  ) {}

  async validate(
    params: ValidateCellphone.Params
  ): Promise<ValidateCellphone.Model> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "post",
      body: params,
    });

    console.log("httpResponse.body!", httpResponse.body!);
    console.log("httpResponse.statusCode!", httpResponse.statusCode);
    console.log("HttpStatusCode.conflict", HttpStatusCode.conflict);

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body!;
      case HttpStatusCode.conflict:
        throw new CellphoneAlreadyRegisteredError();
      case HttpStatusCode.notFound:
        throw new CellphoneNotFoundError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteValidateCellphone {
  export type Model = ValidateCellphone.Model;
}

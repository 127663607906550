import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useResetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import { collection, query, where, onSnapshot } from "firebase/firestore";

import { Authentication } from "../../../domain/usecases";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../../main/firebase";
import logo from "../../assets/logo.svg";
import { currentAccountState } from "../../components";

import { Validation } from "../../protocols";
import { FormStatus, loginState } from "./components";
import Input from "./components/input";

type Props = {
  validation: Validation;
  authentication: Authentication;
};

export default function Login({ validation, authentication }: Props) {
  const resetLoginState = useResetRecoilState(loginState);

  const { setCurrentAccount } = useRecoilValue(currentAccountState);
  const navigate = useNavigate();
  const [state, setState] = useRecoilState(loginState);
  const [showError, setshowError] = useState(false);

  useEffect(() => resetLoginState(), []);
  useEffect(() => validate("email"), [state.email]);
  useEffect(() => validate("password"), [state.password]);

  console.log(" state", state);

  const validate = (field: string): void => {
    const { email, password } = state;
    const formData = { email, password };
    setState((old) => ({
      ...old,
      [`${field}Error`]: validation.validate(field, formData),
    }));
    setState((old) => ({
      ...old,
      isFormInvalid: !!old.emailError || !!old.passwordError,
    }));
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    setState((old) => ({
      ...old,
      isLoading: true,
    }));

    try {
      if (state.isLoading || state.isFormInvalid) {
        setshowError(true);
        setState((old) => ({ ...old, isLoading: false }));
        return;
      }

      await signInWithEmailAndPassword(auth, state.email, state.password)
        .then(async (userCredential) => {
          const accessToken = await userCredential.user.getIdToken();
          const displayName = userCredential.user.displayName
            ? userCredential.user.displayName
            : "";
          const email = userCredential.user.email
            ? userCredential.user.email
            : "";

          console.log("userCredential", userCredential);

          const userRecord = {
            accessToken: accessToken,
            uid: userCredential.user.uid,
            displayName: displayName,
            email: email,
            account: 5214,
            merchantName: "Gaborone City Council",
            user_id: "",
            role: "",
            api_key: "",
            buffer: "",
          };

          return userRecord;
        })
        .then(async (userRecord) => {
          const q = query(
            collection(db, "users"),
            where("uid", "==", userRecord.uid)
          );
          await onSnapshot(q, (querySnapshot) => {
            const userDetails = querySnapshot.docs[0].data();

            userRecord.user_id = userDetails.user_id;
            userRecord.role = userDetails.role;
            userRecord.account = userDetails.account;
            userRecord.merchantName = userDetails.accountName;
            userRecord.api_key = userDetails.apiKey;
            userRecord.buffer = userDetails.apiKeyBuffer;

            setCurrentAccount(userRecord);

            // navigate("/loginas", { replace: true });

            navigate("/dashboard/orders", { replace: true });
          });
        });

      setshowError(false);
    } catch (error: any) {
      setshowError(false);
      console.log("error message", error.message);
      switch (error.message) {
        case "Firebase: Error (auth/user-not-found).":
          return setState((old) => ({
            ...old,
            isLoading: false,
            mainError: "Provided email was not found",
          }));
        case "Firebase: Error (auth/wrong-password).":
          return setState((old) => ({
            ...old,
            isLoading: false,
            mainError: "We could not authenticate provided credentials",
          }));
        case "Firebase: Error (auth/invalid-login-credentials).":
          return setState((old) => ({
            ...old,
            isLoading: false,
            mainError: "We could not authenticate provided credentials",
          }));
        default:
          return setState((old) => ({
            ...old,
            isLoading: false,

            mainError:
              "We could not log you on, Verify you have internet connection",
          }));
      }
    }
  };

  return (
    <div className=" bg-white shadow shadow-sm sm:rounded-sm  py-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={logo} alt="PrepaidPlus" />

        <h2 className="mt-6 text-center text-xl font-semibold text-gray-900">
          Sign in to your PrepaidPlus account
        </h2>
      </div>
      {/* {errorMessage && (
          <div className="mt-4 text-center sm:mx-auto sm:w-full sm:max-w-md">
            <p style={{ color: "red" }}>{errorMessage}</p>
          </div>
        )} */}
      <div className="py-8 px-4 sm:px-10">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email address
            </label>
            <div className="mt-1"></div>
            <Input type="email" name="email" placeholder="email address" />
            {showError && (
              <div className=" block text-sm text-red-500">
                {state.emailError && state.emailError}
              </div>
            )}
          </div>

          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <div className="mt-1">
              {/* <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                /> */}
              <Input type="password" name="password" placeholder="password" />
              {showError && (
                <div className=" block text-sm text-red-500">
                  {state.passwordError && state.passwordError}
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="text-sm">
              <Link
                to="/auth/forgot"
                className="font-medium text-blue-600 hover:text-blue-500"
              >
                Forgot your password?
              </Link>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-sm shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 active:bg-blue-800 focus:outline-none "
            >
              Sign in
            </button>
          </div>

          <FormStatus />
        </form>
      </div>
    </div>
  );
}

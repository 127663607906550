import {
  ValidationComposite,
  ValidationBuilder as Builder,
} from "../../../../../validation/validators";

export const makeValidateSmartcardValidation = (): ValidationComposite =>
  ValidationComposite.build([
    ...Builder.field("smartcardNumber").required().build(),
    ...Builder.field("smartcardLocation").required().build(),
  ]);

import { useState } from "react";
// import format from "date-fns/format";
import { MeterModel } from "../../../../../domain/models/meter-model";
// import { classNames } from "../../../utils/helpers";
import MeterApplicatioDetails from "./meterApplicationDetails";

type Props = {
  transaction: MeterModel;
  index: number;
};

// const statusStyles: { [key: string]: string } = {
//   complete: "bg-green-100 text-green-800",
//   pending: "bg-yellow-100 text-yellow-800",
//   processing: "bg-yellow-100 text-yellow-800",
//   failed: "bg-red-100 text-red-800",
//   rejected: "bg-red-100 text-red-800",
// };

export default function MeterApplicationRow({ transaction, index }: Props) {
  const [isDetailOpen, setOpenDetail] = useState(false);
  const toggleRowExpansion = () => {
    setOpenDetail((isDetailOpen) => !isDetailOpen);
  };

  console.log("transaction", transaction);
  return (
    <>
      <tr
        key={transaction.meter_id}
        onClick={() => toggleRowExpansion()}
        className="bg-white"
      >
        <td className="max-w-0  px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
          <div className="flex">
            <p className="truncate ">{transaction.registeredOwner}</p>
          </div>
        </td>
        <td className="px-6 py-3 text-left whitespace-nowrap text-sm text-gray-500">
          <span className="text-gray-900 font-normal">
            {transaction.number}
          </span>
        </td>
        <td className="px-6 py-3 text-left whitespace-nowrap text-sm text-gray-500">
          <span className="text-gray-900 font-bold">{transaction.name}</span>
        </td>
        <td className="px-6 py-3 text-left whitespace-nowrap text-sm text-gray-500">
          <span className="text-gray-900 font-bold">
            {transaction.location}
          </span>
        </td>

        <td className="px-2 py-2   whitespace-nowrap text-sm text-gray-500 ">
          <div className="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </td>
      </tr>
      {isDetailOpen ? (
        <tr className="bg-gray-200">
          <td
            colSpan={7}
            className=" px-6 py-5 whitespace-nowrap text-sm text-gray-500 "
          >
            <MeterApplicatioDetails transaction={transaction} />
          </td>
        </tr>
      ) : null}
    </>
  );
}

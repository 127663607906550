import {
  ValidationComposite,
  ValidationBuilder as Builder,
} from "../../../../../../../validation/validators";

export const makeChangeUserDetailsValidation = (): ValidationComposite =>
  ValidationComposite.build([
    ...Builder.field("firstName").required().build(),
    ...Builder.field("lastName").required().build(),
    ...Builder.field("emailAddress").required().email().build(),
    ...Builder.field("contactNumber").required().build(),
  ]);

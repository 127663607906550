import { MinLengthFieldError } from "../../errors";
import { FieldValidation } from "../../protocols";

export class MinLengthValidation implements FieldValidation {
  constructor(readonly field: string, private readonly minLength: number) {}

  validate(input: any): Error | null {
    console.log("min lenth validation", input);

    return input[this.field]?.length < this.minLength
      ? new MinLengthFieldError()
      : null;
  }
}

import { atom } from "recoil";
import { GetUsersState } from "../../../../../domain/usecases/get-user";

export const userAccountState = atom({
  key: "userAccountState",
  default: {
    users: [] as GetUsersState.Model[],
    currentUser: null as GetUsersState.Model,
    roles: [],
    isOpenIndex: -1,
    isLoading: true,
    isFormInvalid: false,
    firestoreId: "",
    uid: "",
    isEnabled: false,
    firstName: "",
    lastName: "",
    emailAddress: "",
    contactNumber: "",
    role: "",
    firstNameError: "",
    lastNameError: "",
    emailAddressError: "",
    contactNumberError: "",
    password: "",
    passwordError: "",
    confirmPassword: "",
    confirmPasswordError: "",
    roleError: "",
    dateCreated: new Date(),
    mainError: "",
  },
});

export const toggleEditCurrentUserState = atom({
  key: "toggleEditCurrentUserState",
  default: {
    isSelected: false,
  },
});

export const toggleChangePasswordState = atom({
  key: "toggleChangePasswordState",
  default: {
    isSelected: false,
  },
});

import { currencyFormatter } from "../../utils/helpers";

import { SuccessfulPurchase } from "../../../domain/usecases/get-account-balances";
import { DownloadIcon } from "@heroicons/react/outline";

type Props = {
  transaction: any;
};

export default function ItemisedPurchasesFailureTable({ transaction }: Props) {
  const electricityHeader = () => {
    return (
      <>
        <th
          scope="col"
          className="px-4 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <div>Meter Name &amp;</div>
          <br />
          <div>Location</div>
        </th>
        <th>
          Meter Number &amp; <br />
          Registered Owner
        </th>

        <th
          scope="col"
          className="px-4 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          Recharge
        </th>
        <th
          scope="col"
          className="px-4 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          Amount
        </th>
      </>
    );
  };

  const airtimeHeader = () => {
    return (
      <>
        <th
          scope="col"
          className="px-4 py-1 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <div>Owner &amp;</div>
          <div>Job Title</div>
        </th>
        <th
          scope="col"
          className="px-4 py-1 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <div>Number &amp;</div>
          <div>Network</div>
        </th>
        <th
          scope="col"
          className="px-4 py-1 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          Status
        </th>
        <th
          scope="col"
          className="px-4 py-1 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          Amount
        </th>
        <th
          scope="col"
          className="px-4 py-1 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <DownloadIcon
            className="text-gray-900 ml-1 h-5 w-5 group-hover:text-gray-900 "
            aria-hidden="true"
          />
        </th>
      </>
    );
  };

  const dstvHeader = () => {
    return (
      <>
        <th
          scope="col"
          className="px-4 py-1 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <div>Smartcard Name &amp;</div>
          <div>Location</div>
        </th>
        <th
          scope="col"
          className="px-4 py-1 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <div>Smartcard Number &amp;</div>
          <div>Registered Owner</div>
        </th>
        <th
          scope="col"
          className="px-4 py-1 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          Status
        </th>
        <th
          scope="col"
          className="px-4 py-1 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          Amount
        </th>
        <th
          scope="col"
          className="px-4 py-1 text-left text-sm font-medium text-gray-600 tracking-wide"
        >
          <DownloadIcon
            className="text-gray-900 ml-1 h-5 w-5 group-hover:text-gray-900 "
            aria-hidden="true"
          />
        </th>
      </>
    );
  };

  const tableHeader: { [key: string]: JSX.Element } = {
    electricity: electricityHeader(),
    airtime: airtimeHeader(),
    dstv: dstvHeader(),
  };

  const electricityRow = () => {
    return (
      transaction.itemType === "ELECTRICITY" && (
        <>
          <tr key={transaction.number} className="border-b border-gray-150">
            <td className="px-4 py-1 whitespace-wrap">
              {transaction.name}
              <br />

              {transaction.location}
            </td>
            <td className="px-4 py-1 whitespace-wrap">
              {transaction.number}
              <br />

              {transaction.registeredOwner}
            </td>

            <td className="px-4 py-1 whitespace-nowrap text-sm font-normal text-gray-500">
              {transaction.status === "SUCCESSFUL"
                ? (transaction.response as SuccessfulPurchase).creditVendReceipt
                    .stsCipher
                : "Failed"}
            </td>
            <td className="px-4 py-1 whitespace-nowrap text-sm text-gray-500">
              <span className="text-gray-900 font-bold">
                {currencyFormatter(transaction.purchaseAmount)}
              </span>
            </td>
          </tr>
          <tr className="border-b border-gray-150">
            <td
              colSpan={4}
              className="px-4 py-1 whitespace-wrap text-sm font-medium text-gray-500"
            >
              Reason: {transaction.response.description}
            </td>
          </tr>
        </>
      )
    );
  };

  const airtimeRow = () => {
    return (
      transaction.itemType === "AIRTIME" && (
        <>
          <tr key={transaction.number} className="border-b border-gray-150">
            <td className="px-4 py-1 whitespace-wrap">
              {transaction.owner}
              <br />
              {transaction.jobTitle}
            </td>
            <td className="px-4 py-1 whitespace-wrap">
              {transaction.number}
              <br />
              {transaction.network}
            </td>

            <td className="px-4 py-1 whitespace-nowrap text-sm font-normal text-gray-500">
              {transaction.status === "SUCCESSFUL"
                ? (transaction.response as SuccessfulPurchase).creditVendReceipt
                    .stsCipher
                : "Failed"}
            </td>
            <td className="px-4 py-1 whitespace-nowrap text-sm text-gray-500">
              <span className="text-gray-900 font-bold">
                {currencyFormatter(transaction.purchaseAmount)}
              </span>
            </td>
          </tr>
          <tr className="border-b border-gray-150">
            <td
              colSpan={4}
              className="px-4 py-1 whitespace-wrap text-sm font-medium text-gray-500"
            >
              Reason: {transaction.response.description}
            </td>
          </tr>
        </>
      )
    );
  };

  const dstvRow = () => {
    return (
      transaction.itemType === "DSTV" && (
        <>
          <tr key={transaction.number} className="border-b border-gray-150">
            <td className="px-4 py-1 whitespace-wrap">
              {transaction.name}
              <br />

              {transaction.location}
            </td>
            <td className="px-4 py-1 whitespace-wrap">
              {transaction.number}
              <br />

              {transaction.registeredOwner}
            </td>

            <td className="px-4 py-1 whitespace-nowrap text-sm font-normal text-gray-500">
              {transaction.status === "SUCCESSFUL"
                ? (transaction.response as SuccessfulPurchase).creditVendReceipt
                    .stsCipher
                : "Failed"}
            </td>
            <td className="px-4 py-1 whitespace-nowrap text-sm text-gray-500">
              <span className="text-gray-900 font-bold">
                {currencyFormatter(transaction.purchaseAmount)}
              </span>
            </td>
          </tr>
          <tr className="border-b border-gray-150">
            <td
              colSpan={4}
              className="px-4 py-1 whitespace-wrap text-sm font-medium text-gray-500"
            >
              Reason: {transaction.response.description}
            </td>
          </tr>
        </>
      )
    );
  };

  const tableRow: { [key: string]: JSX.Element } = {
    electricity: electricityRow(),
    airtime: airtimeRow(),
    dstv: dstvRow(),
  };

  return (
    <table
      id="failure-table"
      className="min-w-full hidden bg-gray-50 border rounded-sm border-gray-200"
    >
      <thead className="sticky bg-gray-200">
        <tr>{tableHeader[transaction.itemType.toLowerCase()]}</tr>
      </thead>
      <tbody>{tableRow[transaction.itemType.toLowerCase()]}</tbody>
    </table>
  );
}

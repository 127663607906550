import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useResetRecoilState } from "recoil";
import { AddSmartcards } from "../../../../../domain/usecases/add-smartcards";
import { StepType } from "../../../../../types";
import { addSmartcardsStepsState, addSmartcardState } from "../atoms/atoms";

interface StepsButtonProps {
  step: string;
  isEnabled: boolean;
  primaryText: string;
  secondaryText: string;
  addSmartcards: AddSmartcards;
}

export const AddSmartcardStepsButton = ({
  step,
  isEnabled,
  primaryText,
  secondaryText,
  addSmartcards,
}: StepsButtonProps) => {
  const navigate = useNavigate();

  const [state, setState] = useRecoilState(addSmartcardState);
  const resetState = useResetRecoilState(addSmartcardState);
  const [stepsState, setStepsState] = useRecoilState(addSmartcardsStepsState);

  // const purchaseOrder = useRecoilValue(purchaseOrderState);

  const resetAddSmartcard = () => {
    resetState();
  };

  const submitForApproval = async () => {
    const steps = JSON.parse(JSON.stringify(stepsState)) as StepType[];
    // const { smartcards } = state;

    const step1 = steps.findIndex((step) => step.number === 1);
    const step2 = steps.findIndex((step) => step.number === 2);

    if (steps[step2]) {
      steps[step2].status = "complete";
    }

    if (steps[step1]) {
      steps[step1].status = "complete";
    }
    console.log("addSmartcardsState.smartcardsToAdd", state.smartcardsToAdd);
    await addSmartcards.add(state.smartcardsToAdd);

    setState((old) => ({
      ...old,
      smartcardsToAdd: [],
      currentValidatedSmartcard: null,
      validateSmartcardFailed: false,
    }));

    setStepsState(steps);
  };

  const handleSecondaryButtonClick = (step: string) => {
    if (step === "initiate") {
      resetAddSmartcard();
    }
  };

  const handlePrimaryButtonClick = async (step: string) => {
    if (step === "initiate") {
      const smartcardCount = state.smartcardsToAdd.length;
      // submitForApproval();
      setState((old) => ({
        ...old,
        isLoading: true,
      }));

      await submitForApproval();

      setState((old) => ({
        ...old,
        isLoading: false,
      }));

      navigate("/smartcards/add/complete", { state: smartcardCount });
    }
  };

  const mount = document.getElementById("stepsButton") as HTMLElement;

  let jsx = (
    <>
      <button
        disabled={!isEnabled || state.isLoading}
        onClick={() => handleSecondaryButtonClick(step)}
        type="button"
        className="bg-white py-2 px-6 border border-2 border-red-500 rounded-sm shadow-sm text-sm font-medium text-red-500 disabled:opacity-50 hover:bg-gray-50 focus:outline-none "
      >
        {secondaryText}
      </button>
      <button
        disabled={!isEnabled || state.isLoading}
        onClick={() => handlePrimaryButtonClick(step)}
        type="submit"
        className="ml-3 inline-flex justify-center py-2 px-6 border border-transparent rounded-sm shadow-sm disabled:opacity-50 text-sm font-medium  text-white  bg-blue-600 hover:bg-blue-700 active:bg-blue-800 focus:outline-none "
      >
        {primaryText}
      </button>
    </>
  );

  return ReactDOM.createPortal(jsx, mount);
};

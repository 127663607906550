import { PlusIcon } from "@heroicons/react/outline";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  addSmartcardsStepsState,
  addSmartcardState,
  smartcardCompaniesListState,
} from "../atoms/atoms";

import AddSmartcardList from "../components/addSmartcardsList";

import { Validation } from "../../../../protocols";
import { useEffect, useState } from "react";
import Input from "../components/input";
import { query, collection, onSnapshot, where } from "firebase/firestore";
import { db } from "../../../../../main/firebase";
import { makeAddSmartcardDetails } from "../../../../../main/factories/pages/smartcards/add/add-smartcard-details-factory";
import { makeAddSmartcardStepsButton } from "../../../../../main/factories/pages/smartcards/add/add-smartcard-steps-button-factory";
import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";
import { currentAccountState } from "../../../../components";
import FormStatus from "../components/form-status";
import { ValidateSmartcard } from "../../../../../domain/usecases";

type Props = {
  validateSmartcard: ValidateSmartcard;
  validation: Validation;
};

export default function AddSmartcards({
  validateSmartcard,
  validation,
}: Props) {
  const { getCurrentAccount } = useRecoilValue(currentAccountState);
  const setSmartcardsStepsState = useSetRecoilState(addSmartcardsStepsState);
  const [state, setState] = useRecoilState(addSmartcardState);
  const setCompaniesList = useSetRecoilState(smartcardCompaniesListState);

  const [showError, setshowError] = useState(false);

  useEffect(() => validate("smartcardNumber"), [state.smartcardNumber]);
  useEffect(() => resetStepsState(), []);

  const resetStepsState = () => {
    const resetState = [
      {
        number: 1,
        name: "Add Smartcards",
        description: "Add smartcards to recharge.",
        href: "#",
        status: "current",
      },
      {
        number: 2,
        name: "Submit for approval",
        description: "Submit for approval.",
        href: "#",
        status: "upcoming",
      },
    ];

    setSmartcardsStepsState(resetState);
  };

  const validate = (field: string): void => {
    const { smartcardNumber } = state;
    const formData = { smartcardNumber };
    // if (submitForm) {
    setState((old) => ({
      ...old,
      [`${field}Error`]: validation.validate(field, formData),
    }));

    setState((old) => ({ ...old, isFormInvalid: !!old.smartcardNumberError }));
    // }
  };

  useEffect(() => {
    const q = query(
      collection(db, "companies"),
      where("account", "==", getCurrentAccount().account)
    );
    return onSnapshot(q, (querySnapshot) => {
      let result = querySnapshot.docs.map((doc) => {
        return {
          account: doc.data().account,
          createdBy: doc.data().createdBy,
          dateCreated: doc.data().dateCreated,
          isActive: doc.data().isActive,
          name: doc.data().name,
          companyNumber: doc.data().companyNumber,
          postalAddress: doc.data().postalAddress,
          city: doc.data().city,
          phoneNumber: doc.data().phoneNumber,
        };
      });
      setCompaniesList((old) => ({ ...old, companiesList: result }));
      // setState((old) => ({ ...old, companiesList: result }));
    });
  }, []);

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();

    try {
      if (state.isLoading || state.isFormInvalid) {
        setshowError(true);
        return;
      }

      setState((old) => ({ ...old, mainError: "", isLoading: true }));

      const isSmartcardInAddList = state.smartcardsToAdd.find(
        (m) => m.smartcardNumber === state.smartcardNumber
      );

      if (isSmartcardInAddList) {
        // state.mainError();
        setState((old) => ({
          ...old,
          isLoading: false,
          mainError: "The supplied smartcard is already in list.",
        }));

        return;
      }

      let { currentValidatedSmartcard } = state;

      const validatedSmartcard = await validateSmartcard.validate({
        account: getCurrentAccount().account,
        smartcardNumber: state.smartcardNumber,
      });

      if (validatedSmartcard) {
        console.log("validatedSmartcard", validatedSmartcard);
        currentValidatedSmartcard = validatedSmartcard!;
      } else {
        console.log("validatedSmartcard fail", validatedSmartcard);
        setState({
          ...state,
          validateSmartcardFailed: true,
        });
      }

      setState({
        ...state,
        validateSmartcardFailed: false,
        isLoading: false,
        isFormInvalid: true,
        smartcardName: "",
        smartcardLocation: "",
        company: "",
        mainError: "",
        currentValidatedSmartcard: currentValidatedSmartcard,
      });

      setState((old) => ({
        ...old,
        isLoading: false,
        smartcardNumber: "",
        mainError: "",
      }));
      setshowError(false);
      return;

      // setCurrentAccount(account)
      // history.replace('/')
    } catch (error) {
      setState((old) => ({
        ...old,
        validateSmartcardFailed: true,
      }));

      setState((old) => ({
        ...old,
        isLoading: false,
        mainError: error.message,
      }));
    }
  };

  return (
    <>
      {!state.currentValidatedSmartcard && (
        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
          <div className="sm:col-span-12">
            <form onSubmit={(e) => handleSubmit(e)} className="flex">
              <div className="flex flex-col">
                <label htmlFor="smartcard" className="sr-only">
                  Smartcard Number
                </label>
                <Input
                  type="number"
                  name="smartcardNumber"
                  className="block border border-gray-200 placeholder-gray-300 font-medium rounded-sm py-2 mr-4 border-b border-transparent bg-white focus:border-blue-600 focus:ring-0 sm:text-sm"
                  placeholder="MultiChoice Smartcard"
                />
                {showError && (
                  <div className=" block text-sm text-red-500">
                    {state.smartcardNumberError}
                  </div>
                )}
              </div>
              <div>
                <button
                  type="submit"
                  className="relative inline-flex justify-center py-2 px-6 border border-transparent rounded-sm shadow-sm disabled:opacity-50 text-sm font-medium  text-white bg-blue-600 hover:bg-blue-700  active:bg-indingo-800 focus:outline-none "
                  disabled={state.isLoading}
                >
                  {state.isLoading ? (
                    <>
                      <Spinner className=" h-5" /> Validating Smartcard...
                    </>
                  ) : (
                    <>
                      <PlusIcon
                        className="-ml-1 mr-2 h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      Validate MultiChoice Smartcard
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {makeAddSmartcardDetails()}
      {/* <ValidateSmartcardFail /> */}
      <FormStatus />
      <AddSmartcardList />

      {state.smartcardsToAdd.length > 0 &&
        state.currentValidatedSmartcard === null &&
        makeAddSmartcardStepsButton({
          step: "initiate",
          isEnabled:
            state.smartcardsToAdd.length > 0 &&
            state.currentValidatedSmartcard === null,
          primaryText: "Submit Smartcards",
          secondaryText: "Clear All",
        })}
    </>
  );
}

import { atom } from "recoil";
import { StepType } from "../../../../../types";
import { CompanyModel } from "../../../../../domain/models";

export const addCellphonesStepsState = atom({
  key: "addCellphonesStepsState",
  default: [
    {
      number: 1,
      name: "Add Cellphones",
      description: "Add cellphones to recharge.",
      href: "#",
      status: "current",
    },
    {
      number: 2,
      name: "Submit for approval",
      description: "Submit for approval.",
      href: "#",
      status: "upcoming",
    },
  ] as StepType[],
});

// export const validateCellphoneState = atom({
//   key: "validateCellphoneState",
//   default: {
//     isLoading: false,
//     isFormInvalid: false,
//     cellphoneNumber: "",
//     cellphoneNumberError: "",
//     mainError: "",
//   },
// });

export const cellphoneCompaniesListState = atom({
  key: "cellphoneCompaniesListState",
  default: {
    companiesList: [] as CompanyModel[],
  },
});

export const networkListState = atom({
  key: "networkListState",
  default: {
    networkList: [
      { name: "Mascom" },
      { name: "Orange" },
      { name: "beMobile" },
    ] as { name: string }[],
  },
});

export const addCellphoneState = atom({
  key: "addCellphoneState",
  default: {
    cellphonesToAdd: [],
    showForm: true,
  },
});

export const addCellphoneFormState = atom({
  key: "addCellphoneFormState",
  default: {
    isLoading: false,
    isFormInvalid: true,
    jobTitle: "",
    jobTitleError: "",
    cellphoneNumber: "",
    cellphoneNumberError: "",
    cellphoneOwner: "",
    cellphoneOwnerError: "",
    company: "Select Company",
    companyError: "",
    companyNumber: "",
    network: "Select Network",
    networkError: "",
    mainError: "",
  },
});

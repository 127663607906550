import { Outlet } from "react-router-dom";

import Steps from "./steps/steps";

export default function AddSmartcardsLayout() {
  return (
    <>
      <div className="w-full flex flex-col flex-grow lg:max-w-7xl lg:mx-auto">
        {/* <div className="pb-6"></div> */}
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
          <aside className="py-8 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
            <Steps />
          </aside>

          <div className="space-y-8 sm:px-6 lg:px-0 lg:col-span-9">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

import { atom, selector } from "recoil";
import {
  PurchaseRequestMeter,
  PurchaseRequestType,
  PurchaseRequestStatus,
  PurchaseRequestStep,
} from "../types";
import { PurchaseRequestCompany } from "../types/purchaseRequestCompany";

export const purchaseRequestMetersState = atom({
  key: "purchaseRequestMetersState",
  default: {
    meters: [] as PurchaseRequestMeter[],
    filteredMeters: [] as PurchaseRequestMeter[],
    companies: [] as PurchaseRequestCompany[],
    filteredCompanies: [] as PurchaseRequestCompany[],
    isFiltered: false,
    isCompaniesFiltered: false,
    showFilters: true,
    reload: false,
    isLoading: false,
    error: "",
    initiator: "",
  },
});

export const electricityPurchaseRequestStepsState = atom({
  key: "electricityPurchaseRequestStepsState",
  default: [
    {
      number: 1,
      name: "Meters",
      description: "Select meters to recharge.",
      href: "#",
      status: "current",
    },
    {
      number: 2,
      name: "Confirm",
      description: "Review and submit for approval.",
      href: "#",
      status: "upcoming",
    },
    {
      number: 3,
      name: "Completed",
      description: "Request submitted for approval.",
      href: "#",
      status: "upcoming",
    },
  ] as PurchaseRequestStep[],
});

export const electricityPurchaseRequestStatusState = atom({
  key: "electricityPurchaseRequestStatusState",
  default: {
    purchaseRequestStatus: "PurchaseRequestInitiated" as PurchaseRequestStatus,
  },
});

export const showMeterLocationFilters = selector({
  key: "showMeterLocationFilters",
  get: ({ get }) => {
    const meters = get(purchaseRequestMetersState).meters;

    const set = Array.from(new Set(meters.map((x) => x.location))).sort();

    const locations = ["All Locations", ...set];
    return locations;
  },
});

export const enableMeterStepsButton = selector({
  key: "enableMeterStepsButton",
  get: ({ get }) => {
    const meters = get(purchaseRequestMetersState).meters;

    return (
      meters.filter((meter) => {
        return +meter.purchaseAmount > 0;
      }).length > 0
    );
  },
});

export const meterLocationState = selector({
  key: "meterLocationState",
  get: ({ get }) => {
    const meters = get(purchaseRequestMetersState).meters;

    const set = Array.from(new Set(meters.map((x) => x.location))).sort();

    const locations = ["All Locations", ...set];
    return locations;
  },
});

export const electricityPurchaseRequestCompanyState = selector({
  key: "electricityPurchaseRequestCompanyState",
  get: ({ get }) => {
    const companies = get(purchaseRequestMetersState).companies;

    const set = Array.from(new Set(companies.map((x) => x.name))).sort();

    const locations = ["All Companies", ...set];
    return locations;
  },
});

export const electricityPurchaseRequestAmount = selector({
  key: "electricityPurchaseRequestAmount",
  get: ({ get }) => {
    const meters = get(purchaseRequestMetersState).meters;

    const amount = meters.reduce((acc, meter) => {
      return acc + +meter.purchaseAmount;
    }, 0);
    return amount;
  },
});

const electricityPurchaseAmountsValid = selector({
  key: "electricityPurchaseAmountsValid",
  get: ({ get }) => {
    const meters = get(purchaseRequestMetersState).meters;

    const isValid = meters.every((m) => m.purchaseAmount <= 10000);
    console.log("isValid", isValid);
    return isValid;
  },
});

export const electricityPurchaseRequestState = selector({
  key: "electricityPurchaseRequestState",
  get: ({ get }) => {
    return {
      meters: get(purchaseRequestMetersState).meters,
      filteredMeters: get(purchaseRequestMetersState).filteredMeters,
      filteredCompanies: get(purchaseRequestMetersState).filteredCompanies,
      isFiltered: get(purchaseRequestMetersState).isFiltered,
      isCompaniesFiltered: get(purchaseRequestMetersState).isCompaniesFiltered,
      showFilters: true,
      enableStepsButton: get(enableMeterStepsButton),
      locations: get(meterLocationState),
      requestDetails: [],
      requestPurchaseAmount: get(electricityPurchaseRequestAmount),
      purchaseAmountsValid: get(electricityPurchaseAmountsValid),
      initiator: get(purchaseRequestMetersState).initiator,
      steps: get(electricityPurchaseRequestStepsState),
      purchaseRequestStatus: get(electricityPurchaseRequestStatusState)
        .purchaseRequestStatus,
    } as PurchaseRequestType;
  },
});

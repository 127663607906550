import { atom } from "recoil";
import { GetUsersState } from "../../../../../domain/usecases/get-user";

export const manageUserState = atom({
  key: "manageUserState",
  default: {
    users: [] as GetUsersState.Model[],
    selectedUser: null as GetUsersState.Model,
    roles: [],
    isOpenIndex: -1,
    isLoading: true,
    isFormInvalid: false,
    firestoreId: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    contactNumber: "",
    role: "",
    firstNameError: "",
    lastNameError: "",
    emailAddressError: "",
    contactNumberError: "",
    roleError: "",
    mainError: "",
  },
});

export const toggleEditUserState = atom({
  key: "toggleEditUserState",
  default: {
    toggle: false,
  },
});

import { useRecoilValue } from "recoil";
import { currencyFormatter } from "../../../utils/helpers";
import { pendingPurchaseAmount, pendingApprovalsState } from "../atoms/atoms";

export default function PurchaseRequestBalances() {
  const purchaseAmount = useRecoilValue(pendingApprovalsState).avaliableBalance;

  const pendingPurchaseTotal = useRecoilValue(pendingPurchaseAmount);

  return (
    <div className="flex justify-end lg:col-span-6 text-right">
      <div>
        <h2 className="text-md leading-6  text-sm text-gray-500">
          Value of Pending Approvals
        </h2>
        <p className=" max-w-2xl text-lg font-medium leading-6 text-gray-900 ">
          {currencyFormatter(pendingPurchaseTotal)}
        </p>
      </div>
      <div className="pl-6 ml-6 border-l border-gray-300 border-l border-gray-300">
        <h2 className="text-md leading-6  text-sm text-gray-500">
          Account Balance
        </h2>
        <p className=" max-w-2xl text-lg font-medium leading-6 text-gray-900 ">
          {currencyFormatter(purchaseAmount)}
        </p>
      </div>
    </div>
  );
}

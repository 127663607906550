import { Navigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { currentAccountState } from "..";

const AuthorizedRoute = ({ children }: { children: JSX.Element }) => {
  let location = useLocation();

  console.log("In Authorised route");

  const { getCurrentAccount } = useRecoilValue(currentAccountState);
  return getCurrentAccount()?.accessToken ? (
    children
  ) : (
    <Navigate to="/auth/login" state={{ from: location }} />
  );
};

export default AuthorizedRoute;

// function RequireAuth({ children }: { children: JSX.Element }) {
//   let auth = useAuth();
//   let location = useLocation();

//   if (!auth.user) {
//     // Redirect them to the /login page, but save the current location they were
//     // trying to go to when they were redirected. This allows us to send them
//     // along to that page after they login, which is a nicer user experience
//     // than dropping them off on the home page.
//     return <Navigate to="/login" state={{ from: location }} />;
//   }

//   return children;
// }

import { format } from "date-fns";
import moment from "moment";
import { atom, selector } from "recoil";
import {
  ProductProvider,
  PurchaseOrderModel,
  PurchaseRequestModel,
  PurchasesOrderItem,
} from "../../../../domain/usecases/get-account-balances";

export const reportsState = atom({
  key: "reportsState",
  default: {
    itemisedPurchases: [] as PurchasesOrderItem[],
    filteredItemisedPurchases: [] as PurchasesOrderItem[],
    isFiltered: false,
    isLoading: true,
    isPurchaseOrderFiltered: false,
    isPurchaseRequestFiltered: false,
    userPurchases: [] as PurchaseOrderModel[],
    providers: [] as ProductProvider[],
    dailyPurchases: [] as PurchasesOrderItem[],
    purchaseRequests: [] as PurchaseRequestModel[],
    purchaseOrders: [] as PurchaseOrderModel[],
    filteredPurchaseOrders: [] as PurchaseOrderModel[],
    filteredPurchaseRequests: [] as PurchaseRequestModel[],
    startDate: moment(),
    statement: null as any,
    endDate: moment(),
    accountDetails: {
      phoneNumber: "",
      postalAddress: "string",
      city: "string",
    },
  },
});

export const productTypeListState = selector({
  key: "productTypeListState",
  get: ({ get }) => {
    const productTypes = [
      "All Purchase Types",
      "Airtime",
      "DSTV",
      "Electricity",
    ];

    // const set = Array.from(new Set(companies.map((x) => x.name))).sort();

    // const locations = ["All Companies", ...set];
    return productTypes;
  },
});

export const userPurchasesState = selector({
  key: "userPurchasesState",
  get: ({ get }) => {
    const itemisedPurchases = get(reportsState).itemisedPurchases;

    // const set = Array.from(new Set(meters.map((x) => x.location))).sort();

    // const locations = ["All Locations", ...set];
    // return locations;

    return itemisedPurchases
      .map((doc) => {
        return {
          dateCreated: doc.dateUpdated,
          number: doc.number,
          purchaseOrderNo: doc.purchaseOrderNo,
          registeredOwner: doc.owner,
          createdBy: doc.createdBy,
          approvedBy: doc.approvedBy,
          amount: doc.amount,
        };
      })
      .reduce(function (arr, doc) {
        let createdBy = doc.createdBy;

        if (!(createdBy in arr)) {
          arr.push((arr[createdBy] = doc));
          return arr;
        }

        arr[createdBy].amount += doc.amount ? doc.amount : 0;
        // arr[createdBy].commissionAmount += parseFloat(
        //   doc.commissionAmount
        // )
        //   ? parseFloat(doc.commissionAmount)
        //   : 0;

        return arr;
      }, []);
  },
});

export const purchasesByDayState = selector({
  key: "purchasesByDayState",
  get: ({ get }) => {
    const itemisedPurchases = get(reportsState).itemisedPurchases;

    // const set = Array.from(new Set(meters.map((x) => x.location))).sort();

    // const locations = ["All Locations", ...set];
    // return locations;

    return itemisedPurchases
      .map((doc) => {
        return {
          dateCreated: format(new Date(doc.dateCreated), "yyyy-MM-dd"),
          amount: doc.amount,
          count: 1,
        };
      })
      .reduce(function (arr, doc) {
        let dateCreated = format(new Date(doc.dateCreated), "yyyy-MM-dd");

        if (!(dateCreated in arr)) {
          arr.push((arr[dateCreated] = doc));
          return arr;
        }

        arr[dateCreated].count += doc.count ? doc.count : 0;
        arr[dateCreated].amount += doc.amount ? doc.amount : 0;
        // arr[createdBy].commissionAmount += parseFloat(
        //   doc.commissionAmount
        // )
        //   ? parseFloat(doc.commissionAmount)
        //   : 0;

        return arr;
      }, []);
  },
});
//   (Note: This report shows transactions for all the User IDs/ User Names which performed transactions.
//     It classifies the sub totals for each user who originated a purchase order,
//     also showing who approved the purchase)

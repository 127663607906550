import { useRecoilState } from "recoil";
import React, { useEffect, useState } from "react";
import { Input, toggleChangePasswordState, userAccountState } from ".";

import { Validation } from "../../../../protocols";
import { useFirestore } from "../../../../hooks/use-firestore";
// import { ChangeUserDetails } from "../../../../../domain/usecases";
// import { GetUsersState } from "../../../../../domain/usecases/get-user";

type Props = {
  validation: Validation;
};
// makeChangeCurrentUserPassword
export default function ChangeCurrentUserPassword({ validation }: Props) {
  const [state, setState] = useRecoilState(userAccountState);
  const [changePasswordState, setChangePasswordState] = useRecoilState(
    toggleChangePasswordState
  );
  // const [togglePasswordState, setTogglePasswordState] = useRecoilState(
  //   toggleChangePasswordState
  // );
  const [showError, setshowError] = useState(false);
  const [showMismatchPasswordError, setMismatchPasswordError] = useState(false);

  const firestore = useFirestore();

  const validate = (field: string): void => {
    const { password, confirmPassword } = state;
    const formData = {
      password,
      confirmPassword,
    };

    setState((old) => ({
      ...old,
      [`${field}Error`]: validation.validate(field, formData),
    }));
    setState((old) => ({
      ...old,
      isFormInvalid: !!old.passwordError || !!old.confirmPasswordError,
    }));
  };

  useEffect(
    () =>
      setState((old) => ({
        ...old,
        password: state.password,
        confirmPassword: state.confirmPassword,
      })),
    []
  );

  useEffect(() => validate("password"), [state.password]);
  useEffect(() => validate("confirmPassword"), [state.confirmPassword]);

  const togglePasswordClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    setChangePasswordState((old) => ({
      ...old,
      isSelected: !changePasswordState.isSelected,
    }));
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    try {
      if (state.isLoading || state.isFormInvalid) {
        setshowError(true);
        return;
      }

      if (state.password !== state.confirmPassword) {
        setMismatchPasswordError(true);
        return;
      }

      // if (state.password.length < 6) {
      //   setPasswordLengthError(true);
      //   return;
      // }

      setState((old) => ({ ...old, isLoading: true }));
      await firestore.updateCurrentUserPassword(state.password);

      setState((old) => ({
        ...old,
        isLoading: false,
      }));

      setChangePasswordState((old) => ({
        ...old,
        isSelected: !changePasswordState.isSelected,
      }));

      // navigate("/", { replace: true });
      // navigate("/users/create/complete");
    } catch (error) {
      setState((old) => ({
        ...old,
        isLoading: false,
        mainError: error.message,
      }));
    }
  };

  return (
    <div className="min-w-0 flex-1 lg:grid lg:grid-cols-12 lg:gap-x-5">
      <div className="lg:col-span-3"> </div>
      <div className="lg:col-span-9">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="overflow-hidden sm:rounded-sm  pb-6">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-3 sm:col-span-3">
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>

                  <Input
                    type="password"
                    name="password"
                    id="password"
                    value={state.password}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-sm"
                  />
                  {showError && (
                    <div className=" block text-sm text-red-500">
                      {state.passwordError && state.passwordError}
                    </div>
                  )}
                  {showMismatchPasswordError && (
                    <div className=" block text-sm text-red-500">
                      Passwords do not match.
                    </div>
                  )}
                </div>

                <div className="col-span-3 sm:col-span-3">
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Confirm Password
                  </label>
                  <Input
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    value={state.confirmPassword}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-sm"
                  />
                  {showError && (
                    <div className=" block text-sm text-red-500">
                      {state.confirmPasswordError && state.confirmPasswordError}
                    </div>
                  )}
                  {showMismatchPasswordError && (
                    <div className=" block text-sm text-red-500">
                      Passwords do not match.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="px-4 py-3 pb-6 bg-white text-right sm:px-6">
              <button
                onClick={(e) => togglePasswordClick(e)}
                type="button"
                className="bg-white py-2 px-6 border border-2 border-red-500 rounded-sm shadow-sm text-sm font-medium text-red-500 disabled:opacity-50 hover:bg-gray-50 focus:outline-none "
              >
                Cancel
              </button>
              <button
                type="submit"
                className="inline-flex ml-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
              >
                Change Password
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

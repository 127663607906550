import React from "react";

import { useRecoilValue } from "recoil";
import { loginState } from "./atoms";
import { FormStatusBase } from "../../../components";

const FormStatus: React.FC = () => {
  const state = useRecoilValue(loginState);

  console.log("loginState", loginState);

  return <FormStatusBase state={state} />;
};

export default FormStatus;

import { NavLink, Outlet } from "react-router-dom";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentAccountState } from "../../components";
import { reportsState } from "./atoms/atoms";
import Loading from "../../components/loading/loading";

const tabs = [
  {
    name: "Itemised Purchases",
    description: "Managed approved BPC meters.",
    href: "/reports/itemised",
    current: true,
  },
  {
    name: "Purchase Orders",
    description: "Company Purchase Orders.",
    href: "/reports/purchase-orders",
    current: false,
  },
  {
    name: "Statement",
    description: "Merchant Statement.",
    href: "/reports/statement",
    current: false,
  },
  {
    name: "Purchase Requests",
    description: "Company Purchase Requests.",
    href: "/reports/purchase-requests",
    current: false,
  },
];

export default function ReportsLayout() {
  const [state, setState] = useRecoilState(reportsState);
  let [startDate, setStartDate] = useState(moment());
  let [endDate, setEndDate] = useState(moment());
  let [today] = useState(moment());
  const [focusedInput, setFocusedInput] = useState(null);

  const { getCurrentAccount } = useRecoilValue(currentAccountState);

  useEffect(() => {
    setStartDate(moment());
    setEndDate(moment());

    setState((old) => ({
      ...old,
      startDate: startDate!,
      endDate: endDate!,
    }));

    return () => {
      // clearFinancialTransactions();
    };
  }, []);

  const changeDates = (e: React.SyntheticEvent) => {
    if (!startDate) {
      setStartDate(endDate);
      setState((old) => ({
        ...old,
        startDate: endDate!,
      }));
    } else {
      setState((old) => ({
        ...old,
        startDate: startDate!,
      }));
    }

    if (!endDate) {
      setEndDate(startDate);
      setState((old) => ({
        ...old,
        endDate: startDate!,
      }));
    } else {
      setState((old) => ({
        ...old,
        endDate: endDate!,
      }));
    }
  };

  const onFocusChangeRangeHandler = (focusedInput: any) => {
    setFocusedInput(focusedInput);
  };

  return (
    <>
      <div className="relative flex flex-col flex-grow">
        <div className="z-40 bg-blue-100 w-full">
          <div className="lg:max-w-7xl lg:mx-auto">
            <div className="py-4 md:flex md:items-center md:justify-between">
              <div className="flex-1 min-w-0">
                {/* Profile */}
                <div className="flex items-center">
                  <h3 className="text-lg font-medium leading-6 text-gray-900 ">
                    <span>Reports </span>
                  </h3>
                </div>
              </div>
              <div className="mt-4 flex space-x-2 md:mt-0 md:ml-4">
                <span className="text-xl leading-6 font-medium text-gray-900">
                  {getCurrentAccount().merchantName}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-grow w-full max-w-7xl mx-auto lg:flex">
          {/* Left sidebar & main wrapper */}

          <div className="mt-6 flex-grow flex max-w-7xl w-full mx-auto bg-transparent">
            <div className="flex flex-grow w-full">
              <div className="flex-grow w-full max-w-7xl mx-auto lg:flex lg:flex-col items-start overflow-hidden">
                <div className="z-40 w-full lg:max-w-7xl lg:mx-auto py-6 lg:col-span-6">
                  <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                      Select a tab
                    </label>
                    <select
                      id="tabs"
                      name="tabs"
                      className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                      defaultValue={tabs.find((tab) => tab.current)?.name}
                    >
                      {tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="hidden  sm:block">
                    <div className="flex justify-between border-b border-gray-200 ">
                      <nav
                        className="-mb-px flex space-x-8  justify-start"
                        aria-label="Tabs"
                      >
                        {tabs.map((tab) => (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "text-gray-900 border-b-2 px-4 border-gray-900 whitespace-nowrap font-medium"
                                : "border-transparent border-b-2 px-4 text-gray-400 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-2 px-0 border-b-2"
                            }
                            // activeClassName="border-gray-700 text-gray-900 border-gray-900 whitespace-nowrap font-medium"
                            // className="border-transparent text-gray-400 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-2 px-0 border-b-2"
                            to={tab.href}
                            key={tab.name}
                            aria-current={tab.current ? "page" : undefined}
                          >
                            <>
                              <h2
                                id="reports-tab-title"
                                className="text-md leading-6 font-medium"
                              >
                                {tab.name}
                              </h2>
                            </>
                          </NavLink>
                        ))}
                      </nav>
                      <div className="flex flex-col-reverse justify-end space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                        <DateRangePicker
                          startDate={startDate} // momentPropTypes.momentObj or null,
                          startDateId="startDate" // PropTypes.string.isRequired,
                          endDate={endDate} // momentPropTypes.momentObj or null,
                          endDateId="endDate" // PropTypes.string.isRequired,
                          onDatesChange={({ startDate, endDate }) => {
                            setStartDate(startDate!);
                            setEndDate(endDate!);
                          }} // PropTypes.func.isRequired,
                          focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                          onFocusChange={onFocusChangeRangeHandler} // PropTypes.func.isRequired,
                          numberOfMonths={1}
                          isOutsideRange={(date) => date.isAfter(today)}
                          displayFormat={"YYYY-MM-DD"}
                          minimumNights={0}
                          small={true}
                          readOnly={true}
                        />
                        <button
                          onClick={(e) => changeDates(e)}
                          type="button"
                          className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-sm shadow-sm text-white bg-blue-600 hover:bg-blue-700 active:bg-blue-800 focus:outline-none"
                        >
                          Change Dates
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <Outlet />
              </div>
            </div>
          </div>

          {/* Activity feed */}
        </div>
      </div>
    </>
  );
}

import { useRecoilValue } from "recoil";
import { FormStatusBase } from "../../../../components";
import { addCellphoneFormState } from "../atoms/atoms";

// const FormStatus: React.FC = () => {
export default function FormStatus() {
  const state = useRecoilValue(addCellphoneFormState);
  return <FormStatusBase state={state} />;
}

// export default FormStatus;

import {
  ValidationComposite,
  ValidationBuilder as Builder,
} from "../../../../../validation/validators";

export const makeValidateAddCellphoneDetailsValidation =
  (): ValidationComposite =>
    ValidationComposite.build([
      ...Builder.field("cellphoneNumber").required().min(8).max(8).build(),
      ...Builder.field("cellphoneOwner").required().build(),
      ...Builder.field("jobTitle").required().build(),
      ...Builder.field("network").sameAs("Select Network").build(),
      ...Builder.field("company").sameAs("Select Company").build(),
    ]);

import {
  ValidationComposite,
  ValidationBuilder as Builder,
} from "../../../../../validation/validators";

export const makeValidateAmendSmartcardDetailsValidation =
  (): ValidationComposite =>
    ValidationComposite.build([
      ...Builder.field("smartcardName").required().build(),
      ...Builder.field("smartcardLocation").required().build(),
    ]);

import { atom } from "recoil";
import { CompanyModel } from "../../../../../domain/models";
// import { StepType } from "../../../../../types";
import { AddMetersModel } from "../../../../../domain/models/add-meters-model";

export const addMetersState = atom({
  key: "addMetersState",
  default: {
    metersToAdd: [],
    currentValidatedMeter: null,
    validateMeterFailed: false,
  } as AddMetersModel,
});

export const meterCompaniesState = atom({
  key: "meterCompaniesState",
  default: {
    isLoading: true,
    isFormInvalid: false,
    companiesList: [] as CompanyModel[],
    name: "",
    nameError: "",
    companyNumber: "",
    companyNumberError: "",
    postalAddress: "",
    postalAddressError: "",
    phoneNumber: "",
    phoneNumberError: "",
    city: "",
    cityError: "",
    showAddVATForm: false,
    mainError: "",
  },
});

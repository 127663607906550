import { useEffect } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentAccountState } from "../../components";
import { useErrorHandler } from "../../hooks";
import { classNames } from "../../utils/helpers";
import { Buffer } from "buffer";

import { pendingApprovalsState } from "./atoms/atoms";
import Loading from "../../components/loading/loading";
import { GetPurchaseRequestsByStatus } from "../../../domain/usecases";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../main/firebase";
import { RemotePurchaseRequestModel } from "../../../data/models";
//require("dotenv").config();

const tabs = [
  {
    name: "Purchase Requests",
    href: "/approvals/purchase-requests",
    current: true,
  },
  { name: "Meters", href: "/approvals/meters", current: false },
  { name: "Cellphones", href: "/approvals/cellphones", current: false },
  { name: "Smartcards", href: "/approvals/smartcards", current: false },
];

const statusStyles: { [key: string]: string } = {
  orders: "bg-green-400 text-white",
  requests: "bg-blue-400 text-white",
};

type Props = {
  getPendingPurchaseRequests: GetPurchaseRequestsByStatus;
};

export default function Approvals({ getPendingPurchaseRequests }: Props) {
  const { getCurrentAccount } = useRecoilValue(currentAccountState);
  const [state, setState] = useRecoilState(pendingApprovalsState);

  console.log("log url env", process.env.REACT_APP_PORTAL_URL);
  useEffect(() => {
    const q = query(
      collection(db, "meter"),
      where("account", "==", getCurrentAccount().account),
      where("status", "==", "PENDING")
    );
    return onSnapshot(q, (querySnapshot) => {
      const pendingMeterApprovals = [];
      querySnapshot.forEach((doc) => {
        pendingMeterApprovals.push({
          id: doc.id,
          account: doc.data().account,
          createdBy: doc.data().createdBy,
          dateCreated: doc.data().dateCreated.toDate(),
          emailContact: doc.data().emailContact,
          isActive: doc.data().isActive,
          location: doc.data().location,
          meter_id: doc.data().meter_id,
          name: doc.data().name,
          number: doc.data().number,
          status: doc.data().status,
          company: doc.data().company,
          registeredOwner: doc.data().registeredOwner,
        });
      });

      setState((old) => ({
        ...old,
        pendingMeterApprovals: pendingMeterApprovals,
        metersChecked: true,
      }));
    });
  }, []);

  useEffect(() => {
    const q = query(
      collection(db, "cellphone"),
      where("account", "==", getCurrentAccount().account),
      where("status", "==", "PENDING")
    );
    return onSnapshot(q, (querySnapshot) => {
      const pendingCellphoneApprovals = [];
      querySnapshot.forEach((doc) => {
        pendingCellphoneApprovals.push({
          id: doc.id,
          account: doc.data().account,
          createdBy: doc.data().createdBy,
          dateCreated: doc.data().dateCreated.toDate(),
          emailContact: doc.data().emailContact,
          isActive: doc.data().isActive,
          cellphone_id: doc.data().cellphone_id,
          jobTitle: doc.data().jobTitle,
          network: doc.data().network,
          number: doc.data().number,
          status: doc.data().status,
          company: doc.data().company,
          cellphoneOwner: doc.data().cellphoneOwner,
        });
      });

      setState((old) => ({
        ...old,
        pendingCellphoneApprovals: pendingCellphoneApprovals,
        cellphonesChecked: true,
      }));
    });
  }, []);

  useEffect(() => {
    const q = query(
      collection(db, "purchaseRequests"),
      where("account", "==", getCurrentAccount().account),
      where("status", "==", "PENDING"),
      orderBy("dateUpdated")
    );
    return onSnapshot(q, (querySnapshot) => {
      const pendingPurchaseRequestApprovals =
        [] as RemotePurchaseRequestModel[];

      querySnapshot.forEach((doc) => {
        const item = doc.data();

        switch (item.purchaseType) {
          case "ELECTRICITY":
            pendingPurchaseRequestApprovals.push({
              account: item.account,
              id: item.id,
              purchaseRequestNo: item.purchaseRequestNo,
              purchaseRequestTotal: item.purchaseRequestTotal,
              purchaseType: item.purchaseType,
              requestId: item.requestId,
              createdBy: item.createdBy,
              createdByName: item.createdByName,
              dateTime: item.dateTime,
              ipAddress: item.ipAddress,
              purchaseRequestItems: item.purchaseItems.map((i) => {
                return {
                  account: i.account,
                  itemNumber: i.itemNumber,
                  itemType: i.itemType,
                  amount: i.amount,
                  owner: i.owner,
                  accountName: i.accountName,
                  location: i.location,
                  provider: i.provider,
                  name: i.name,
                  lastPurchaseDate: i.lastPurchaseDate,
                  lastPurchaseAmount: i.lastPurchaseAmount,
                };
              }),
              statusHistory: item.status_history.map((s) => {
                return {
                  actionedBy: s.actionedBy,
                  actionedByName: s.actionedByName,
                  ipAddress: s.ipAddress,
                  status: s.status,
                  dateTimeActioned: s.dateTimeActioned.toDate(),
                };
              }),
              status: item.status,
              dateUpdated: item.dateUpdated.toDate(),
              dateCreated: item.dateCreated.toDate(),
              currency: "BWP",
            });
            return;
          case "AIRTIME":
            pendingPurchaseRequestApprovals.push({
              account: item.account,
              id: item.id,
              purchaseRequestNo: item.purchaseRequestNo,
              purchaseRequestTotal: item.purchaseRequestTotal,
              purchaseType: item.purchaseType,
              requestId: item.requestId,
              createdBy: item.createdBy,
              createdByName: item.createdByName,
              dateTime: item.dateTime,
              ipAddress: item.ipAddress,
              purchaseRequestItems: item.purchaseItems.map((i) => {
                return {
                  account: i.account,
                  itemNumber: i.itemNumber,
                  itemType: i.itemType,
                  amount: i.amount,
                  owner: i.owner,
                  accountName: i.accountName,
                  jobTitle: i.jobTitle,
                  network: i.network,
                  provider: i.provider,
                  lastPurchaseDate: i.lastPurchaseDate,
                  lastPurchaseAmount: i.lastPurchaseAmount,
                };
              }),
              statusHistory: item.status_history.map((s) => {
                return {
                  actionedBy: s.actionedBy,
                  actionedByName: s.actionedByName,
                  ipAddress: s.ipAddress,
                  status: s.status,
                  dateTimeActioned: s.dateTimeActioned.toDate(),
                };
              }),
              status: item.status,
              dateUpdated: item.dateUpdated.toDate(),
              dateCreated: item.dateCreated.toDate(),
              currency: "BWP",
            });
            return;
          case "DSTV":
            pendingPurchaseRequestApprovals.push({
              account: item.account,
              id: item.id,
              purchaseRequestNo: item.purchaseRequestNo,
              purchaseRequestTotal: item.purchaseRequestTotal,
              purchaseType: item.purchaseType,
              requestId: item.requestId,
              createdBy: item.createdBy,
              createdByName: item.createdByName,
              dateTime: item.dateTime,
              ipAddress: item.ipAddress,
              purchaseRequestItems: item.purchaseItems.map((i) => {
                return {
                  account: i.account,
                  itemNumber: i.itemNumber,
                  itemType: i.itemType,
                  amount: i.amount,
                  owner: i.owner,
                  accountName: i.accountName,
                  location: i.location,
                  provider: i.provider,
                  name: i.name,
                  registeredOwner: i.registeredOwner,
                  lastPurchaseDate: i.lastPurchaseDate,
                  lastPurchaseAmount: i.lastPurchaseAmount,
                };
              }),
              statusHistory: item.status_history.map((s) => {
                return {
                  actionedBy: s.actionedBy,
                  actionedByName: s.actionedByName,
                  ipAddress: s.ipAddress,
                  status: s.status,
                  dateTimeActioned: s.dateTimeActioned.toDate(),
                };
              }),
              status: item.status,
              dateUpdated: item.dateUpdated.toDate(),
              dateCreated: item.dateCreated.toDate(),
              currency: "BWP",
            });
            return;
          default:
            return;
        }
      });

      setState((old) => ({
        ...old,
        pendingPurchaseRequests: pendingPurchaseRequestApprovals,
        requestsChecked: true,
      }));
    });
  }, []);

  useEffect(() => {
    const q = query(
      collection(db, "smartcard"),
      where("account", "==", getCurrentAccount().account),
      where("status", "==", "PENDING")
    );
    return onSnapshot(q, (querySnapshot) => {
      const pendingSmartcardApprovals = [];
      querySnapshot.forEach((doc) => {
        pendingSmartcardApprovals.push({
          id: doc.id,
          account: doc.data().account,
          createdBy: doc.data().createdBy,
          dateCreated: doc.data().dateCreated.toDate(),
          emailContact: doc.data().emailContact,
          isActive: doc.data().isActive,
          location: doc.data().location,
          meter_id: doc.data().meter_id,
          name: doc.data().name,
          number: doc.data().number,
          status: doc.data().status,
          company: doc.data().company,
          smartcardOwner: doc.data().smartcardOwner,
        });
      });

      setState((old) => ({
        ...old,
        pendingSmartcardApprovals: pendingSmartcardApprovals,
        smartcardsChecked: true,
      }));
    });
  }, []);

  useEffect(() => {
    const apiKey = getCurrentAccount().api_key;
    const encodedString = Buffer.from(apiKey).toString("base64");
    // const encodedString = getCurrentAccount().buffer;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Basic ${encodedString}`);

    var raw = "";

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };
    const baseUrl = process.env.REACT_APP_PORTAL_URL;
    console.log(
      "log url",
      `https://${baseUrl}.cloudfunctions.net/apikey-getMerchantBalance`
    );
    fetch(
      `https://${baseUrl}.cloudfunctions.net/apikey-getMerchantBalance`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);

        setState((old) => ({
          ...old,
          avaliableBalance: +data.balance,
        }));
      })
      .catch((error) => console.log("error", error));
  }, []);

  const tabBody = (tabName: string) => {
    switch (tabName) {
      case "Purchase Requests":
        return (
          <span
            className={classNames(
              statusStyles["orders"],
              " text-white w-1.5 h-1.5 rounded-full flex  justify-center items-center text-center p-3 text-xs font-medium capitalize"
            )}
          >
            {state.pendingPurchaseRequests.length}
          </span>
        );

      case "Meters":
        return (
          <span
            className={classNames(
              statusStyles["requests"],
              " text-white w-1.5 h-1.5 rounded-full flex  justify-center items-center text-center p-3 text-xs font-medium capitalize"
            )}
          >
            {state.pendingMeterApprovals.length}
          </span>
        );
      case "Cellphones":
        return (
          <span
            className={classNames(
              statusStyles["requests"],
              " text-white w-1.5 h-1.5 rounded-full flex  justify-center items-center text-center p-3 text-xs font-medium capitalize"
            )}
          >
            {state.pendingCellphoneApprovals.length}
          </span>
        );
      case "Smartcards":
        return (
          <span
            className={classNames(
              statusStyles["requests"],
              " text-white w-1.5 h-1.5 rounded-full flex  justify-center items-center text-center p-3 text-xs font-medium capitalize"
            )}
          >
            {state.pendingSmartcardApprovals.length}
          </span>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {state.requestsChecked && state.metersChecked && (
        <>
          <div
            className="fixed top-0 left-0 w-1/2 h-full bg-gray-100"
            aria-hidden="true"
          />
          <div
            className="fixed top-0 right-0 w-1/2 h-full bg-gray-100"
            aria-hidden="true"
          />
          <div className="relative flex flex-col flex-grow">
            {/* Navbar */}
            <div className="z-40 bg-blue-100 w-full">
              <div className="lg:max-w-7xl lg:mx-auto">
                <div className="py-4 md:flex md:items-center md:justify-between">
                  <div className="flex-1 min-w-0">
                    {/* Profile */}
                    <div className="flex items-center">
                      <h3 className="text-xl font-medium leading-6 text-gray-900 ">
                        <span>Approvals </span>
                      </h3>
                    </div>
                  </div>
                  <div className="mt-4 flex space-x-2 md:mt-0 md:ml-4">
                    <span className="text-xl leading-6 font-medium text-gray-900">
                      {getCurrentAccount().merchantName}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* 3 column wrapper */}
            <div className="flex-grow w-full max-w-7xl mx-auto lg:flex">
              {/* Left sidebar & main wrapper */}
              <div className="max-w-7xl w-full mx-auto pr-4 sm:pr-6 lg:pr-8 bg-gray-100">
                <div className="w-full mt-6">
                  <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                      Select a tab
                    </label>
                    <select
                      id="tabs"
                      name="tabs"
                      className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                      defaultValue={tabs.find((tab) => tab.current)!.name}
                    >
                      {tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        {tabs.map((tab) => (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "text-gray-900 border-b-2 px-4 border-gray-900 whitespace-nowrap font-medium"
                                : "border-transparent border-b-2 px-4 text-gray-400 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-2 px-0 border-b-2"
                            }
                            to={tab.href}
                            key={tab.name}
                            aria-current={tab.current ? "page" : undefined}
                          >
                            <>
                              <h2
                                id="meters-tab-title"
                                className="text-md leading-6 flex font-medium"
                              >
                                <span>{tab.name} </span> <div>&nbsp;</div>
                                {/* {tab.name === "Purchase Requests" ? (
                                  <span
                                    className={classNames(
                                      statusStyles["orders"],
                                      " text-white w-1.5 h-1.5 rounded-full flex  justify-center items-center text-center p-3 text-xs font-medium capitalize"
                                    )}
                                  >
                                    {state.pendingPurchaseRequests.length}
                                  </span>
                                ) : (
                                  <span
                                    className={classNames(
                                      statusStyles["requests"],
                                      " text-white w-1.5 h-1.5 rounded-full flex  justify-center items-center text-center p-3 text-xs font-medium capitalize"
                                    )}
                                  >
                                    {state.pendingMeterApprovals.length}
                                  </span>
                                )} */}
                                {tabBody(tab.name)}
                              </h2>
                            </>
                          </NavLink>
                        ))}
                      </nav>
                    </div>
                    <div className="mt-6">
                      <Outlet />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {((!state.requestsChecked && !state.metersChecked) ||
        state.isLoading) && <Loading />}

      {/* {state.isLoading && <Loading />} */}
    </>
  );
}

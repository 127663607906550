import { manageUserState } from "./atoms";
import { InputBase } from "../../../../components";

import { useRecoilState } from "recoil";
import React from "react";

type Props = {
  type: string;
  name: string;
  id?: string;
  className: string;
  value: string;
};

const Input: React.FC<Props> = ({
  type,
  id,
  name,
  className,
  value,
  ...props
}: Props) => {
  const [state, setState] = useRecoilState(manageUserState);

  console.log("state", state);

  return (
    <InputBase
      {...props}
      type={type}
      id={id}
      name={name}
      state={state}
      value={value}
      setState={setState}
      className={className}
    />
  );
};

export default Input;

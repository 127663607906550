import { RequiredFieldError } from "../../errors";
import { FieldValidation } from "../../protocols";

export class RequiredFieldValidation implements FieldValidation {
  constructor(readonly field: string) {}

  validate(input: any): Error | null {
    console.log("required validation", input);
    console.log("this.field", this.field);

    console.log("input[this.field].length", input[this.field].length);

    return input[this.field] ? null : new RequiredFieldError();
  }
}

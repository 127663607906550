import {
  ValidationComposite,
  ValidationBuilder as Builder,
} from "../../../../../validation/validators";

export const makeValidateAmendCellphoneDetailsValidation =
  (): ValidationComposite =>
    ValidationComposite.build([
      ...Builder.field("cellphoneNumber").required().min(8).max(8).build(),
      ...Builder.field("cellphoneOwner").required().build(),
      ...Builder.field("jobTitle").required().build(),
    ]);

import AddCellphoneDetails from "../../../../../presentation/pages/cellphones/add-cellphone/components/addCellphoneDetails";
import { makeRemoteValidateCellphone } from "../../../usecases/cellphone";

import { makeValidateAddCellphoneDetailsValidation } from "./validate-add-cellphone-details-validation-factory";

export const makeAddCellphoneDetails = () => {
  return (
    <AddCellphoneDetails
      validateCellphone={makeRemoteValidateCellphone()}
      validation={makeValidateAddCellphoneDetailsValidation()}
    />
  );
};

import { GetUsersState } from "../../../../../../../domain/usecases/get-user";
import EditUser from "../../../../../../../presentation/pages/users/manageUsers/editUser";
import { makeRemoteChangeUserDetails } from "../../../../../usecases";
import { makeChangeUserDetailsValidation } from "./edit-user-details-validation-factory";

export const makeChangeUserDetails = (user: GetUsersState.Model) => {
  return (
    <EditUser
      changeUserDetails={makeRemoteChangeUserDetails()}
      validation={makeChangeUserDetailsValidation()}
      user={user}
    />
  );
};

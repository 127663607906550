import { atom } from "recoil";
import { StepType } from "../../../../../types";
// import { AddSmartcardsModel } from "../../../../../domain/models/add-smartcards-model";
import { CompanyModel } from "../../../../../domain/models";

// export const addSmartcardsState = atom({
//   key: "addSmartcardsState",
//   default: {
//     smartcardsToAdd: [],
//     currentValidatedSmartcard: null,
//     validateSmartcardFailed: false,
//   } as AddSmartcardsModel,
// });

// export const companyNumbersState = atom({
//   key: "companyNumbersState",
//   default: { companyNumbers: [] as string[] },
// });

export const addSmartcardsStepsState = atom({
  key: "addSmartcardsStepsState",
  default: [
    {
      number: 1,
      name: "Add Smartcards",
      description: "Add smartcards to recharge.",
      href: "#",
      status: "current",
    },
    {
      number: 2,
      name: "Submit for approval",
      description: "Submit for approval.",
      href: "#",
      status: "upcoming",
    },
  ] as StepType[],
});

export const validateSmartcardState = atom({
  key: "validateSmartcardState",
  default: {
    isLoading: false,
    isFormInvalid: false,
    smartcardNumber: "",
    smartcardNumberError: "",
    mainError: "",
  },
});

export const smartcardCompaniesListState = atom({
  key: "smartcardCompaniesListState",
  default: {
    companiesList: [] as CompanyModel[],
  },
});

export const addSmartcardState = atom({
  key: "addSmartcardState",
  default: {
    smartcardsToAdd: [],
    currentValidatedSmartcard: null,
    validateSmartcardFailed: false,
    isLoading: false,
    isFormInvalid: true,
    smartcardNumber: "",
    smartcardNumberError: "",
    smartcardName: "",
    smartcardNameError: "",
    smartcardLocation: "",
    smartcardLocationError: "",
    company: "",
    companyError: "This is a required field",
    mainError: "",
  },
});

import { AddSmartcardStepsButton } from "../../../../../presentation/pages/smartcards/add-smartcard/components/stepsButton";
import { makeRemoteAddSmartcards } from "../../../usecases/smartcard";

export const makeAddSmartcardStepsButton = (props) => {
  return (
    <AddSmartcardStepsButton
      {...props}
      addSmartcards={makeRemoteAddSmartcards()}
    />
  );
};

// isEnabled={
//   this.smartcardsToAdd.smartcardsToAdd.length > 0 &&
//   smartcardsToAdd.currentValidatedSmartcard === null
// },

import { atom } from "recoil";
import { StepType } from "../../../../../types";
// import { AddMetersModel } from "../../../../../domain/models/add-meters-model";
import { CompanyModel } from "../../../../../domain/models";

// export const addMetersState = atom({
//   key: "addMetersState",
//   default: {
//     metersToAdd: [],
//     currentValidatedMeter: null,
//     validateMeterFailed: false,
//   } as AddMetersModel,
// });

export const companyNumbersState = atom({
  key: "companyNumbersState",
  default: { companyNumbers: [] as string[] },
});

export const addMetersStepsState = atom({
  key: "addMetersStepsState",
  default: [
    {
      number: 1,
      name: "Add Meters",
      description: "Add meters to recharge.",
      href: "#",
      status: "current",
    },
    {
      number: 2,
      name: "Submit for approval",
      description: "Submit for approval.",
      href: "#",
      status: "upcoming",
    },
  ] as StepType[],
});

export const validateMeterState = atom({
  key: "validateMeterState",
  default: {
    isLoading: false,
    isFormInvalid: false,
    meterNumber: "",
    meterNumberError: "",
    mainError: "",
  },
});

export const meterCompaniesListState = atom({
  key: "meterCompaniesListState",
  default: {
    companiesList: [] as CompanyModel[],
  },
});

export const addMeterState = atom({
  key: "addMeterState",
  default: {
    metersToAdd: [],
    currentValidatedMeter: null,
    validateMeterFailed: false,
    isLoading: false,
    isFormInvalid: true,
    meterNumber: "",
    meterNumberError: "",
    meterName: "",
    meterNameError: "",
    meterLocation: "",
    meterLocationError: "",
    company: "",
    companyNumber: "",
    companyNumberError: "This is a required field",
    mainError: "",
  },
});

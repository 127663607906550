import {
  query,
  collection,
  where,
  onSnapshot,
  orderBy,
  limit,
} from "firebase/firestore";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { Buffer } from "buffer";

import {
  ProductProvider,
  PurchaseOrderModel,
  PurchaseRequestModel,
  PurchasesOrderItem,
} from "../../../domain/usecases/get-account-balances";
import { db } from "../../../main/firebase";
import { currentAccountState } from "../../components";
import { useFirestore } from "../../hooks/use-firestore";
import { classNames, currencyFormatter } from "../../utils/helpers";
import { dashboardState } from "./atoms/atoms";
//require("dotenv").config();

const actions = [
  {
    name: "Purchase Request",
    href: "#",
    description: (
      <>
        <ul>
          <li>Create purchase requests</li>
        </ul>
      </>
    ),
    menus: [
      {
        name: "Electricity",
        href: "/purchase/electricity/initiate",
      },
      {
        name: "Airtime",
        href: "/purchase/airtime/initiate",
      },
      {
        name: "DSTV",
        href: "/purchase/dstv/initiate",
      },
    ],
  },
  {
    name: "Approvals",
    href: "/approvals/purchase-requests",
    description: (
      <ul>
        <li>Approve new applications</li>
      </ul>
    ),
    menus: null,
  },
  {
    name: "Manage",
    href: "#",
    description: (
      <ul>
        <li>Manage products and users.</li>
      </ul>
    ),
    menus: [
      {
        name: "Meters",
        href: "/meters/add/initiate",
      },
      {
        name: "Cellphones",
        href: "/cellphones/add/initiate",
      },
      {
        name: "DSTV",
        href: "/smartcards/add/initiate",
      },
      {
        name: "Users",
        href: "/users/manage",
      },
    ],
  },
  {
    name: "Reports",
    href: "/reports/itemised",
    description: "View transaction reports",
    menus: null,
  },
] as {
  name: string;
  href: string;
  description: string;
  menus:
    | {
        name: string;
        href: string;
      }[]
    | null;
}[];

const tabs = [
  {
    name: "Purchase Orders",
    href: "/dashboard/orders",
    current: true,
  },
  { name: "Purchase Requests", href: "/dashboard/requests", current: false },
];

const statusStyles: { [key: string]: string } = {
  orders: "bg-green-400 text-white",
  requests: "bg-blue-400 text-white",
};

export default function Dashboard() {
  const { getCurrentAccount } = useRecoilValue(currentAccountState);
  const firestore = useFirestore();
  const [state, setState] = useRecoilState(dashboardState);
  console.log("log url env", process.env.REACT_APP_PORTAL_URL);

  const [todayPurchases, setTodayPurchases] = useState({
    name: "Todays Purchase ",
    href: "#",
    type: "currency",
    amount: 0,
  });
  const [valueFailedPurchases, setValueFailedPurchases] = useState({
    name: "Value of Failed Purchases",
    href: "#",
    type: "currency",
    amount: 0,
  });
  const [valuePendingApprovals, setValuePendingApprovals] = useState({
    name: "Value of Pending Approvals",
    href: "#",
    type: "currency",
    amount: 0,
  });
  const [avaliableBalance] = useState({
    name: "Account Balance",
    href: "#",
    type: "currency",
    amount: state.avaliableBalance,
  });

  const todaysPurchases = (transactions: { amount: number }[]) => {
    const amount = transactions
      .map((p) => {
        return p.amount;
      })
      .reduce((acc, amount) => {
        return acc + +amount;
      }, 0);

    setTodayPurchases({
      ...todayPurchases,
      amount: amount,
    });
  };

  // const pendingApprovals = (transactions: PurchaseRequestModel[]) => {
  //   setPendingApprovals({
  //     ...valueFailedPurchases,
  //     amount: transactions.length,
  //   });
  // };

  const valueOfPurchaseRequests = (transactions: PurchaseRequestModel[]) => {
    const amount = transactions
      .filter((t) => t.status === "PENDING")
      .map((p) => {
        return p.amount;
      })
      .reduce((acc, amount) => {
        return acc + +amount;
      }, 0);

    setValuePendingApprovals({
      ...valuePendingApprovals,
      amount: amount,
    });
  };

  const valueOfFailedSales = (transactions: { amount: number }[]) => {
    const amount = transactions
      .map((p) => {
        return p.amount;
      })
      .reduce((acc, amount) => {
        return acc + +amount;
      }, 0);

    setValueFailedPurchases({
      ...valueFailedPurchases,
      amount: amount,
    });
  };

  // const firestore = useFirestore();
  const account = getCurrentAccount().account;

  useEffect(() => {
    const q = query(
      collection(db, "purchaseOrders"),
      where("account", "==", account),
      orderBy("dateCreated", "desc")
    );

    return onSnapshot(q, (querySnapshot) => {
      let transactions = querySnapshot.docs
        .sort(
          (a, b) =>
            b.data().dateUpdated.toDate() - a.data().dateUpdated.toDate()
        )
        .map((doc) => {
          return {
            id: doc.data().purchaseRequestNo,
            purchaseOrderReference: doc.data().purchaseOrderReference,
            purchaseOrderNo: doc.data().purchaseOrderNo,
            purchaseOrderType: doc.data().purchaseOrderType,
            initiator: doc
              .data()
              .status_history.find((s) => s.status === "PENDING")
              .actionedByName,
            approver: doc
              .data()
              .status_history.find((s) => s.status === "APPROVED")
              .actionedByName,
            amount: doc.data().purchaseRequestTotal,
            purchaseOrderId: doc.data().purchaseOrderId,
            currency: "BWP",
            status: doc.data().status,
            date: doc.data().dateUpdated.toDate(),
            items: [],
          } as PurchaseOrderModel;
        });

      // todaysPurchases(transactions);

      setState((old) => ({
        ...old,

        purchaseOrders: transactions,
      }));
    });
  }, []);

  useEffect(() => {
    const today = moment();

    const q = query(
      collection(db, "purchaseOrderLineItem"),
      where("account", "==", account),
      where("dateCreated", ">=", today.startOf("day").toDate()),
      where("dateCreated", "<=", today.endOf("day").toDate()),
      where("status", "==", "SUCCESSFUL")
    );

    return onSnapshot(q, (querySnapshot) => {
      let transactions = querySnapshot.docs.map((doc) => {
        console.log("doc.data()", doc.data());
        return {
          amount: doc.data().amount,
        } as { amount: number };
      });

      todaysPurchases(transactions);
    });
  }, []);

  useEffect(() => {
    const today = moment();

    const q = query(
      collection(db, "purchaseOrderLineItem"),
      where("account", "==", account),
      where("dateCreated", ">=", today.startOf("day").toDate()),
      where("dateCreated", "<=", today.endOf("day").toDate()),
      where("status", "==", "FAILED")
    );

    return onSnapshot(q, (querySnapshot) => {
      let transactions = querySnapshot.docs.map((doc) => {
        console.log("doc.data()", doc.data());
        return {
          amount: doc.data().amount,
        } as { amount: number };
      });

      valueOfFailedSales(transactions);
    });
  }, []);

  useEffect(() => {
    console.log("");
    const q = query(
      collection(db, "account"),
      where("accountNumber", "==", account)
    );

    return onSnapshot(q, (querySnapshot) => {
      const doc = querySnapshot.docs[0].data();
      const accountDetails = {
        name: "",
        city: "",
        phoneNumber: "",
        postalAddress: "",
      };
      accountDetails.name = doc.name;
      accountDetails.phoneNumber = doc.phoneNumber;
      accountDetails.postalAddress = doc.postalAddress;
      accountDetails.city = doc.city;

      setState((old) => ({
        ...old,

        accountDetails: accountDetails,
      }));
    });
  }, []);

  useEffect(() => {
    const apiKey = getCurrentAccount().api_key;
    // const buffer = getCurrentAccount().buffer;
    const encodedString = Buffer.from(apiKey).toString("base64");

    // const encodedString = getCurrentAccount().buffer;
    // console.log("encodedString", encodedString);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Basic ${encodedString}`);

    var raw = "";

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };
    const baseUrl = process.env.REACT_APP_PORTAL_URL;
    console.log(
      "log url",
      `https://${baseUrl}.cloudfunctions.net/apikey-getMerchantBalance`
    );
    fetch(
      `https://${baseUrl}.cloudfunctions.net/apikey-getMerchantBalance`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);

        setState((old) => ({
          ...old,
          avaliableBalance: +data.balance,
        }));
      })
      .catch((error) => console.log("error", error));
  }, []);

  useEffect(() => {
    const q = query(
      collection(db, "purchaseRequests"),
      where("account", "==", account),
      where("status", "in", ["PENDING", "REJECTED"])
    );

    return onSnapshot(q, (querySnapshot) => {
      let transactions = querySnapshot.docs
        .sort(
          (a, b) =>
            a.data().dateUpdated.toDate() - b.data().dateUpdated.toDate()
        )
        .map((doc) => {
          console.log("doc.data()", doc.data());
          return {
            id: doc.data().purchaseRequestNo,
            initiator: doc
              .data()
              .status_history.find((s) => s.status === "PENDING")
              .actionedByName,
            amount: doc.data().purchaseRequestTotal,
            currency: "BWP",
            purchaseType: doc.data().purchaseType,
            status: doc.data().status,
            date: doc.data().dateUpdated.toDate(),
            rejectionReason: doc
              .data()
              .status_history.find((s) => s.status === "REJECTED")
              ? doc.data().status_history.find((s) => s.status === "REJECTED")
                  .reason
              : null,
            items: doc.data().purchaseItems.map((doc) => {
              return {
                account: doc.account,
                merchant: doc.accountName,
                location: doc.location ? doc.location : null,
                name: doc.name ? doc.name : null,
                jobTitle: doc.jobTitle ? doc.jobTitle : null,
                network: doc.network ? doc.network : null,
                owner: doc.registeredOwner ? doc.registeredOwner : doc.owner,
                itemType: doc.itemType,
                number: doc.meterNumber,
                purchaseAmount: doc.amount,
                lastPurchaseDate: doc.lastPurchaseDate,
                lastPurchaseAmount: doc.lastPurchaseAmount,
              };
            }),
          } as PurchaseRequestModel;
        });

      // balances[1].amount = transactions.length;
      valueOfPurchaseRequests(transactions);
      // pendingApprovals(transactions);
      // setPendingApprovals({ amount: pendingApprovals(), ...valueFailedPurchases });
      setState((old) => ({
        ...old,
        purchaseRequests: transactions,
      }));
    });
  }, []);

  useEffect(() => {
    const q = query(collection(db, "providers"));

    return onSnapshot(q, (querySnapshot) => {
      let providers = querySnapshot.docs.map((doc) => {
        console.log("doc.data()", doc.data());
        return {
          acronym: doc.data().acronym,
          city: doc.data().city,
          name: doc.data().name,
          postalAddress: doc.data().postalAddress,
          product: doc.data().product,
          telephone: doc.data().telephone,
          companyNumber: doc.data().companyNumber,
          callCentre: doc.data().callCentre ? doc.data().callCentre : "",
          ussdCall: doc.data().ussdCall ? doc.data().ussdCall : "",
        } as ProductProvider;
      });

      setState((old) => ({
        ...old,
        providers: providers,
      }));
    });
  }, []);

  return (
    <>
      <div
        className="fixed top-0 left-0 w-1/2 h-full bg-gray-100"
        aria-hidden="true"
      />
      <div
        className="fixed top-0 right-0 w-1/2 h-full bg-gray-100"
        aria-hidden="true"
      />
      <div className="relative flex flex-col flex-grow">
        <div className="bg-blue-100 w-full">
          <div className=" lg:max-w-7xl lg:mx-auto">
            <div className="py-4 md:flex md:items-center md:justify-between">
              <div className="flex-1 min-w-0">
                <div className="flex items-center">
                  <h3 className="text-xl font-medium leading-6 text-gray-900 ">
                    <span>Dashboard </span>
                  </h3>
                </div>
              </div>
              <div className="mt-4 flex space-x-2 md:mt-0 md:ml-4">
                <span className="text-xl leading-6 font-medium text-gray-900">
                  {getCurrentAccount().merchantName}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-grow w-full  max-w-7xl mx-auto lg:flex">
          <div className="max-w-7xl w-full mx-auto pr-4 sm:pr-6 lg:pr-8 bg-gray-100">
            <div className="mt-6">
              <div className="w-full">
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                  Balances
                </h2>
                <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                  <div>
                    <div className="pt-1">
                      <div className="flex items-center">
                        <div className="w-0 flex-1">
                          <dl>
                            <dt className="text-sm font-normal text-gray-500 truncate">
                              {todayPurchases.name}
                            </dt>
                            <dd>
                              <div className="lg:text-xl font-medium text-gray-900">
                                {console.log(
                                  "currencyFormatter(+todayPurchases.amount)",
                                  currencyFormatter(+todayPurchases.amount)
                                )}
                                {todayPurchases.type === "currency"
                                  ? currencyFormatter(+todayPurchases.amount)
                                  : +todayPurchases.amount}
                              </div>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="pt-1">
                      <div className="flex items-center">
                        <div className="w-0 flex-1">
                          <dl>
                            <dt className="text-sm font-normal text-gray-500 truncate">
                              {valueFailedPurchases.name}
                            </dt>
                            <dd>
                              <div className="lg:text-xl font-medium text-gray-900">
                                {valueFailedPurchases.type === "currency"
                                  ? currencyFormatter(
                                      +valueFailedPurchases.amount
                                    )
                                  : +valueFailedPurchases.amount}
                              </div>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="pt-1">
                      <div className="flex items-center">
                        <div className="w-0 flex-1">
                          <dl>
                            <dt className="text-sm font-normal text-gray-500 truncate">
                              {valuePendingApprovals.name}
                            </dt>
                            <dd>
                              <div className="lg:text-xl font-medium text-gray-900">
                                {valuePendingApprovals.type === "currency"
                                  ? currencyFormatter(
                                      +valuePendingApprovals.amount
                                    )
                                  : +valuePendingApprovals.amount}
                              </div>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="pt-1">
                      <div className="flex items-center">
                        <div className="w-0 flex-1">
                          <dl>
                            <dt className="text-sm font-normal text-gray-500 truncate">
                              {avaliableBalance.name}
                            </dt>
                            <dd>
                              <div className="lg:text-xl font-medium text-gray-900">
                                {avaliableBalance.type === "currency"
                                  ? currencyFormatter(+state.avaliableBalance)
                                  : +state.avaliableBalance}
                              </div>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="py-8">
                  {/* <Transactions /> */}

                  <div className="w-full mt-6">
                    <div className="sm:hidden">
                      <label htmlFor="tabs" className="sr-only">
                        Select a tab
                      </label>
                      <select
                        id="tabs"
                        name="tabs"
                        className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                        defaultValue={tabs.find((tab) => tab.current)!.name}
                      >
                        {tabs.map((tab) => (
                          <option key={tab.name}>{tab.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="hidden sm:block">
                      <div className="border-b border-gray-200">
                        <nav
                          className="-mb-px flex space-x-8"
                          aria-label="Tabs"
                        >
                          {tabs.map((tab) => (
                            // <NavLink
                            //   className={({ isActive }) =>
                            //     isActive
                            //       ? "text-gray-900 border-b-2 px-4 border-gray-900 whitespace-nowrap font-medium"
                            //       : "border-transparent border-b-2 px-4 text-gray-400 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-2 px-0 border-b-2"
                            //   }
                            //   to={tab.href}
                            //   key={tab.name}
                            //   aria-current={tab.current ? "page" : undefined}
                            // >
                            //   <>
                            //     <h2
                            //       id="meters-tab-title"
                            //       className="text-md leading-6  font-medium"
                            //     >
                            //       {tab.name}-
                            //     </h2>
                            //   </>
                            // </NavLink>

                            <NavLink
                              className={({ isActive }) =>
                                isActive
                                  ? "text-gray-900 border-b-2 px-4 border-gray-900 whitespace-nowrap font-medium"
                                  : "border-transparent border-b-2 px-4 text-gray-400 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-2 px-0 border-b-2"
                              }
                              end
                              to={tab.href}
                              key={tab.name}
                              aria-current={tab.current ? "page" : undefined}
                            >
                              <>
                                <h2
                                  id="meters-tab-title"
                                  className="text-md leading-6 flex font-medium"
                                >
                                  <span>{tab.name} </span> <div>&nbsp;</div>
                                  {tab.name === "Purchase Orders" ? (
                                    <span
                                      className={classNames(
                                        statusStyles["orders"],
                                        " text-white w-1.5 h-1.5 rounded-full flex  justify-center items-center text-center p-3 text-xs font-medium capitalize"
                                      )}
                                    >
                                      {state.purchaseOrders.length}
                                    </span>
                                  ) : (
                                    <span
                                      className={classNames(
                                        statusStyles["requests"],
                                        " text-white w-1.5 h-1.5 rounded-full flex  justify-center items-center text-center p-3 text-xs font-medium capitalize"
                                      )}
                                    >
                                      {state.purchaseRequests.length}
                                    </span>
                                  )}
                                </h2>
                              </>
                            </NavLink>
                          ))}
                        </nav>
                      </div>
                      <div className="mt-6">
                        <Outlet />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-100 pr-4 sm:pr-6 lg:pr-8 lg:flex-shrink-0 lg:border-l lg:border-gray-200 xl:pr-0">
            <div className="pl-6 lg:w-80">
              <div className="pt-6 pb-2">
                <section aria-labelledby="quick-links-title">
                  <div className="rounded-lg bg-gray-100 overflow-hidden ">
                    <h2
                      className="text-base font-medium text-gray-900"
                      id="quick-links-title"
                    >
                      Quick links
                    </h2>

                    {actions.map((action, actionIdx) =>
                      action.menus ? (
                        <div
                          key={action.name}
                          className={classNames(
                            "relative mt-2 group bg-white p-4 mb-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500"
                          )}
                        >
                          <div>
                            <h3 className="text-md font-medium">
                              <span
                                className="absolute inset-0 text-gray-700"
                                aria-hidden="true"
                              />
                              {action.name}
                            </h3>
                          </div>
                          <div className="mt-2">
                            <p className="mt-2 text-sm text-gray-500">
                              {action.description}
                            </p>
                          </div>

                          <div className="mt-2 flex-shrink-0 flex items-start space-x-4">
                            {action.menus.map((menu) => (
                              <Link
                                to={menu.href}
                                className="z-10 font-medium text-purple-600 hover:text-purple-500 focus:outline-none"
                              >
                                {menu.name}
                              </Link>
                            ))}

                            {/* <span className="text-gray-300" aria-hidden="true">
                              |
                            </span>
                            <Link
                              to={action.menus[1].href}
                              className="z-10 font-medium text-purple-600 hover:text-purple-500 focus:outline-none"
                            >
                              {action.menus[1].name}
                            </Link> */}
                          </div>

                          <span
                            className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                            aria-hidden="true"
                          >
                            <svg
                              className="h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                            </svg>
                          </span>
                        </div>
                      ) : (
                        <div
                          key={action.name}
                          className={classNames(
                            "relative mt-2 group bg-white p-4 mb-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500"
                          )}
                        >
                          <div>
                            <h3 className="text-md font-medium">
                              <Link
                                to={action.href}
                                className="focus:outline-none"
                              >
                                <span
                                  className="absolute inset-0 text-gray-700"
                                  aria-hidden="true"
                                />
                                {action.name}
                              </Link>
                            </h3>
                          </div>
                          <div className="mt-2">
                            <p className="mt-2 text-sm text-gray-500">
                              {action.description}
                            </p>
                          </div>

                          <span
                            className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                            aria-hidden="true"
                          >
                            <svg
                              className="h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                            </svg>
                          </span>
                        </div>
                      )
                    )}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

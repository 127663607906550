import { NavLink, Outlet } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { currentAccountState } from "../../presentation/components";

const tabs = [
  {
    name: "Manage Users",
    description: "Managed approved BPC meters.",
    href: "/users/manage",
    current: false,
  },
  {
    name: "Add New User",
    description: "Add new BPC meters for and submit for approval.",
    href: "/users/create/initiate",
    current: true,
  },
];

export default function ManageUsersLayout() {
  const { getCurrentAccount } = useRecoilValue(currentAccountState);

  return (
    <>
      <div className="relative flex flex-col flex-grow">
        <div className="bg-blue-100 w-full">
          <div className="lg:max-w-7xl lg:mx-auto">
            <div className="py-4 md:flex md:items-center md:justify-between">
              <div className="flex-1 min-w-0">
                {/* Profile */}
                <div className="flex items-center">
                  <h3 className="text-lg font-medium leading-6 text-gray-900 ">
                    <span>Users</span>
                  </h3>
                </div>
              </div>
              <div className="mt-4 flex space-x-2 md:mt-0 md:ml-4">
                <span className="text-xl leading-6 font-medium text-gray-900">
                  {getCurrentAccount().merchantName}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-grow w-full max-w-7xl mx-auto lg:flex lg:flex-col items-start overflow-hidden">
          <div className=" w-full lg:max-w-7xl lg:mx-auto py-6 lg:col-span-6">
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                defaultValue={tabs.find((tab) => tab.current)?.name}
              >
                {tabs.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <div className="border-b border-gray-200 ">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "text-gray-900 border-b-2 px-4 border-gray-900 whitespace-nowrap font-medium"
                          : "border-transparent border-b-2 px-4 text-gray-400 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-2 px-0 border-b-2"
                      }
                      to={tab.href}
                      key={tab.name}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      <>
                        <h2
                          id="applicant-information-title"
                          className="text-md leading-6 font-medium"
                        >
                          {tab.name}
                        </h2>
                      </>
                    </NavLink>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <Outlet />
        </div>
      </div>
    </>
  );
}

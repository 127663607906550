import { makeAddCompanyForm } from "../../../../main/factories/pages/meters/company/add-company-form-factory";
import CompaniesList from "./components/companiesList";

export default function ManageCompany() {
  // const state = useRecoilValue(companiesState);
  return (
    <>
      <div className="w-full mt-10 sm:mt-0">
        <div className="w-full md:grid md:grid-cols-4 md:gap-6">
          <div className="col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Company Details
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                List of Companies to be assigned meters.
              </p>
            </div>
          </div>
          <div className="col-span-3">
            <div className="sm:col-span-12">{makeAddCompanyForm()}</div>
          </div>
        </div>
        <div className="col-span-6">
          <CompaniesList />
        </div>
      </div>
    </>
  );
}

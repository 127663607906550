import { createUserState } from "./atoms";
import { FormStatusBase } from "../../../../components";

import { useRecoilValue } from "recoil";
import React from "react";

const FormStatus: React.FC = () => {
  const state = useRecoilValue(createUserState);
  return <FormStatusBase state={state} />;
};

export default FormStatus;

import {
  CheckIcon,
  ChevronRightIcon,
  SelectorIcon,
} from "@heroicons/react/outline";
import { format } from "date-fns";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { Fragment, useEffect, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  ProductProvider,
  PurchaseOrderModel,
} from "../../../../domain/usecases/get-account-balances";
import { productTypeListState, reportsState } from "../atoms/atoms";
import TransactionRows from "./transactionRows";

import { db } from "../../../../main/firebase";
import { currentAccountState } from "../../../components";
import { classNames, currencyFormatter } from "../../../utils/helpers";
import Loading from "../../../components/loading/loading";
import { PurchaseRequestCellphone } from "../../purchaseRequest/airtime/types";
import { Listbox, Transition } from "@headlessui/react";

export default function PurchaseOrders() {
  const purchaseOrders = useRecoilValue(reportsState).purchaseOrders;
  const { getCurrentAccount } = useRecoilValue(currentAccountState);
  const [state, setState] = useRecoilState(reportsState);
  const [referenceFilter, setReferenceFilter] = useState(null);
  const [purchaseNumberFilter, setPurchaseNumberFilter] = useState(null);

  const purchaseOrderTypes = useRecoilValue(productTypeListState);

  const [selectedPurchaseOrderType, setSelectedPurchaseOrderType] = useState(
    purchaseOrderTypes[0]
  );
  const [purchaseOrderReference, setPurchaseOrderReference] = useState("");
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState("");

  const account = getCurrentAccount().account;

  useEffect(() => {
    setState((old) => ({
      ...old,
      isFiltered: false,
      isLoading: true,
    }));

    const q = query(
      collection(db, "purchaseOrders"),
      where("account", "==", account),
      where("dateCreated", ">=", state.startDate.startOf("day").toDate()),
      where("dateCreated", "<=", state.endDate.endOf("day").toDate())
    );

    return onSnapshot(q, (querySnapshot) => {
      let transactions = querySnapshot.docs
        .sort(
          (a, b) =>
            a.data().dateUpdated.toDate() - b.data().dateUpdated.toDate()
        )
        .map((doc) => {
          return {
            id: doc.data().id,
            purchaseOrderReference: doc.data().purchaseOrderReference,
            purchaseOrderNo: doc.data().purchaseOrderNo,
            purchaseOrderType: doc.data().purchaseOrderType,
            initiator: doc
              .data()
              .status_history.find((s) => s.status === "PENDING")
              .actionedByName,
            approver: doc
              .data()
              .status_history.find((s) => s.status === "APPROVED")
              .actionedByName,
            amount: doc.data().purchaseRequestTotal,
            purchaseOrderId: doc.data().purchaseOrderId,
            currency: "BWP",
            status: doc.data().status,
            date: doc.data().dateUpdated.toDate(),
            items: [],
          } as PurchaseOrderModel;
        });

      // todaysPurchases(transactions);
      console.log("transactions", transactions);

      setState((old) => ({
        ...old,
        isLoading: false,
        purchaseOrders: transactions,
      }));
    });
  }, [state.startDate, state.endDate]);

  useEffect(() => {
    const q = query(
      collection(db, "account"),
      where("accountNumber", "==", account)
    );

    return onSnapshot(q, (querySnapshot) => {
      const doc = querySnapshot.docs[0].data();
      const accountDetails = {
        name: "",
        city: "",
        phoneNumber: "",
        postalAddress: "",
      };
      accountDetails.name = doc.name;
      accountDetails.phoneNumber = doc.phoneNumber;
      accountDetails.postalAddress = doc.postalAddress;
      accountDetails.city = doc.city;

      setState((old) => ({
        ...old,
        isLoading: false,
        accountDetails: accountDetails,
      }));
    });
  }, []);

  useEffect(() => {
    const q = query(collection(db, "providers"));

    return onSnapshot(q, (querySnapshot) => {
      let providers = querySnapshot.docs.map((doc) => {
        return {
          acronym: doc.data().acronym,
          city: doc.data().city,
          name: doc.data().name,
          postalAddress: doc.data().postalAddress,
          product: doc.data().product,
          telephone: doc.data().telephone,
          companyNumber: doc.data().companyNumber,
        } as ProductProvider;
      });

      setState((old) => ({
        ...old,
        providers: providers,
      }));
    });
  }, []);

  const handlePurchaseOrderTypeChange = (type: string) => {
    setSelectedPurchaseOrderType(type);

    const purchaseOrderType =
      type.toLowerCase() === "none" ? "Not Registered" : type;

    const isPurchaseTypesFiltered =
      purchaseOrderType.toLowerCase() !== "all purchase types";
    const isPurchaseOrderReferenceFiltered =
      purchaseOrderReference.toLowerCase() !== "";
    const isPurchaseNumberFiltered = purchaseOrderNumber.toLowerCase() !== "";

    let filtered: PurchaseOrderModel[];

    console.log("isPurchaseTypesFiltered", isPurchaseTypesFiltered);
    console.log(
      "isPurchaseOrderReferenceFiltered",
      isPurchaseOrderReferenceFiltered
    );
    console.log("isPurchaseNumberFiltered", isPurchaseNumberFiltered);

    // purchaseOrderReference: string;
    // purchaseOrderNumber: string;

    if (
      isPurchaseNumberFiltered &&
      !isPurchaseTypesFiltered &&
      !isPurchaseOrderReferenceFiltered
    ) {
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return purchaseOrder.purchaseOrderNo
          .toLowerCase()
          .includes(purchaseOrderNumber.toLowerCase());
      });
    } else if (
      isPurchaseNumberFiltered &&
      isPurchaseTypesFiltered &&
      !isPurchaseOrderReferenceFiltered
    ) {
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderNo
            .toLowerCase()
            .includes(purchaseOrderNumber.toLowerCase()) &&
          purchaseOrder.purchaseOrderType.toLowerCase() ===
            purchaseOrderType.toLowerCase()
        );
      });
    } else if (
      isPurchaseNumberFiltered &&
      isPurchaseTypesFiltered &&
      !isPurchaseOrderReferenceFiltered
    ) {
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderNo
            .toLowerCase()
            .includes(purchaseOrderNumber.toLowerCase()) &&
          purchaseOrder.purchaseOrderType.toLowerCase() ===
            purchaseOrderType.toLowerCase()
        );
      });
    } else if (
      isPurchaseNumberFiltered &&
      isPurchaseTypesFiltered &&
      isPurchaseOrderReferenceFiltered
    ) {
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderNo
            .toLowerCase()
            .includes(purchaseOrderNumber.toLowerCase()) &&
          purchaseOrder.purchaseOrderType.toLowerCase() ===
            purchaseOrderType.toLowerCase() &&
          purchaseOrder.purchaseOrderReference
            .toLowerCase()
            .includes(purchaseOrderReference.toLowerCase())
        );
      });
    } else if (
      !isPurchaseNumberFiltered &&
      isPurchaseTypesFiltered &&
      isPurchaseOrderReferenceFiltered
    ) {
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderType.toLowerCase() ===
            purchaseOrderType.toLowerCase() &&
          purchaseOrder.purchaseOrderReference
            .toLowerCase()
            .includes(purchaseOrderReference.toLowerCase())
        );
      });
    } else if (
      !isPurchaseNumberFiltered &&
      !isPurchaseTypesFiltered &&
      isPurchaseOrderReferenceFiltered
    ) {
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return purchaseOrder.purchaseOrderReference
          .toLowerCase()
          .includes(purchaseOrderReference.toLowerCase());
      });
    } else if (
      !isPurchaseNumberFiltered &&
      !isPurchaseTypesFiltered &&
      isPurchaseOrderReferenceFiltered
    ) {
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return purchaseOrder.purchaseOrderReference
          .toLowerCase()
          .includes(purchaseOrderReference.toLowerCase());
      });
    } else if (
      !isPurchaseNumberFiltered &&
      isPurchaseTypesFiltered &&
      !isPurchaseOrderReferenceFiltered
    ) {
      console.log("purchaseOrderType", purchaseOrderType.toLowerCase());
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderType.toLowerCase() ===
          purchaseOrderType.toLowerCase()
        );
      });
    } else if (
      !isPurchaseNumberFiltered &&
      isPurchaseTypesFiltered &&
      isPurchaseOrderReferenceFiltered
    ) {
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderType.toLowerCase() ===
            purchaseOrderType.toLowerCase() &&
          purchaseOrder.purchaseOrderReference
            .toLowerCase()
            .includes(purchaseOrderReference.toLowerCase())
        );
      });
    } else if (
      !isPurchaseNumberFiltered &&
      isPurchaseTypesFiltered &&
      !isPurchaseOrderReferenceFiltered
    ) {
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderType.toLowerCase() ===
          purchaseOrderType.toLowerCase()
        );
      });
    } else if (
      isPurchaseNumberFiltered &&
      !isPurchaseTypesFiltered &&
      isPurchaseOrderReferenceFiltered
    ) {
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderNo
            .toLowerCase()
            .includes(purchaseOrderNumber.toLowerCase()) &&
          purchaseOrder.purchaseOrderReference
            .toLowerCase()
            .includes(purchaseOrderReference.toLowerCase())
        );
      });
    } else if (
      isPurchaseNumberFiltered &&
      !isPurchaseTypesFiltered &&
      !isPurchaseNumberFiltered
    ) {
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return purchaseOrder.purchaseOrderNo
          .toLowerCase()
          .includes(purchaseOrderNumber.toLowerCase());
      });
    } else if (
      isPurchaseNumberFiltered &&
      !isPurchaseTypesFiltered &&
      isPurchaseOrderReferenceFiltered
    ) {
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderNo
            .toLowerCase()
            .includes(purchaseOrderNumber.toLowerCase()) &&
          purchaseOrder.purchaseOrderReference
            .toLowerCase()
            .includes(purchaseOrderReference.toLowerCase())
        );
      });
    } else if (
      isPurchaseNumberFiltered &&
      isPurchaseTypesFiltered &&
      isPurchaseOrderReferenceFiltered
    ) {
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderNo
            .toLowerCase()
            .includes(purchaseOrderNumber.toLowerCase()) &&
          purchaseOrder.purchaseOrderReference
            .toLowerCase()
            .includes(purchaseOrderReference.toLowerCase()) &&
          purchaseOrder.purchaseOrderType.toLowerCase() ===
            purchaseOrderType.toLowerCase()
        );
      });
    } else {
      filtered = [];
    }

    console.log("filtered", filtered);

    let updatetypeState = {
      ...state,
      filteredPurchaseOrders: filtered,
      isFiltered:
        isPurchaseTypesFiltered ||
        isPurchaseOrderReferenceFiltered ||
        isPurchaseNumberFiltered
          ? true
          : false,
    };

    setState(updatetypeState);
  };

  const handlePurchaseOrderNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    const keyword = e.target.value.toLowerCase();
    const purchaseOrderType = selectedPurchaseOrderType;

    const isPurchaseTypesFiltered =
      purchaseOrderType.toLowerCase() !== "all purchase types";
    const isPurchaseOrderReferenceFiltered =
      purchaseOrderReference.toLowerCase() !== "";

    const isKeyword = keyword.length > 0 ? true : false;

    let filtered: PurchaseOrderModel[];

    console.log("isPurchaseTypesFiltered", isPurchaseTypesFiltered);
    console.log(
      "isPurchaseOrderReferenceFiltered",
      isPurchaseOrderReferenceFiltered
    );
    console.log("isKeyword", isKeyword);

    if (
      isKeyword &&
      !isPurchaseTypesFiltered &&
      !isPurchaseOrderReferenceFiltered
    ) {
      setPurchaseOrderNumber(keyword);
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return purchaseOrder.purchaseOrderNo.toLowerCase().includes(keyword);
      });
    } else if (
      isKeyword &&
      isPurchaseTypesFiltered &&
      !isPurchaseOrderReferenceFiltered
    ) {
      setPurchaseOrderNumber(keyword);
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderNo.toLowerCase().includes(keyword) &&
          purchaseOrder.purchaseOrderType.toLowerCase() ===
            purchaseOrderType.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      isPurchaseTypesFiltered &&
      !isPurchaseOrderReferenceFiltered
    ) {
      setPurchaseOrderNumber(keyword);
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderNo.toLowerCase().includes(keyword) &&
          purchaseOrder.purchaseOrderType.toLowerCase() ===
            purchaseOrderType.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      isPurchaseTypesFiltered &&
      isPurchaseOrderReferenceFiltered
    ) {
      setPurchaseOrderNumber(keyword);
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderNo.toLowerCase().includes(keyword) &&
          purchaseOrder.purchaseOrderType.toLowerCase() ===
            purchaseOrderType.toLowerCase() &&
          purchaseOrder.purchaseOrderReference
            .toLowerCase()
            .includes(purchaseOrderReference.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      isPurchaseTypesFiltered &&
      isPurchaseOrderReferenceFiltered
    ) {
      setPurchaseOrderNumber("");
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderType.toLowerCase() ===
            purchaseOrderType.toLowerCase() &&
          purchaseOrder.purchaseOrderReference
            .toLowerCase()
            .includes(purchaseOrderReference.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      !isPurchaseTypesFiltered &&
      isPurchaseOrderReferenceFiltered
    ) {
      setPurchaseOrderNumber("");
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return purchaseOrder.purchaseOrderReference
          .toLowerCase()
          .includes(purchaseOrderReference.toLowerCase());
      });
    } else if (
      !isKeyword &&
      !isPurchaseTypesFiltered &&
      isPurchaseOrderReferenceFiltered
    ) {
      setPurchaseOrderNumber("");
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return purchaseOrder.purchaseOrderReference
          .toLowerCase()
          .includes(purchaseOrderReference.toLowerCase());
      });
    } else if (
      !isKeyword &&
      isPurchaseTypesFiltered &&
      !isPurchaseOrderReferenceFiltered
    ) {
      setPurchaseOrderNumber("");
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderType.toLowerCase() ===
          purchaseOrderType.toLowerCase()
        );
      });
    } else if (
      !isKeyword &&
      isPurchaseTypesFiltered &&
      isPurchaseOrderReferenceFiltered
    ) {
      setPurchaseOrderNumber("");
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderType.toLowerCase() ===
            purchaseOrderType.toLowerCase() &&
          purchaseOrder.purchaseOrderReference
            .toLowerCase()
            .includes(purchaseOrderReference.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      isPurchaseTypesFiltered &&
      !isPurchaseOrderReferenceFiltered
    ) {
      setPurchaseOrderNumber("");
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderType.toLowerCase() ===
          purchaseOrderType.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      !isPurchaseTypesFiltered &&
      isPurchaseOrderReferenceFiltered
    ) {
      setPurchaseOrderNumber(keyword);
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderNo.toLowerCase().includes(keyword) &&
          purchaseOrder.purchaseOrderReference
            .toLowerCase()
            .includes(purchaseOrderReference.toLowerCase())
        );
      });
    } else if (
      isKeyword &&
      !isPurchaseTypesFiltered &&
      !isPurchaseOrderReferenceFiltered
    ) {
      setPurchaseOrderNumber(keyword);
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return purchaseOrder.purchaseOrderNo.toLowerCase().includes(keyword);
      });
    } else if (
      isKeyword &&
      !isPurchaseTypesFiltered &&
      isPurchaseOrderReferenceFiltered
    ) {
      setPurchaseOrderNumber(keyword);
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderNo.toLowerCase().includes(keyword) &&
          purchaseOrder.purchaseOrderReference
            .toLowerCase()
            .includes(purchaseOrderReference.toLowerCase())
        );
      });
    } else if (
      isKeyword &&
      isPurchaseTypesFiltered &&
      isPurchaseOrderReferenceFiltered
    ) {
      setPurchaseOrderNumber(keyword);
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderNo.toLowerCase().includes(keyword) &&
          purchaseOrder.purchaseOrderReference
            .toLowerCase()
            .includes(purchaseOrderReference.toLowerCase()) &&
          purchaseOrder.purchaseOrderType.toLowerCase() ===
            purchaseOrderType.toLowerCase()
        );
      });
    } else {
      setPurchaseOrderNumber("");
      filtered = [];
    }

    console.log("filtered", filtered);
    console.log("isKeyword", isKeyword);
    console.log("isPurchaseTypesFiltered", isPurchaseTypesFiltered);
    console.log(
      "isPurchaseOrderReferenceFiltered",
      isPurchaseOrderReferenceFiltered
    );

    let updateCellphonesState = {
      ...state,
      filteredPurchaseOrders: filtered.length > 0 ? filtered : [],
      isFiltered:
        isPurchaseTypesFiltered || isPurchaseOrderReferenceFiltered || isKeyword
          ? true
          : false,
    };

    setState(updateCellphonesState);
  };

  const handlePurchaseOrderReferenceChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();

    const keyword = e.target.value.toLowerCase();
    const purchaseOrderType = selectedPurchaseOrderType;

    const isPurchaseTypesFiltered =
      purchaseOrderType.toLowerCase() !== "all purchase types";
    const isPurchaseNumberFiltered = purchaseOrderNumber.toLowerCase() !== "";

    const isKeyword = keyword.length > 0 ? true : false;

    let filtered: PurchaseOrderModel[];

    console.log("isPurchaseTypesFiltered", isPurchaseTypesFiltered);
    console.log("isPurchaseNumberFiltered", isPurchaseNumberFiltered);
    console.log(
      "purchaseOrderNumber.toLowerCase().length",
      purchaseOrderNumber.toLowerCase().length
    );
    console.log("purchaseOrderNumber", purchaseOrderNumber);
    console.log("isKeyword", isKeyword);

    if (isKeyword && !isPurchaseTypesFiltered && !isPurchaseNumberFiltered) {
      setPurchaseOrderReference(keyword);
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return purchaseOrder.purchaseOrderReference
          .toLowerCase()
          .includes(keyword);
      });
    } else if (
      isKeyword &&
      isPurchaseTypesFiltered &&
      !isPurchaseNumberFiltered
    ) {
      setPurchaseOrderReference(keyword);
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderReference
            .toLowerCase()
            .includes(keyword) &&
          purchaseOrder.purchaseOrderType.toLowerCase() ===
            purchaseOrderType.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      isPurchaseTypesFiltered &&
      !isPurchaseNumberFiltered
    ) {
      setPurchaseOrderReference(keyword);
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderReference
            .toLowerCase()
            .includes(keyword) &&
          purchaseOrder.purchaseOrderType.toLowerCase() ===
            purchaseOrderType.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      isPurchaseTypesFiltered &&
      isPurchaseNumberFiltered
    ) {
      setPurchaseOrderReference(keyword);
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderReference
            .toLowerCase()
            .includes(keyword) &&
          purchaseOrder.purchaseOrderType.toLowerCase() ===
            purchaseOrderType.toLowerCase() &&
          purchaseOrder.purchaseOrderNo
            .toLowerCase()
            .includes(purchaseOrderNumber.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      isPurchaseTypesFiltered &&
      isPurchaseNumberFiltered
    ) {
      setPurchaseOrderReference(keyword);
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderType.toLowerCase() ===
            purchaseOrderType.toLowerCase() &&
          purchaseOrder.purchaseOrderNo
            .toLowerCase()
            .includes(purchaseOrderNumber.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      !isPurchaseTypesFiltered &&
      isPurchaseNumberFiltered
    ) {
      setPurchaseOrderReference(keyword);
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return purchaseOrder.purchaseOrderNo
          .toLowerCase()
          .includes(purchaseOrderNumber.toLowerCase());
      });
    } else if (
      !isKeyword &&
      !isPurchaseTypesFiltered &&
      isPurchaseNumberFiltered
    ) {
      setPurchaseOrderReference(keyword);
      console.log("keyword", keyword);
      console.log(
        "purchaseOrderNumber.toLowerCase()",
        purchaseOrderNumber.toLowerCase()
      );
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return purchaseOrder.purchaseOrderNo
          .toLowerCase()
          .includes(purchaseOrderNumber.toLowerCase());
      });
    } else if (
      !isKeyword &&
      isPurchaseTypesFiltered &&
      !isPurchaseNumberFiltered
    ) {
      setPurchaseOrderReference(keyword);
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderType.toLowerCase() ===
          purchaseOrderType.toLowerCase()
        );
      });
    } else if (
      !isKeyword &&
      isPurchaseTypesFiltered &&
      isPurchaseNumberFiltered
    ) {
      setPurchaseOrderReference(keyword);
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderType.toLowerCase() ===
            purchaseOrderType.toLowerCase() &&
          purchaseOrder.purchaseOrderNo
            .toLowerCase()
            .includes(purchaseOrderNumber.toLowerCase())
        );
      });
    } else if (
      !isKeyword &&
      isPurchaseTypesFiltered &&
      !isPurchaseNumberFiltered
    ) {
      setPurchaseOrderReference(keyword);
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderType.toLowerCase() ===
          purchaseOrderType.toLowerCase()
        );
      });
    } else if (
      isKeyword &&
      !isPurchaseTypesFiltered &&
      isPurchaseNumberFiltered
    ) {
      setPurchaseOrderReference(keyword);
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderReference
            .toLowerCase()
            .includes(keyword) &&
          purchaseOrder.purchaseOrderNo
            .toLowerCase()
            .includes(purchaseOrderNumber.toLowerCase())
        );
      });
    } else if (
      isKeyword &&
      !isPurchaseTypesFiltered &&
      !isPurchaseNumberFiltered
    ) {
      setPurchaseOrderReference(keyword);
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return purchaseOrder.purchaseOrderReference
          .toLowerCase()
          .includes(keyword);
      });
    } else if (
      isKeyword &&
      !isPurchaseTypesFiltered &&
      isPurchaseNumberFiltered
    ) {
      setPurchaseOrderReference(keyword);
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderReference
            .toLowerCase()
            .includes(keyword) &&
          purchaseOrder.purchaseOrderNo
            .toLowerCase()
            .includes(purchaseOrderNumber.toLowerCase())
        );
      });
    } else if (
      isKeyword &&
      isPurchaseTypesFiltered &&
      isPurchaseNumberFiltered
    ) {
      setPurchaseOrderReference(keyword);
      filtered = state.purchaseOrders.filter((purchaseOrder) => {
        return (
          purchaseOrder.purchaseOrderReference
            .toLowerCase()
            .includes(keyword) &&
          purchaseOrder.purchaseOrderNo
            .toLowerCase()
            .includes(purchaseOrderNumber.toLowerCase()) &&
          purchaseOrder.purchaseOrderType.toLowerCase() ===
            purchaseOrderType.toLowerCase()
        );
      });
    } else {
      setPurchaseOrderReference("");
      filtered = [];
    }

    console.log("filtered", filtered);
    console.log("isPurchaseTypesFiltered", isPurchaseTypesFiltered);
    console.log("isPurchaseNumberFiltered", isPurchaseNumberFiltered);
    console.log("isKeyword", isKeyword);
    console.log("keyword", keyword);

    let updateCellphonesState = {
      ...state,
      filteredPurchaseOrders: filtered.length > 0 ? filtered : [],
      isFiltered:
        isPurchaseTypesFiltered || isPurchaseNumberFiltered || isKeyword
          ? true
          : false,
    };

    setState(updateCellphonesState);
  };

  const purchasesList = state.isFiltered
    ? state.filteredPurchaseOrders.length > 0
      ? state.filteredPurchaseOrders
      : []
    : state.purchaseOrders;

  console.log("purchasesList", purchasesList);

  const transactions =
    purchasesList &&
    purchasesList.map((transaction, index) => {
      return (
        <TransactionRows
          key={transaction.id}
          index={index}
          transaction={transaction}
        />
      );
    });

  return (
    <>
      {transactions && (
        <div className="w-full lg:max-w-7xl bg-white overflow-hidden shadow rounded-sm">
          <div className="px-6 py-4">
            <div className="mb-0">
              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                <div className="sm:col-span-1 my-auto align-middle">
                  <h2 className="mx-auto text-lg leading-6 font-medium text-gray-900">
                    Filter:
                  </h2>
                </div>

                <div className="sm:col-span-2">
                  <Listbox
                    value={selectedPurchaseOrderType}
                    onChange={handlePurchaseOrderTypeChange}
                  >
                    {({ open }) => (
                      <>
                        {/* <Listbox.Label className="block text-xs font-normal text-gray-400">
                          Company
                        </Listbox.Label> */}
                        <div className="relative">
                          <Listbox.Button className="bg-gray-50 relative w-full border-b border-gray-300 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                            <span className="block truncate">
                              {selectedPurchaseOrderType}
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <SelectorIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60  py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                              {purchaseOrderTypes.map((type, index) => (
                                <Listbox.Option
                                  key={index}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? "text-white bg-blue-600"
                                        : "text-gray-900",
                                      "cursor-default select-none relative py-2 pl-3 pr-9"
                                    )
                                  }
                                  value={type}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )}
                                      >
                                        {type}
                                      </span>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? "text-white"
                                              : "text-blue-600",
                                            "absolute inset-y-0 right-0 flex items-center pr-4"
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>

                <div className="sm:col-span-2">
                  <div className=" border-b border-gray-300 focus-within:border-blue-600">
                    <input
                      onChange={(e) => handlePurchaseOrderReferenceChange(e)}
                      type="text"
                      name="name"
                      id="name"
                      className="block w-full border-0 py-2 border-b border-transparent bg-gray-50 focus:border-blue-600 focus:ring-0 sm:text-sm"
                      placeholder="Order Reference"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <div className=" border-b border-gray-300 focus-within:border-blue-600">
                    <input
                      onChange={(e) => handlePurchaseOrderNumberChange(e)}
                      type="text"
                      name="name"
                      id="name"
                      className="block w-full border-0  py-2 border-b border-transparent bg-gray-50 focus:border-blue-600 focus:ring-0 sm:text-sm"
                      placeholder="Purchase Number"
                    />
                  </div>
                </div>

                <div className="lg:col-span-7 text-right">
                  {/* <ItemisedAmountTotal transactions={itemisedPurchasesList} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="px-6 pb-6">
            <div className="shadow sm:hidden">
              <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                {purchaseOrders.map((transaction) => (
                  <li key={transaction.id}>
                    <a
                      href={transaction.id}
                      className="block px-4 py-4 bg-white hover:bg-gray-50"
                    >
                      <span className="flex items-center space-x-4">
                        <span className="flex-1 flex space-x-2 truncate">
                          <span className="flex flex-col text-gray-500 text-sm truncate">
                            <span className="truncate">{transaction.id}</span>
                            <span>
                              <span className="text-gray-900 font-medium">
                                {currencyFormatter(transaction.amount)}
                              </span>
                            </span>
                            <div>
                              {format(transaction.date, "yyyy-MM-dd HH:mm:ss")}
                            </div>
                          </span>
                        </span>
                        <ChevronRightIcon
                          className="flex-shrink-0 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </a>
                  </li>
                ))}
              </ul>

              <nav
                className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
                aria-label="Pagination"
              >
                <div className="flex-1 flex justify-between">
                  <a
                    href="/"
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                  >
                    Previous
                  </a>
                  <a
                    href="/"
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                  >
                    Next
                  </a>
                </div>
              </nav>
            </div>

            {/* Activity table (small breakpoint and up) */}
            <div className="hidden  sm:block">
              <div className="max-w-7xl mx-auto">
                <div className="flex flex-col mt-2">
                  <div className="align-middle min-w-full flex-grow overflow-x-auto overflow-hidden ">
                    {transactions.length > 0 ? (
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr>
                            <th className="pr-6 py-2  text-left text-sm font-medium text-gray-500  tracking-wider">
                              Date
                            </th>
                            <th className="px-6 py-2 text-left text-sm font-medium text-gray-500  tracking-wider">
                              Order Ref.
                            </th>
                            <th className="px-6 py-2 text-left text-sm font-medium text-gray-500  tracking-wider">
                              Purchase Number
                            </th>
                            <th className="px-6 py-2  text-left text-sm font-medium text-gray-500  tracking-wider">
                              Type
                            </th>
                            <th className="px-6 py-2  text-left text-sm font-medium text-gray-500  tracking-wider">
                              Approver
                            </th>
                            <th className="px-6 py-2 text-right text-sm font-medium text-gray-500  tracking-wider">
                              Amount
                            </th>
                            <th className="hidden px-6 py-2  text-left text-sm font-medium text-gray-500  tracking-wider md:block">
                              Status
                            </th>
                            <th className="px-2 py-2  text-left text-sm font-medium text-gray-500  tracking-wider"></th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-100">
                          {transactions}
                        </tbody>
                      </table>
                    ) : (
                      <h2 className="text-md leading-6 font-medium text-gray-900">
                        There no transactions for selected parameters.
                      </h2>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {state.isLoading && <Loading />}
    </>
  );
}

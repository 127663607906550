import axios, { AxiosResponse } from "axios";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
} from "../../../data/protocols/http";

export class AxiosHttpClient implements HttpClient {
  async request(data: HttpRequest): Promise<HttpResponse> {
    let axiosResponse: AxiosResponse;
    try {
      axiosResponse = await axios.request({
        url: data.url,
        method: data.method,
        data: data.body,
        headers: data.headers,
      });
    } catch (error: any) {
      console.log("axiosResponse", error);
      // axiosResponse = error.response;
      if (error.response) axiosResponse = error.response;

      console.log("error.response", error.response);

      if (error === "Network Error")
        return {
          statusCode: 502,
          body: error,
        };
    }

    console.log("statusCode: axiosResponse.status", axiosResponse);
    return {
      statusCode: axiosResponse.status,
      body: axiosResponse.data,
    };
  }
}

import ConfirmPurchaseRequest from "../../../../presentation/pages/purchaseRequest/dstv/confirmPurchaseRequest";
import { makeRemoteCreateDSTVPurchaseRequest } from "../../usecases/purchase-requests";

export const makeCreateDSTVPurchaseRequest = () => {
  return (
    <ConfirmPurchaseRequest
      createDSTVPurchaseRequest={makeRemoteCreateDSTVPurchaseRequest()}
    />
  );
};

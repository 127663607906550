import { db } from "../../main/firebase";
import {
  arrayUnion,
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getAuth, updatePassword, updateProfile } from "firebase/auth";
import { useRecoilValue } from "recoil";
import { currentAccountState } from "../components";
import { PurchasesOrderItem } from "../../domain/usecases/get-account-balances";
// import { getAuth, updateProfile } from "firebase/auth";

export const useFirestore = () => {
  const { getCurrentAccount } = useRecoilValue(currentAccountState);
  const auth = getAuth();

  const updateCurrentUserPassword = async (newPassword: string) => {
    const user = auth.currentUser;

    return updatePassword(user, newPassword)
      .then(() => {
        console.log("password updated");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const approveMeterApplication = async (id: string) => {
    const meterRef = doc(db, "meter", id);

    await updateDoc(meterRef, {
      status: "Active",
      isActive: true,
      approvedBy: getCurrentAccount().displayName,
      dateUpdated: new Date(),
    });
  };

  const rejectMeterApplication = async (
    id: string,
    rejectionReason: string
  ) => {
    const meterRef = doc(db, "meter", id);

    await updateDoc(meterRef, {
      isActive: false,
      status: "Rejected",
      rejectedBy: getCurrentAccount().displayName,
      rejectionReason: rejectionReason,
      dateUpdated: new Date(),
    });
  };

  const deactivateMeter = async (id: string, disabledReason: string) => {
    const meterRef = doc(db, "meter", id);

    await updateDoc(meterRef, {
      isActive: false,
      status: "Disabled",
      disabledBy: getCurrentAccount().displayName,
      disabledReason: disabledReason,
      dateUpdated: new Date(),
    });
  };

  const activateMeter = async (id: string) => {
    const meterRef = doc(db, "meter", id);

    await updateDoc(meterRef, {
      isActive: true,
      status: "Active",
      dateUpdated: new Date(),
    });
  };

  const setMeterForApproval = async (
    id: string,
    rejectionReversalReason: string
  ) => {
    const meterRef = doc(db, "meter", id);

    await updateDoc(meterRef, {
      isActive: false,
      status: "PENDING",
      rejectionReversalBy: getCurrentAccount().displayName,
      rejectionReversalReason: rejectionReversalReason,
      dateUpdated: new Date(),
    });
  };

  const approveCellphoneApplication = async (id: string) => {
    const cellphoneRef = doc(db, "cellphone", id);

    await updateDoc(cellphoneRef, {
      status: "Active",
      isActive: true,
      approvedBy: getCurrentAccount().displayName,
      dateUpdated: new Date(),
    });
  };

  const rejectCellphoneApplication = async (
    id: string,
    rejectionReason: string
  ) => {
    const cellphoneRef = doc(db, "cellphone", id);

    await updateDoc(cellphoneRef, {
      isActive: false,
      status: "Rejected",
      rejectedBy: getCurrentAccount().displayName,
      rejectionReason: rejectionReason,
      dateUpdated: new Date(),
    });
  };

  const deactivateCellphone = async (id: string, disabledReason: string) => {
    const cellphoneRef = doc(db, "cellphone", id);

    await updateDoc(cellphoneRef, {
      isActive: false,
      status: "Disabled",
      disabledBy: getCurrentAccount().displayName,
      disabledReason: disabledReason,
      dateUpdated: new Date(),
    });
  };

  const activateCellphone = async (id: string) => {
    const cellphoneRef = doc(db, "cellphone", id);

    await updateDoc(cellphoneRef, {
      isActive: true,
      status: "Active",
      dateUpdated: new Date(),
    });
  };

  const setCellphoneForApproval = async (
    id: string,
    rejectionReversalReason: string
  ) => {
    const cellphoneRef = doc(db, "cellphone", id);

    await updateDoc(cellphoneRef, {
      isActive: false,
      status: "PENDING",
      rejectionReversalBy: getCurrentAccount().displayName,
      rejectionReversalReason: rejectionReversalReason,
      dateUpdated: new Date(),
    });
  };

  const approveSmartcardApplication = async (id: string) => {
    const smartcardRef = doc(db, "smartcard", id);

    await updateDoc(smartcardRef, {
      status: "Active",
      isActive: true,
      approvedBy: getCurrentAccount().displayName,
      dateUpdated: new Date(),
    });
  };

  const rejectSmartcardApplication = async (
    id: string,
    rejectionReason: string
  ) => {
    const smartcardRef = doc(db, "smartcard", id);

    await updateDoc(smartcardRef, {
      isActive: false,
      status: "Rejected",
      rejectedBy: getCurrentAccount().displayName,
      rejectionReason: rejectionReason,
      dateUpdated: new Date(),
    });
  };

  const deactivateSmartcard = async (id: string, disabledReason: string) => {
    const smartcardRef = doc(db, "smartcard", id);

    await updateDoc(smartcardRef, {
      isActive: false,
      status: "Disabled",
      disabledBy: getCurrentAccount().displayName,
      disabledReason: disabledReason,
      dateUpdated: new Date(),
    });
  };

  const activateSmartcard = async (id: string) => {
    const smartcardRef = doc(db, "smartcard", id);

    await updateDoc(smartcardRef, {
      isActive: true,
      status: "Active",
      dateUpdated: new Date(),
    });
  };

  const setSmartcardForApproval = async (
    id: string,
    rejectionReversalReason: string
  ) => {
    const smartcardRef = doc(db, "smartcard", id);

    await updateDoc(smartcardRef, {
      isActive: false,
      status: "PENDING",
      rejectionReversalBy: getCurrentAccount().displayName,
      rejectionReversalReason: rejectionReversalReason,
      dateUpdated: new Date(),
    });
  };

  const changeUserNames = async (
    id: string,
    firstName: string,
    lastName: string
  ) => {
    console.log("changeUserNames ", id, firstName, lastName);
    const userRef = doc(db, "users", id);

    await updateDoc(userRef, {
      firstName: firstName,
      lastName: lastName,
      displayName: `${firstName} ${lastName}`,
      approvedBy: getCurrentAccount().displayName,
      dateUpdated: new Date(),
    });
  };

  const editUserDetails = async (
    id: string,
    user: {
      firstName: string;
      lastName: string;
      emailAddress: string;
      role: string;
      contactNumber: string;
    }
  ) => {
    const userRef = doc(db, "users", id);

    const auth = getAuth();
    await updateProfile(auth.currentUser, {
      displayName: `${user.firstName} ${user.lastName}`,
    })
      .then(async () => {
        await updateDoc(userRef, {
          firstName: user.firstName,
          lastName: user.lastName,
          displayName: `${user.firstName} ${user.lastName}`,
          role: user.role,
          email: user.emailAddress,
          contactNumber: user.contactNumber,
          updatedBy: getCurrentAccount().displayName,
          dateUpdated: new Date(),
        });
      })
      .catch((error) => {
        // An error occurred
        // ...
      });
  };

  const rejectPurchaseRequest = async (
    id: string,
    rejection: {
      actionedBy: string;
      actionedByName: string;
      ipAddress: string;
      status: string;
      reason: string;
      dateTimeActioned: string | Date;
    }
  ) => {
    const purchaseRequestRef = doc(db, "purchaseRequests", id);

    await updateDoc(purchaseRequestRef, {
      isActive: false,
      status: "REJECTED",
      dateUpdated: new Date(),
      status_history: arrayUnion(rejection),
    });
  };

  const disableUser = async (
    id: string,
    disableReason: string,
    disabledBy: string
  ) => {
    const meterRef = doc(db, "users", id);

    await updateDoc(meterRef, {
      isEnabled: false,
      disableReason: disableReason,
      updatedBy: disabledBy,
      dateUpdated: new Date(),
    });
  };

  const enableUser = async (id: string, enabledBy: string) => {
    const meterRef = doc(db, "users", id);

    await updateDoc(meterRef, {
      isEnabled: true,
      updatedBy: enabledBy,
      dateUpdated: new Date(),
    });
  };

  const fetchPurchaseRequests = async (account: string) => {
    const q = query(
      collection(db, "purchaseRequests"),
      where("account", "==", account),
      where("status", "!=", "APPROVED")
    );

    return onSnapshot(q, (querySnapshot) => {
      return querySnapshot.docs.map((doc) => {
        return {
          id: doc.data().id,
          initiator: doc.data().createdByName,
          amount: doc.data().purchaseRequestTotal,
          currency: "BWP",
          status: doc.data().status,
          date: doc.data().dateUpdated.toDate(),
          items: doc.data().purchaseItems.map((doc) => {
            return {
              account: doc.account,
              merchant: doc.accountName,
              location: doc.location,
              name: doc.name,
              number: doc.meterNumber,
              amount: doc.amount,
              lastPurchaseDate: doc.lastPurchaseDate,
              lastPurchaseAmount: doc.lastPurchaseAmount,
            };
          }),
        };
      });
    });
  };

  const fetchPurchaseOrders = async (account: string) => {
    const q = query(
      collection(db, "purchaseOrders"),
      where("account", "==", account),
      orderBy("dateCreated", "desc")
    );

    return onSnapshot(q, (querySnapshot) => {
      return querySnapshot.docs.map((doc) => {
        return {
          id: doc.data().id,
          initiator: doc.data().createdByName,
          amount: doc.data().purchaseRequestTotal,
          currency: "BWP",
          status: doc.data().status,
          date: doc.data().dateUpdated.toDate(),
          items: doc.data().purchaseOrderLineItems.map((doc) => {
            return {
              account: doc.account,
              merchant: doc.accountName,
              location: doc.location,
              name: doc.name,
              number: doc.meterNumber,
              amount: doc.amount,
              lastPurchaseDate: doc.lastPurchaseDate,
              lastPurchaseAmount: doc.lastPurchaseAmount,
            };
          }),
        };
      });
    });
  };

  const fetchMerchantPurchaseOrdersItems = (purchaseOrderNo: string) => {
    const q = query(
      collection(db, "purchaseOrderLineItem"),
      where("purchaseOrderNo", "==", purchaseOrderNo)
    );

    return onSnapshot(q, (querySnapshot) => {
      return querySnapshot.docs.map((d) => {
        let doc = d.data();

        return {
          account: doc.account,
          status: doc.status,
          merchant: doc.accountName,
          location: doc.location ? doc.location : null,
          name: doc.name ? doc.name : null,
          company: doc.company,
          owner: doc.registeredOwner ? doc.registeredOwner : doc.owner,
          jobTitle: doc.jobTitle ? doc.jobTitle : null,
          network: doc.network ? doc.network : null,
          number: doc.meterNumber,
          amount: doc.amount,
          itemType: doc.itemType,
          response: doc.response,
          dateUpdated: doc.dateUpdated.toDate(),
          dateCreated: doc.dateUpdated.toDate(),
          lastPurchaseDate: doc.lastPurchaseDate,
          lastPurchaseAmount: doc.lastPurchaseAmount,
        } as PurchasesOrderItem;
      });
    });
  };

  const checkIfOrdersReferenceExists = async (
    account: number,
    orderReference: string
  ) => {
    console.log("checkIfOrdersReferenceExists", account, orderReference);

    const q = query(
      collection(db, "purchaseOrders"),
      where("account", "==", account),
      where("purchaseOrderReference", "==", orderReference.toUpperCase())
    );

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log("checkIfOrdersReferenceExists", false);
      return false;
    } else {
      console.log("checkIfOrdersReferenceExists!!!", true);

      return true;
    }
    // const q = query(collection(db, "cities"), where("capital", "==", true));

    // querySnapshot.forEach((doc) => {
    //   // doc.data() is never undefined for query doc snapshots
    //   console.log(doc.id, " => ", doc.data());
    // });

    //     return (q, (querySnapshot) => {

    //     });
  };

  const checkEmail = async (email: string) => {
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return false;
    } else {
      return true;
    }
  };

  return {
    approveMeterApplication,
    rejectMeterApplication,
    deactivateMeter,
    activateMeter,
    setMeterForApproval,
    approveCellphoneApplication,
    rejectCellphoneApplication,
    deactivateCellphone,
    activateCellphone,
    setCellphoneForApproval,
    approveSmartcardApplication,
    rejectSmartcardApplication,
    deactivateSmartcard,
    activateSmartcard,
    setSmartcardForApproval,
    fetchPurchaseRequests,
    fetchPurchaseOrders,
    changeUserNames,
    rejectPurchaseRequest,
    checkIfOrdersReferenceExists,
    disableUser,
    enableUser,
    editUserDetails,
    updateCurrentUserPassword,
    checkEmail,
    fetchMerchantPurchaseOrdersItems,
  };
};

import { UnexpectedError } from "../../../domain/errors";
import { ApprovePurchaseRequest } from "../../../domain/usecases";
import { HttpClient, HttpStatusCode } from "../../protocols/http";

export class RemoteApprovePurchaseRequest implements ApprovePurchaseRequest {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<void>
  ) {}

  async approve(params: ApprovePurchaseRequest.Params): Promise<void> {
    console.log("ApprovePurchaseRequest", params);
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "post",
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body!;
      case HttpStatusCode.conflict:
        return httpResponse.body!;
      default:
        throw new UnexpectedError();
    }
  }
}

// export namespace RemoteApprovePurchaseRequest {
//   export type Model = ApprovePurchaseRequest.Model;
// }

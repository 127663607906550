import { CheckCircleIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";

export default function ResetCompleted() {
  return (
    <div className=" bg-white shadow shadow-sm sm:rounded-sm  py-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={logo} alt="PrepaidPlus" />
        {/* 
        <h2 className="mt-6 text-center text-xl font-semibold text-gray-900">
          PrepaidPlus Password Reset
        </h2> */}
      </div>
      <div className="max-w-3xl mx-auto grid grid-cols-1 gap-6  lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2">
        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
          {/* Description list*/}
          <section aria-labelledby="applicant-information-title">
            <div className="bg-white px-4 py-4 ">
              <div className="px-4 pb-5 mt-8 flex w-full text-center">
                {/* <h2
                  id="applicant-information-title"
                  className="text-lg leading-6 font-medium text-green-500"
                >
                  Password reset sent to your email.
                </h2>
                <CheckCircleIcon
                  className="hidden flex-shrink-0 ml-1 h-6 w-6 text-green-500 lg:block"
                  aria-hidden="true"
                /> */}

                <h2
                  id="applicant-information-title"
                  className=" text-xl leading-6 font-medium text-green-500"
                >
                  Password reset sent to your email.
                </h2>
                <CheckCircleIcon
                  className="hidden flex-shrink-0 ml-1 h-6 w-6 text-green-500 lg:block"
                  aria-hidden="true"
                />
              </div>

              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <div className=" pb-5">
                  <h2
                    id="applicant-information-title"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Next Steps
                  </h2>
                </div>
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-700">
                      1. Go to your email and reset your password.
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900"></dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-700">
                      2.
                      <Link to="/auth/login">
                        Click here to return to Login Screen
                      </Link>
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900"></dd>
                  </div>
                </dl>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

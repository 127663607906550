import { atom } from "recoil";
import {
  GetAccountState,
  ProductProvider,
  PurchaseOrderModel,
  PurchaseRequestModel,
} from "../../../../domain/usecases/get-account-balances";
// import { DashboardListType } from "../../../../types/dashboadListType";
// import { pendingPurchaseRequestDasboardState } from "../../approvals/atoms/atoms";

// export const dasboardState = selector({
//   key: "dasboardState",
//   get: ({ get }) => {
//     return {
//       items: get(pendingPurchaseRequestDasboardState),
//     } as DashboardListType;
//   },
// });

export const dashboardState = atom({
  key: "dashboardState",
  default: {
    purchases: 0,
    valueFailedPurchases: 0,
    valuePendingApprovals: 0,
    avaliableBalance: 0,
    providers: [] as ProductProvider[],
    purchaseRequests: [] as PurchaseRequestModel[],
    purchaseOrders: [] as PurchaseOrderModel[],
    accountDetails: {
      phoneNumber: "",
      postalAddress: "string",
      city: "string",
    },
  } as GetAccountState.Model,
});

import { EmailInUseError, UnexpectedError } from "../../../domain/errors";
import { UserAlreadyExistsError } from "../../../domain/errors/user-already-exists-error";
import { CreateUser } from "../../../domain/usecases";
import { HttpClient, HttpStatusCode } from "../../protocols/http";

export class RemoteAddAccount implements CreateUser {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<void>
  ) {}

  async create(params: CreateUser.Params): Promise<void> {
    console.log("createuser", params);
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "post",
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body!;
      case HttpStatusCode.forbidden:
        throw new EmailInUseError();
      case HttpStatusCode.conflict:
        throw new UserAlreadyExistsError();
      default:
        throw new UnexpectedError();
    }
  }
}

// export namespace RemoteAddAccount {
//   export type Model = CreateUser.Model;
// }

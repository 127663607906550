/* eslint-disable react-hooks/exhaustive-deps */
import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon, CheckIcon } from "@heroicons/react/outline";
import { Fragment, useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState, useRecoilValue } from "recoil";
import { ValidateCellphone } from "../../../../../domain/usecases";
import { currentAccountState } from "../../../../components";
import { Validation } from "../../../../protocols";

import { classNames } from "../../../../utils/helpers";
import {
  addCellphoneFormState,
  addCellphoneState,
  // addCellphoneState,
  cellphoneCompaniesListState,
  networkListState,
} from "../atoms/atoms";
import Input from "./input";

type Props = {
  validateCellphone: ValidateCellphone;
  validation: Validation;
};

export default function AddCellphoneDetails({
  validateCellphone,
  validation,
}: Props) {
  // const resetAddCellphoneFormState = useResetRecoilState(addCellphoneFormState);
  const resetFormState = useResetRecoilState(addCellphoneFormState);
  const resetState = useResetRecoilState(addCellphoneState);
  const [formState, setFormState] = useRecoilState(addCellphoneFormState);
  const [state, setState] = useRecoilState(addCellphoneState);

  const { getCurrentAccount } = useRecoilValue(currentAccountState);
  const companiesList = useRecoilValue(
    cellphoneCompaniesListState
  ).companiesList;
  const networkList = useRecoilValue(networkListState).networkList;

  useEffect(() => resetFormState(), []);
  useEffect(() => resetState(), []);
  // useEffect(() => validate("cellphoneOwner"), []);
  useEffect(() => validate("cellphoneNumber"), [formState.cellphoneNumber]);
  useEffect(() => validate("cellphoneOwner"), [formState.cellphoneOwner]);
  useEffect(() => validate("jobTitle"), [formState.jobTitle]);
  useEffect(() => validate("network"), [formState.network]);
  useEffect(() => validate("company"), [formState.company]);

  const validate = (field: string): void => {
    const { cellphoneNumber, cellphoneOwner, jobTitle, network, company } =
      formState;

    const formData = {
      cellphoneNumber,
      cellphoneOwner,
      jobTitle,
      network,
      company,
    };
    setFormState((old) => ({
      ...old,
      [`${field}Error`]: validation.validate(field, formData),
    }));

    setFormState((old) => ({
      ...old,
      isFormInvalid:
        !!old.cellphoneNumberError ||
        !!old.cellphoneOwnerError ||
        !!old.jobTitleError ||
        !!old.networkError ||
        !!old.companyError,
    }));
  };

  const [showError, setShowError] = useState(false);

  const handleAddCellphoneDetailsSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    try {
      console.log("formState.isFormInvalid", formState.isFormInvalid);

      if (formState.isLoading || formState.isFormInvalid) {
        setShowError(true);
        return;
      }

      setFormState((old) => ({
        ...old,
        isLoading: true,
      }));

      const isCellphoneInAddList = state.cellphonesToAdd.find(
        (c) => c.cellphoneNumber === formState.cellphoneNumber
      );

      if (isCellphoneInAddList) {
        setState((old) => ({
          ...old,
          mainError: "The cellphone is already in the list.",
        }));

        setFormState((old) => ({
          ...old,
          isLoading: false,
        }));

        return;
      }

      const isCellphoneAlreadyRegistered = await validateCellphone.validate({
        account: getCurrentAccount().account,
        cellphoneNumber: formState.cellphoneNumber,
      });

      if (isCellphoneAlreadyRegistered.response) {
        // formState.mainError();
        setState((old) => ({
          ...old,
          mainError: "The cellphone is already registered.",
        }));

        setFormState((old) => ({
          ...old,
          isLoading: false,
        }));

        return;
      }

      const item = {
        account: getCurrentAccount().account,
        createdBy: getCurrentAccount().email,
        createdByName: getCurrentAccount().displayName,
        dateCreated: new Date(),
        dateUpdated: new Date(),
        emailContact: getCurrentAccount().email,
        isActive: false,
        cellphoneNumber: formState.cellphoneNumber,
        jobTitle: formState.jobTitle,
        cellphoneStatus: "PENDING",
        ipAddress: "41.18.20.78",
        cellphoneOwner: formState.cellphoneOwner,
        company: formState.company,
        companyNumber: formState.companyNumber,
        network: formState.network,
      };

      let newCellphonesArray = [...state.cellphonesToAdd];

      // if (
      //   newCellphonesArray.filter(
      //     (c) => c.cellphoneNumber === formState.cellphoneNumber
      //   ).length > 0
      // ) {
      //   setShowError(true);
      //   return;
      // }

      if (item !== null) {
        newCellphonesArray = [...newCellphonesArray, item];
      }

      // resetAddCellphoneFormState();

      setState((old) => ({
        ...old,
        cellphonesToAdd: newCellphonesArray,
        mainError: "",
        showForm: false,
      }));

      setFormState((old) => ({
        ...old,
        isLoading: false,
      }));

      setShowError(false);
    } catch (error) {
      setFormState((old) => ({
        ...old,
        isLoading: false,
        mainError: error.message,
      }));
    }

    // resetCellphoneState();
    // return item ? item : null;
  };

  const handleAddCellphoneDetailsCancel = (e: React.SyntheticEvent) => {
    e.preventDefault();

    resetFormState();
    setShowError(false);

    setState((old) => ({
      ...old,
      showForm: false,
    }));
  };

  const handleCompanyChange = (value: string) => {
    // event.preventDefault();
    let company, companyNumber: string;

    // if (value === "Not Registered") {
    //   setSelectedCompany("None");
    //   company = "None";
    // } else {
    //   company = companiesList.find((c) => c.name === value).name;
    //   setSelectedCompany(company);
    // }

    if (value === "Not Registered") {
      company = "None";
      companyNumber = "Not Registered";
    } else {
      company = companiesList.find((c) => c.name === value).name;
      companyNumber = companiesList.find((c) => c.name === value).companyNumber;
    }

    setFormState((old) => ({
      ...old,
      company: company,
      companyNumber: companyNumber,
      companyError: undefined,
    }));
  };

  const handleNetworkChange = (value: string) => {
    // event.preventDefault();
    let network: string;

    if (value === "Select Network") {
      network = "";
    } else {
      network = networkList.find((c) => c.name === value).name;
    }

    setFormState((old) => ({
      ...old,
      network: network,
      networkError: undefined,
    }));
  };

  return state.showForm ? (
    <form
      className="flex-grow flex flex-col"
      onSubmit={(e) => handleAddCellphoneDetailsSubmit(e)}
    >
      <div className="-my-2 flex-grow bg-transparent ">
        <div className="pb-5">
          <h2
            id="add-cellphone-detail"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            Cellphone Details
          </h2>
        </div>
        <div className="bg-transparent">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-4">
              <label
                htmlFor="cellphoneNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Cellphone (8-digits)
              </label>
              <Input
                type="text"
                name="cellphoneNumber"
                className="mt-1 block border border-gray-300 placeholder-gray-300 font-medium rounded-sm py-2 mr-4 border-b bg-white focus:border-blue-600 focus:ring-0 sm:text-sm"
                placeholder="Cellphone Number"
              />
              {showError && (
                <div className=" block text-sm text-red-500">
                  {formState.cellphoneNumberError &&
                    formState.cellphoneNumberError}
                </div>
              )}
            </div>

            <div className="col-span-4">
              <label
                htmlFor="cellphoneOwner"
                className="block text-sm font-medium text-gray-700"
              >
                Cellphone Owner
              </label>
              <Input
                type="text"
                name="cellphoneOwner"
                className="mt-1 block border border-gray-300 placeholder-gray-300 font-medium rounded-sm py-2 mr-4 border-b bg-white focus:border-blue-600 focus:ring-0 sm:text-sm"
                placeholder="Cellphone Owner"
              />
              {showError && (
                <div className=" block text-sm text-red-500">
                  {formState.cellphoneOwnerError &&
                    formState.cellphoneOwnerError}
                </div>
              )}
            </div>

            <div className="col-span-4">
              <label
                htmlFor="jobTitle"
                className="block text-sm font-medium text-gray-700"
              >
                Job Title
              </label>
              <Input
                type="text"
                name="jobTitle"
                className="mt-1 block border border-gray-300 placeholder-gray-300 font-medium rounded-sm py-2 mr-4 border-b bg-white focus:border-blue-600 focus:ring-0 sm:text-sm"
                placeholder="Job Title"
              />
              {showError && (
                <div className=" block text-sm text-red-500">
                  {formState.jobTitleError && formState.jobTitleError}
                </div>
              )}
            </div>

            <div className="col-span-3">
              <div className="col-span-12">
                <label
                  htmlFor="company"
                  className="block text-sm font-medium text-gray-700"
                >
                  Select Company...
                </label>

                <Listbox
                  value={formState.company}
                  onChange={handleCompanyChange}
                >
                  {({ open }) => (
                    <>
                      <div className="relative">
                        <Listbox.Button className="mt-1 bg-gray-50 relative w-full border border-gray-300 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                          <span className="block truncate">
                            {formState.company}
                          </span>
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60  py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                            <Listbox.Option
                              key={0}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "text-white bg-blue-600"
                                    : "text-gray-900",
                                  "cursor-default select-none relative py-2 pl-3 pr-9"
                                )
                              }
                              value="Not Registered"
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={classNames(
                                      selected
                                        ? "font-semibold"
                                        : "font-normal",
                                      "block truncate"
                                    )}
                                  >
                                    {`None`}
                                  </span>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active ? "text-white" : "text-blue-600",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                            {companiesList.map((company, index) => (
                              <Listbox.Option
                                key={index}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "text-white bg-blue-600"
                                      : "text-gray-900",
                                    "cursor-default select-none relative py-2 pl-3 pr-9"
                                  )
                                }
                                value={company.name}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "block truncate"
                                      )}
                                    >
                                      {`${company.name}`}
                                    </span>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-blue-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
                {showError && (
                  <div className=" block text-sm text-red-500">
                    {/* {formState.company === "Select Company" &&
                      "This is a required field"} */}

                    {formState.companyError && formState.companyError}
                  </div>
                )}
              </div>
            </div>
            <div className="col-span-1">
              <div className="col-span-12"> </div>
            </div>

            <div className="col-span-3">
              <div className="col-span-12">
                <label
                  htmlFor="network"
                  className="block text-sm font-medium text-gray-700"
                >
                  Select a network...
                </label>

                <Listbox
                  value={formState.network}
                  onChange={handleNetworkChange}
                >
                  {({ open }) => (
                    <>
                      <div className="relative">
                        <Listbox.Button className="mt-1 bg-gray-50 relative w-full border border-gray-300 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                          <span className="block truncate">
                            {formState.network}
                          </span>
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60  py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                            {networkList.map((network, index) => (
                              <Listbox.Option
                                key={index}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "text-white bg-blue-600"
                                      : "text-gray-900",
                                    "cursor-default select-none relative py-2 pl-3 pr-9"
                                  )
                                }
                                value={network.name}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "block truncate"
                                      )}
                                    >
                                      {`${network.name}`}
                                    </span>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-blue-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
                {showError && (
                  <div className=" block text-sm text-red-500">
                    {/* {formState.network === "Select Network" &&
                      "This is a required field"} */}
                    {formState.networkError && formState.networkError}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border shadow-sm text-sm font-medium rounded-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
          >
            Create User
          </button>
        </div> */}

        <div className="mt-8 flex items-center">
          <div className="flex justify-end">
            <button
              onClick={(e) => handleAddCellphoneDetailsCancel(e)}
              className="bg-white py-2 px-6  border-2 border-red-500 rounded-sm shadow-sm text-sm font-medium text-red-500 hover:bg-gray-50 focus:outline-none "
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-6 border rounded-sm shadow-sm disabled:opacity-50 text-sm font-medium  text-white bg-blue-600 hover:bg-blue-700  active:bg-indingo-800 focus:outline-none "
            >
              Add Cellphone
            </button>
          </div>
        </div>
      </div>
    </form>
  ) : null;
}

import { StepsButton } from "./components/stepsButton";
import { currencyFormatter } from "../../../utils/helpers";

// import { useRecoilState, useRecoilValue } from "recoil";
import { purchaseRequestMetersState } from "./atoms/atoms";
import { CreateElectricityPurchaseRequest } from "../../../../domain/usecases";
import Loading from "../../../components/loading/loading";
import { useRecoilValue } from "recoil";
import { format } from "date-fns";

type Props = {
  createElectricityPurchaseRequest: CreateElectricityPurchaseRequest;
};

export default function ConfirmPurchaseRequest({
  createElectricityPurchaseRequest,
}: Props) {
  const state = useRecoilValue(purchaseRequestMetersState);
  // const [state, setState] = useRecoilState(purchaseRequestMetersState);

  // payload = locations.filter((c) => c !== location);
  const meterList = state.meters.filter((meter) => {
    return +meter.purchaseAmount > 0;
  });

  return (
    <>
      <div className="w-full">
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-1 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="pb-8 overflow-hidden">
                <table className="min-w-full bg-gray-50 border rounded-sm border-gray-200">
                  <thead className="sticky bg-gray-200">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
                      >
                        <div>Meter Name &amp;</div>
                        <div>Location</div>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
                      >
                        <div>Meter Number &amp;</div>
                        <div>Registered Owner</div>
                      </th>

                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
                      >
                        Last Purchase &amp; Amount
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-medium text-gray-600 tracking-wide"
                      >
                        Purchase Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {meterList.map((meter, meterIdx) => (
                      <tr key={meter.id} className="border-b border-gray-150">
                        <td className="px-6 py-1 whitespace-nowrap text-sm font-normal">
                          <div className="text-sm text-gray-500">
                            {meter.location}
                          </div>
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {meter.name}
                          </div>
                          <div className="text-sm text-gray-500">
                            {meter.number}
                          </div>
                        </td>

                        <td className="px-6 py-1 whitespace-nowrap text-sm font-normal text-gray-500">
                          {meter.lastPurchaseDate === "None"
                            ? "None"
                            : format(
                                new Date(meter.lastPurchaseDate),
                                "yyyy-MM-dd"
                              )}
                          &nbsp; &mdash; &nbsp;
                          {currencyFormatter(meter.lastPurchaseAmount)}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-sm text-gray-500">
                          <span className="text-gray-900 font-bold">
                            {currencyFormatter(meter.purchaseAmount)}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <StepsButton
          createElectricityPurchaseRequest={createElectricityPurchaseRequest}
          step="confirm"
          isEnabled={true}
          primaryText="Submit Request"
          secondaryText="   Back   "
        />
      </div>

      {state.isLoading && <Loading />}
    </>
  );
}

import { NavLink, Outlet } from "react-router-dom";
// import { DateRangePicker } from "react-dates";
// import "react-dates/initialize";
// import "react-dates/lib/css/_datepicker.css";
import { GetItemisedPurchases } from "../../../domain/usecases/get-itemised-purchases";
import { query, collection, where, onSnapshot } from "firebase/firestore";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { db } from "../../../main/firebase";
import { currentAccountState } from "../../components";
import { reportsState } from "./atoms/atoms";
import { PurchasesOrderItem } from "../../../domain/usecases/get-account-balances";
// import moment from "moment";
// import { useEffect, useState } from "react";
// import { useRecoilValue } from "recoil";
// import { currentAccountState } from "../../presentation/components";

// const tabs = [
//   { name: "Itemised Orders", href: "#", current: true },
//   { name: "Purchases By User", href: "#", current: false },
//   { name: "Purchases By Day", href: "#", current: false },
// ];

const tabs = [
  {
    name: "Itemised Orders",
    description: "Managed approved BPC meters.",
    href: "/reports/common/itemised",
    current: true,
  },
  {
    name: "Purchases By Day",
    description: "Company Purchase Orders.",
    href: "/reports/common/daily",
    current: false,
  },
];

export default function CommonReports() {
  // let [startDate, setStartDate] = useState(moment());
  // let [endDate, setEndDate] = useState(moment());
  // let [today] = useState(moment());

  const [state, setState] = useRecoilState(reportsState);
  const { getCurrentAccount } = useRecoilValue(currentAccountState);

  // useEffect(() => {
  //   const q = query(
  //     collection(db, "purchaseOrderLineItem"),
  //     where("account", "==", getCurrentAccount().account)
  //   );

  //   return onSnapshot(q, (querySnapshot) => {
  //     let itemisedPurchases = querySnapshot.docs
  //       .sort(
  //         (a, b) =>
  //           a.data().dateUpdated.toDate() - b.data().dateUpdated.toDate()
  //       )
  //       .map((doc) => {
  //         console.log("doc.data()", doc.data());
  //         return {
  //           account: doc.data().account,
  //           accountName: doc.data().accountName,
  //           amount: doc.data().amount,
  //           dateCreated: doc.data().dateCreated.toDate(),
  //           dateUpdated: doc.data().dateUpdated.toDate(),
  //           createdBy: doc.data().createdBy,
  //           approvedBy: doc.data().approvedBy,
  //           id: doc.data().id,
  //           location: doc.data().location,
  //           meterNumber: doc.data().meterNumber,
  //           createdByName: "Tefo Mmono",
  //           name: doc.data().name,
  //           purchaseOrderNo: doc.data().purchaseOrderNo,
  //           registeredOwner: doc.data().registeredOwner,
  //           response: doc.data().response,
  //           transactionAmount: doc.data().transactionAmount,
  //           transactionId: doc.data().transactionId,
  //           status: doc.data().status,
  //         } as PurchasesOrderItem;
  //       });

  //     setState((old) => ({
  //       ...old,
  //       itemisedPurchases: itemisedPurchases,
  //     }));
  //   });
  // }, []);

  useEffect(() => {
    const q = query(
      collection(db, "purchaseOrderLineItem"),
      where("account", "==", getCurrentAccount().account),
      where("dateCreated", ">=", state.startDate.startOf("day").toDate()),
      where("dateCreated", "<=", state.endDate.endOf("day").toDate())
    );

    return onSnapshot(q, (querySnapshot) => {
      let itemisedPurchases = querySnapshot.docs
        .sort(
          (a, b) =>
            a.data().dateUpdated.toDate() - b.data().dateUpdated.toDate()
        )
        .map((doc) => {
          console.log("doc.data()", doc.data());

          return {
            account: doc.data().account,
            accountName: doc.data().accountName,
            amount: doc.data().amount,
            dateCreated: doc.data().dateCreated.toDate(),
            dateUpdated: doc.data().dateUpdated.toDate(),
            id: doc.data().id,
            // purchaseOrderId: doc.data().purchaseOrderId,

            number: doc.data().itemNumber,
            createdByName: doc.data().createdBy,
            createdBy: doc.data().createdBy,
            approvedBy: doc.data().approvedBy,
            location: doc.data().location ? doc.data().location : null,
            name: doc.data().name ? doc.data().name : null,
            owner: doc.data().registeredOwner
              ? doc.data().registeredOwner
              : doc.data().owner,
            merchant: doc.data().merchant,
            itemType: doc.data().itemType,
            purchaseAmount: doc.data().purchaseAmount,
            lastPurchaseDate: doc.data().lastPurchaseDate,
            lastPurchaseAmount: doc.data().lastPurchaseAmount,
            jobTitle: doc.data().jobTitle ? doc.data().jobTitle : null,
            network: doc.data().network ? doc.data().network : null,
            company: {
              name: doc.data().company.name,
              companyNumber: doc.data().company.companyNumber,
              phoneNumber: doc.data().company.phoneNumber,
              postalAddress: doc.data().company.postalAddress,
              city: doc.data().company.city,
            },
            purchaseOrderNo: doc.data().purchaseOrderNo,
            purchaseOrderReference: doc.data().purchaseOrderReference,
            registeredOwner: doc.data().registeredOwner,
            response: doc.data().response,
            transactionId: doc.data().transactionId,
            status: doc.data().status,
          } as PurchasesOrderItem;
        });

      setState((old) => ({
        ...old,
        itemisedPurchases: itemisedPurchases,
      }));
    });
  }, [state.startDate, state.endDate]);

  return (
    <>
      <div className="bg-white overflow-hidden  w-full shadow rounded-sm flex flex-col flex-grow">
        {/* <div className="px-4 py-5 sm:px-6">
           <h2 className="max-w-7xl mx-auto  text-lg leading-6 font-medium text-gray-900">
            Reports
          </h2> 
          <span>Day to day merchant reports</span>
        </div> */}

        <div className="px-4 flex-grow w-full max-w-7xl mx-auto lg:flex">
          <div className="max-w-7xl w-full mx-auto bg-transparent">
            <div className="w-full">
              <div className="flex-grow w-full max-w-7xl mx-auto lg:flex lg:flex-col items-start overflow-hidden">
                <div className=" w-full lg:max-w-7xl lg:mx-auto py-2 lg:col-span-6">
                  <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                      Select a tab
                    </label>
                    <select
                      id="tabs"
                      name="tabs"
                      className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                      defaultValue={tabs.find((tab) => tab.current)?.name}
                    >
                      {tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="hidden sm:block">
                    <div className="border-b border-gray-200 ">
                      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        {tabs.map((tab) => (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "text-gray-900 border-b-2 px-4 border-gray-900 whitespace-nowrap font-medium"
                                : "border-transparent border-b-2 px-4 text-gray-400 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-2 px-0 border-b-2"
                            }
                            // activeClassName="border-gray-700 text-gray-900 border-gray-900 whitespace-nowrap font-medium"
                            // className="border-transparent text-gray-400 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-2 px-0 border-b-2"
                            to={tab.href}
                            key={tab.name}
                            aria-current={tab.current ? "page" : undefined}
                          >
                            <>
                              <h2
                                id="reports-tab-title"
                                className="text-md leading-6 font-medium"
                              >
                                {tab.name}
                              </h2>
                            </>
                          </NavLink>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>
                <Outlet />
                {/* <AddMeters /> */}
              </div>
            </div>
          </div>

          {/* Activity feed */}
        </div>
      </div>
    </>
  );
}

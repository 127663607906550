import { PurchasesOrderItem } from "../../../domain/usecases/get-account-balances";
import { GetItemisedPurchases } from "../../../domain/usecases/get-itemised-purchases";
import { currencyFormatter } from "../../utils/helpers";

type Props = {
  transactions: PurchasesOrderItem[];
};

export default function ItemisedAmountTotal({ transactions }: Props) {
  const total = transactions
    .filter((t) => {
      return t.status === "SUCCESSFUL";
    })
    .map((p) => {
      return p.amount;
    })
    .reduce((acc, amount) => {
      return acc + +amount;
    }, 0);

  return (
    <div className="lg:col-span-6 text-right">
      <h2 className="text-md leading-6  text-sm text-gray-500">
        Total Purchases
      </h2>
      <p className="  text-right text-lg font-medium leading-6 text-gray-900 ">
        {currencyFormatter(total)}
      </p>
    </div>
  );
}

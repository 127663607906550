import { ChevronRightIcon } from "@heroicons/react/outline";
import { query, collection, where, onSnapshot } from "firebase/firestore";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { GetUsersState } from "../../../../domain/usecases/get-user";
import { db } from "../../../../main/firebase";
import { currentAccountState } from "../../../components";
import Loading from "../../../components/loading/loading";
import { currencyFormatter } from "../../../utils/helpers";
import { manageUserState } from "./components/atoms";
import TransactionRows from "./userRows";

export default function ManageUsers() {
  const [state, setState] = useRecoilState(manageUserState);
  const { getCurrentAccount } = useRecoilValue(currentAccountState);

  // !toggleEditUser.toggle

  useEffect(() => {
    const q = query(
      collection(db, "users"),
      where("account", "==", getCurrentAccount().account)
    );

    return onSnapshot(q, (querySnapshot) => {
      let users = querySnapshot.docs
        .sort((a, b) => a.data().displayName - b.data().displayName)
        .map((doc) => {
          console.log("doc.data()", doc.data());
          return {
            id: doc.data().uid,
            account: doc.data().account,
            accountName: doc.data().accountName,
            contactNumber: doc.data().contactNumber,
            displayName: doc.data().displayName,
            email: doc.data().email,
            firstName: doc.data().firstName,
            lastName: doc.data().lastName,
            dateCreated: doc.data().dateCreated.toDate(),
            createdBy: doc.data().createdBy,
            isEnabled: doc.data().isEnabled ? "true" : "false",
            role: doc.data().role,
            uid: doc.data().uid,
            user_id: doc.data().user_id,
          } as GetUsersState.Model;
        });

      setState((old) => ({
        ...old,
        users: users,
        isLoading: false,
      }));
    });
  }, []);

  // useEffect(() => {
  //   getActiveMeters
  //     .fetch({
  //       meterStatus: "Active",
  //       accountNumber: getCurrentAccount().account,
  //     })
  //     .then((result) =>
  //       setState((old) => ({
  //         ...old,
  //         meters: result.map((meter, index) => {
  //           console.log("meter", meter);
  //           return {
  //             id: index,
  //             account: meter.account.toString(),
  //             merchant: getCurrentAccount().merchantName,
  //             location: meter.location,
  //             name: meter.name,
  //             number: meter.number,
  //             purchaseAmount: 0.0,
  //             lastPurchaseDate: "None",
  //             lastPurchaseAmount: 0.0,
  //           };
  //         }),
  //         isLoading: false,
  //       }))
  //     )
  //     .catch(handleError);
  // }, []);

  const users =
    state.users &&
    state.users.map((user, index) => {
      return <TransactionRows key={user.uid} index={index} user={user} />;
    });

  return (
    <>
      {state.users && (
        <div className="w-full bg-white overflow-hidden shadow rounded-sm">
          <div className="px-4 py-5 sm:px-6">
            <h2 className="max-w-7xl mx-auto  text-lg leading-6 font-medium text-gray-900">
              Users
            </h2>
          </div>
          <div className="px-6 pb-6">
            <div className="shadow sm:hidden">
              <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                {state.users.map((user) => (
                  <li key={user.uid}>
                    <a
                      href={user.uid}
                      className="block px-4 py-4 bg-white hover:bg-gray-50"
                    >
                      <span className="flex items-center space-x-4">
                        <span className="flex-1 flex space-x-2 truncate">
                          <span className="flex flex-col text-gray-500 text-sm truncate">
                            <span className="truncate">{user.uid}</span>
                            <span>
                              <span className="text-gray-900 font-medium">
                                {currencyFormatter(user.displayName)}
                              </span>
                            </span>
                            <div>{user.email}</div>
                          </span>
                        </span>
                        <ChevronRightIcon
                          className="flex-shrink-0 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </a>
                  </li>
                ))}
              </ul>

              <nav
                className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
                aria-label="Pagination"
              >
                <div className="flex-1 flex justify-between">
                  <a
                    href="/"
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                  >
                    Previous
                  </a>
                  <a
                    href="/"
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                  >
                    Next
                  </a>
                </div>
              </nav>
            </div>

            <div className="hidden  sm:block">
              <div className="max-w-7xl mx-auto">
                <div className="flex flex-col">
                  <div className="align-middle min-w-full flex-grow overflow-x-auto overflow-hidden ">
                    {state.users.length > 0 ? (
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr>
                            <th className="pr-6 py-2  text-left text-sm font-medium text-gray-500  tracking-wider">
                              Name
                            </th>
                            <th className="px-6 py-2 text-left text-sm font-medium text-gray-500  tracking-wider">
                              E-mail
                            </th>

                            <th className="px-6 py-2  text-left text-sm font-medium text-gray-500  tracking-wider">
                              Role
                            </th>

                            <th className="px-6 py-2 text-left text-sm font-medium text-gray-500  tracking-wider">
                              Status
                            </th>

                            <th className="px-2 py-2  text-left text-sm font-medium text-gray-500  tracking-wider">
                              Manage
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-100">
                          {users}
                        </tbody>
                      </table>
                    ) : (
                      <h2 className="text-md leading-6 font-medium text-gray-900">
                        Merchant has no users.
                      </h2>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {state.isLoading && <Loading />}
    </>
  );
}

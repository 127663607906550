import { ChevronRightIcon } from "@heroicons/react/outline";
import { query, collection, onSnapshot, where } from "firebase/firestore";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { db } from "../../../../../main/firebase";
import { currentAccountState } from "../../../../components";
import Loading from "../../../../components/loading/loading";
import { meterCompaniesState } from "../atoms/atoms";

// import { meterCompaniesState } from "../atoms/atoms";

export default function CompaniesList() {
  const [state, setState] = useRecoilState(meterCompaniesState);
  const { getCurrentAccount } = useRecoilValue(currentAccountState);

  useEffect(() => {
    const q = query(
      collection(db, "companies"),
      where("account", "==", getCurrentAccount().account)
    );
    return onSnapshot(q, (querySnapshot) => {
      let result = querySnapshot.docs
        .sort((a, b) => a.data().name.localeCompare(b.data().name))
        .map((doc) => {
          return {
            account: doc.data().account,
            createdBy: doc.data().createdBy,
            dateCreated: doc.data().dateCreated,
            isActive: doc.data().isActive,
            name: doc.data().name,
            companyNumber: doc.data().companyNumber,
            postalAddress: doc.data().postalAddress,
            city: doc.data().city,
            phoneNumber: doc.data().phoneNumber,
          };
        });

      setState((old) => ({ ...old, isLoading: false, companiesList: result }));
    });
  }, []);

  console.log("state.companiesList", state.companiesList);
  return (
    <>
      {state.companiesList &&
        (state.companiesList.length > 0 ? (
          <div className="bg-white border-t border-gray-200 mt-4 overflow-hidden shadow rounded-sm">
            <div className="px-6 py-4">
              {/* Activity list (smallest breakpoint only) */}
              <div className="shadow sm:hidden">
                <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                  {state.companiesList.map((company) => (
                    <li key={company.companyNumber}>
                      <a
                        href={company.companyNumber}
                        className="block px-4 py-4 bg-white hover:bg-gray-50"
                      >
                        <span className="flex items-center space-x-4">
                          <span className="flex-1 flex space-x-2 truncate">
                            <span className="flex flex-col text-gray-500 text-sm truncate">
                              <span className="truncate">
                                {company.companyNumber}
                              </span>
                              <span>
                                <span className="text-gray-900 font-medium">
                                  {company.companyNumber}
                                </span>
                              </span>
                              {/* <time dateTime={meter.datetime}>
                          {meter.date}
                        </time> */}
                            </span>
                          </span>
                          <ChevronRightIcon
                            className="flex-shrink-0 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </a>
                    </li>
                  ))}
                </ul>

                <nav
                  className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
                  aria-label="Pagination"
                >
                  <div className="flex-1 flex justify-between">
                    <a
                      href="/"
                      className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                    >
                      Previous
                    </a>
                    <a
                      href="/"
                      className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                    >
                      Next
                    </a>
                  </div>
                </nav>
              </div>

              {/* Activity table (small breakpoint and up) */}
              <div className="hidden  sm:block">
                <div className="max-w-7xl mx-auto">
                  <div className="flex flex-col">
                    <div className="align-middlemin-w-full flex-grow overflow-x-auto overflow-hidden ">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr>
                            <th className="px-6 py-2  text-left text-sm font-medium text-gray-500  tracking-wider">
                              Company
                            </th>
                            <th className="px-6 py-2 text-left text-sm font-medium text-gray-500  tracking-wider">
                              CIPA Number
                            </th>

                            <th className=" px-6 py-2  text-left text-sm font-medium text-gray-500  tracking-wider md:block">
                              Postal Address
                            </th>
                            <th className="px-6 py-2 text-left text-sm font-medium text-gray-500  tracking-wider">
                              Location
                            </th>

                            <th className="px-6 py-2  text-left text-sm font-medium text-gray-500  tracking-wider">
                              Initiator
                            </th>
                          </tr>
                        </thead>

                        <tbody className="bg-white divide-y divide-gray-100">
                          {/* {state.companiesList.map((company) => ( */}
                          {state.companiesList.map((company) => (
                            <tr
                              key={company.companyNumber}
                              className="bg-white"
                            >
                              <td className="px-6 py-3 text-left whitespace-nowrap text-sm text-gray-900 font-bold">
                                {company.name}
                              </td>
                              <td className="px-6 py-3 text-left whitespace-nowrap text-sm text-gray-900 font-bold">
                                {company.companyNumber}
                              </td>
                              <td className=" px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                                {company.postalAddress}
                              </td>
                              <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                                {company.city}
                              </td>

                              <td className=" px-6 py-3 whitespace-nowrap text-sm text-gray-500 md:block">
                                {company.createdBy}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <h2 className="text-lg font-medium ">
            Merchant has no registered companies.
          </h2>
        ))}

      {state.isLoading && <Loading />}
    </>
  );
}

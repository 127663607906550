import { atom } from "recoil";
import { CompanyModel } from "../../../../../domain/models";
import { GetSmartcardsState } from "../../../../../domain/usecases/get-smartcard";

export const manageSmartcardState = atom({
  key: "manageSmartcardState",
  default: {
    smartcards: [] as GetSmartcardsState.Model[],
    smartcard: null as GetSmartcardsState.Model,
    companiesList: [] as CompanyModel[],
    isLoading: true,
    error: "",
    isFormInvalid: true,
    smartcardNumber: "",
    smartcardNumberError: "",
    smartcardName: "",
    smartcardNameError: "",
    smartcardLocation: "",
    smartcardLocationError: "",
    company: "",
    companyError: "This is a required field",
    mainError: "",
  },
});

export const toggleEditSmartcardState = atom({
  key: "toggleEditSmartcardState",
  default: {
    toggle: false,
  },
});

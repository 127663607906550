import AddSmartcardDetails from "../../../../../presentation/pages/smartcards/add-smartcard/components/addSmartcardDetails";
import { makeValidateAddSmartcardDetailsValidation } from "./validate-add-smartcard-details-validation-factory";

export const makeAddSmartcardDetails = () => {
  return (
    <AddSmartcardDetails
      validation={makeValidateAddSmartcardDetailsValidation()}
    />
  );
};

import { atom, selector } from "recoil";
import {
  PurchaseRequestCellphone,
  PurchaseRequestType,
  PurchaseRequestStatus,
  PurchaseRequestStep,
} from "../types";
import { PurchaseRequestCompany } from "../types/purchaseRequestCompany";

export const purchaseRequestCellphonesState = atom({
  key: "purchaseRequestCellphonesState",
  default: {
    cellphones: [] as PurchaseRequestCellphone[],
    filteredCellphones: [] as PurchaseRequestCellphone[],
    companies: [] as PurchaseRequestCompany[],
    filteredCompanies: [] as PurchaseRequestCompany[],
    isFiltered: false,
    isCompaniesFiltered: false,
    showFilters: true,
    reload: false,
    isLoading: false,
    error: "",
    initiator: "",
  },
});

export const airtimePurchaseRequestStepsState = atom({
  key: "airtimePurchaseRequestStepsState",
  default: [
    {
      number: 1,
      name: "Cellphones",
      description: "Select cellphones to recharge.",
      href: "#",
      status: "current",
    },
    {
      number: 2,
      name: "Confirm",
      description: "Review and submit for approval.",
      href: "#",
      status: "upcoming",
    },
    {
      number: 3,
      name: "Completed",
      description: "Request submitted for approval.",
      href: "#",
      status: "upcoming",
    },
  ] as PurchaseRequestStep[],
});

export const airtimePurchaseRequestStatusState = atom({
  key: "airtimePurchaseRequestStatusState",
  default: {
    purchaseRequestStatus: "PurchaseRequestInitiated" as PurchaseRequestStatus,
  },
});

export const showCellphoneJobTitleFilters = selector({
  key: "showCellphoneJobTitleFilters",
  get: ({ get }) => {
    const cellphones = get(purchaseRequestCellphonesState).cellphones;

    const set = Array.from(new Set(cellphones.map((x) => x.network))).sort();

    const locations = ["All Job Titles", ...set];
    return locations;
  },
});

export const enableCellphoneStepsButton = selector({
  key: "enableCellphoneStepsButton",
  get: ({ get }) => {
    const cellphones = get(purchaseRequestCellphonesState).cellphones;

    return (
      cellphones.filter((cellphone) => {
        return +cellphone.purchaseAmount > 0;
      }).length > 0
    );
  },
});

export const cellphoneJobTitleState = selector({
  key: "cellphoneJobTitleState",
  get: ({ get }) => {
    const cellphones = get(purchaseRequestCellphonesState).cellphones;

    const set = Array.from(new Set(cellphones.map((x) => x.jobTitle))).sort();

    const jobTitles = ["All Job Titles", ...set];
    return jobTitles;
  },
});

export const airtimePurchaseRequestCompanyState = selector({
  key: "airtimePurchaseRequestCompanyState",
  get: ({ get }) => {
    const companies = get(purchaseRequestCellphonesState).companies;

    const set = Array.from(new Set(companies.map((x) => x.name))).sort();

    const locations = ["All Companies", ...set];
    return locations;
  },
});

export const airtimePurchaseRequestAmount = selector({
  key: "airtimePurchaseRequestAmount",
  get: ({ get }) => {
    const cellphones = get(purchaseRequestCellphonesState).cellphones;

    const amount = cellphones.reduce((acc, cellphone) => {
      return acc + +cellphone.purchaseAmount;
    }, 0);
    return amount;
  },
});

const airtimePurchaseAmountsValid = selector({
  key: "airtimePurchaseAmountsValid",
  get: ({ get }) => {
    const cellphones = get(purchaseRequestCellphonesState).cellphones;

    const isValid = cellphones.every((m) => m.purchaseAmount <= 1000);
    return isValid;
  },
});

export const airtimePurchaseRequestState = selector({
  key: "airtimePurchaseRequestState",
  get: ({ get }) => {
    return {
      cellphones: get(purchaseRequestCellphonesState).cellphones,
      filteredCellphones: get(purchaseRequestCellphonesState)
        .filteredCellphones,
      filteredCompanies: get(purchaseRequestCellphonesState).filteredCompanies,
      isFiltered: get(purchaseRequestCellphonesState).isFiltered,
      isCompaniesFiltered: get(purchaseRequestCellphonesState)
        .isCompaniesFiltered,
      showFilters: true,
      enableStepsButton: get(enableCellphoneStepsButton),
      purchaseAmountsValid: get(airtimePurchaseAmountsValid),
      locations: get(cellphoneJobTitleState),
      requestDetails: [],
      requestPurchaseAmount: get(airtimePurchaseRequestAmount),
      initiator: get(purchaseRequestCellphonesState).initiator,
      steps: get(airtimePurchaseRequestStepsState),
      purchaseRequestStatus: get(airtimePurchaseRequestStatusState)
        .purchaseRequestStatus,
    } as PurchaseRequestType;
  },
});

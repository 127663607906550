import ConfirmPurchaseRequest from "../../../../presentation/pages/purchaseRequest/airtime/confirmPurchaseRequest";
import { makeRemoteCreateAirtimePurchaseRequest } from "../../usecases/purchase-requests";

export const makeCreateAirtimePurchaseRequest = () => {
  return (
    <ConfirmPurchaseRequest
      createAirtimePurchaseRequest={makeRemoteCreateAirtimePurchaseRequest()}
    />
  );
};

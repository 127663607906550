import { atom } from "recoil";
import { CompanyModel } from "../../../../../domain/models";
import { GetMetersState } from "../../../../../domain/usecases/get-meter";

export const manageMeterState = atom({
  key: "manageMeterState",
  default: {
    meters: [] as GetMetersState.Model[],
    meter: null as GetMetersState.Model,
    companiesList: [] as CompanyModel[],
    isLoading: true,
    error: "",
    isFormInvalid: true,
    meterNumber: "",
    meterNumberError: "",
    meterName: "",
    meterNameError: "",
    meterLocation: "",
    meterLocationError: "",
    companyNumber: "",
    companyNumberError: "This is a required field",
    mainError: "",
  },
});

export const toggleEditMeterState = atom({
  key: "toggleEditMeterState",
  default: {
    toggle: false,
  },
});

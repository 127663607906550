import { PlusIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { AddCompany } from "../../../../../domain/usecases";
import { currentAccountState } from "../../../../components";
import { Validation } from "../../../../protocols";
import { meterCompaniesState } from "../atoms/atoms";
import FormStatus from "./form-status";

import Input from "./input";

type Props = {
  addCompany: AddCompany;
  validation: Validation;
};

export default function AddCompanyForm({ addCompany, validation }: Props) {
  const { getCurrentAccount } = useRecoilValue(currentAccountState);
  const [state, setState] = useRecoilState(meterCompaniesState);
  const resetState = useResetRecoilState(meterCompaniesState);
  const [showError, setshowError] = useState(false);

  useEffect(() => validate("name"), [state.name]);
  useEffect(() => validate("companyNumber"), [state.companyNumber]);
  useEffect(() => validate("postalAddress"), [state.postalAddress]);
  useEffect(() => validate("city"), [state.city]);
  useEffect(() => validate("phoneNumber"), [state.phoneNumber]);

  const validate = (field: string): void => {
    const { name, companyNumber, postalAddress, phoneNumber, city } = state;
    const formData = { name, companyNumber, postalAddress, phoneNumber, city };
    setState((old) => ({
      ...old,
      [`${field}Error`]: validation.validate(field, formData),
    }));
    setState((old) => ({
      ...old,
      isFormInvalid:
        !!old.nameError ||
        !!old.companyNumberError ||
        !!old.postalAddressError ||
        !!old.cityError ||
        !!old.phoneNumberError,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      console.log("companies state", state);

      setState((old) => ({ ...old, isLoading: true }));

      if (state.isFormInvalid) {
        setshowError(true);
        setState((old) => ({ ...old, isLoading: false }));
        return;
      }
      await addCompany.add({
        account: getCurrentAccount().account,
        createdBy: getCurrentAccount().displayName,
        name: state.name,
        companyNumber: state.companyNumber,
        postalAddress: state.postalAddress,
        city: state.city,
        phoneNumber: state.phoneNumber,
      });

      resetState();

      setState((old) => ({
        ...old,
        isLoading: false,
        showAddVATForm: false,
      }));

      setshowError(false);
    } catch (error) {
      setState((old) => ({
        ...old,
        validateMeterFailed: true,
      }));

      setState((old) => ({
        ...old,
        isLoading: false,
        mainError: error.message,
      }));
    }
  };

  const handleShowAddAddressForm = (e: React.SyntheticEvent) => {
    e.preventDefault();

    setState((old) => ({
      ...old,
      showAddVATForm: true,
    }));
  };

  const handleCloseAddAddressForm = (e: React.SyntheticEvent) => {
    e.preventDefault();

    setState((old) => ({
      ...old,
      showAddVATForm: false,
    }));
  };

  return (
    <>
      {state.showAddVATForm ? (
        <>
          <form onSubmit={(e) => handleSubmit(e)} className="w-full">
            <div className="pb-5">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 grid grid-cols-12 gap-6">
                  <div className="col-span-4 ">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Company
                    </label>

                    <Input
                      type="text"
                      name="name"
                      className="block mt-1 border border-gray-300 placeholder-gray-400 font-medium rounded-sm py-2 mr-4 border-b border-transparent bg-white focus:border-blue-600 focus:ring-0 sm:text-sm"
                      placeholder="Company Name"
                    />
                    {showError && (
                      <div className=" block text-sm text-red-500">
                        {state.nameError && state.nameError}
                      </div>
                    )}
                  </div>

                  <div className="col-span-4 ">
                    <label
                      htmlFor="companyNumber"
                      className="block text-sm font-medium text-gray-700"
                    >
                      CIPA Company Number
                    </label>

                    <Input
                      type="text"
                      name="companyNumber"
                      className="block mt-1 border border-gray-300 placeholder-gray-400 font-medium rounded-sm py-2 mr-4 border-b border-transparent bg-white focus:border-blue-600 focus:ring-0 sm:text-sm"
                      placeholder="CIPA Company Number"
                    />
                    {showError && (
                      <div className=" block text-sm text-red-500">
                        {state.companyNumberError && state.companyNumberError}
                      </div>
                    )}
                  </div>

                  <div className="col-span-4 ">
                    <label
                      htmlFor="phoneNumber"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Telephone
                    </label>

                    <Input
                      type="text"
                      name="phoneNumber"
                      className="block mt-1 border border-gray-300 placeholder-gray-400 font-medium rounded-sm py-2 mr-4 border-b border-transparent bg-white focus:border-blue-600 focus:ring-0 sm:text-sm"
                      placeholder="Telephone"
                    />
                    {showError && (
                      <div className=" block text-sm text-red-500">
                        {state.phoneNumberError && state.phoneNumberError}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-span-12 grid grid-cols-12 gap-6">
                  <div className="col-span-4 ">
                    <label
                      htmlFor="postalAddress"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Postal Address
                    </label>

                    <Input
                      type="text"
                      name="postalAddress"
                      className="block mt-1 border border-gray-300 placeholder-gray-400 font-medium rounded-sm py-2 mr-4 border-b border-transparent bg-white focus:border-blue-600 focus:ring-0 sm:text-sm"
                      placeholder="Postal Address"
                    />
                    {showError && (
                      <div className=" block text-sm text-red-500">
                        {state.postalAddressError && state.postalAddressError}
                      </div>
                    )}
                  </div>
                  <div className="col-span-4">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium text-gray-700"
                    >
                      City/Village
                    </label>
                    <Input
                      type="text"
                      name="city"
                      className="block mt-1 border border-gray-300 placeholder-gray-400 font-medium rounded-sm py-2 mr-4 border-b border-transparent bg-white focus:border-blue-600 focus:ring-0 sm:text-sm"
                      placeholder="City/Village"
                    />
                    {showError && (
                      <div className=" block text-sm text-red-500">
                        {state.cityError && state.cityError}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-start">
              <button
                onClick={(e) => handleCloseAddAddressForm(e)}
                className="bg-white py-2 px-6 border border-2 border-red-500 rounded-sm shadow-sm text-sm font-medium text-red-500 hover:bg-gray-50 focus:outline-none "
              >
                Close Form
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex  py-2 px-6 border border-transparent rounded-sm shadow-sm disabled:opacity-50 text-sm font-medium  text-white bg-blue-600 hover:bg-blue-700  active:bg-indingo-800 focus:outline-none "
              >
                <PlusIcon
                  className="-ml-1 mr-2 h-5 w-5 text-white"
                  aria-hidden="true"
                />
                Add a Company
              </button>
            </div>

            {/* <div className="mt-8 flex items-center">
          <div className="flex justify-end">
            <button
              onClick={(e) => handleAddMeterDetailsCancel(e)}
              className="bg-white py-2 px-6 border border-2 border-red-500 rounded-sm shadow-sm text-sm font-medium text-red-500 hover:bg-gray-50 focus:outline-none "
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-6 border border-transparent rounded-sm shadow-sm disabled:opacity-50 text-sm font-medium  text-white bg-blue-600 hover:bg-blue-700  active:bg-indingo-800 focus:outline-none "
            >
              Add Meter
            </button>
          </div>
        </div> */}
          </form>
          <FormStatus />
        </>
      ) : (
        <div className="px-4 py-3 text-right sm:px-6">
          <button
            className="relative inline-flex items-center px-4 py-2 rounded-sm border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
            onClick={(e) => handleShowAddAddressForm(e)}
          >
            <PlusIcon
              className="-ml-1 mr-2 h-5 w-5 text-gray-700"
              aria-hidden="true"
            />
            Add a Company
          </button>
        </div>
      )}
    </>
  );
}

import { atom, selector } from "recoil";
import { PurchaseRequestCompany } from "../../purchaseRequest/electricity/types/purchaseRequestCompany";

export const merchantsState = atom({
  key: "merchantsState",
  default: {
    merchants: [] as PurchaseRequestCompany[],
  },
});

export const merchantState = selector({
  key: "merchantState",
  get: ({ get }) => {
    const merchants = get(merchantsState).merchants;

    const set = Array.from(new Set(merchants.map((x) => x.name))).sort();

    const locations = ["Select a Merchant", ...set];
    return locations;
  },
});

import { CheckIcon } from "@heroicons/react/outline";

import { useRecoilState } from "recoil";
import { electricityPurchaseRequestStepsState } from "./atoms/atoms";

export default function PurchaseRequestSteps() {
  const [steps] = useRecoilState(electricityPurchaseRequestStepsState);

  return (
    <nav aria-label="Progress">
      {/* <ol
        role="list"
        className="divide-y divide-gray-300 md:flex md:divide-y-0"
      > */}
      <ol className="border border-gray-300 rounded-sm bg-gray-50 divide-y divide-gray-300 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex-1 md:flex">
            {step.status === "complete" ? (
              <a href={step.href} className="group flex items-center w-full">
                <span className="px-6 py-2 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                    <CheckIcon
                      className="w-6 h-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900">
                    {step.name}
                  </span>
                </span>
              </a>
            ) : step.status === "current" ? (
              <a
                href={step.href}
                className="px-6 py-2 flex items-center text-xs"
                aria-current="step"
              >
                <span className="h-9 flex items-center" aria-hidden="true">
                  <span className="relative z-10 w-6 h-6 flex items-center justify-center bg-white border-2 border-blue-600 rounded-full">
                    <span className="h-2.5 w-2.5 bg-blue-600 rounded-full" />
                  </span>
                </span>
                <span className="ml-4 text-sm font-medium text-indigo-600">
                  {step.name}
                </span>
              </a>
            ) : (
              <a href={step.href} className="group flex items-center">
                <span className="px-6 py-2 flex items-center text-sm font-medium">
                  <span className="h-9 flex items-center" aria-hidden="true">
                    <span className="relative z-10 w-6 h-6 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                      <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                    </span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                    {step.name}
                  </span>
                </span>
              </a>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div
                  className="hidden md:block absolute top-0 right-0 h-full w-5  text-gray-400"
                  aria-hidden="true"
                >
                  {/* <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg> */}

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 m-0 absolute top-1/2 transform -translate-y-1/2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
}

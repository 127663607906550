import { ChevronRightIcon } from "@heroicons/react/outline";
import { useRecoilValue } from "recoil";

import { addCellphoneState } from "../atoms/atoms";

export default function AddCellphoneList() {
  const cellphonesToAdd = useRecoilValue(addCellphoneState).cellphonesToAdd;

  return cellphonesToAdd.length > 0 ? (
    <div className="bg-white  overflow-hidden shadow rounded-sm">
      <div className="px-6 py-4">
        <div className="shadow sm:hidden">
          <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
            {cellphonesToAdd.map((cellphone) => (
              <li key={cellphone.cellphoneNumber}>
                <a
                  href={cellphone.cellphoneNumber}
                  className="block px-4 py-4 bg-white hover:bg-gray-50"
                >
                  <span className="flex items-center space-x-4">
                    <span className="flex-1 flex space-x-2 truncate">
                      <span className="flex flex-col text-gray-500 text-sm truncate">
                        <span className="truncate">
                          {cellphone.cellphoneName}
                        </span>
                        <span>
                          <span className="text-gray-900 font-medium">
                            {cellphone.cellphoneName}
                          </span>
                        </span>
                        {/* <time dateTime={cellphone.datetime}>
                          {cellphone.date}
                        </time> */}
                      </span>
                    </span>
                    <ChevronRightIcon
                      className="flex-shrink-0 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </a>
              </li>
            ))}
          </ul>

          <nav
            className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
            aria-label="Pagination"
          >
            <div className="flex-1 flex justify-between">
              <a
                href="/"
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
              >
                Previous
              </a>
              <a
                href="/"
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
              >
                Next
              </a>
            </div>
          </nav>
        </div>

        {/* Activity table (small breakpoint and up) */}
        <div className="hidden  sm:block">
          <div className="max-w-7xl mx-auto">
            <div className="flex flex-col">
              <div className="align-middlemin-w-full flex-grow overflow-x-auto overflow-hidden ">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th className="pr-6 py-2  text-left text-sm font-medium text-gray-500  tracking-wider">
                        Number
                      </th>
                      <th className="px-6 py-2 text-left text-sm font-medium text-gray-500  tracking-wider">
                        Owner
                      </th>
                      <th className="px-6 py-2 text-left text-sm font-medium text-gray-500  tracking-wider">
                        Job Title
                      </th>
                      <th className="px-6 py-2 text-left text-sm font-medium text-gray-500  tracking-wider">
                        Network
                      </th>
                      <th className="px-6 py-2  text-left text-sm font-medium text-gray-500  tracking-wider">
                        Company
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-100">
                    {cellphonesToAdd.map((cellphone) => (
                      <tr key={cellphone.cellphoneNumber} className="bg-white">
                        <td className="pr-6 py-3 text-left whitespace-nowrap text-sm text-gray-900 font-bold">
                          {cellphone.cellphoneNumber}
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                          {cellphone.cellphoneOwner}
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                          {cellphone.jobTitle}
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                          {cellphone.network}
                        </td>
                        <td className="px-6 py-3 text-left whitespace-nowrap text-sm text-gray-500">
                          {cellphone.company}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

import { ChevronRightIcon } from "@heroicons/react/outline";
import { format } from "date-fns";
import { useRecoilValue } from "recoil";

import { currencyFormatter } from "../../../../utils/helpers";
import { dashboardState } from "../../atoms/atoms";
// import { dasboardState } from "../../atoms/atoms";
import TransactionRows from "./transactionRows";

export default function Requests() {
  const purchaseRequests = useRecoilValue(dashboardState).purchaseRequests;

  const transactions =
    purchaseRequests &&
    purchaseRequests.map((transaction, index) => {
      return (
        <TransactionRows
          key={transaction.id}
          index={index}
          transaction={transaction}
        />
      );
    });

  return (
    <div className="bg-white overflow-hidden shadow rounded-sm">
      <div className="p-4">
        <div className="shadow sm:hidden">
          <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
            {purchaseRequests.map((transaction) => (
              <li key={transaction.id}>
                <a
                  href={transaction.id}
                  className="block px-4 py-4 bg-white hover:bg-gray-50"
                >
                  <span className="flex items-center space-x-4">
                    <span className="flex-1 flex space-x-2 truncate">
                      <span className="flex flex-col text-gray-500 text-sm truncate">
                        <span className="truncate">{transaction.id}</span>
                        <span>
                          <span className="text-gray-900 font-medium">
                            {currencyFormatter(transaction.amount)}
                          </span>
                        </span>
                        <div>
                          {format(transaction.date, "yyyy-MM-dd HH:mm:ss")}
                        </div>
                      </span>
                    </span>
                    <ChevronRightIcon
                      className="flex-shrink-0 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </a>
              </li>
            ))}
          </ul>

          <nav
            className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
            aria-label="Pagination"
          >
            <div className="flex-1 flex justify-between">
              <a
                href="/"
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
              >
                Previous
              </a>
              <a
                href="/"
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
              >
                Next
              </a>
            </div>
          </nav>
        </div>

        {/* Activity table (small breakpoint and up) */}
        <div className="hidden  sm:block">
          <div className="max-w-7xl mx-auto">
            <div className="flex flex-col">
              <div className="align-middle min-w-full flex-grow overflow-x-auto overflow-hidden ">
                {transactions.length > 0 ? (
                  <table
                    id="my-table"
                    className="min-w-full divide-y divide-gray-200"
                  >
                    <thead>
                      <tr>
                        <th className="pr-6 py-2  text-left text-sm font-medium text-gray-500  tracking-wider">
                          Date
                        </th>
                        <th className="px-6 py-2 text-left text-sm font-medium text-gray-500  tracking-wider">
                          Order Reference
                        </th>

                        <th className="px-6 py-2  text-left text-sm font-medium text-gray-500  tracking-wider">
                          Initiator
                        </th>
                        <th className="px-6 py-2  text-left text-sm font-medium text-gray-500  tracking-wider">
                          Type
                        </th>
                        <th className="px-6 py-2 text-right text-sm font-medium text-gray-500  tracking-wider">
                          Amount
                        </th>
                        <th className="hidden px-6 py-2  text-left text-sm font-medium text-gray-500  tracking-wider md:block">
                          Status
                        </th>
                        <th className="px-2 py-2  text-left text-sm font-medium text-gray-500  tracking-wider"></th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-100">
                      {transactions}
                    </tbody>
                  </table>
                ) : (
                  <h2 className="text-md leading-6 font-medium text-gray-900">
                    Merchant has no transactions.
                  </h2>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import {
  MeterAlreadyRegisteredError,
  MeterNotFoundError,
  UnexpectedError,
} from "../../../domain/errors";

import { ValidateMeter } from "../../../domain/usecases/validate-meter";
import { HttpClient, HttpStatusCode } from "../../protocols/http";

export class RemoteValidateMeter implements ValidateMeter {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<ValidateMeter.Model>
  ) {}

  async validate(params: ValidateMeter.Params): Promise<ValidateMeter.Model> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "post",
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body!;
      case HttpStatusCode.conflict:
        throw new MeterAlreadyRegisteredError();
      case HttpStatusCode.notFound:
        throw new MeterNotFoundError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteValidateMeter {
  export type Model = ValidateMeter.Model;
}

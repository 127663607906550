import { MaxLengthFieldError } from "../../errors";
import { FieldValidation } from "../../protocols";

export class MaxLengthValidation implements FieldValidation {
  constructor(readonly field: string, private readonly maxLength: number) {}

  validate(input: any): Error | null {
    return input[this.field]?.length > this.maxLength
      ? new MaxLengthFieldError()
      : null;
  }
}

import { RemoteCreateAirtimePurchaseRequest } from "../../../../data/usecases";
import { CreateAirtimePurchaseRequest } from "../../../../domain/usecases";
import { makeApiUrl, makeAxiosHttpClient } from "../../http";

export const makeRemoteCreateAirtimePurchaseRequest =
  (): CreateAirtimePurchaseRequest =>
    new RemoteCreateAirtimePurchaseRequest(
      makeApiUrl("/purchases/create-purchase-request"),
      makeAxiosHttpClient()
    );

import AuthorizedPrimaryHeader from "../../presentation/components/header/AuthorisedPrimaryHeader";
import { Navigate, Route, Routes } from "react-router-dom";
import { Footer } from "../../presentation/components/footer/Footer";

import ManageMetersLayout from "../../presentation/pages/meters/manageMetersLayout";
import ManageCellphonesLayout from "../../presentation/pages/cellphones/manageCellphonesLayout";
import ManageSmartcardsLayout from "../../presentation/pages/smartcards/manageSmartcardsLayout";
import ManageUsersLayout from "./manageUsersLayout.";
import ReportsLayout from "../../presentation/pages/reports/reportsLayout";

// import InitiatePurchaseRequest from "../../presentation/pages/purchaseRequest/initiatePurchaseRequest";
// import ConfirmPurchaseRequest from "../../presentation/pages/purchaseRequest/confirmPurchaseRequest";
import InitiateElectricityPurchaseRequest from "../../presentation/pages/purchaseRequest/electricity/initiatePurchaseRequest";
import SubmitElectricityPurchaseRequest from "../../presentation/pages/purchaseRequest/electricity/submitPurchaseRequest";
import Dashboard from "../../presentation/pages/dashboard/dashboard";
import AddMetersLayout from "../../presentation/pages/meters/add-meter/addMetersLayout";
import AddCellphonesLayout from "../../presentation/pages/cellphones/add-cellphone/addCellphonesLayout";
import AddSmartcardsLayout from "../../presentation/pages/smartcards/add-smartcard/addSmartcardsLayout";
import SubmitMetersForApproval from "../../presentation/pages/meters/add-meter/steps/submitMeters";
import SubmitCellphonesForApproval from "../../presentation/pages/cellphones/add-cellphone/steps/submitCellphones";
import SubmitSmartcardsForApproval from "../../presentation/pages/smartcards/add-smartcard/steps/submitSmartcards";
import { makeCreateUser } from "../factories/pages/signup/users/createuser/create-user-factory";
import { makeAddMeter } from "../factories/pages/meters/add/validate-meter-factory";
// import { makeAddCellphone } from "../factories/pages/cellphones/add/validate-cellphone-factory";
import { makeAddSmartcard } from "../factories/pages/smartcards/add/validate-smartcard-factory";
import ManageCompany from "../../presentation/pages/meters/company/manageCompany";
import UserCreated from "../../presentation/pages/users/createUser/userCreated";
import CreateUserLayout from "../../presentation/pages/users/createUser/createUserLayout";
import MeterApplications from "../../presentation/pages/approvals/components/meter/meterApplications";
import CellphoneApplications from "../../presentation/pages/approvals/components/cellphone/cellphoneApplications";
import SmartcardApplications from "../../presentation/pages/approvals/components/smartcard/smartcardApplications";
import { makeElectricityPurchaseRequest } from "../factories/pages/purchaseRequests/electricity-purchase-request-factory";
import { makeCreateElectricityPurchaseRequest } from "../factories/pages/purchaseRequests/create-electricity-purchase-request-factory";
import { makeApprovals } from "../factories/pages/purchaseRequests";

import Orders from "../../presentation/pages/dashboard/components/orders/orders";
import Requests from "../../presentation/pages/dashboard/components/requests/requests";
import ItemisedPurchases from "../../presentation/pages/reports/itemisedPurchases";
import DailyPurchases from "../../presentation/pages/reports/dailyPurchases";
import CommonReports from "../../presentation/pages/reports/commonReports";
import ManageUsers from "../../presentation/pages/users/manageUsers/manageUsers";
import ManageMeters from "../../presentation/pages/meters/manage-meters/manageMeters";
import ManageCellphones from "../../presentation/pages/cellphones/manage-cellphones/manageCellphones";
import ManageSmartcards from "../../presentation/pages/smartcards/manage-smartcards/manageSmartcards";
import PurchaseRequests from "../../presentation/pages/reports/requests/purchaseRequests";
import PurchaseOrders from "../../presentation/pages/reports/orders/purchaseOrders";
import Statement from "../../presentation/pages/reports/statement/statement";
import Account from "../../presentation/pages/users/account/account";

import SessionTimeout from "../../presentation/components/sessionTimeout/SessionTimeout";
import PurchaseRequestApplications from "../../presentation/pages/approvals/components/purchaseRequestApplications";
import PurchaseRequestLayout from "../../presentation/pages/purchaseRequest/purchasesLayout";
import InitiateAirtimePurchaseRequest from "../../presentation/pages/purchaseRequest/airtime/initiatePurchaseRequest";
import { makeAirtimePurchaseRequest } from "../factories/pages/purchaseRequests/airtime-purchase-request-factory";
import InitiateDSTVPurchaseRequest from "../../presentation/pages/purchaseRequest/dstv/initiatePurchaseRequest";
import SubmitDSTVPurchaseRequest from "../../presentation/pages/purchaseRequest/dstv/submitPurchaseRequest";
import { makeDSTVPurchaseRequest } from "../factories/pages/purchaseRequests/dstv-purchase-request-factory";
import { makeCreateAirtimePurchaseRequest } from "../factories/pages/purchaseRequests/create-airtime-purchase-request-factory";
import { makeCreateDSTVPurchaseRequest } from "../factories/pages/purchaseRequests/create-dstv-purchase-request-factory";
import SubmitAirtimePurchaseRequest from "../../presentation/pages/purchaseRequest/airtime/submitPurchaseRequest";
import AddCellphones from "../../presentation/pages/cellphones/add-cellphone/steps/addCellphone";

const AuthorizedLayout = () => (
  <div className=" flex flex-col min-h-screen bg-gray-100">
    <AuthorizedPrimaryHeader />
    <main className="flex-grow flex flex-col">
      <div className="w-full mx-auto  flex flex-col flex-grow">
        <Routes>
          <Route path="/dashboard/*" element={<Dashboard />}>
            <Route path="orders" element={<Orders />} />
            <Route path="requests" element={<Requests />} />
          </Route>

          <Route path="/account" element={<Account />} />
          {/* <Route path="details" element={<Details />} />
            <Route path="password" element={<Password />} /> */}
          {/* </Route> */}

          <Route path="/purchase/*" element={<PurchaseRequestLayout />}>
            <Route
              path="electricity/*"
              element={makeElectricityPurchaseRequest()}
            >
              <Route
                path="initiate"
                element={<InitiateElectricityPurchaseRequest />}
              />
              <Route
                path="confirm"
                element={makeCreateElectricityPurchaseRequest()}
              />
              <Route
                path="complete"
                element={<SubmitElectricityPurchaseRequest />}
              />
            </Route>
            <Route path="airtime/*" element={makeAirtimePurchaseRequest()}>
              <Route
                path="initiate"
                element={<InitiateAirtimePurchaseRequest />}
              />
              <Route
                path="confirm"
                element={makeCreateAirtimePurchaseRequest()}
              />
              <Route
                path="complete"
                element={<SubmitAirtimePurchaseRequest />}
              />
            </Route>
            <Route path="dstv/*" element={makeDSTVPurchaseRequest()}>
              <Route
                path="initiate"
                element={<InitiateDSTVPurchaseRequest />}
              />
              <Route path="confirm" element={makeCreateDSTVPurchaseRequest()} />
              <Route path="complete" element={<SubmitDSTVPurchaseRequest />} />
            </Route>
          </Route>

          <Route path="/meters/*" element={<ManageMetersLayout />}>
            <Route path="add/*" element={<AddMetersLayout />}>
              <Route path="initiate" element={makeAddMeter()} />
              <Route path="complete" element={<SubmitMetersForApproval />} />
            </Route>
            <Route path="manage/*" element={<ManageMeters />}>
              {/* <Route path="meter" element={makeAmendMeterDetails()} /> */}
            </Route>
            <Route path="companies" element={<ManageCompany />} />
          </Route>

          <Route path="/cellphones/*" element={<ManageCellphonesLayout />}>
            <Route path="add/*" element={<AddCellphonesLayout />}>
              {/* <Route path="initiate" element={makeAddCellphone()} /> */}
              <Route path="initiate" element={<AddCellphones />} />

              <Route
                path="complete"
                element={<SubmitCellphonesForApproval />}
              />
            </Route>
            <Route path="manage/*" element={<ManageCellphones />}>
              {/* <Route path="meter" element={makeAmendMeterDetails()} /> */}
            </Route>
            <Route path="companies" element={<ManageCompany />} />
          </Route>

          <Route path="/smartcards/*" element={<ManageSmartcardsLayout />}>
            <Route path="add/*" element={<AddSmartcardsLayout />}>
              <Route path="initiate" element={makeAddSmartcard()} />
              <Route
                path="complete"
                element={<SubmitSmartcardsForApproval />}
              />
            </Route>
            <Route path="manage/*" element={<ManageSmartcards />}>
              {/* <Route path="meter" element={makeAmendMeterDetails()} /> */}
            </Route>
            <Route path="companies" element={<ManageCompany />} />
          </Route>

          <Route path="/users/*" element={<ManageUsersLayout />}>
            <Route path="create/*" element={<CreateUserLayout />}>
              <Route path="initiate" element={makeCreateUser()} />
              <Route path="complete" element={<UserCreated />} />
            </Route>

            <Route path="manage" element={<ManageUsers />}>
              {/* <Route path="password" element={makeResetUserPassword()} />
              <Route path="email" element={<ChangeEmail />} />
              <Route path="name" element={makeEditUserNames()} />
              <Route path="role" element={<EditRole />} />
              <Route path="status" element={makeChangeUserStatus()} /> */}
            </Route>
          </Route>

          <Route path="/approvals/*" element={makeApprovals()}>
            <Route
              path="purchase-requests"
              element={<PurchaseRequestApplications />}
            />
            <Route path="meters" element={<MeterApplications />} />
            <Route path="cellphones" element={<CellphoneApplications />} />
            <Route path="smartcards" element={<SmartcardApplications />} />
          </Route>

          <Route path="/reports/*" element={<ReportsLayout />}>
            <Route path="common/*" element={<CommonReports />}>
              <Route path="daily" element={<DailyPurchases />} />
            </Route>
            <Route path="itemised" element={<ItemisedPurchases />} />
            <Route path="statement" element={<Statement />} />
            <Route path="purchase-orders" element={<PurchaseOrders />} />
            <Route path="purchase-requests" element={<PurchaseRequests />} />
          </Route>

          <Route path="*" element={<Navigate to="/dashboard/orders" />} />
        </Routes>
      </div>
    </main>
    <Footer />
    <SessionTimeout />
  </div>
);

export default AuthorizedLayout;

// import  AddCellphoneStepsButton  from "../../../../../presentation/pages/cellphones/add-cellphone/components/stepsButton";
import { AddCellphoneStepsButton } from "../../../../../presentation/pages/cellphones/add-cellphone/components/stepsButton";
import { makeRemoteAddCellphones } from "../../../usecases/cellphone";

export const makeAddCellphoneStepsButton = (props) => {
  return (
    <AddCellphoneStepsButton
      {...props}
      addCellphones={makeRemoteAddCellphones()}
    />
  );
};

// isEnabled={
//   this.cellphonesToAdd.cellphonesToAdd.length > 0 &&
//   cellphonesToAdd.currentValidatedCellphone === null
// },

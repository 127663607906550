import { CellphoneNotFoundError, UnexpectedError } from "../../../domain/errors";
import { CellphoneAlreadyRegisteredError } from "../../../domain/errors/cellphone-already-exists-error";
import { AmendCellphone } from "../../../domain/usecases/amend-cellphone";
import { HttpClient, HttpStatusCode } from "../../protocols/http";

export class RemoteAmendCellphone implements AmendCellphone {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<void>
  ) {}

  async amend(params: AmendCellphone.Params): Promise<void> {
    console.log("AmendCellphone.Params", params);
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "post",
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body!;
      case HttpStatusCode.notFound:
        throw new CellphoneNotFoundError();
      case HttpStatusCode.conflict:
        throw new CellphoneAlreadyRegisteredError();
      default:
        throw new UnexpectedError();
    }
  }
}

// export namespace RemoteAmendAccount {
//   export type Model = CreateUser.Model;
// }

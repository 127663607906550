import { Outlet } from "react-router-dom";

export default function CreateUserLayout() {
  return (
    <div className="mt-10 sm:mt-0">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              New User
            </h3>
            <p className="mt-1 text-sm text-gray-600">
              Please note that the created user will be sent an email to
              activate and set a password for their account.
            </p>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

import { makeRemoteGetPurchaseRequestsByStatus } from "../../usecases/purchase-requests/remote-get-purchase-requests-by-status-factory";
import Approvals from "../../../../presentation/pages/approvals/approvals";

export const makeApprovals = () => {
  return (
    <Approvals
      getPendingPurchaseRequests={makeRemoteGetPurchaseRequestsByStatus()}
    />
  );
};

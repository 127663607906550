import { useRecoilValue } from "recoil";
import { currencyFormatter } from "../../../utils/helpers";
import { airtimePurchaseRequestState } from "./atoms/atoms";

export default function AirtimePurchaseRequestAmount() {
  const purchaseAmount = useRecoilValue(
    airtimePurchaseRequestState
  ).requestPurchaseAmount;

  return (
    <div className="lg:col-span-6 text-right">
      <h2 className="text-md leading-6  text-sm text-gray-500">
        Purchase Amount
      </h2>
      <p className=" max-w-2xl text-lg font-medium leading-6 text-gray-900 ">
        {currencyFormatter(purchaseAmount)}
      </p>
    </div>
  );
}

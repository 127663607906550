import { AccountModel } from "../../../domain/models";

import { atom } from "recoil";

export const currentAccountState = atom({
  key: "currentAccountState",
  default: {
    getCurrentAccount: null as () => AccountModel,
    setCurrentAccount: null as (account: AccountModel) => void,
  },
});

// default: JSON.parse(localStorage.getItem("account")) as AccountModel,

import { atom } from "recoil";
import { CompanyModel } from "../../../../../domain/models";
import { GetCellphonesState } from "../../../../../domain/usecases/get-cellphone";

export const manageCellphoneState = atom({
  key: "manageCellphoneState",
  default: {
    cellphones: [] as GetCellphonesState.Model[],
    cellphone: null as GetCellphonesState.Model,
    companiesList: [] as CompanyModel[],
    networkList: [
      { name: "Mascom" },
      { name: "Orange" },
    ] as { name: string }[],
    isLoading: true,
    error: "",
    isFormInvalid: true,
    currentCellphoneNumber: "",
    cellphoneNumber: "",
    cellphoneNumberError: "",
    cellphoneOwner: "",
    cellphoneOwnerError: "",
    jobTitle: "",
    jobTitleError: "",
    company: "",
    companyError: "",
    companyNumber: "",
    network: "",
    networkError: "",
    mainError: "",
  },
});

export const toggleEditCellphoneState = atom({
  key: "toggleEditCellphoneState",
  default: {
    toggle: false,
  },
});

import AddMeters from "../../../../../presentation/pages/meters/add-meter/steps/addMeter";

import { makeRemoteValidateMeter } from "../../../usecases/meter/remote-validate-meter-factory";
import { makeValidateMeterValidation } from "./validate-meter-validation-factory";

export const makeAddMeter = () => {
  return (
    <AddMeters
      validateMeter={makeRemoteValidateMeter()}
      validation={makeValidateMeterValidation()}
    />
  );
};

import jsPDF from "jspdf";
import { format } from "date-fns";

import autoTable from "jspdf-autotable";

import {
  ProductProvider,
  SuccessfulPurchase,
  PurchasesOrderItem,
} from "../../../../domain/usecases/get-account-balances";
import { currencyFormatter } from "../../../utils/helpers";
import { GetItemisedPurchases } from "../../../../domain/usecases/get-itemised-purchases";

export const printElecticityTaxReceipt = (
  meter: PurchasesOrderItem,
  provider: ProductProvider
) => {
  console.log("transaction", meter);
  console.log("provider", provider);
  const response = meter.response as SuccessfulPurchase;
  // const meter = transaction.items[meterIdx] as PurchasesOrderItem;
  const doc = new jsPDF();

  autoTable(doc, {
    theme: "plain",

    headStyles: { fontSize: 14, fontStyle: "bold", halign: "left" },
    bodyStyles: {
      fontSize: 10,
      fontStyle: "normal",
      cellWidth: "auto",
      halign: "center",
    },
    styles: { cellPadding: 0.5 },
    columnStyles: {
      0: { halign: "left", cellWidth: 100 },
      2: { halign: "right", cellWidth: "auto" },
      3: { halign: "right", cellWidth: "auto" },
    },

    head: [[`${provider.name}`, "", "", ""]],

    body: [
      [`${provider.postalAddress}`, "", "VAT No.", `${provider.vatNumber}`],
      [`${provider.city}`, "", "Telephone:", `${provider.telephone}`],
    ],
  });

  autoTable(doc, {
    theme: "plain",
    headStyles: { fontSize: 14, fontStyle: "bold", halign: "center" },
    styles: { cellPadding: 0.5 },
    startY: 43,
    tableWidth: "auto",
    head: [["", "", "TAX INVOICE", "", ""]],
    body: [["", "", "", "", ""]],
  });

  autoTable(doc, {
    theme: "plain",
    headStyles: { fontSize: 12, fontStyle: "bold", halign: "left" },
    bodyStyles: {
      fontSize: 10,
      fontStyle: "normal",
      halign: "center",
    },
    styles: { cellPadding: 0.5 },
    startY: 55,

    columnStyles: {
      0: { halign: "left", cellWidth: 100 },
      2: { halign: "right", cellWidth: "auto" },
      3: { halign: "right", cellWidth: "auto" },
    },

    head: [[`To: ${meter.company.name}`, "", "", ""]],

    body: [
      [
        `${meter.company.postalAddress}`,
        "",
        "Date:",
        `${format(meter.dateUpdated as Date, "yyyy-MM-dd")}`,
      ],
      [
        `${provider.city}`,
        "",
        "Receipt No:",
        `${response.creditVendReceipt.receiptNo}`,
      ],
      [`VAT: ${meter.company.companyNumber}`, "", "", ""],
    ],
  });

  doc.line(195.5, 81, 14, 81);

  let wantedTableWidth = 82;
  let pageWidth = doc.internal.pageSize.width;
  let margin = (pageWidth - wantedTableWidth) / 2;

  autoTable(doc, {
    tableWidth: 76,
    theme: "plain",
    headStyles: { fontSize: 10, fontStyle: "bold", halign: "center" },
    bodyStyles: {
      fontSize: 10,
      fontStyle: "normal",
      halign: "center",
      cellWidth: "auto",
    },
    styles: { cellPadding: 0.5 },
    startY: 89,
    columnStyles: {
      center: { halign: "center" },
    },

    head: [["Sale  |  Credit Purchase  -  BPC"]],

    body: [
      {
        center: `${format(new Date(meter.dateUpdated), "yyyy-MM-dd HH:mm:ss")}`,
      },
    ],
    margin: { left: margin, right: margin },
    columns: [{ header: "", dataKey: "center" }],
  });

  autoTable(doc, {
    tableWidth: 96,
    theme: "plain",
    headStyles: { fontSize: 10, fontStyle: "bold" },
    bodyStyles: { fontSize: 10, fontStyle: "normal", cellWidth: "wrap" },
    styles: { cellPadding: 0.5 },
    startY: 99,
    head: [["", ""]],
    body: [
      [
        `ID:${response.creditVendReceipt.clientId}`,
        `Receipt:${response.creditVendReceipt.receiptNo}`,
      ],
      [
        `Acc:${response.creditVendReceipt.account}`,
        `Plot:${response.creditVendReceipt.location}`,
      ],
    ],
    margin: { left: margin, right: margin },
  });

  doc.setFontSize(10);
  doc.setTextColor(20);
  doc.text(`Name:${response.creditVendReceipt.name}`, 64.5, 117.5);

  autoTable(doc, {
    tableWidth: 76,
    theme: "plain",
    headStyles: { fontSize: 10, fontStyle: "bold", halign: "center" },
    bodyStyles: {
      fontSize: 10,
      fontStyle: "normal",
      halign: "center",
      cellWidth: "auto",
    },
    styles: { cellPadding: 0.5 },

    columnStyles: {
      center: { halign: "left" },
    },
    startY: 124,
    head: [["TOKEN"]],
    margin: { left: margin, right: margin },
    columns: [{ header: "", dataKey: "center" }],
  });

  const charge =
    response.creditVendReceipt.tariff === "Domestic"
      ? [
          "Domestic Charge",
          ":",
          currencyFormatter(response.creditVendReceipt.standardCharge),
        ]
      : [
          "Business Charge",
          ":",
          currencyFormatter(response.creditVendReceipt.standardCharge),
        ];

  autoTable(doc, {
    tableWidth: 76,
    theme: "plain",
    headStyles: { fontSize: 10, fontStyle: "bold" },
    bodyStyles: { fontSize: 10, fontStyle: "normal" },
    styles: { cellPadding: 0.5 },
    startY: 133,
    head: [["Desription", ":", "Amount"]],
    body: [
      [
        "Tendered",
        ":",
        `${currencyFormatter(response.creditVendReceipt.amtTendered)}`,
      ],
      [
        "Cost of Units",
        ":",
        `${currencyFormatter(response.creditVendReceipt.costUnits)}`,
      ],
      charge,
      [
        "Government Levy",
        ":",
        `${currencyFormatter(response.creditVendReceipt.governmentLevy)}`,
      ],
      ["VAT", ":", `${currencyFormatter(response.creditVendReceipt.vat)}`],
      [""],
      ["Tariff", ":", `${response.creditVendReceipt.tariff}`],
      ["Units", ":", `${response.creditVendReceipt.tokenUnits}`],
      ["Meter Type", ":", `${response.creditVendReceipt.meterType}`],
      ["krn", ":", `${response.creditVendReceipt.krn}`],
      ["ti", ":", `${response.creditVendReceipt.ti}`],
      ["STS Meter", ":", `${response.creditVendReceipt.meterNumber}`],
      ["sgc", ":", `${response.creditVendReceipt.sgc}`],
    ],
    margin: { left: margin, right: margin },
  });
  const keychangetokenexists = response.creditVendReceipt.keychangetoken1
    ? response.creditVendReceipt.keychangetoken1
    : undefined;

  if (keychangetokenexists) {
    doc.line(146.5, 211, 64.5, 211);

    doc.setFontSize(16);
    doc.text(`${response.creditVendReceipt.keychangetoken1}`, 70.5, 220);

    doc.line(146.5, 224, 64.5, 224);

    doc.setFontSize(16);
    doc.text(`${response.creditVendReceipt.keychangetoken2}`, 70.5, 233);

    doc.line(146.5, 237, 64.5, 237);

    doc.setFontSize(16);
    doc.text(`${response.creditVendReceipt.stsCipher}`, 70.5, 246);

    doc.line(146.5, 250, 64.5, 250);
  } else {
    doc.line(146.5, 211, 64.5, 211);

    doc.setFontSize(16);
    doc.text(`${response.creditVendReceipt.stsCipher}`, 70.5, 220);

    doc.line(146.5, 224, 64.5, 224);
  }
  doc.save(
    `${meter.number}-${format(meter.dateUpdated as Date, "yyyy-MM-dd")}.pdf`
  );
};

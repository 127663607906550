import { ChevronRightIcon } from "@heroicons/react/outline";
import { useRecoilValue } from "recoil";
import { currencyFormatter, classNames } from "../../utils/helpers";
import { purchasesByDayState } from "./atoms/atoms";

import pdf from "../../assets/pdf.svg";
import { Link } from "react-router-dom";

export default function DailyPurchases() {
  const state = useRecoilValue(purchasesByDayState);
  console.log("state", state);

  const print = (date: string) => {
    console.log("Date: ", date);
  };

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2 className="max-w-7xl mx-auto  text-lg leading-6 font-medium text-gray-900">
          Purchases By Day(Month-to-Date)
        </h2>
        {/* We use less vertical padding on card headers on desktop than on body sections */}
      </div>
      <div className="px-6 pb-6">
        {/* Activity list (smallest breakpoint only) */}
        <div className="shadow sm:hidden">
          <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
            {state.map((transaction) => (
              <li key={transaction.dateCreated}>
                <a
                  href={transaction.dateCreated}
                  className="block px-4 py-4 bg-white hover:bg-gray-50"
                >
                  <span className="flex items-center space-x-4">
                    <span className="flex-1 flex space-x-2 truncate">
                      <span className="flex flex-col text-gray-500 text-sm truncate">
                        <span className="truncate">{transaction.count}</span>
                        <span>
                          <span className="text-gray-900 font-medium">
                            {currencyFormatter(transaction.amount)}
                          </span>
                        </span>
                        <time dateTime={transaction.datetime}>
                          {transaction.date}
                        </time>
                      </span>
                    </span>
                    <ChevronRightIcon
                      className="flex-shrink-0 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </a>
              </li>
            ))}
          </ul>

          <nav
            className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
            aria-label="Pagination"
          >
            <div className="flex-1 flex justify-between">
              <a
                href="/"
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
              >
                Previous
              </a>
              <a
                href="/"
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
              >
                Next
              </a>
            </div>
          </nav>
        </div>

        {/* Activity table (small breakpoint and up) */}
        <div className="hidden  sm:block">
          <div className="max-w-7xl mx-auto">
            <div className="flex flex-col mt-2">
              <div className="align-middle min-w-full flex-grow overflow-x-auto overflow-hidden ">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th className="pr-8 py-3  text-left text-sm font-medium text-gray-500  tracking-wider">
                        Date
                      </th>
                      <th className="px-8 py-3 text-left text-sm font-medium text-gray-500  tracking-wider">
                        Purchases
                      </th>

                      <th className="px-8 py-3 text-left text-sm font-medium text-gray-500  tracking-wider">
                        Amount
                      </th>
                      <th className="px-8 py-3  text-left text-sm font-medium text-gray-500  tracking-wider">
                        Download
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-100">
                    {state.map((transaction) => (
                      <tr key={transaction.dateCreated} className="bg-white">
                        <td className="pr-8 py-3 text-left whitespace-nowrap text-sm text-gray-900 font-bold">
                          <time dateTime={transaction.dateCreated}>
                            {transaction.dateCreated}
                          </time>
                        </td>
                        <td className=" text-left px-8 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                          <div className="flex">
                            <p className="truncate ">{transaction.count}</p>
                          </div>
                        </td>
                        <td className="px-8 py-3  text-left whitespace-nowrap text-sm text-gray-500">
                          <span className="text-gray-900 font-bold">
                            {currencyFormatter(transaction.amount)}
                          </span>
                        </td>
                        <td className="px-8 py-3  text-left  whitespace-nowrap text-sm text-gray-500 ">
                          <div className="flex">
                            <div className="block cursor-pointer h-5 w-auto">
                              <img
                                className="text-gray-400 h-5 w-5 group-hover:text-gray-900"
                                aria-hidden="true"
                                onClick={() => print(transaction.dateCreated)}
                                src={pdf}
                                alt="Download"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

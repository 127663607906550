import { InvalidFieldError } from "../../errors";
import { FieldValidation } from "../../protocols";

export class CompareFieldsValidation implements FieldValidation {
  constructor(
    readonly field: string,
    private readonly fieldToCompare: string
  ) {}

  validate(input: any): Error | null {
    console.log("CompareFieldsValidation", input);
    console.log("this.field", input[this.field]);
    console.log("input[this.fieldToCompare]", this.fieldToCompare);

    console.log(
      "input[this.field] === input[this.fieldToCompare]",
      input[this.field] === this.fieldToCompare
    );

    return input[this.field] === this.fieldToCompare
      ? new InvalidFieldError()
      : null;
  }
}

import { EmailInUseError, UnexpectedError } from "../../../domain/errors";
import { CreateDSTVPurchaseRequest } from "../../../domain/usecases";
import { HttpClient, HttpStatusCode } from "../../protocols/http";

export class RemoteCreateDSTVPurchaseRequest
  implements CreateDSTVPurchaseRequest
{
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<void>
  ) {}

  async create(params: CreateDSTVPurchaseRequest.Params): Promise<void> {
    console.log("CreateDSTVPurchaseRequest", params);
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "post",
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body!;
      case HttpStatusCode.forbidden:
        throw new EmailInUseError();
      default:
        throw new UnexpectedError();
    }
  }
}

// export namespace RemoteCreateDSTVPurchaseRequest {
//   export type Model = CreateDSTVPurchaseRequest.Model;
// }

/* eslint-disable react-hooks/exhaustive-deps */
import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon, CheckIcon } from "@heroicons/react/outline";
import { Fragment, useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState, useRecoilValue } from "recoil";
import { currentAccountState } from "../../../../components";
import { Validation } from "../../../../protocols";

import { classNames } from "../../../../utils/helpers";
import { addSmartcardState, smartcardCompaniesListState } from "../atoms/atoms";
import Input from "./input";

type Props = {
  validation: Validation;
};

export default function AddSmartcardDetails({ validation }: Props) {
  const resetLoginState = useResetRecoilState(addSmartcardState);
  const [state, setState] = useRecoilState(addSmartcardState);
  const { getCurrentAccount } = useRecoilValue(currentAccountState);
  const companiesList = useRecoilValue(
    smartcardCompaniesListState
  ).companiesList;
  // const [newSmartcards, setNewSmartcards] = useRecoilState(addSmartcardsState);
  const [selectedCompany, setSelectedCompany] = useState("Select Company");

  const validate = (field: string): void => {
    const { smartcardName, smartcardLocation } = state;
    const formData = {
      smartcardName,
      smartcardLocation,
    };
    setState((old) => ({
      ...old,
      [`${field}Error`]: validation.validate(field, formData),
    }));

    setState((old) => ({
      ...old,
      isFormInvalid:
        !old.smartcardName || !old.smartcardLocation || !state.company,
    }));
  };
  const [showError, setshowError] = useState(false);

  useEffect(() => resetLoginState(), []);
  useEffect(() => validate("smartcardName"), [state.smartcardName]);
  useEffect(() => validate("smartcardLocation"), [state.smartcardLocation]);
  // useEffect(() => validate("company"), [state.company]);

  const handleAddSmartcardDetailsSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    // validate("smartcardName");
    // validate("smartcardLocation");
    // validate("company");
    if (
      state.isLoading ||
      !state.smartcardName ||
      !state.smartcardLocation ||
      !state.company
    ) {
      setshowError(true);
      return;
    }

    const item = {
      account: getCurrentAccount().account,
      createdBy: getCurrentAccount().email,
      createdByName: getCurrentAccount().displayName,
      dateCreated: new Date(),
      dateUpdated: new Date(),
      emailContact: getCurrentAccount().email,
      isActive: false,
      location: state.smartcardLocation,
      smartcardName: state.smartcardName,
      smartcardNumber: state.currentValidatedSmartcard.smartCard,
      smartcardStatus: "PENDING",
      ipAddress: "41.18.20.78",
      company: state.company,
      smartcardOwner: state.currentValidatedSmartcard.customerName,
    };

    let newSmartcardsArray = [...state.smartcardsToAdd];
    console.log("newSmartcardsArray", newSmartcardsArray);
    if (item !== null) {
      newSmartcardsArray = [...newSmartcardsArray, item];
    }

    setState((old) => ({ ...old, isLoading: false, isFormInvalid: false }));

    setState({
      ...state,
      smartcardsToAdd: newSmartcardsArray,
      currentValidatedSmartcard: null,
      validateSmartcardFailed: false,
      isLoading: false,
      isFormInvalid: true,
      smartcardName: "",
      smartcardLocation: "",
      company: "",
      mainError: "",
    });
    // return item ? item : null;
  };

  const handleAddSmartcardDetailsCancel = (e: React.SyntheticEvent) => {
    e.preventDefault();

    setState({
      ...state,
      smartcardNumber: "",
      currentValidatedSmartcard: null,
      validateSmartcardFailed: false,
      isLoading: false,
      isFormInvalid: true,
      smartcardName: "",
      smartcardLocation: "",
      company: "",
      mainError: "",
    });
  };

  const handleCompanyChange = (value: string) => {
    // event.preventDefault();
    let company: string;

    console.log("company", value);
    if (value === "Not Registered") {
      setSelectedCompany("None");
      company = "None";
    } else {
      company = companiesList.find((c) => c.name === value).name;
      setSelectedCompany(company);
    }

    setState((old) => ({
      ...old,
      company: value,
      companyError: undefined,
    }));
    console.log("state", state);
  };

  //   {
  //     "clientSaleId": "1650530866093",
  //     "transactionAmount": 100,
  //     "customerName": "JE Smith",
  //     "smartCard": "1234567890",
  //     "amountDue": 100,
  //     "dueDate": "2016-06-29",
  //     "lookupId": "776863",
  //     "response": "Successful"
  // }

  return state.currentValidatedSmartcard ? (
    <form
      className="flex-grow flex flex-col"
      onSubmit={(e) => handleAddSmartcardDetailsSubmit(e)}
    >
      <div className="-my-2 flex-grow bg-transparent ">
        <div className="pb-5">
          <h2
            id="add-smartcard-detail"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            Smartcard Details - {state.currentValidatedSmartcard.smartCard}
          </h2>
        </div>
        <div className="bg-transparent">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-6">
              <label
                htmlFor="smartcardOwner"
                className="block text-sm font-medium text-gray-700"
              >
                Smartcard Owner
              </label>
              <span className="mt-1 text-sm text-gray-500">
                {state.currentValidatedSmartcard.customerName}
              </span>
            </div>

            <div className="grid grid-cols-12 gap-4 col-span-6">
              <div className="col-span-5">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Select Company...
                </label>

                <Listbox value={selectedCompany} onChange={handleCompanyChange}>
                  {({ open }) => (
                    <>
                      <div className="relative">
                        <Listbox.Button className="mt-1 bg-gray-50 relative w-full border border-gray-300 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                          <span className="block truncate">
                            {selectedCompany}
                          </span>
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60  py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                            <Listbox.Option
                              key={0}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "text-white bg-blue-600"
                                    : "text-gray-900",
                                  "cursor-default select-none relative py-2 pl-3 pr-9"
                                )
                              }
                              value="Not Registered"
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={classNames(
                                      selected
                                        ? "font-semibold"
                                        : "font-normal",
                                      "block truncate"
                                    )}
                                  >
                                    {`None`}
                                  </span>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active ? "text-white" : "text-blue-600",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                            {companiesList.map((company, index) => (
                              <Listbox.Option
                                key={index}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "text-white bg-blue-600"
                                      : "text-gray-900",
                                    "cursor-default select-none relative py-2 pl-3 pr-9"
                                  )
                                }
                                value={company.name}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "block truncate"
                                      )}
                                    >
                                      {`${company.name}`}
                                    </span>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-blue-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
                {showError && (
                  <div className=" block text-sm text-red-500">
                    {state.companyError && state.companyError}
                  </div>
                )}
              </div>
            </div>

            <div className="col-span-6">
              <label
                htmlFor="emailAddress"
                className="block text-sm font-medium text-gray-700"
              >
                Smartcard Name
              </label>
              <Input
                type="text"
                name="smartcardName"
                className="mt-1 block  placeholder-gray-300 font-medium rounded-sm py-2 mr-4 border border-gray-300 bg-white focus:border-blue-600 focus:ring-0 sm:text-sm"
                placeholder="Smartcard Name"
              />
              {showError && (
                <div className=" block text-sm text-red-500">
                  {state.smartcardNameError && state.smartcardNameError}
                </div>
              )}
            </div>

            <div className="col-span-6">
              <label
                htmlFor="smartcardLocation"
                className="block text-sm font-medium text-gray-700"
              >
                City/Village
              </label>
              <Input
                type="text"
                name="smartcardLocation"
                className="mt-1 block  placeholder-gray-300 font-medium rounded-sm py-2 mr-4 border border-gray-300 bg-white focus:border-blue-600 focus:ring-0 sm:text-sm"
                placeholder="Smartcard Location"
              />
              {showError && (
                <div className=" block text-sm text-red-500">
                  {state.smartcardLocationError && state.smartcardLocationError}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
          >
            Create User
          </button>
        </div> */}

        <div className="mt-8 flex items-center">
          <div className="flex justify-end">
            <button
              onClick={(e) => handleAddSmartcardDetailsCancel(e)}
              className="bg-white py-2 px-6 border-2 border-red-500 rounded-sm shadow-sm text-sm font-medium text-red-500 hover:bg-gray-50 focus:outline-none "
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-6 border border-transparent rounded-sm shadow-sm disabled:opacity-50 text-sm font-medium  text-white bg-blue-600 hover:bg-blue-700  active:bg-indingo-800 focus:outline-none "
            >
              Add Smartcard
            </button>
          </div>
        </div>
      </div>
    </form>
  ) : null;
}

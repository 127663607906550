import { useRecoilState } from "recoil";
import React from "react";
import { InputBase } from "../../../../components";
import { manageMeterState } from "../atoms/atoms";

type Props = {
  type: string;
  name: string;
  placeholder: string;
  className: string;
  value: string;
};

const Input: React.FC<Props> = ({
  type,
  name,
  placeholder,
  className,
  value,
  ...props
}: Props) => {
  const [state, setState] = useRecoilState(manageMeterState);
  return (
    <InputBase
      {...props}
      type={type}
      name={name}
      placeholder={placeholder}
      state={state}
      setState={setState}
      className={className}
      value={value}
    />
  );
};

export default Input;

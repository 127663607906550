import { useState } from "react";
import format from "date-fns/format";

import { SmartcardModel } from "../../../../../domain/models/smartcard-model";
import { useFirestore } from "../../../../hooks/use-firestore";
import { useRecoilValue } from "recoil";
import { currentAccountState } from "../../../../components";

type Props = {
  transaction: SmartcardModel;
};

export default function SmartcardApplicatioDetails({ transaction }: Props) {
  const { getCurrentAccount } = useRecoilValue(currentAccountState);
  const [toggleReject, setToggleReject] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");

  const firestore = useFirestore();

  const approveSmartcardApplicationClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    await firestore.approveSmartcardApplication(transaction.id);
  };

  const rejectSmartcardApplicationClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    await firestore.rejectSmartcardApplication(transaction.id, rejectionReason);
  };

  const toggleRejectClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setToggleReject(!toggleReject);
  };

  const handleChange = (event) => {
    event.preventDefault();
    setRejectionReason(event.target.value);
  };

  // function approveSmartcardApplication(id: any) {
  //   throw new Error("Function not implemented.");
  // }

  console.log("getCurrentAccount().role", getCurrentAccount().role);
  return (
    <>
      <div className="overflow-hidden w-full">
        <div className="flex flex-col">
          <div className=" overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-1 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <section aria-labelledby="purchase-request-approval-title">
                <div className="bg-white py-4 px-6 shadow shadow-sm ">
                  <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                    <div className="lg:col-span-6">
                      <h2 className="text-md leading-6 font-medium text-gray-900">
                        Smartcard Approval
                      </h2>
                      <p className=" max-w-2xl text-sm text-gray-500">
                        Please review the following smartcard submitted for
                        approval.
                      </p>
                    </div>
                    <div className="lg:col-span-6 text-right">
                      {getCurrentAccount().role === "Administrator" &&
                      !toggleReject ? (
                        <>
                          <button
                            type="button"
                            onClick={(e) => toggleRejectClick(e)}
                            className="bg-white py-2 px-6 border border-2 border-red-500 rounded-sm shadow-sm text-sm font-medium text-red-500 disabled:opacity-50 hover:text-white hover:bg-red-500 hover:border-transparent focus:outline-none active:bg-red-700"
                          >
                            Reject
                          </button>

                          <button
                            onClick={(e) => approveSmartcardApplicationClick(e)}
                            className="ml-4 inline-flex justify-center py-2 px-6 border 
                                border-transparent rounded-sm shadow-sm disabled:opacity-50 
                                text-sm font-medium  text-white  bg-blue-600 hover:bg-blue-700  active:bg-indingo-800 focus:outline-none"
                          >
                            Approve
                          </button>
                        </>
                      ) : null}
                    </div>
                  </div>

                  <div className="border-t border-gray-200 py-4 mt-3 mb-3 ">
                    {toggleReject ? (
                      <div className="min-w-0 flex-1 lg:grid lg:grid-cols-12 lg:gap-x-5">
                        <div className="lg:col-span-6"> </div>
                        <div className="lg:col-span-6">
                          <form action="#">
                            <div>
                              <label
                                htmlFor="rejectionReason"
                                className="sr-only"
                              >
                                Rejection Reason
                              </label>
                              <textarea
                                id="rejectionReason"
                                name="rejectionReason"
                                rows={3}
                                value={rejectionReason}
                                onChange={handleChange}
                                className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-sm"
                                placeholder="Please provide the reason for not appoving the smartcard."
                                defaultValue={""}
                              />
                            </div>
                            <div className="mt-3 flex items-center justify-between">
                              <span> </span>

                              <div>
                                <>
                                  <button
                                    onClick={(e) => toggleRejectClick(e)}
                                    type="button"
                                    className="bg-white py-2 px-6 border border-2 border-red-500 rounded-sm shadow-sm text-sm font-medium text-red-500 disabled:opacity-50 hover:bg-gray-50 focus:outline-none "
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    onClick={(e) =>
                                      rejectSmartcardApplicationClick(e)
                                    }
                                    type="submit"
                                    className="ml-3 inline-flex justify-center py-2 px-6 border border-transparent rounded-sm shadow-sm disabled:opacity-50 text-sm font-medium  text-white  bg-blue-600 hover:bg-blue-700 active:bg-blue-800 focus:outline-none "
                                  >
                                    Submit
                                  </button>
                                </>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    ) : null}

                    <div className=" pb-5">
                      <h2
                        id="purchase-request-approval-title"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Smartcard Details
                      </h2>
                    </div>
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                      {transaction.company.name !== "Not Registered" ? (
                        <>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-700">
                              Company Name
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {transaction.company.name}
                            </dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-700">
                              Postal Address
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {transaction.company.postalAddress}
                            </dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-700">
                              City/Town/Village
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {transaction.company.city}
                            </dd>
                          </div>
                        </>
                      ) : null}
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-700">
                          Submitted By
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {transaction.createdBy}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-700">
                          Date of Submission
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {format(transaction.dateCreated, "yyyy-MM-dd")}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
